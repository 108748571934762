import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Space, Avatar, Card, List } from 'antd';

import { dummyUserProfile } from '../../constant';
import { getUrl, isDeviceMobile } from '../../utils';
import { getAccessToken } from '../../getAccessToken';
import { EnergyValue } from '../EnergyValue/EnergyValue';
import { SupportHistoryObject } from '../../services/apiService';

import styles from './index.module.scss';
export interface SupportHistoryListCardProps {
  data: SupportHistoryObject[] | undefined;
}
export const SupportHistoryListCard = ({
  data,
}: SupportHistoryListCardProps) => {
  const [token, setToken] = useState<string | undefined>('');

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  if (isDeviceMobile())
    return (
      <>
        <div className={styles.mobileCard}>
          <Card className={styles.totalAmountHistoryCard}>
            <div className={styles.historyDate}>April 21, 2021</div>
            <div className={styles.energyList}>
              <List
                itemLayout="horizontal"
                dataSource={data}
                className={styles.listMainContentMobile}
                renderItem={(item: SupportHistoryObject) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={
                            item.user.profileImage?.id
                              ? getUrl(item.user.profileImage.id, token)
                              : dummyUserProfile
                          }
                          size={40}
                          shape={'circle'}
                        />
                      }
                      title={
                        <div className={styles.listTitle}>
                          <span>{item.comment ? item.comment : '-'}</span>
                        </div>
                      }
                    />

                    <div>
                      <Space align="center">
                        <div className={styles.enegrySymbol}>
                          <EnergyValue energy={item.energy} />
                        </div>
                        <div className={styles.energyText}>
                          <FormattedMessage id="energyChargePlan.energy" />
                        </div>
                      </Space>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </Card>
        </div>
      </>
    );
  return (
    <>
      <div>
        <Card className={styles.totalAmountHistoryCard}>
          <div className={styles.historyDate}>April 21, 2021</div>
          <div className={styles.energyList}>
            <List
              locale={{ emptyText: 'No Invitations sent!' }}
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item: SupportHistoryObject) => (
                <List.Item>
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        src={
                          item.user.profileImage?.id
                            ? getUrl(item.user.profileImage.id, token)
                            : dummyUserProfile
                        }
                        size={40}
                        shape={'circle'}
                      />
                    }
                    title={
                      <div className={styles.listTitle}>
                        <span>{item.comment ? item.comment : '-'}</span>
                      </div>
                    }
                  />

                  <div>
                    <Space align="center">
                      <div className={styles.enegrySymbol}>
                        <EnergyValue energy={item.energy} />
                      </div>
                      <div className={styles.energyText}>
                        <FormattedMessage id="energyChargePlan.energy" />
                      </div>
                    </Space>
                  </div>
                </List.Item>
              )}
            />
          </div>
        </Card>
      </div>
    </>
  );
};
