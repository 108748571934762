/* eslint-disable react-hooks/exhaustive-deps */
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Button, notification, Popconfirm } from 'antd';
import {
  InvitationType,
  useCancelInvitationsMutation,
} from '../../services/apiService';
import { AwsDeleteUser } from './AwsInviteUser';

export const CancelInvitationButton = ({
  invitation,
}: {
  invitation: InvitationType;
}) => {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);

  const [cancelInvitation, { isError, isSuccess, isLoading }] =
    useCancelInvitationsMutation();

  const handleClick = (invitation: InvitationType) => {
    if (invitation.status === 'Pending') {
      AwsDeleteUser({
        phoneNumber: invitation.invitedToUser.phoneNumber,
        callback: () => {
          cancelInvitation(invitation.id);
        },
        setVisible,
      });
    }
  };

  useEffect(() => {
    if (isError) {
      notification.error({
        placement: 'topRight',
        message: intl.formatMessage({ id: 'notification.requestFailed' }),
      });
    }
    if (isSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.invitationCancel' }),
        placement: 'topRight',
      });
    }
  }, [isError, isSuccess]);

  return (
    <Popconfirm
      okText="Yes"
      cancelText="No"
      visible={visible}
      title="Are you sure you want to cancel invitation?"
      onCancel={() => setVisible(false)}
      okButtonProps={{ loading: isLoading }}
      onConfirm={() => handleClick(invitation)}
    >
      <Button
        type="primary"
        shape="round"
        size="large"
        onClick={() => setVisible(true)}
      >
        Cancel Invitation
      </Button>
    </Popconfirm>
  );
};
