import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Form, Button, notification } from 'antd';

import { Input } from '../../../stories/Input/Input';
import { withSettingLayout } from './withSettingLayout';
import { AccountSettingTab } from '../../../stories/AccountSettingTab/AccountSettingTab';

import { Auth } from 'aws-amplify';

const PasswordSettings = () => {
  const intl = useIntl();

  const onFinish = ({
    oldPassword,
    newPassword,
  }: {
    oldPassword: string;
    newPassword: string;
  }) => {
    return Auth.currentAuthenticatedUser().then((resp) => {
      return Auth.changePassword(resp, oldPassword, newPassword)
        .then(() => {
          notification.success({
            message: intl.formatMessage({ id: 'notification.passwordChanged' }),
            placement: 'topRight',
            duration: 5,
          });
        })
        .catch((err) => {
          notification.error({
            message: err.message,
            placement: 'topRight',
            duration: 5,
          });
        });
    });
  };

  return (
    <AccountSettingTab
      title={<FormattedMessage id="accountSetting.setLanguage.header" />}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        wrapperCol={{ span: 24 }}
      >
        <Row>
          <Col>
            <Input
              type="password"
              name="oldPassword"
              label={<FormattedMessage id="accountSetting.oldPassword" />}
              rules={[{ required: true }]}
            />
            <Input
              type="password"
              name="newPassword"
              label={<FormattedMessage id="accountSetting.newPassword" />}
              rules={[
                {
                  required: true,
                },
                {
                  pattern:
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
                  message: (
                    <FormattedMessage id="accountSetting.passwordFormatError" />
                  ),
                },
              ]}
            />
            <Input
              type="password"
              name="confirmNewPassword"
              label={
                <FormattedMessage id="accountSetting.confirmNewPassword" />
              }
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      <FormattedMessage id="accountSetting.passwordDidNotMatch" />
                    );
                  },
                }),
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="accountSetting.updatePassword" />
          </Button>
        </Row>
      </Form>
    </AccountSettingTab>
  );
};

export default withSettingLayout(PasswordSettings);
