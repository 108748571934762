/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import moment from 'moment';
import { useState } from 'react';
import { Popconfirm, Typography } from 'antd';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';

import { Avatar } from '../Avatar/Avatar';
import { Square } from '../Icons/Square/Square';
import { CommentType, MeType } from '../../services/apiService';
import { CommentInputBox } from '../CommentInputBox/CommentInputBox';

import styles from './index.module.scss';

const { Title } = Typography;

export interface CommentBoxProps {
  onLikeComment: any;
  isSeeMore: boolean;
  handleComment: any;
  comment: CommentType;
  handleEdit: any;
  handleDelete: any;
  handleSeeMore: () => void;
  user: MeType;
  isLoading: boolean;
}
export const CommentBox = ({
  user,
  isSeeMore,
  comment,
  onLikeComment,
  handleComment,
  handleSeeMore,
  handleEdit,
  handleDelete,
  isLoading,
}: CommentBoxProps) => {
  const [visible, setVisible] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [commentTxt, setCommentTxt] = useState<string | undefined>('');
  const [editItem, setEditItem] = useState<number | string | undefined>('');

  const handleReply = () => {
    setIsReply((isReply) => !isReply);
  };

  const handleConfirm = () => {
    handleDelete(comment?.id);
    setVisible(false);
  };
  const onFinish = (comment: string, itemData: CommentType | undefined) => {
    if (editItem) {
      handleEdit(comment, itemData);
      setCommentTxt('');
      setEditItem('');
      setIsReply(false);
    } else {
      handleComment(comment, itemData);
      setIsReply(false);
      setCommentTxt('');
    }
  };

  const onEditComment = (
    id: number | undefined,
    comment: string | undefined
  ) => {
    if (editItem) {
      setIsReply(false);
      setCommentTxt('');
      setEditItem('');
    } else {
      setEditItem(id);
      setIsReply(true);
      setCommentTxt(comment);
    }
  };
  return (
    <>
      <div className={styles.userDetailsContent}>
        <div className={styles.commentedUserDetailsContent}>
          <Avatar urlId={user.profileImage?.id} />
        </div>
        <div className={styles.commentUserContent}>
          <div className={styles.commentedTitleContent}>
            <div>
              <Title level={4}>{user.fullName}</Title>
              <Square />
            </div>
            <p>1 hour ago</p>
          </div>
          <p className={styles.commentDescription}>{comment.comment}</p>

          <div className={styles.commentDate}>
            <div className={styles.commentFirstContent}>
              <p className={styles.dateContent}>
                {moment(comment.createdAt).format('YYYY/MM/DD hh:mm')}
              </p>

              {comment.likes.length ? (
                <div
                  onClick={() => {
                    onLikeComment(comment.id);
                  }}
                >
                  <HeartFilled className={styles.likeRedIcon} />
                </div>
              ) : (
                <div
                  onClick={() => {
                    onLikeComment(comment.id);
                  }}
                >
                  <HeartOutlined className={styles.likeIcon} />
                </div>
              )}
            </div>
            <div className={styles.commentSecondContent}>
              <p className={styles.replyContent} onClick={handleReply}>
                Reply
              </p>
              <p
                className={styles.replyContent}
                onClick={() => {
                  onEditComment(comment.id, comment.comment);
                }}
              >
                Edit
              </p>
              <Popconfirm
                okText="Yes"
                cancelText="No"
                visible={visible}
                title="Are you sure you want to delete?"
                onCancel={() => setVisible(false)}
                onConfirm={handleConfirm}
              >
                <p
                  className={styles.replyContent}
                  onClick={() => setVisible(true)}
                >
                  Delete
                </p>
              </Popconfirm>
              {isSeeMore && (
                <p className={styles.replyContent} onClick={handleSeeMore}>
                  SeeMore
                </p>
              )}
            </div>
          </div>
        </div>
      </div>

      {isReply && (
        <div className={styles.inputBox}>
          <CommentInputBox
            onFinish={({ comment: commentText }: { comment: string }) => {
              onFinish(commentText, comment);
            }}
            initialValue={{ comment: commentTxt }}
            onChange={(e) => setCommentTxt(e.target.value)}
            disable={commentTxt?.length ? false : true}
            user={{
              id: Number(user?.id),
              fullName: user?.fullName,
              profileImage: { id: Number(user.profileImage?.id) },
            }}
            isLoading={isLoading}
          />
        </div>
      )}
    </>
  );
};
