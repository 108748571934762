/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import Title from 'antd/lib/typography/Title';
import { Breadcrumb, notification } from 'antd';

import { ActivityReportForm } from '../ActivityReportForm/ActivityReportForm';
import { useAddActivityReportMutation } from '../../../../../services/apiService';

const NewActivityReport = () => {
  const intl = useIntl();
  const history = useHistory();
  const [createActivityReport, { isError, isSuccess, isLoading }] =
    useAddActivityReportMutation();

  const onFinish = async (formData: any) => {
    const submitObj = {
      title: formData.title,
      facilityId: formData.facilityId,
      description: formData.description.toString(),
      coverImageId: formData.coverImageId.toString(),
    };

    createActivityReport(submitObj);

    if (isSuccess) {
      history.goBack();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
      notification.success({
        message: intl.formatMessage({
          id: 'notification.ActivityReportUpdate',
        }),
        placement: 'topRight',
        duration: 5,
      });
    }
    if (isError) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.FailedToUpdate' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [history, isError, isSuccess]);

  return (
    <>
      <>
        <Breadcrumb>
          <Breadcrumb.Item>Activity report</Breadcrumb.Item>
          <Breadcrumb.Item>Creating a new activity report</Breadcrumb.Item>
        </Breadcrumb>
      </>
      <Title level={3}>New activity report</Title>
      <ActivityReportForm
        onFinish={onFinish}
        actionType={'create'}
        isLoading={isLoading}
      />
    </>
  );
};

export default NewActivityReport;
