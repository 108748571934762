import { useEffect, useState } from 'react';
import { HeartOutlined } from '@ant-design/icons';
import { Card, Avatar, Divider, Typography, Popconfirm } from 'antd';

import { TagBox } from '../TagBox/TagBox';
import { Trash } from '../Icons/Trash/Trash';
import { Square } from '../Icons/Square/Square';
import { Pencil } from '../Icons/Pencil/Pencil';
import { PostType } from '../../services/apiService';
import { getAccessToken } from '../../getAccessToken';
import CommentIcon from '../Icons/CommentIcon/CommentIcon';
import { PostLikeModal } from '../PostLikeModal/PostLikeModal';
import { dummyUserProfile, enableFeature } from '../../constant';

import styles from './index.module.scss';

const { Title } = Typography;

export interface UserPostProps {
  onLikeClick?: () => void;
  item?: PostType;
  user: {
    id: number;
    fullName: string;
    profileImage: { id: number | undefined };
  };
  onCardClick?: () => void;
  onDeleteClick?: any;
  onUpdateClick?: () => void;
  onCommentClick?: () => void;
}

export const UserPost = ({
  onLikeClick,
  item,
  onCardClick,
  onDeleteClick,
  onUpdateClick,
  user,
  onCommentClick,
}: UserPostProps) => {
  const [token, setToken] = useState<string | undefined>('');
  const [visible, setVisible] = useState(false);
  const [likeVisible, setLikeVisible] = useState(false);
  const dataSource = [...Array(10)].map((_, i) => {
    return {
      id: i + 1,
      title: 'Test',
      description: 'Test',
      createdAt: 'April 20, 2021 20:00',
    };
  });
  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };
  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  const onLikeModalClick = () => {
    setLikeVisible(!likeVisible);
  };
  return (
    <Card className={styles.mainCard} key={item?.id}>
      <div>
        <div className={styles.titleContent}>
          <div className={styles.titleFirstContent} onClick={onCardClick}>
            <Avatar
              src={
                user.profileImage?.id
                  ? getUrl(user.profileImage.id)
                  : dummyUserProfile
              }
              size={40}
              shape={'circle'}
            />
            <div className={styles.titleSubContent}>
              <div>
                <p>{user?.fullName}</p>
                <Square />
              </div>
              <p>@astra001 ・ 1 hour ago</p>
            </div>
          </div>
          <div className={styles.titleSecContent}>
            <div onClick={onUpdateClick}>
              <Pencil />
            </div>
            <Popconfirm
              okText="Yes"
              cancelText="No"
              visible={visible}
              title="Are you sure you want to delete?"
              onCancel={() => setVisible(false)}
              onConfirm={() => {
                onDeleteClick();
                setVisible(false);
              }}
            >
              <div onClick={() => setVisible(true)}>
                <Trash />
              </div>
            </Popconfirm>
          </div>
        </div>
        <div className={styles.tagContent} onClick={onCardClick}>
          <div className={styles.detailContent}>
            <div className={styles.tagMain}>
              {item?.tags?.map((i: any) => (
                <TagBox name={i.name} key={i.id} />
              ))}
            </div>
            <div>
              <Title level={5}>{item?.title}</Title>
              <p className={styles.descriptionText}>{item?.description}</p>
            </div>
          </div>
          <div className={styles.image}>
            <img
              alt="city images"
              src={
                item?.coverImage?.id
                  ? getUrl(item.coverImage.id)
                  : dummyUserProfile
              }
            />
          </div>
        </div>
      </div>
      <Divider className={styles.dividerContent} />
      <div className={styles.footerContent}>
        {enableFeature.heartLikeContent && (
          <div>
            <div onClick={onLikeModalClick} className={styles.heartCommentIcon}>
              <HeartOutlined />
              <p>10</p>
            </div>
            <div onClick={onCommentClick} className={styles.heartCommentIcon}>
              <CommentIcon />
              <p>10</p>
            </div>
          </div>
        )}
        {enableFeature.heartLikeContent && (
          <div onClick={onLikeClick} className={styles.titleSubLikeContent}>
            <HeartOutlined />
          </div>
        )}
      </div>

      <PostLikeModal
        open={likeVisible}
        handleCancel={onLikeModalClick}
        dataSource={dataSource}
      />
    </Card>
  );
};
