import { Space, Statistic } from 'antd';

import { FormattedMessage } from 'react-intl';
import { EnergySymbol } from '../Icons/EnergySymbol/EnergySymbol';

import styles from './index.module.scss';

export interface PossessionProps {
  amount: number;
}

export const Possession = ({ amount }: PossessionProps) => {
  return (
    <div className={styles.possessionValue}>
      <Space>
        <span className={styles.possessionValueSymbol}>
          <EnergySymbol />
        </span>
        <Statistic
          value={amount}
          valueStyle={{
            fontSize: 32,
            marginRight: 5,
            color: '#142d24',
            fontFamily: 'Times New Roman',
          }}
        />
        <div className={styles.possessionValueLabel}>
          <FormattedMessage id="energyChargePlan.energy" />
        </div>
      </Space>
    </div>
  );
};
