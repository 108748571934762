/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Card, Form, Button, notification } from 'antd';

import { Input } from '../../../stories/Input/Input';
import { usePostInquiryMutation } from '../../../services/apiService';

import styles from './index.module.scss';

const Inquiry = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [postInquiry, { isLoading, isSuccess, isError }] =
    usePostInquiryMutation();

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'inquiry.successMessage' }),
        placement: 'topRight',
        duration: 5,
      });
      form.resetFields();
    }
  }, [isSuccess]);
  useEffect(() => {
    if (isError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.requestFailed' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isError]);

  const onFinish = ({
    email,
    name,
    subject,
    inquiryContent,
  }: {
    email: string;
    name: string;
    subject: string;
    inquiryContent: string;
  }) => {
    postInquiry({
      name: name,
      email: email,
      title: subject,
      description: inquiryContent,
    });
  };

  return (
    <Card className={styles.cardContent}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ name: '', subjet: '', email: '', inquiryContent: '' }}
      >
        <Input
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please input your name!' }]}
        />

        <Input
          label="Reply email address"
          name="email"
          rules={[
            { required: true, message: 'Please input your email address!' },
          ]}
        />

        <Input
          label="Subject"
          name="subject"
          rules={[{ required: true, message: 'Please input your subject!' }]}
        />

        <Input
          type="textarea"
          label="Contents of inquiry"
          name="inquiryContent"
          rows={7}
          rules={[
            {
              required: true,
              message: 'Please input your contents of inquiry!',
            },
          ]}
        />
        <Button type="primary" htmlType="submit" loading={isLoading}>
          <FormattedMessage id="companyProfile.Send" />
        </Button>
      </Form>
    </Card>
  );
};

export default Inquiry;
