import { Card } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';

import styles from './index.module.scss';

export const CommercialLaw = () => {
  return (
    <Card>
      <Paragraph className={styles.textCenter}>
        特定商取引法に基づく表示
      </Paragraph>
      <Paragraph>
        1. 事業者の名称
        <div>株式会社 Astra</div>
      </Paragraph>
      <Paragraph>
        2. 代表者又は通信販売に関する業務の責任者の氏名
        <div>代表取締役 佐藤 拳</div>
      </Paragraph>
      <Paragraph>
        3. 住所
        <div>〒108-0075</div>
        <div>東京都港区港南 1-9-36 アレア品川 13F</div>
      </Paragraph>
      <Paragraph>
        <div>4. お問い合わせ</div>
        <div>
          <u>astrakk.info@gmail.com</u>
        </div>
        <div>
          なお、連絡先電話番号についても、上のメールアドレス宛でご請求いただければ、遅滞なく開示
          いたします。
        </div>
      </Paragraph>
      <Paragraph>
        <div>5. 商品の販売価格・サービスの対価</div>
        <div>【エネルギー】</div>
        <div>
          当社が販売する前払式支払手段であるエネルギーの販売単位及び販売価格は、以下のとおりとし
          ます。
        </div>
        <div>・ 10 エネルギー＝100 円</div>
        <div>・ 110 エネルギー＝1,000 円</div>
        <div>・ 1,200 エネルギー＝10,000 円</div>
        <div>【アイテム】</div>
        <div>
          エネルギーの支払により購入するアイテムの販売価格は、対象となるアイテムごとに本サービス
          上で表示されます。
        </div>
      </Paragraph>
      <Paragraph>
        <div>6. 対価以外に必要となる費用</div>
        <div>
          パケット代等のスマートフォンの利用に伴う通信費用及び電気通信回線の通信料金等(インター
          ネット接続料金を含む。)がかかります。これらの料金は、お客様がご契約されている通信事業者
          等にお問い合わせください。
        </div>
      </Paragraph>
      <Paragraph>
        <div>7. 支払方法</div>
        <div>【エネルギー】</div>
        <div>クレジット決済</div>
        <div>デビット決済</div>
        <div>【アイテム】</div>
        <div>
          アイテムを購入するお客様には、当社が販売する前払式支払手段であるエネルギーにより、その
          代金のお支払いをしていただきます。
        </div>
      </Paragraph>
      <Paragraph>
        <div>8. 代金の支払時期・商品の引渡し又はサービス提供の時期</div>
        <div>【エネルギー】</div>
        <div>お支払手続完了後、直ちに提供いたします。</div>
        <div>【アイテム】</div>
        <div>お支払手続完了後、直ちに提供いたします。</div>
      </Paragraph>
      <Paragraph>
        <div>9. 返品等に関する特約</div>
        <div>
          エネルギー及びアイテムの、返品及び交換はできないものとします。
        </div>
      </Paragraph>
      <div className={styles.textRight}>以上</div>
    </Card>
  );
};
