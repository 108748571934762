import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FacilityUser {
  image: string;
  username: string;
  userId: string;
  supportAmount: number;
  supportDateTime: string;
}

interface FacilityUserData {
  facilityUsers: FacilityUser[];
}

const initialState: FacilityUserData = {
  facilityUsers: [],
};

export const facilityUserSlice = createSlice({
  name: 'facility',
  initialState,
  reducers: {
    setFacilityUserList: (
      state,
      action: PayloadAction<{ facilityUsers: FacilityUser[] }>
    ) => {
      state.facilityUsers = action.payload.facilityUsers;
    },
  },
});

export const { setFacilityUserList } = facilityUserSlice.actions;

export default facilityUserSlice.reducer;
