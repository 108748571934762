/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Col,
  Row,
  Tag,
  Space,
  Image,
  Button,
  Tabs,
  Typography,
  notification,
} from 'antd';

import {
  SupportOption,
  FacilityResponse,
  ActivityReportsResponse,
  SupportType,
} from '../../services/apiService';
import { getUrl } from '../../utils';
import { supportTypeText } from '../../constant';
import { EnergyValue } from '../EnergyValue/EnergyValue';
import { BookmarkStar } from '../Icons/BookmarkStar/BookmarkStar';
import { MobileStoryTab } from '../MobileStoryTab/MobileStoryTab';
import { MobileMissionCard } from '../MobileMissionCard/MobileMissionCard';
import { SupportCommentModal } from '../SupportCommentModal/SupportCommentModal';
import { MobileSupportAmountBox } from '../MobileSupportAmountBox/MobileSupportAmountBox';
import { MobileActivityReportTab } from '../MobileActivityReportTab/MobileActivityReportTab';
import { MobileResponsiblePersonTab } from '../MobileResponsiblePersonTab/MobileResponsiblePersonTab';

import styles from './index.module.scss';

const { TabPane } = Tabs;

export interface DonorMobileFacilityDetailModelProps {
  energy: number;
  data: SupportOption[];
  facilityData: FacilityResponse;
  activityReports?: ActivityReportsResponse;
  error?: any;
  isError?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  handleDonateEnergy: ({
    comment,
    supportUnitId,
    supportUnitBoost,
  }: {
    comment: string;
    supportUnitId: number;
    supportUnitBoost: number;
  }) => void;
  token?: string;
}

export const DonorMobileFacilityDetailModel = ({
  facilityData,
  energy,
  activityReports,
  data,
  error,
  isError,
  isSuccess,
  isLoading,
  handleDonateEnergy,
  token,
}: DonorMobileFacilityDetailModelProps) => {
  const { Text, Title } = Typography;

  let defaultActiveKey = '1';
  const intl = useIntl();
  const [modalVisible, setModalVisible] = useState(false);
  const [supportDetail, setSupportType] = useState<SupportOption>({
    id: 0,
    energy: 0,
    type: SupportType.sowSeeds,
  });

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.energyDonated' }),
        placement: 'topRight',
        duration: 5,
      });
      setSupportType({
        id: 0,
        energy: 0,
        type: SupportType.sowSeeds,
      });
      setModalVisible(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const errMsg = (error as any)?.data?.message?.donationUnitBoost[0];
      notification.error({
        message: errMsg,
        placement: 'topRight',
        duration: 10,
      });
      setSupportType({
        id: 0,
        energy: 0,
        type: SupportType.sowSeeds,
      });
      setModalVisible(false);
    }
  }, [error, isError]);

  const onSubmit = (item: SupportOption) => {
    setSupportType(item);
    setModalVisible(true);
  };

  return (
    <div className={styles.facilitiesDetail}>
      <Image
        placeholder
        width="100%"
        src={getUrl(facilityData.coverImage.id, token)}
      />
      <div className={styles.facilityArea}>
        <Button
          type="default"
          shape="round"
          size="middle"
          className={styles.bookmarkBtn}
        >
          <FormattedMessage id="donorFacilityDetail.bookmark" />
          <BookmarkStar />
        </Button>
        <div>
          <Tag className={styles.cityName}>{facilityData.area}</Tag>
          <Text type="secondary">{facilityData.streetAddress}</Text>
        </div>
      </div>
      <Row className={styles.facilityInfo}>
        <Col flex={1}>
          <Title level={2} className={styles.facilityName}>
            {facilityData.name}
          </Title>
          <div>
            <p className={styles.facilityText}>
              <FormattedMessage id="donorFacilityDetail.children" />:{' '}
              <b>{facilityData.children}</b>
            </p>
          </div>
        </Col>
      </Row>
      <div className={styles.followerContent}>
        <div>
          {facilityData.totalDonors}
          <p>
            <FormattedMessage id="donorFacilityDetail.supporter" />
          </p>
        </div>
      </div>
      <div className={styles.dividerContent} />
      <div className={styles.energyContent}>
        {/* <Space size="large">
          <Space direction="vertical">
            <Text type="secondary">
              <FormattedMessage id="donorFacilities.targetEnergy" />
            </Text>
            <EnergyValue energy={energy} />
          </Space>
        </Space> */}
        <Space size="large">
          <Space direction="vertical">
            <Text type="secondary">
              <FormattedMessage id="donorFacilityDetail.supportEnergy" />
            </Text>
            <EnergyValue energy={facilityData.totalDonatedEnergy!} />
          </Space>
        </Space>
      </div>

      <Tabs
        defaultActiveKey={defaultActiveKey}
        className={styles.facilityDetailsTab}
      >
        <TabPane tab={<FormattedMessage id="support.support" />} key="1">
          <MobileSupportAmountBox
            data={data}
            energy={energy}
            onSubmit={onSubmit}
          />
        </TabPane>
        <TabPane
          tab={<FormattedMessage id="facilityForm.Mission" />}
          key="2"
          className={styles.missionContent}
        >
          <MobileMissionCard
            mission={facilityData.mission}
            missionImage={{ id: facilityData.missionImage?.id }}
            token={token}
          />
        </TabPane>
        <TabPane
          tab={<FormattedMessage id="facilityForm.story" />}
          key="3"
          className={styles.missionContent}
        >
          <MobileStoryTab
            storyImage={{ id: facilityData.storyImage?.id }}
            story={facilityData.story}
            token={token}
          />
        </TabPane>
        <TabPane
          tab={<FormattedMessage id="facilityDetails.tabActivityReport" />}
          key="4"
        >
          {activityReports?.data.map(
            (
              { title, reportDate, coverImage, description, createdAt },
              index
            ) => (
              <MobileActivityReportTab
                key={index}
                title={title}
                token={token}
                createdAt={createdAt}
                description={description}
                coverImage={{ id: Number(coverImage?.id) }}
              />
            )
          )}
        </TabPane>
        <TabPane
          tab={
            <FormattedMessage id="facilityDetails.tabResponsiblePersonInfo" />
          }
          key="5"
          className={styles.missionContent}
        >
          <MobileResponsiblePersonTab
            representativeImage={{ id: facilityData.representativeImage.id }}
            representativeProfile={facilityData.representativeProfile}
            representativeName={facilityData.representativeName}
            token={token}
          />
        </TabPane>
      </Tabs>
      <SupportCommentModal
        open={modalVisible}
        isLoading={isLoading}
        energy={supportDetail.energy}
        supportType={supportDetail.type}
        supportTypeText={(type) => (
          <FormattedMessage id={supportTypeText(type)} />
        )}
        handleCancel={() => setModalVisible(false)}
        handleSubmit={(val) =>
          handleDonateEnergy({
            comment: val.comment,
            supportUnitId: supportDetail.id,
            supportUnitBoost: val.donationUnitBoost,
          })
        }
      />
    </div>
  );
};
