import { Row, Col, Button, Divider } from 'antd';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

interface PaymentFailedProps {
  closeModal: Function;
}

export const PaymentFailed: React.FC<PaymentFailedProps> = ({ closeModal }) => {
  return (
    <div className={styles.confirmModelMain}>
      <Row>
        <Col span={24}>
          <b>
            <FormattedMessage id="payment.errorMessage" />
          </b>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col>
          <Button onClick={() => closeModal()}>Close</Button>
        </Col>
      </Row>
    </div>
  );
};
