import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { routes } from '../../constant';
import { getUsername } from '../../utils';
import { ForgotPasswordOtpVerifyComponent } from '../../stories/ForgotPasswordOtpVerifyComponent/ForgotPasswordOtpVerifyComponent';

const ForgotPasswordOtpVerify = ({ phoneNumber }: { phoneNumber: string }) => {
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const username = getUsername(phoneNumber);

  const onFinish = ({ otp, password }: { otp: string; password: string }) => {
    setLoading(true);
    Auth.forgotPasswordSubmit(username, otp, password)
      .then((user) => {
        setLoading(false);
        notification.success({
          message: intl.formatMessage({ id: 'notification.passwordChanged' }),
          placement: 'topRight',
          duration: 5,
        });
        history.push(routes.LoginUrl);
      })
      .catch((err) => {
        setLoading(false);
        notification.error({
          message: err.message,
          placement: 'topRight',
          duration: 5,
        });
      });
  };

  const resendCode = () => {
    Auth.forgotPassword(username).then((user) => {
      notification.success({
        message: <FormattedMessage id="accountSetting.resentOtp" />,
        placement: 'topRight',
        duration: 5,
      });
    });
  };

  return (
    <ForgotPasswordOtpVerifyComponent
      loading={loading}
      onFinish={onFinish}
      resendCode={resendCode}
      phoneNumber={phoneNumber}
    />
  );
};

export default ForgotPasswordOtpVerify;
