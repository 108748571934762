import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { useIntl } from 'react-intl';
import { Row, Col, Form, Card, Button, Typography, notification } from 'antd';

import { Input } from '../../../../stories/Input/Input';

const { Title } = Typography;

interface AccountFormProps {
  setPhoneNumber: Function;
}

export const AccountForm = ({ setPhoneNumber }: AccountFormProps) => {
  const intl = useIntl();
  const onFinish = ({ phoneNumber }: { phoneNumber: string }) => {
    verifyNewUser(phoneNumber);
  };

  const verifyNewUser = async (phoneNumber: string) => {
    const {
      REACT_APP_AWS_REGION,
      REACT_APP_AWS_USER_POOL_ID,
      REACT_APP_AWS_IDENTITY_POOL_ID,
    } = process.env;
    const code = process.env.REACT_APP_PHONE_CODE;

    var params = {
      AttributesToGet: ['phone_number'],
      Filter: `phone_number = "${code}${phoneNumber}"`,
      UserPoolId: REACT_APP_AWS_USER_POOL_ID as string,
    };

    const login: { [key: string]: string } = {};
    const user = await Auth.currentAuthenticatedUser();
    const idToken = user.signInUserSession.idToken.getJwtToken();
    const awsClientId = `cognito-idp.${REACT_APP_AWS_REGION}.amazonaws.com/${REACT_APP_AWS_USER_POOL_ID}`;

    login[awsClientId] = idToken;
    AWS.config.region = process.env.REACT_APP_AWS_REGION;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      Logins: login,
      IdentityPoolId: REACT_APP_AWS_IDENTITY_POOL_ID as string,
    });

    const cognitoidentityServiceProvider =
      new AWS.CognitoIdentityServiceProvider();
    return await cognitoidentityServiceProvider.listUsers(
      params,
      function (err, data) {
        if (err) {
          notification.error({
            message: intl.formatMessage({ id: 'notification.somethingWrong' }),
            placement: 'topRight',
            duration: 15,
          });
        } else {
          if (data.Users && data.Users.length > 0) {
            notification.error({
              message: intl.formatMessage({
                id: 'notification.userPhoneNumberExist',
              }),
              placement: 'topRight',
              duration: 15,
            });
          } else {
            setPhoneNumber(phoneNumber);
            notification.success({
              message: intl.formatMessage({
                id: 'notification.phoneNumberVerified',
              }),
              placement: 'topRight',
              duration: 15,
            });
          }
        }
      }
    );
  };

  return (
    <Card>
      <Form layout="vertical" onFinish={onFinish} requiredMark={false}>
        <Row>
          <Col span={6}>
            <Title level={4}>
              {intl.formatMessage({ id: 'accountForm.title' })}
            </Title>
            <Input
              type="number"
              name="phoneNumber"
              label="Phone Number"
              placeholder="cell phone number"
              rules={[{ required: true, len: 10 }]}
            />
          </Col>
        </Row>
        <Row align="middle" justify="start">
          <Col span="6">
            <Button type="primary" htmlType="submit" size="large" block>
              {intl.formatMessage({ id: 'accountForm.btnTitle' })}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
