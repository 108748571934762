import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Divider, Typography, Breadcrumb } from 'antd';

import {
  FacilityType,
  useAddFacilityMutation,
  useSetAdminFacilityMutation,
} from '../../../../services/apiService';
import { AccountForm } from './AccountForm';
import { routes } from '../../../../constant';
import { FacilityForm } from '../FacilityForm';
import { AwsInviteUser } from '../../../InviteUser/AwsInviteUser';
import { getAccessToken } from '../../../../getAccessToken';

const { Title } = Typography;

const NewFacility = () => {
  const history = useHistory();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [token, setToken] = useState<string | undefined>('');

  const [setAdmin] = useSetAdminFacilityMutation();
  const [createFacility, { data, isLoading, isSuccess }] =
    useAddFacilityMutation();
  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  useEffect(() => {
    const onSuccess = async () => {
      await AwsInviteUser({
        phoneNumber,
        facilityId: data!.id,
        callback: () => {
          const countryCode = process.env.REACT_APP_PHONE_CODE;
          const phoneNumberWithCode = countryCode + phoneNumber;
          setAdmin({ phoneNumber: phoneNumberWithCode, facilityId: data!.id });
          history.push(routes.FacilityListUrl);
        },
      });
    };

    if (isSuccess) {
      onSuccess();
    }
  }, [data, setAdmin, history, phoneNumber, isSuccess]);

  const onFinish = async (formData: FacilityType) => {
    const data = {
      ...formData,
      children: parseInt(`${formData.children}`, 10),
    };
    createFacility(data);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>Facility</Breadcrumb.Item>
        <Breadcrumb.Item>Creating a new facility</Breadcrumb.Item>
      </Breadcrumb>
      <Title level={3}>New Facility</Title>
      <Divider />
      {!phoneNumber && <AccountForm setPhoneNumber={setPhoneNumber} />}
      {phoneNumber && (
        <FacilityForm
          token={token}
          actionType="create"
          onFinish={onFinish}
          isLoading={isLoading}
          setPhoneNumber={setPhoneNumber}
        />
      )}
    </>
  );
};

export default NewFacility;
