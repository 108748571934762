/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Spin, Button, Tabs } from 'antd';

import { isDeviceMobile } from '../../../utils';
import { Star } from '../../../stories/Icons/Star/Star';
import bannerMain from '../../../assets/images/bannerMain.jpg';
import bannerMobile from '../../../assets/images/BannerMobile.png';
import { useGetFacilitiesQuery } from '../../../services/apiService';
import { DonorFooter } from '../../../stories/DonorFooter/DonorFooter';
import astraLogoBelge from '../../../assets/images/AstraLogoBelge.png';
import { FacilityListBox } from '../../../stories/FacilityListBox/FacilityListBox';

import styles from './index.module.scss';

const { TabPane } = Tabs;

const Home = () => {
  const [paginateInfo] = useState({
    page: 1,
    limit: 100,
    search: '',
    orderDirection: 'ASC',
    orderField: 'createdAt',
  });

  const { isLoading, data: facilities } = useGetFacilitiesQuery(paginateInfo);

  if (isLoading) {
    return (
      <div>
        <Row>
          <Col span={24}>
            <img
              className={styles.bannerRight}
              src={isDeviceMobile() ? bannerMobile : bannerMain}
              width="100%"
              alt="banner"
            />
          </Col>
        </Row>
        <div className="space-align-container">
          <Spin size="large" />
        </div>
        <DonorFooter />
      </div>
    );
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <div className={styles.bannerMain}>
            <div className={styles.bannerLeft}>
              <Star />
              <img className={styles.logo} src={astraLogoBelge} alt="Astra" />
              <h3 className={styles.subTitle}>
                <FormattedMessage id="home.subTitle" />
              </h3>
              <p className={styles.desc}>
                <FormattedMessage id="home.description" />
              </p>
              <Button
                className={styles.banerBtn}
                type="default"
                htmlType="submit"
              >
                <FormattedMessage id="home.button" />
              </Button>
            </div>
            <div className={styles.bannerRight}>
              <img
                src={isDeviceMobile() ? bannerMobile : bannerMain}
                width="100%"
                alt="banner"
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row justify="center">
        <Col span={20}>
          <div className={styles.facilitiesMain}>
            <span className={styles.headingTitle}>
              <FormattedMessage id="home.headingTitle" />
            </span>
            <span className={styles.headingSubtitle}>
              <FormattedMessage id="home.headingSubtitle" />
            </span>
          </div>
          <div className={styles.tabMain}>
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane
                tab={<FormattedMessage id="home.list" />}
                key="1"
              ></TabPane>
              <TabPane
                tab={<FormattedMessage id="home.favorite" />}
                key="2"
              ></TabPane>
            </Tabs>
          </div>
          <FacilityListBox facilities={facilities} />
        </Col>
      </Row>

      <DonorFooter />
    </div>
  );
};

export default Home;
