import { FormattedMessage } from 'react-intl';
import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';

import { PlanType } from '../../services/apiService';
import { GoldPlan } from '../Icons/GoldPlan/GoldPlan';
import { BlackPlan } from '../Icons/BlackPlan/BlackPlan';
import { PlatinumPlan } from '../Icons/PlatinumPlan/PlatinumPlan';

import styles from './index.module.scss';

export interface PlanBoxProps {
  planType: PlanType;
  price: number;
  planText: string;
  active: boolean;
}

export const PlanBox = ({
  planType,
  price,
  planText,
  active,
}: PlanBoxProps) => {
  const PlanType = () => {
    switch (planType) {
      case 'Gold':
        return <GoldPlan />;
      case 'Platinum':
        return <PlatinumPlan />;
      case 'Black':
        return <BlackPlan />;
      default:
        return null;
    }
  };
  const PlanTypeText = () => {
    switch (planType) {
      case 'Gold':
        return <FormattedMessage id="Plan.Gold" />;
      case 'Platinum':
        return <FormattedMessage id="Plan.Platinum" />;
      default:
        return <FormattedMessage id="Plan.Black" />;
    }
  };

  return (
    <div
      className={`${styles.planMainContent} ${active ? styles.activePlan : ''}`}
    >
      <div className={styles.titleContent}>
        <div className={styles.iconContent}>
          <PlanType />
          <p className={styles[planType.toLowerCase()]}>
            <PlanTypeText />
          </p>
        </div>
        <div className={styles.checkIcon}>
          {active ? (
            <CheckCircleFilled className={styles.activeIcon} />
          ) : (
            <CheckCircleOutlined />
          )}
        </div>
      </div>
      <div className={styles.planDescription}>
        <p className={styles.planHeading}>{planText}</p>
        <h2 className={styles.planTitle}>
          <PlanTypeText />
          {'  '} <FormattedMessage id="donorAccountSetting.plan" />
        </h2>
        <h2 className={styles.planPrice}>
          {price}
          <p>円/月</p>
        </h2>
        <p className={styles.planSubDes}>
          <FormattedMessage id="Plan.planDescription" />
        </p>
      </div>
    </div>
  );
};
