import { Avatar, Space } from 'antd';
import { FormattedMessage } from 'react-intl';

import { getUrl } from '../../utils';

import styles from './index.module.scss';

export interface MobileResponsiblePersonTabProps {
  representativeImage: { id: number };
  representativeName: string;
  representativeProfile: string;
  token?: string;
}

export function MobileResponsiblePersonTab({
  representativeImage,
  representativeName,
  representativeProfile,
  token,
}: MobileResponsiblePersonTabProps) {
  return (
    <div className={styles.mobileContent}>
      <Space size="large" align="baseline">
        <Avatar
          size={64}
          src={
            representativeImage?.id
              ? getUrl(representativeImage?.id, token)
              : 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
          }
          className={styles.profileImage}
        />
        <p className={styles.representativeName}>{representativeName}</p>
      </Space>
      <p className={styles.profileText}>
        <FormattedMessage id="facilityForm.representativeProfile" />
      </p>
      <p>{representativeProfile}</p>
    </div>
  );
}
