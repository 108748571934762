import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Progress, Statistic, Typography, Tag } from 'antd';

import { dummyUserProfile } from '../../constant';
import { getAccessToken } from '../../getAccessToken';
import { FacilityResponse } from '../../services/apiService';
import { EnergySymbolIcon } from '../Icons/EnergySymbol/EnergySymbol.stories';

import styles from './index.module.scss';

const { Title } = Typography;
export interface SearchFacilityBoxProps {
  item?: FacilityResponse;
  onCardClick?: () => void;
}

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const SearchFacilityBox = ({
  onCardClick,
  item,
}: SearchFacilityBoxProps) => {
  const [token, setToken] = useState<string | undefined>('');

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };
  return (
    <div className={styles.supportMain} onClick={onCardClick}>
      <div className={styles.imageMain}>
        <div className={styles.image}>
          <img
            alt="city images"
            className={styles.image}
            src={
              item?.coverImage?.id
                ? getUrl(item?.coverImage?.id)
                : dummyUserProfile
            }
          />
        </div>
        <div className={styles.imageContent}>
          <Tag>{item?.area}</Tag>
          <Title level={3}>{item?.name}</Title>
          <div className={styles.memberContent}>
            <span>Number of children: </span>
            <span>{item?.children} Peoples</span>
          </div>
        </div>
      </div>
      <div className={styles.subContent}>
        <div className={styles.subCardContent}>
          <div className={styles.facilityDetail}>
            <div className={styles.targetAmount}>
              <div className={styles.totalEnergyText}>
                <FormattedMessage id="search.totalEnergy" />
              </div>
              <div className={styles.targetAmountDesc}>
                <div className={styles.targetAmountDescIcon}>
                  <EnergySymbolIcon />
                </div>
                <div className={styles.targetAmountDescPrice}>
                  <Statistic value={1000000} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.facilityDetail}>
            <div className={styles.targetAmount}>
              <div className={styles.totalEnergyText}>
                <FormattedMessage id="facilityBox.supportEnergyText" />
              </div>
              <div className={styles.targetAmountDesc}>
                <div className={styles.targetAmountDescIcon}>
                  <EnergySymbolIcon />
                </div>
                <div className={styles.targetAmountDescPrice}>
                  <Statistic value={500000} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.progress}>
          <Progress percent={30} />
        </div>
        <div className={styles.bottomContent}>
          <span>1413</span>
          <p> Supporter</p>
        </div>
      </div>
    </div>
  );
};
