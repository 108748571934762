export function PourLight(props: {
  height?: number;
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      width={120}
      height={120}
      fill="none"
      viewBox="0 0 120 120"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55 25l5-20 5 20H55zm10 70l-5 20-5-20h10zm-37.855-2.149l11.642-4.57-7.071-7.072-4.572 11.642zm54.068-61.14l11.643-4.57-4.572 11.642-7.07-7.071zm-54.068-4.568l4.571 11.642 7.071-7.07-11.642-4.572zm61.14 54.069l4.57 11.642-11.641-4.571 7.07-7.071zM5 59.995l20 5v-10l-20 5zM95 55l20 5-20 5V55zm-5 5c0 16.569-13.431 30-30 30-16.569 0-30-13.431-30-30 0-16.569 13.431-30 30-30 16.569 0 30 13.431 30 30zm-41.49-9.64c-11.25 13.405-4.58 28.792-4.58 28.792-10.577-8.875-11.956-24.644-3.081-35.22 8.875-10.578 24.644-11.957 35.22-3.082 0 0-16.31-3.897-27.56 9.51z"
        fill={props.fill}
      />
    </svg>
  );
}
