export function MenuBook() {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 1C18.89 0.65 17.67 0.5 16.5 0.5C14.55 0.5 12.45 0.9 11 2C9.55 0.9 7.45 0.5 5.5 0.5C3.55 0.5 1.45 0.9 0 2V16.65C0 16.9 0.25 17.15 0.5 17.15C0.6 17.15 0.65 17.1 0.75 17.1C2.1 16.45 4.05 16 5.5 16C7.45 16 9.55 16.4 11 17.5C12.35 16.65 14.8 16 16.5 16C18.15 16 19.85 16.3 21.25 17.05C21.35 17.1 21.4 17.1 21.5 17.1C21.75 17.1 22 16.85 22 16.6V2C21.4 1.55 20.75 1.25 20 1ZM20 14.5C18.9 14.15 17.7 14 16.5 14C14.8 14 12.35 14.65 11 15.5V4C12.35 3.15 14.8 2.5 16.5 2.5C17.7 2.5 18.9 2.65 20 3V14.5Z"
        fill="white"
      />
      <path
        d="M16.5 6.5C17.38 6.5 18.23 6.59 19 6.76V5.24C18.21 5.09 17.36 5 16.5 5C14.8 5 13.26 5.29 12 5.83V7.49C13.13 6.85 14.7 6.5 16.5 6.5Z"
        fill="white"
      />
      <path
        d="M12 8.49003V10.15C13.13 9.51003 14.7 9.16003 16.5 9.16003C17.38 9.16003 18.23 9.25003 19 9.42003V7.90003C18.21 7.75003 17.36 7.66003 16.5 7.66003C14.8 7.66003 13.26 7.96003 12 8.49003Z"
        fill="white"
      />
      <path
        d="M16.5 10.33C14.8 10.33 13.26 10.62 12 11.16V12.82C13.13 12.18 14.7 11.83 16.5 11.83C17.38 11.83 18.23 11.92 19 12.09V10.57C18.21 10.41 17.36 10.33 16.5 10.33Z"
        fill="white"
      />
    </svg>
  );
}
