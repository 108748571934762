export function PlatinumPlan() {
  return (
    <svg width={40} height={40} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.453 12.713 4.67 9.18c2.452-2.504 9.72-7.512 15.606-7.512 5.886 0 13.155 5.008 15.607 7.512l-1.784 3.535 3.122 3.976c-.149 2.136-.848 7.114-2.453 9.942-3.21 7.423-11.296 11.194-14.492 12.151-3.196-.957-11.281-4.728-14.491-12.15-1.606-2.829-2.304-7.807-2.453-9.943l3.121-3.977Z"
        fill="#536C56"
      />
      <path
        d="m35.881 9.179-1.784 3.534 3.122 3.977c-.149 2.136-.848 7.114-2.453 9.942-3.21 7.424-11.296 11.194-14.492 12.151V1.667c5.886 0 13.154 5.008 15.607 7.512Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m9.921 13-1.443-2.86c1.985-2.028 7.033-5.245 11.797-5.245 4.765 0 9.813 3.217 11.798 5.244l-1.444 2.862 3.363 4.055c-.12 1.73-.686 5.76-1.985 8.048-2.6 6.01-9.145 9.062-11.732 9.837-2.587-.775-9.132-3.827-11.731-9.837-1.3-2.289-1.865-6.319-1.985-8.048l3.362-4.055Z"
        fill="url(#a)"
      />
      <path
        d="M28.893 27.281H24.65l-5.38-7.43c-.398.016-.722.024-.972.024h-.328a88.095 88.095 0 0 0-.363-.023v4.617c0 1 .11 1.62.328 1.863.297.344.742.516 1.336.516h.62v.433h-6.808v-.433h.598c.672 0 1.152-.22 1.441-.657.164-.242.246-.816.246-1.722V14.203c0-1-.109-1.621-.328-1.863-.304-.344-.758-.516-1.36-.516h-.597v-.433h5.79c1.687 0 2.929.125 3.726.375a4.179 4.179 0 0 1 2.039 1.359c.562.656.844 1.441.844 2.355 0 .977-.32 1.825-.961 2.543-.633.72-1.617 1.227-2.953 1.524l3.28 4.558c.75 1.047 1.395 1.743 1.934 2.086.54.344 1.243.563 2.11.657v.433Zm-11.285-8.168c.148 0 .277.004.386.012h.27c1.516 0 2.656-.328 3.422-.984.773-.657 1.16-1.493 1.16-2.508 0-.992-.313-1.797-.938-2.414-.617-.625-1.437-.938-2.46-.938-.454 0-1.067.074-1.84.223v6.61Z"
        fill="#fff"
      />
      <g clipPath="url(#b)">
        <path
          d="m12.685 12.774 1.542-.202-1.542-.203.636-.829-.829.636-.203-1.542-.203 1.543-.828-.637.636.829-1.542.203 1.542.202-.636.829.829-.636.203 1.542.202-1.542.829.636-.636-.829Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#c)">
        <path
          d="m9.26 24.501 1.852-.243-1.851-.243.763-.995-.994.764-.244-1.851-.243 1.85-.994-.763.763.995-1.851.243 1.851.243-.763.994.994-.763.243 1.851.244-1.85.994.762-.763-.994Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#d)">
        <path
          d="m19.15 29.833.926-.121-.926-.122.381-.497-.497.381-.121-.925-.122.925-.497-.381.382.497-.926.122.926.121-.382.497.497-.381.122.925.121-.925.497.381-.381-.497Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#e)">
        <path
          d="m28.888 25.939.926-.122-.926-.122.382-.497-.497.382-.122-.926-.122.926-.497-.382.382.497-.926.122.926.122-.382.497.497-.382.122.926.122-.926.497.382-.382-.497Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#f)">
        <path
          d="m27.721 10.947 2.469-.324-2.469-.324 1.018-1.326-1.326 1.017-.324-2.468-.325 2.469-1.325-1.018 1.018 1.326-2.469.324 2.469.324-1.018 1.326 1.326-1.018.324 2.468.324-2.468 1.326 1.018-1.018-1.326Z"
          fill="#F7F6F0"
        />
      </g>
      <path
        d="m36.33 8.69-1.826 3.62 3.195 4.071c-.152 2.186-.867 7.283-2.51 10.178C31.901 34.16 23.622 38.02 20.351 39V1c6.026 0 13.467 5.127 15.978 7.69Z"
        fill="#536C56"
      />
      <path
        d="m4.37 8.69 1.826 3.62L3 16.38c.152 2.186.867 7.283 2.51 10.178C8.799 34.16 17.077 38.02 20.349 39V1C14.322 1 6.88 6.127 4.37 8.69Z"
        fill="#C1C2CE"
      />
      <path
        d="M36.326 8.69 34.5 12.31l3.195 4.071c-.152 2.186-.867 7.283-2.51 10.178C31.896 34.16 23.618 38.02 20.346 39V1c6.027 0 13.468 5.127 15.979 7.69Z"
        fill="#A8A9B8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m30.951 12.604 1.478-2.93c-2.032-2.075-7.2-5.37-12.078-5.37v30.762c2.648-.793 9.35-3.918 12.01-10.07 1.33-2.344 1.91-6.47 2.033-8.24l-3.443-4.152Zm-21.203 0-1.479-2.93c2.033-2.074 7.2-5.369 12.08-5.369v30.762c-2.65-.794-9.35-3.919-12.012-10.07-1.33-2.345-1.91-6.47-2.032-8.24l3.443-4.153Z"
        fill="url(#g)"
      />
      <path
        d="M19.004 20.21v4.63c0 1 .11 1.62.328 1.863.297.344.746.516 1.348.516h.609v.433H14.48v-.433h.598c.672 0 1.152-.219 1.441-.657.157-.242.235-.816.235-1.722V14.574c0-1-.106-1.62-.317-1.863-.304-.344-.758-.516-1.359-.516h-.598v-.433h5.825c1.421 0 2.543.148 3.363.445.82.29 1.512.781 2.074 1.477.563.695.844 1.52.844 2.472 0 1.297-.43 2.352-1.29 3.164-.85.813-2.058 1.22-3.62 1.22-.383 0-.797-.028-1.243-.083a16.186 16.186 0 0 1-1.43-.246Zm0-.667c.414.078.781.137 1.101.176.32.039.594.058.82.058.813 0 1.512-.312 2.098-.937.594-.633.89-1.45.89-2.45 0-.687-.14-1.324-.421-1.91-.281-.593-.68-1.035-1.195-1.324-.516-.297-1.102-.445-1.758-.445-.399 0-.91.074-1.535.223v6.609Z"
        fill="#fff"
      />
      <g clipPath="url(#h)">
        <path
          d="m12.58 12.374 1.58-.207-1.58-.208.651-.848-.848.65-.208-1.578-.207 1.58-.848-.652.65.848-1.579.208 1.58.207-.651.848.848-.65.207 1.578.208-1.579.848.651-.651-.848Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#i)">
        <path
          d="m9.07 24.38 1.895-.25-1.896-.248.782-1.018-1.018.781-.249-1.895-.25 1.895-1.017-.781.782 1.018-1.896.249 1.896.249-.782 1.018 1.018-.782.249 1.895.249-1.895 1.018.782-.782-1.018Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#j)">
        <path
          d="m19.191 29.837.948-.124-.948-.125.39-.509-.508.391-.125-.947-.124.947-.51-.39.392.509-.948.124.948.124-.391.51.509-.391.124.947.125-.947.509.39-.391-.509Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#k)">
        <path
          d="m29.168 25.851.947-.124-.947-.125.39-.509-.509.39-.124-.947-.125.948-.509-.391.391.51-.948.123.948.125-.39.509.508-.39.125.947.124-.948.51.39-.392-.508Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#l)">
        <path
          d="m27.982 10.505 2.527-.332-2.527-.331 1.042-1.358-1.358 1.042-.331-2.527-.333 2.527-1.356-1.042 1.041 1.358-2.527.332 2.527.331-1.041 1.357 1.357-1.041.332 2.526.331-2.526 1.358 1.041-1.042-1.357Z"
          fill="#F7F6F0"
        />
      </g>
      <defs>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="translate(10.344 10.626)"
            d="M0 0h3.895v3.895H0z"
          />
        </clipPath>
        <clipPath id="c">
          <path
            fill="#fff"
            transform="translate(6.45 21.923)"
            d="M0 0h4.674v4.674H0z"
          />
        </clipPath>
        <clipPath id="d">
          <path
            fill="#fff"
            transform="translate(17.746 28.544)"
            d="M0 0h2.337v2.337H0z"
          />
        </clipPath>
        <clipPath id="e">
          <path
            fill="#fff"
            transform="translate(27.484 24.65)"
            d="M0 0h2.337v2.337H0z"
          />
        </clipPath>
        <clipPath id="f">
          <path
            fill="#fff"
            transform="translate(23.977 7.51)"
            d="M0 0h6.232v6.232H0z"
          />
        </clipPath>
        <clipPath id="h">
          <path
            fill="#fff"
            transform="translate(10.18 10.172)"
            d="M0 0h3.988v3.988H0z"
          />
        </clipPath>
        <clipPath id="i">
          <path
            fill="#fff"
            transform="translate(6.191 21.737)"
            d="M0 0h4.786v4.785H0z"
          />
        </clipPath>
        <clipPath id="j">
          <path
            fill="#fff"
            transform="translate(17.754 28.517)"
            d="M0 0h2.393v2.393H0z"
          />
        </clipPath>
        <clipPath id="k">
          <path
            fill="#fff"
            transform="translate(27.727 24.53)"
            d="M0 0h2.393v2.393H0z"
          />
        </clipPath>
        <clipPath id="l">
          <path
            fill="#fff"
            transform="translate(24.14 6.982)"
            d="M0 0h6.381v6.38H0z"
          />
        </clipPath>
        <radialGradient
          id="a"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 14.9273 -6.81458 0 20.275 20.225)"
        >
          <stop stopColor="#344640" />
          <stop offset={1} stopColor="#142D24" />
        </radialGradient>
        <radialGradient
          id="g"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 15.3811 -14.0447 0 20.35 19.686)"
        >
          <stop stopColor="#AFB0BC" />
          <stop offset={1} stopColor="#8D8E99" />
        </radialGradient>
      </defs>
    </svg>
  );
}
