import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Form, Button } from 'antd';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { Input } from '../../stories/Input/Input';

import styles from './index.module.scss';

interface UserProfileFormProps {
  isLoading: boolean;
  onFinish: (values: any) => void;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export const UserProfileForm = ({
  error,
  onFinish,
  isLoading,
}: UserProfileFormProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (error) {
      const { message } = (error as any).data;
      const fields = Object.keys(message).map((fieldName) => {
        return { name: fieldName, errors: message[fieldName] };
      });
      form.setFields(fields);
    }
  }, [form, error]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
      wrapperCol={{ span: 24 }}
      className={styles.form}
    >
      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
          <Input
            name="email"
            type="email"
            label={<FormattedMessage id="userProfile.email" />}
            placeholder=""
            rules={[{ type: 'email', required: true }]}
          />
        </Col>
        <Col className="gutter-row" xs={24} sm={12} md={12} lg={12} xl={12}>
          <Input
            name="fullName"
            type="string"
            label={<FormattedMessage id="userProfile.fullName" />}
            placeholder=""
            rules={[{ required: true }]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" xs={12} sm={8} md={8} lg={8} xl={8}>
          <Input
            name="dob"
            type="date"
            placeholder=""
            label={<FormattedMessage id="userProfile.dob" />}
            rules={[{ required: true }]}
          />
        </Col>
        <Col className="gutter-row" xs={12} sm={8} md={8} lg={8} xl={8}>
          <Input
            name="gender"
            type="select"
            label={<FormattedMessage id="userProfile.gender" />}
            placeholder=""
            rules={[{ required: true }]}
            options={{ Male: 'Male', Female: 'Female' }}
          />
        </Col>
        <Col className="gutter-row" xs={24} sm={8} md={8} lg={8} xl={8}>
          <Input
            type="string"
            placeholder=""
            name="annualIncome"
            label={<FormattedMessage id="userProfile.annualIncome" />}
            rules={[{ required: true }]}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col className="gutter-row" xs={24} sm={16} md={16} lg={16} xl={16}>
          <Input
            type="string"
            placeholder=""
            name="streetAddress"
            label={<FormattedMessage id="userProfile.streetAddress" />}
            rules={[{ required: true }]}
          />
        </Col>
        <Col className="gutter-row" xs={24} sm={8} md={8} lg={8} xl={8}>
          <Input
            type="string"
            name="postalCode"
            label={<FormattedMessage id="userProfile.postalCode" />}
            placeholder=""
            rules={[{ required: true }]}
          />
        </Col>
      </Row>
      <Row align="middle" justify="center">
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          size="large"
          block
        >
          <FormattedMessage id="userProfile.startOf" />
        </Button>
      </Row>
    </Form>
  );
};
