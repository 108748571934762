import { Image } from 'antd';

import { getUrl } from '../../utils';

export interface MobileStoryTabProps {
  storyImage: { id: number };
  story: string;
  token?: string;
}

export const MobileStoryTab = ({
  storyImage,
  story,
  token,
}: MobileStoryTabProps) => {
  return (
    <div>
      <Image
        width={'100%'}
        src={
          storyImage?.id
            ? getUrl(storyImage.id, token)
            : 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        }
      />
      <p>{story}</p>
    </div>
  );
};
