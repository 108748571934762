import { useState, useEffect } from 'react';

import { getUrl } from './utils';
import { getAccessToken } from './getAccessToken';

export const useGetUrlById = (id: number | undefined) => {
  const [token, setToken] = useState<string | undefined>('');
  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  return getUrl(id, token);
};
