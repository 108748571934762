import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from './store';

const useGaTracker = () => {
  const location = useLocation();
  const { id } = useSelector((state: RootState) => state.me);

  useEffect(() => {
    const gaMeasurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
    ReactGA.initialize(gaMeasurementId!, {
      gaOptions: { userId: `${id}` },
    });
  }, [id]);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);
};

export default useGaTracker;
