import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface CognitoDetailType {
  role: string;
  email: string;
  facilityId: string;
  phoneNumber: string;
  emailVerified: boolean;
  phoneNumberVerified: boolean;
}

const initialState: {
  isFetching: boolean;
  authenticated: boolean;
  cognitoUser: CognitoDetailType;
} = {
  isFetching: true,
  authenticated: false,
  cognitoUser: {
    email: '',
    role: '',
    facilityId: '',
    phoneNumber: '',
    emailVerified: false,
    phoneNumberVerified: false,
  },
};

export const cognitoUserDetail = createSlice({
  name: 'cognitoUserDetail',
  initialState,
  reducers: {
    setCognitoUserDetail: (
      state,
      action: PayloadAction<{ data: CognitoDetailType }>
    ) => {
      return {
        isFetching: false,
        cognitoUser: { ...action.payload.data },
        authenticated: !!action.payload.data.phoneNumberVerified,
      };
    },
  },
});

export const { setCognitoUserDetail } = cognitoUserDetail.actions;

export default cognitoUserDetail.reducer;
