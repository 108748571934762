import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Form, Button, notification } from 'antd';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { routes } from '../../constant';
import { Input } from '../../stories/Input/Input';
import { CardBox } from '../../stories/CardBox/CardBox';

export const ForceChangePassword = ({
  cognitoUser,
}: {
  cognitoUser: CognitoUser;
}) => {
  const intl = useIntl();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const onFinish = ({ newPassword }: { newPassword: string }) => {
    setLoading(true);
    Auth.completeNewPassword(cognitoUser, newPassword).then((user) => {
      notification.success({
        message: intl.formatMessage({ id: 'notification.passwordChanged' }),
        placement: 'topRight',
        duration: 5,
      });
      setLoading(false);
      Auth.signOut();
      history.push(routes.UserProfileUrl);
    });
  };

  return (
    <CardBox title="Change Password">
      <Form
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        wrapperCol={{ span: 24 }}
        style={{ width: '100%' }}
      >
        <Input
          type="password"
          name="newPassword"
          label={<FormattedMessage id="forceChangePassword.newPassword" />}
          rules={[
            {
              required: true,
            },
            {
              pattern:
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
              message:
                'Must have atleast 6 character with one lowercase, uppercase, number and special character',
            },
          ]}
        />
        <Button
          block
          size="large"
          type="primary"
          htmlType="submit"
          loading={loading}
        >
          <FormattedMessage id="forceChangePassword.updatePassword" />
        </Button>
      </Form>
    </CardBox>
  );
};
