interface PixelCrop {
  x: number;
  y: number;
  width: number;
  height: number;
}

export async function getCroppedImage(
  dataUri: string,
  pixelCrop: PixelCrop,
  heightWidth: { width: number; height: number }
) {
  var image = new Image();
  image.src = dataUri;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  canvas.width = heightWidth.width;
  canvas.height = heightWidth.height;

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    heightWidth.width,
    heightWidth.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/jpeg');
  });
}
