export function Location() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.67501 14.6103L8.67439 14.6111C8.52195 14.82 8.27721 14.9442 8.01775 14.9442C7.75802 14.9442 7.51376 14.8204 7.3596 14.6102C6.57139 13.5347 5.48645 11.9781 4.60075 10.4284C4.15778 9.65336 3.76993 8.88915 3.49405 8.19399C3.21472 7.49011 3.06641 6.8965 3.06641 6.45053C3.06641 3.71668 5.28299 1.5 8.01775 1.5C10.7521 1.5 12.9687 3.71666 12.9687 6.45053C12.9687 6.89649 12.8203 7.49009 12.541 8.19397C12.2651 8.88913 11.8772 9.65334 11.4341 10.4284C10.5483 11.9781 9.46323 13.5348 8.67501 14.6103Z"
        stroke="#666666"
      />
      <path
        d="M6.55566 6.61047C6.55566 5.8046 7.21183 5.14844 8.01769 5.14844C8.82329 5.14844 9.47972 5.80433 9.47972 6.61047C9.47972 7.4158 8.82329 8.07209 8.01769 8.07209C7.21183 8.07209 6.55566 7.41553 6.55566 6.61047Z"
        stroke="#666666"
      />
    </svg>
  );
}
