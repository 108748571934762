/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { difference, find, uniq } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Form, Modal, Button, notification, Select } from 'antd';

import {
  TagsType,
  tagObjectType,
  useGetTagsQuery,
  useAddTagsMutation,
  useSavePostMutation,
  useUpdatePostMutation,
  useUpdateImageMutation,
  useCreateImageMutation,
} from '../../services/apiService';
import { RootState } from '../../store';
import { Input } from '../Input/Input';
import { getAccessToken } from '../../getAccessToken';
import { ImageUploader } from '../ImageUploader/ImageUploader';

import styles from './index.module.scss';

export interface ProfileSettingProps {
  open: boolean;
  handleCancel: () => void;
}
const { Option } = Select;
export function AddPost({ open, handleCancel }: ProfileSettingProps) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [tagArr, setTagArr] = useState<any>([]);
  const [diffArr, setDiffArr] = useState<any>([]);
  const [coverImageId, setCoverImageId] = useState<number>(0);
  const [counter, setCounter] = useState<number>(0);
  const [token, setToken] = useState<string | undefined>('');
  const me = useSelector((state: RootState) => state.me);
  const { postDetails } = useSelector((state: RootState) => state.postList);

  const { data: tagData } = useGetTagsQuery({});
  const [savePost, { data, isLoading, isSuccess, error }] =
    useSavePostMutation();
  const [
    addTag,
    {
      data: tagResponse,
      isLoading: tagLoading,
      isSuccess: tagSuccess,
      error: tagError,
    },
  ] = useAddTagsMutation();
  const [
    updatePost,
    { isLoading: isUpdate, isSuccess: isUpdateSuccess, error: isError },
  ] = useUpdatePostMutation();
  const [postImage, { isSuccess: imagePostSuccess, error: imagePostError }] =
    useCreateImageMutation();
  const [patchImage, { isSuccess: imagePatchSuccess, error: imagePatchError }] =
    useUpdateImageMutation();

  useEffect(() => {
    setCoverImageId(Number(postDetails?.coverImage.id));
  }, [postDetails]);

  const onClose = () => {
    form.resetFields();
    handleCancel();
    setTagArr([]);
    setCoverImageId(0);
    setCounter(counter + 1);
  };

  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  useEffect(() => {
    if (isSuccess || isUpdateSuccess) {
      onClose();
    }
    if (error || isError) {
      notification.error({
        message: error || isError,
        placement: 'topRight',
        duration: 5,
      });
    }
    if (tagSuccess) {
      setDiffArr([]);
      let arr = tagArr;
      arr[arr.length - 1] = {
        key: JSON.stringify(tagResponse?.id),
        value: tagResponse?.name,
        children: tagResponse?.name,
      };
      setTagArr(arr);
    }
  }, [isSuccess, error, isUpdateSuccess, isError, tagSuccess, tagError]);

  useEffect(() => {
    if (imagePatchSuccess || imagePostSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.photoUploadSuccess' }),
        placement: 'topRight',
        duration: 5,
      });
    }
    if (imagePatchError || imagePostError) {
      notification.error({
        message: imagePatchError || imagePostError,
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [imagePatchError, imagePatchSuccess, imagePostError, imagePostSuccess]);
  const uploadImage = async (
    imageUri: string,
    subType: string,
    imageId: number | undefined
  ) => {
    const data = new FormData();
    data.append('file', imageUri);
    data.append('subType', subType);
    data.append('imageableType', 'Post');

    if (imageId) await patchImage({ id: imageId!, imageUri: data });
    else {
      postImage(data).then((d: any) => {
        const responseImageId = d.data;
        setCoverImageId(responseImageId);
        form.setFieldsValue({ profileImageId: responseImageId });
      });
    }
  };
  const onFinish = async ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => {
    if (postDetails) {
      await updatePost({
        id: postDetails?.id,
        body: {
          title: title,
          description: description,
          tagIds: tagArr.map(
            (i: { key: string; value: string; children: string }) => i.key
          ),
        },
      });
      setTagArr([]);
    } else {
      await savePost({
        title: title,
        description: description,
        coverImageId: coverImageId,
        tagIds: tagArr.map(
          (i: { key: string; value: string; children: string }) => i.key
        ),
      });
      setTagArr([]);
    }
  };

  function handleChange(value: any, option: any) {
    let arr = tagData ? uniq(tagData.map((i: any) => i.name)) : [];
    setDiffArr(difference(value, arr));
    setTagArr(option);
  }

  useEffect(() => {
    form.setFieldsValue({
      title: postDetails?.title,
      description: postDetails?.description,
    });
    if (postDetails?.tags?.length) {
      let arr = postDetails?.tags?.map((i: tagObjectType) => i.name);
      setTagArr(arr);
      setCounter(counter + 1);
    }
  }, [postDetails]);

  return (
    <Modal
      centered
      footer={null}
      visible={open}
      onCancel={onClose}
      title={'Add Post'}
    >
      <Row
        align="middle"
        justify="center"
        className={styles.imageUploadContent}
      >
        <ImageUploader
          boxShape="rect"
          name="coverImageId"
          label={<FormattedMessage id="facilityForm.uploadCoverPhoto" />}
          imageUrl={coverImageId ? getUrl(coverImageId) : ''}
          upload={(imgUri: string) =>
            uploadImage(imgUri, 'coverImage', coverImageId)
          }
          required
        />
      </Row>
      <Form
        form={form}
        onFinish={onFinish}
        className={styles.form}
        wrapperCol={{ span: 24 }}
      >
        <Row>
          <Col span={24}>
            <Input
              type="text"
              name="title"
              label={'Title'}
              rules={[{ required: true }]}
            />
            <Input
              rows={5}
              showCount
              name="description"
              label="Description"
              type="textarea"
              maxLength={300}
              rules={[{ required: true }]}
            />
            <div className={styles.tagMain}>
              <label>Tags :</label>
              <Select
                mode="tags"
                style={{ width: '74%' }}
                onChange={handleChange}
                filterOption={true}
                key={counter}
                defaultValue={tagArr}
                onSelect={(LabeledValue: string) => {
                  if (
                    Boolean(
                      find(tagData, function (n) {
                        if (n.name !== LabeledValue) {
                          return true;
                        } else return false;
                      })
                    )
                  ) {
                    addTag({ name: LabeledValue });
                  }
                }}
              >
                {tagData?.map((i: TagsType, index: number) => (
                  <Option key={i?.id} value={i?.name}>
                    {i?.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Col>
        </Row>
        <Row justify="end" gutter={16}>
          <Col>
            <Button type="ghost" onClick={onClose}>
              <FormattedMessage id="supportCommentModel.cancel" />
            </Button>
          </Col>
          <Col>
            <Button
              htmlType="submit"
              type="primary"
              loading={isLoading || isUpdate}
            >
              KEEP
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
