import { Auth } from 'aws-amplify';
import { notification } from 'antd';

export const updateEmailOnCognito = (email: string, intl: any) => {
  try {
    return Auth.currentAuthenticatedUser().then((resp) => {
      return Auth.updateUserAttributes(resp, {
        email: email,
      })
        .then(() => {
          notification.success({
            message: `${intl.formatMessage({
              id: 'notification.emailUpdatedOtpSend',
            })} ${email}!!`,
            placement: 'topRight',
            duration: 5,
          });
        })
        .catch(() => {
          notification.error({
            message: intl.formatMessage({ id: 'notification.requestFailed' }),
            placement: 'topRight',
            duration: 5,
          });
        });
    });
  } catch (e: any) {
    console.log(e.message);
  }
};

export const sendOtp = (email: string, intl: any) => {
  try {
    return Auth.currentAuthenticatedUser().then((user) => {
      Auth.verifyUserAttribute(user, 'email')
        .then((res) => {
          notification.success({
            message: `${intl.formatMessage({
              id: 'notification.otpSentSuccess',
            })} ${email}!!`,
            placement: 'topRight',
            duration: 5,
          });
        })
        .catch((error) => {
          console.error(error);
          notification.error({
            message: intl.formatMessage({
              id: 'notification.requestFailedtoSendOtp',
            }),
            placement: 'topRight',
            duration: 5,
          });
        });
    });
  } catch (e: any) {
    console.log(e.message);
  }
};

export const otpVerification = (email: string, otp: string) => {
  return Auth.currentAuthenticatedUser().then((user) => {
    return Auth.verifyUserAttributeSubmit(user, 'email', otp);
  });
};
