import { Row, Col, Button, Divider, Typography } from 'antd';

import { paymentMethods } from './PaymentType';
import { EnergyChargePlanType } from './Payment';
import { FormattedMessage } from 'react-intl';

const { Title } = Typography;

interface CreditCardProps {
  buyBoost: number;
  paymentType: number;
  closeModal: () => void;
  handlePayment: () => void;
  donateApiLoading: boolean;
  handleChangeMethod: () => void;
  setStep: (arg: number) => void;
  selectedPlan: EnergyChargePlanType;
}

export const PaymentConfirmation = ({
  setStep,
  buyBoost,
  closeModal,
  paymentType,
  selectedPlan,
  handlePayment,
  donateApiLoading,
  handleChangeMethod,
}: CreditCardProps) => {
  const selectPaymentType = paymentMethods.find(
    (paymentMethod) => paymentMethod.id === paymentType
  );

  return (
    <div>
      <Row>
        <Col span={24}>
          <div>
            <FormattedMessage id="paymentConfirmation.purchasePrice" />
          </div>
          <Title level={4}> {selectedPlan.amount * buyBoost}</Title>
        </Col>
      </Row>
      <div>
        {/* <div>Charge amount</div> */}
        <Row justify={'space-between'}>
          <Col>
            <Title level={4}>{selectPaymentType!.title}</Title>
          </Col>
          <Col>
            <Button onClick={handleChangeMethod}>
              <FormattedMessage id="paymentConfirmation.change" />
            </Button>
          </Col>
        </Row>
      </div>
      <Divider />
      <Row justify="space-between">
        <Col>
          <Button onClick={closeModal}>
            <FormattedMessage id="paymentConfirmation.cancel" />
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={handlePayment}
            loading={donateApiLoading}
          >
            <FormattedMessage id="paymentConfirmation.settlement" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};
