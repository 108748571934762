/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import { Col, Row, Card, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useGetFacilityByIdQuery,
  useDonateEnergyMutation,
  useGetActivityReportsQuery,
  useGetDonationUnitsQuery,
  useGetFacilitiesSupporterCommentsQuery,
} from '../../../../services/apiService';
import { RootState } from '../../../../store';
import { getAccessToken } from '../../../../getAccessToken';
import {
  SupporterCommentOption,
  SupporterComments,
} from '../../../../stories/SupporterComments/SupporterComments';
import { useWindowDimensions } from '../../../../useIsDeviceMobile';
import { DonorFooter } from '../../../../stories/DonorFooter/DonorFooter';
import { SupportAmountBox } from '../../../../stories/SupportAmountBox/SupportAmountBox';
import { DonorFacilityDetailModel } from '../../../../stories/DonorFacilityDetailModel/DonorFacilityDetailModel';
import { DonorFacilityOtherInfoCard } from '../../../../stories/DonorFacilityOtherInfoCard/DonorFacilityOtherInfoCard';
import { DonorMobileFacilityDetailModel } from '../../../../stories/DonorMobileFacilityDetailModel/DonorMobileFacilityDetailModel';

import styles from './index.module.scss';

export interface Facility {
  id: number;
  area: string;
  name: string;
  story: string;
  mission: string;
  children: number;
  coverImage: object;
  storyImage: object;
  missionImage: object;
  streetAddress: string;
  representativeName: string;
  representativeImage: object;
  representativeProfile: string;
}

const DonorFacilityDetail = () => {
  const isDeviceMobile = useWindowDimensions();
  const { id } = useParams<{ id: string }>();
  const { data } = useGetFacilityByIdQuery(id);
  const user = useSelector((state: RootState) => state.me);
  const [token, setToken] = useState<string | undefined>('');
  const { data: supportData, isLoading: supportDataLoading } =
    useGetDonationUnitsQuery({});
  const paginateInfo = {
    id: id,
    page: 1,
    limit: 10,
    orderDirection: 'DESC',
    orderField: 'createdAt',
  };
  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  const [donateEnergy, { error, isError, isLoading, isSuccess }] =
    useDonateEnergyMutation();
  const { data: supporterComments, isLoading: supporterCommentsLoading } =
    useGetFacilitiesSupporterCommentsQuery(paginateInfo);

  const { data: activityReports } = useGetActivityReportsQuery({
    page: 1,
    limit: 10,
    facilityId: id,
  });

  const handleDonateEnergy = ({
    comment,
    supportUnitId,
    supportUnitBoost,
  }: {
    comment: string;
    supportUnitId: number;
    supportUnitBoost: number;
  }) => {
    donateEnergy({
      comment,
      supportUnitId,
      facilityId: id!,
      supportUnitBoost: Number(supportUnitBoost),
    });
  };

  if (supportDataLoading || !supportData) {
    return (
      <div className={styles.spinnerContent}>
        <Spin />
      </div>
    );
  }

  if (isDeviceMobile && data)
    return (
      <>
        <DonorMobileFacilityDetailModel
          token={token}
          error={error}
          isError={isError}
          data={supportData}
          facilityData={data}
          energy={user.energy}
          isSuccess={isSuccess}
          isLoading={isLoading}
          activityReports={activityReports}
          handleDonateEnergy={handleDonateEnergy}
        />
        <DonorFooter />
      </>
    );

  return (
    <div className={styles.FacilitiesDetailMain}>
      <Row justify="center" gutter={15}>
        <Col sm={24} xs={24} md={23} lg={13} xl={12} xxl={10}>
          {data && (
            <DonorFacilityDetailModel
              facilityData={data}
              activityReports={activityReports}
            />
          )}
        </Col>

        <Col sm={24} md={23} lg={9} xl={8} xxl={6}>
          <SupportAmountBox
            error={error}
            isError={isError}
            data={supportData}
            isSuccess={isSuccess}
            isLoading={isLoading}
            handleDonateEnergy={handleDonateEnergy}
          />
          {supporterComments && supporterComments.length > 0 ? (
            <Card>
              <div className={styles.CommentCardMain}>
                {supporterComments?.map(
                  (data: SupporterCommentOption, index: number) => (
                    <SupporterComments
                      comment={data.comment}
                      supportUnit={data.supportUnit}
                      user={data.user}
                      energy={data.energy}
                      key={index}
                    />
                  )
                )}
              </div>
            </Card>
          ) : null}

          <DonorFacilityOtherInfoCard {...data} token={token} />
        </Col>
      </Row>
      <DonorFooter />
    </div>
  );
};
export default DonorFacilityDetail;
