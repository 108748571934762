/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';

import { CommentForm3 } from './CommentForm3';
import { useFetchComments } from '../../utils';
import { CommentFormProps } from './CommentForm';
import { CommentBox } from '../CommentBox/CommentBox';
import { CommentType } from '../../services/apiService';

import styles from './index.module.scss';

export const CommentForm2 = ({
  user,
  postId,
  comment,
  isLoading,
  handleEdit,
  handleReply,
  handleDelete,
  onLikeComment,
}: CommentFormProps) => {
  const [isSeeMore, setIsSeeMore] = useState(false);
  const commentData = useFetchComments(postId, Number(comment.id));
  return (
    <>
      <CommentBox
        user={user}
        comment={comment}
        isLoading={isLoading}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleComment={handleReply}
        onLikeComment={onLikeComment}
        isSeeMore={Boolean(
          commentData?.data?.length &&
            commentData?.data[0]?.commentId === comment?.id
        )}
        handleSeeMore={() => setIsSeeMore((isSeeMore) => !isSeeMore)}
      />
      {isSeeMore && (
        <div className={styles.inputBox}>
          {commentData?.data?.length
            ? commentData?.data?.map(
                (replyItem: CommentType, index: number) => (
                  <CommentForm3
                    key={index}
                    user={user}
                    index={index}
                    postId={postId}
                    comment={replyItem}
                    isLoading={isLoading}
                    handleEdit={handleEdit}
                    onLikeComment={() => {}}
                    handleReply={handleReply}
                    handleDelete={handleDelete}
                  />
                )
              )
            : null}
        </div>
      )}
    </>
  );
};
