export function GiveWater(props: {
  height?: number;
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      width={120}
      height={120}
      fill="none"
      viewBox="0 0 120 120"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75 18.604c0 2.152-1.679 3.896-3.75 3.896-2.071 0-3.75-1.744-3.75-3.896 0-2.435 3.75-8.604 3.75-8.604S75 16.169 75 18.604zM85 55c2.761 0 5-2.442 5-5.455 0-3.409-5-12.045-5-12.045s-5 8.636-5 12.045C80 52.558 82.239 55 85 55zm-50 7.5c2.761 0 5-2.442 5-5.455C40 53.636 35 45 35 45s-5 8.636-5 12.045c0 3.013 2.239 5.455 5 5.455zM65 70c4.142 0 7.5-3.838 7.5-8.571C72.5 56.07 65 42.5 65 42.5s-7.5 13.571-7.5 18.929C57.5 66.162 60.858 70 65 70zM55 32.208C55 36.51 52.202 40 48.75 40s-6.25-3.489-6.25-7.792C42.5 27.338 48.75 15 48.75 15S55 27.338 55 32.208zM100 72.5H86.93C74.818 72.5 65 82.318 65 94.43c0 .179.015.355.045.525C65.947 94.061 80.239 80.002 87.5 80c-16.674 9.528-19.73 16.787-19.982 17.45.18.033.364.05.552.05H75c13.807 0 25-11.193 25-25zm-66.93 0H20c0 13.807 11.193 25 25 25h6.93c.188 0 .373-.017.552-.05-.251-.663-3.307-7.922-19.982-17.45 7.261.002 21.553 14.06 22.455 14.955.03-.17.045-.346.045-.525 0-12.112-9.818-21.93-21.93-21.93zM55 95l-2.5 2.5.052.13a11.311 11.311 0 01-1.67 11.267.679.679 0 00.53 1.103h16.482c.849 0 1.32-.982.79-1.644a10.123 10.123 0 01-1.493-10.082l.31-.774L65 95H55z"
        fill={props.fill}
      />
    </svg>
  );
}
