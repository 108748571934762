import { Row, Col, Statistic, Space } from 'antd';
import { FormattedMessage } from 'react-intl';

import { EnergyValue } from '../EnergyValue/EnergyValue';

import styles from './index.module.scss';

export interface EnergyChargeBoxProps {
  data: any;
  active: string;
  setEnergyChargePlan: any;
}

export const EnergyChargeBox: React.FC<EnergyChargeBoxProps> = ({
  data,
  active,
  setEnergyChargePlan,
}) => {
  return (
    <Row
      gutter={[4, 0]}
      onClick={() => setEnergyChargePlan(data)}
      className={`${styles.energyChargeBox} ${active}`}
    >
      <Col flex="auto">
        <Space align="center">
          <EnergyValue energy={data.energy} />
          <div className={styles.energyText}>
            <FormattedMessage id="energyChargePlan.energy" />
          </div>
        </Space>
      </Col>
      <Col className={styles.amountBox}>
        <div className={styles.amount}>
          <Statistic
            prefix="¥"
            value={data.amount}
            valueStyle={{
              color: '#142d24',
              fontSize: '1.3em',
              fontFamily: 'Times New Roman',
            }}
          />
        </div>
      </Col>
    </Row>
  );
};
