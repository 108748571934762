/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { notification, Row, Spin } from 'antd';

import {
  FacilityType,
  useGetFacilityByIdQuery,
  useUpdateFacilityMutation,
} from '../../../../services/apiService';
import { withTabs } from '../withTabs';
import { FacilityForm } from '../FacilityForm';
import { getAccessToken } from '../../../../getAccessToken';

const BasicInformation = () => {
  const intl = useIntl();
  const { id: facilityId } = useParams<{ id: string }>();
  const { data: facilityData, isLoading: isFetching } =
    useGetFacilityByIdQuery(facilityId);
  const [updateFacility, { isSuccess, isLoading }] =
    useUpdateFacilityMutation();
  const [token, setToken] = useState<string | undefined>('');
  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.facilityUpdated' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isSuccess]);

  const onFinish = async (facilityForm: FacilityType) => {
    const data = {
      ...facilityForm,
      children: parseInt(`${facilityForm.children}`, 10),
    };
    updateFacility({ id: facilityId, body: data });
  };

  if (isFetching) {
    return (
      <Row justify="center">
        <div className="space-align-container">
          <Spin size="large" />
        </div>
      </Row>
    );
  }

  const formattedData = {
    ...facilityData!,
    coverImageId: facilityData!.coverImage?.id,
    storyImageId: facilityData!.storyImage?.id,
    missionImageId: facilityData!.missionImage?.id,
    representativeImageId: facilityData!.representativeImage?.id,
  };

  return (
    <FacilityForm
      token={token}
      actionType="update"
      onFinish={onFinish}
      isLoading={isLoading}
      facility={formattedData}
    />
  );
};

export default withTabs(BasicInformation);
