import { ReactElement } from 'react';
import { Row, Divider, Typography } from 'antd';

import styles from './index.module.scss';

const { Title } = Typography;

export interface PlainBoxProps {
  title: string;
  noCompanyName?: boolean;
  subtitle?: ReactElement;
}

export const PlainBox: React.FC<PlainBoxProps> = ({
  title,
  subtitle,
  children,
  noCompanyName = true,
}) => {
  return (
    <Row align="middle" className={styles.plainBox}>
      {noCompanyName && <div className={styles.companyName}>Astra</div>}
      <Title level={4} className={styles.title}>
        {title}
      </Title>
      <Divider className={styles.divider} />
      <div className={styles.subtitle}>{subtitle}</div>
      <Row className={styles.content}>{children}</Row>
    </Row>
  );
};
