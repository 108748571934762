/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Space, Layout, Typography, Select, Table } from 'antd';

import { RootState } from '../../../../store';
import { routes } from '../../../../constant';
import {
  MemberType,
  setMemberList,
  MemberList as MemberListProp,
} from '../../../../features/member/memberListSlice';
import { membersListArr } from '../../../../FakeData';
import MessagePopup from './MessagePopup/MessagePopup';
import { Input } from '../../../../stories/Input/Input';
import { SorterResult } from 'antd/lib/table/interface';
import { Loader } from '../../../../stories/Loader/Loader';
import { Button } from '../../../../stories/Button/Button';

import styles from './index.module.scss';

const { Title } = Typography;
const { Option } = Select;

const MembersList = () => {
  const levels = [1, 2, 3, 4, 5];
  const ranks = ['A', 'B', 'C', 'D', 'E'];
  const badges = ['Regular', 'Gold', 'Platinum', 'Black'];

  const membersListArray: MemberListProp = {
    membersData: membersListArr,
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const [paginateInfo, setPaginateInfo] = useState({
    page: 1,
    search: '',
    orderDirection: 'ASC',
    orderField: 'createdAt',
  });

  const [selectedRows, setSelectedRows] = useState<MemberType[]>([]);
  const [rankValue, setRankValue] = useState(ranks[0]);

  const [memberListData, setMemberListData] = useState(
    membersListArray.membersData
  );

  const [badgeValue, setBadgeValue] = useState(badges[0]);
  const [levelValue, setLevelValue] = useState(levels[0]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const { membersData } = useSelector((state: RootState) => state.membersList);

  useEffect(() => {
    getMemberListData(membersListArray.membersData);
  }, []);

  const getMemberListData = (membersData: MemberType[]) => {
    dispatch(setMemberList({ membersData }));
  };

  const onSearch = (e: any) => {
    setIsLoader(true);
    const text = e.target.value;
    const filteredMembers = membersData.filter((data: MemberType) => {
      let d = data && data.username;
      return String(d.toLowerCase()).includes(text.toLowerCase());
    });
    setMemberListData([...filteredMembers]);
    setIsLoader(false);
  };

  const handleMemberDetails = (rowIndex: number | undefined) => {
    let MemberData = membersData.filter((d: any) => d.key === rowIndex);
    history.push(routes.MemberDetailsUrl, { params: MemberData });
  };

  const handleChange = (value: any) => {
    let filteredLevel = _.includes(levels, value);
    let filteredRanks = _.includes(ranks, value);
    let filteredBadges = _.includes(badges, value);

    if (filteredLevel) {
      let filterLevel = membersData.filter((data: MemberType) => {
        let d = data && data.level;
        return d.toString().indexOf(value.toString()) > -1;
      });
      setMemberListData(filterLevel);
      setLevelValue(value);
    }

    if (filteredRanks) {
      let filterRank = membersData.filter((data: MemberType) => {
        let d = data && data.rank;
        return (
          d.toString().toLowerCase().indexOf(value.toString().toLowerCase()) >
          -1
        );
      });
      setMemberListData(filterRank);
      setRankValue(value);
    }

    if (filteredBadges) {
      let filterBadges = membersData.filter((data: MemberType) => {
        let d = data && data.badge;
        return (
          d.toString().toLowerCase().indexOf(value.toString().toLowerCase()) >
          -1
        );
      });
      setMemberListData(filterBadges);
      setBadgeValue(value);
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="memberList.userName" />,
      dataIndex: 'username',
      render: (text: string, record: any) => (
        <div
          className={styles.memberTableColumn}
          onClick={(event) => {
            handleMemberDetails(record.key);
          }}
        >
          <img
            src={record.image}
            alt="img"
            width={50}
            height={50}
            className={styles.usrImg}
          />
          <div className={styles.title}>
            <p>{text}</p>
          </div>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="memberList.userId" />,
      dataIndex: 'userId',
      render: (text: string) => (
        <div className={styles.memberTableColumn}>
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="memberList.level" />,
      dataIndex: 'level',
      sorter: (a: any, b: any) => a.level - b.level,
      render: (text: string) => (
        <div className={styles.memberTableColumn}>
          <p>Lv. {text}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="memberList.rank" />,
      dataIndex: 'rank',
      render: (text: string) => (
        <div className={styles.memberTableColumn}>
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="memberList.badge" />,
      dataIndex: 'badge',
      render: (text: string) => (
        <div className={styles.memberTableColumn}>
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="memberList.totalAmount" />,
      dataIndex: 'TotalSupportAmount',
      sorter: (a: any, b: any) =>
        a.TotalSupportAmount.localeCompare(b.TotalSupportAmount),
      render: (text: string) => (
        <div className={styles.memberTableColumn}>
          <p>{text} Circle</p>
        </div>
      ),
    },
  ];

  const onSelectChange = (selectedRowKeys: any, selectedRows: MemberType[]) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedRows(selectedRows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleSendMessage = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleCancle = () => {
    setSelectedRows([]);
    setIsModalVisible(!isModalVisible);
  };

  const handleTableChange = (current: any, field: any, orderBy: any) => {
    setPaginateInfo({
      ...paginateInfo,
      page: current,
      orderField: field || 'createdAt',
      orderDirection: orderBy === 'ascend' ? 'ASC' : 'DESC',
    });
  };
  if (isLoader) return <Loader />;

  return (
    <>
      <div className="container">
        <Row justify="space-between">
          <Title level={2}>
            <FormattedMessage id="memberList.memberTitle" />
          </Title>
        </Row>
        <Layout className={styles.memberTable}>
          <div className={styles.search}>
            <Row gutter={24}>
              <Space className={styles.searchBox} align={'end'}>
                <Input name="search" type={'search'} onSearchEnter={onSearch} />
              </Space>
              <div className={styles.select}>
                <Col>
                  <Select
                    value={levelValue}
                    onChange={handleChange}
                    className={styles.memberListSelect}
                  >
                    {levels.map((data, i) => (
                      <Option key={i} value={data}>
                        {data}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col>
                  <Select
                    value={rankValue}
                    onChange={handleChange}
                    className={styles.memberListSelect}
                  >
                    {ranks.map((data, i) => (
                      <Option key={i} value={data}>
                        {data}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col>
                  <Select
                    value={badgeValue}
                    onChange={handleChange}
                    className={styles.memberListSelect}
                  >
                    {badges.map((data, i) => (
                      <Option key={i} value={data}>
                        {data}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </div>
            </Row>
          </div>

          {hasSelected && (
            <Col span={3}>
              <span className={styles.messageBtn}>
                <Button type="primary" label="Message" onClick={handleModal} />
              </span>
            </Col>
          )}

          <Table
            columns={columns}
            dataSource={memberListData}
            rowSelection={rowSelection}
            onChange={(pagination, filters, sorter) => {
              const { field, order } = sorter as SorterResult<any>;
              handleTableChange(pagination.current, field, order);
            }}
            pagination={{
              total: 0,
            }}
          />
        </Layout>
      </div>

      <MessagePopup
        selectedRows={selectedRows}
        isModalVisible={isModalVisible}
        handleSendMessage={handleSendMessage}
        handleCancle={handleCancle}
        handleModal={handleModal}
      />
    </>
  );
};

export default MembersList;
