/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row,
  Col,
  Card,
  Table,
  Space,
  Avatar,
  Popover,
  Typography,
} from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EllipsisOutlined } from '@ant-design/icons';

import {
  InquiryType,
  useDeleteInquiryMutation,
  useGetInquiryQuery,
} from '../../../../services/apiService';
import { Input } from '../../../../stories/Input/Input';
import { Loader } from '../../../../stories/Loader/Loader';
import InquiryMessage from './InquiryMessage/InquiryMessage';
import { dummyUserProfile, routes } from '../../../../constant';

import styles from './index.module.scss';

const { Title } = Typography;

const InquiryList = () => {
  const [paginateInfo, setPaginateInfo] = useState({
    page: 1,
    limit: 10,
  });
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [inquiryListData, setInquiryListData] = useState<InquiryType[]>();

  const {
    data: inquirysData,
    isLoading,
    isError,
  } = useGetInquiryQuery(paginateInfo);

  const [deleteInquiry, { isSuccess, isLoading: isDeleteLoading, error }] =
    useDeleteInquiryMutation();

  useEffect(() => {
    if (inquirysData?.data) {
      setInquiryListData(inquirysData.data);
    }
  }, [inquirysData]);

  useEffect(() => {
    setDeleteId(0);
  }, [isSuccess]);

  const onSearch = (text: any) => {
    let txt = text.target.value;
  };

  const handleTableChange = (current: any) => {
    setPaginateInfo((paginateInfo) => ({
      page: current,
      limit: 10,
    }));
    setPaginateInfo({
      ...paginateInfo,
      page: current,
      limit: 10,
    });
  };

  const content = (
    <div className={styles.actionPopup} onClick={() => onItemDelete()}>
      <Link to={routes.InquiryListUrl}>
        <FormattedMessage id="inquiryList.facilityProfile" />
      </Link>
    </div>
  );
  const onDeleteMessage = () => {
    if (inquiryListData && inquiryListData?.length > 0)
      deleteInquiry(inquiryListData[index]?.id);
    setIsDetail(false);
  };
  const onItemDelete = () => {
    deleteInquiry(deleteId);
  };
  const columns = [
    {
      title: <FormattedMessage id="inquiryList.userName" />,
      dataIndex: 'name',
      render: (text: string, record: any, index: number) => (
        <div
          className={styles.inquiryTableColumn}
          onClick={() => {
            onDataClick(index);
          }}
        >
          <Avatar src={dummyUserProfile} size={32} />
          <div className={styles.title}>
            <p>{text}</p>
          </div>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="inquiryList.title" />,
      dataIndex: 'title',
      render: (text: string, record: any, index: number) => (
        <div
          className={styles.inquiryTableColumn}
          onClick={() => {
            onDataClick(index);
          }}
        >
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="inquiryList.text" />,
      dataIndex: 'description',
      render: (text: string, record: any, index: number) => (
        <div
          className={styles.inquiryTableColumn}
          onClick={() => {
            onDataClick(index);
          }}
        >
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="inquiryList.date" />,
      dataIndex: 'createdAt',
      render: (text: string, record: any, index: number) => (
        <div
          className={styles.inquiryTableColumn}
          onClick={() => {
            onDataClick(index);
          }}
        >
          <p>{moment(text).format('YYYY/MM/DD hh:mm')}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="inquiryList.action" />,
      key: 'action',
      render: (text: string, record: InquiryType) => (
        <Popover content={content} trigger="click">
          <EllipsisOutlined
            onClick={() => {
              setDeleteId(record.id);
            }}
          />
        </Popover>
      ),
    },
  ];

  const onDataClick = (index: number) => {
    setIsDetail(true);
    setIndex(index);
  };

  const onBackArrowClick = () => {
    setIsDetail(false);
  };

  if (isLoading || isDeleteLoading) return <Loader />;

  if (isDetail)
    return (
      <InquiryMessage
        inquiry={inquirysData?.data}
        index={index}
        setIndex={setIndex}
        onBackArrowClick={onBackArrowClick}
        onDeleteMessage={onDeleteMessage}
      />
    );

  return (
    <Row className={styles.tableRow}>
      <Col>
        <Title level={2}>
          <FormattedMessage id="inquiryList.inquiry" />
        </Title>
        <Card className={styles.inquiryTable}>
          <Space className={styles.searchBox} align={'end'}>
            <Input name="search" type={'search'} onSearchEnter={onSearch} />
          </Space>

          <Table
            columns={columns}
            dataSource={inquirysData?.data}
            rowKey={(record) => record?.id}
            onChange={(pagination, filters, sorter) => {
              handleTableChange(pagination.current);
            }}
            pagination={{
              total: inquirysData?.totalCount,
            }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default InquiryList;
