import { Avatar, Button, Row } from 'antd';
import { FormattedMessage } from 'react-intl';

import { Square } from '../Icons/Square/Square';
import { dummyUserProfile } from '../../constant';

import styles from './index.module.scss';

export interface FollowerCardProps {}

export const FollowerCard = () => {
  return (
    <Row className={styles.titleContent}>
      <div>
        <Avatar src={dummyUserProfile} size={40} shape={'circle'} />
        <div className={styles.titleSubContent}>
          <div>
            <p>Astra Team</p>
            <Square />
          </div>
          <p>@astra001</p>
        </div>
      </div>
      <div>
        <Button size="large" shape="round" type="primary" htmlType="submit">
          <FormattedMessage id="follower.following" />
        </Button>
      </div>
    </Row>
  );
};
