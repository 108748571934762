/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Row,
  Col,
  Typography,
  Layout,
  Input,
  Popconfirm,
  Popover,
  Avatar,
  Form,
  notification,
  Button,
} from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  InquiryType,
  usePostInquiryReplyMutation,
} from '../../../../../services/apiService';
import { dummyUserProfile } from '../../../../../constant';
import LeftArrow from '../../../../../stories/Icons/LeftArrow/LeftArrow';
import BackArrow from '../../../../../stories/Icons/BackArrow/BackArrow';
import PaperClip from '../../../../../stories/Icons/PaperClip/PaperClip';
import UploadIcon from '../../../../../stories/Icons/UploadIcon/UploadIcon';
import RightArrow from '../../../../../stories/Icons/RightArrow/RightArrow';
import SendMessage from '../../../../../stories/Icons/SendMessage/SendMessage';

import styles from './index.module.scss';

const { Title, Paragraph } = Typography;
interface InquiryMessageProps {
  index: number;
  onBackArrowClick: () => void;
  setIndex: (index: number) => void;
  inquiry: InquiryType[] | undefined;
  onDeleteMessage: () => void;
}
const InquiryMessage = ({
  inquiry,
  index,
  setIndex,
  onDeleteMessage,
  onBackArrowClick,
}: InquiryMessageProps) => {
  const [form] = Form.useForm();
  const intl = useIntl();

  const [postInquiryReply, { isLoading, isSuccess, isError }] =
    usePostInquiryReplyMutation();
  const goToPrev = () => {
    if (index > 0) setIndex(index - 1);
  };
  const goToNext = () => {
    if (inquiry && index < inquiry?.length - 1) setIndex(index + 1);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      notification.success({
        message: intl.formatMessage({ id: 'inquiry.successMessage' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.requestFailed' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isError]);

  const onFinish = ({ reply }: { reply: string }) => {
    console.log(reply);

    if (inquiry && reply !== '')
      postInquiryReply({
        inquiryId: inquiry[index]?.id,
        description: reply,
        InquiryReplyId: 0,
      });
  };

  const content = (
    <div className={styles.deleteContent}>
      <Popconfirm
        okText="Yes"
        cancelText="No"
        title="Are you sure you want to delete?"
        onConfirm={onDeleteMessage}
      >
        Request for deletion
      </Popconfirm>
      <p onClick={onDeleteMessage}>Forced deletion</p>
    </div>
  );

  return (
    <div className={styles.container}>
      <div className="container">
        <Row justify="center">
          <Col span={14}>
            <Row justify="start">
              <Title level={2}>
                <FormattedMessage id={'inquiryMessage.inquiry'} />
              </Title>
            </Row>
            {inquiry && (
              <Layout className={styles.inquiryMessage}>
                <div className={styles.headerStyle}>
                  <Row justify="space-between">
                    <Col>
                      <div className={styles.dFx}>
                        <div
                          onClick={onBackArrowClick}
                          className={styles.backArrow}
                        >
                          <BackArrow />
                        </div>
                        <div className={styles.userImage}>
                          <Avatar src={dummyUserProfile} size={32} />
                        </div>
                        <div className={styles.userName}>
                          <p className={styles.boldUserName}>
                            {inquiry[index]?.name}
                          </p>
                          <p className={styles.userTitle}>
                            {inquiry[index]?.email}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col>
                      <div className={styles.messageDate}>
                        <div>
                          <p>
                            {moment(inquiry[index]?.createdAt).format(
                              'YYYY/MM/DD hh:mm'
                            )}
                          </p>
                        </div>
                        <Popover
                          content={content}
                          trigger="click"
                          className={styles.ellipsisLine}
                        >
                          <EllipsisOutlined />
                        </Popover>
                        <div className={styles.leftArrow} onClick={goToPrev}>
                          <LeftArrow />
                        </div>
                        <div className={styles.rightArrow} onClick={goToNext}>
                          <RightArrow />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className={styles.detailDesc}>
                  <Typography>
                    <Title level={3}>{inquiry[index]?.name}</Title>
                    <Paragraph>{inquiry[index]?.description}</Paragraph>
                  </Typography>
                </div>

                <div>
                  <Form
                    form={form}
                    initialValues={{ reply: '' }}
                    onFinish={onFinish}
                    className={styles.sendBack}
                  >
                    <div className={styles.innerSendBack}>
                      <Form.Item name="reply">
                        <Input placeholder="Send back" />
                      </Form.Item>
                      <div className={styles.upload}>
                        <UploadIcon />
                        <span className={styles.paperClip}>
                          <PaperClip />
                        </span>
                      </div>
                    </div>
                    <div className={styles.sendIcon}>
                      <Button
                        type="primary"
                        htmlType={'submit'}
                        icon={<SendMessage />}
                      />
                    </div>
                  </Form>
                </div>
              </Layout>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InquiryMessage;
