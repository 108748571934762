import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Row, Form, Typography } from 'antd';

import { Input } from '../../stories/Input/Input';
import { Button } from '../../stories/Button/Button';

import styles from './index.module.scss';

export const ForgotPasswordRequestOtpForm = ({
  onFinish,
}: {
  onFinish: (values: any) => void;
}) => {
  const { Text } = Typography;

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
      wrapperCol={{ span: 24 }}
      className={styles.form}
    >
      <Input
        addonBefore="0"
        type="phoneNumber"
        name="phoneNumber"
        placeholder="XX-XXXX-XXXX"
        rules={[{ required: true }]}
        label={<FormattedMessage id="forgotPasswordRequestOtp.phoneNumber" />}
      />
      <Row align="middle" justify="space-between">
        <Button type="primary" label="Submit" submit="submit" />
      </Row>
      <Text className={styles.or}>
        <FormattedMessage id="forgotPasswordRequestOtp.or" />
      </Text>
      <Link to="/">
        <Text underline>
          <FormattedMessage id="forgotPasswordRequestOtp.login" />
        </Text>
      </Link>
    </Form>
  );
};
