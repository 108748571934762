/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Divider, Button, Card, Radio, Form } from 'antd';
import { CardCvcElement, useElements } from '@stripe/react-stripe-js';

import { CreditCardType } from '../../services/apiService';

import styles from './index.module.scss';
import { Input } from '../Input/Input';

interface creditCardProps {
  setStep: Function;
  closeModal: Function;
  creditCardList: CreditCardType[] | undefined;
  setStripePaymentMethodId: Function;
  handlePayment: Function;
  isLoading: boolean;
}
export const CreditCardList: React.FC<creditCardProps> = ({
  setStep,
  isLoading,
  closeModal,
  setStripePaymentMethodId,
  creditCardList,
  handlePayment,
}) => {
  const [selectCard, setSelectCard] = useState<string>('');
  const [form] = Form.useForm();
  const elements = useElements();
  const handleStep = () => {
    setSelectCard('');
    setStep(1);
  };
  const onClose = () => {
    closeModal(false);
  };

  const handleSubmit = async () => {
    handlePayment(elements?.getElement(CardCvcElement));
  };

  const handleCardSelection = (id: string) => {
    setSelectCard(id);
    setStripePaymentMethodId(id);
  };

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <Row>
          <Col span={24} className={styles.cardContent}>
            {creditCardList?.map((data: CreditCardType, index: number) => (
              <Card
                className={`${styles.cardMain} ${
                  isLoading && styles.disableTouch
                }`}
                onClick={() => handleCardSelection(data.id)}
                key={index}
              >
                <div className={styles.checkBoxContent}>
                  <Radio value={data.id} checked={data.id === selectCard} />
                </div>
                <div className={styles.cardDetailContent}>
                  <div>
                    <p>{data.card.brand + ' ' + data.card.last4} </p>
                    <p>{data.billing_details.name}</p>
                  </div>
                  <div className={styles.dateContent}>
                    <div>
                      <p>
                        Expires {data.card.exp_month + '/' + data.card.exp_year}
                      </p>
                      <div className={styles.cardCvcContent}>
                        <Form.Item>
                          {data.id === selectCard && (
                            <div className={styles.cvcContent}>
                              <CardCvcElement
                                className={styles.cvcInput}
                                onChange={(event) => {
                                  if (event.empty) {
                                    form.setFields([
                                      {
                                        name: 'cardCvc',
                                        value: '',
                                        errors: ['Card CVC is required'],
                                      },
                                    ]);
                                  }

                                  if (event.error) {
                                    form.setFields([
                                      {
                                        name: 'cardCvc',
                                        value: 'garbage value',
                                        errors: [event.error.message],
                                      },
                                    ]);
                                  }
                                  if (event.complete) {
                                    form.setFields([
                                      {
                                        name: 'cardCvc',
                                        value: 'garbage value',
                                        errors: [],
                                      },
                                    ]);
                                  }
                                }}
                              />
                              <div className="hidden-inputs">
                                <Input
                                  type="text"
                                  name="cardCvc"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Card CVC is required',
                                    },
                                  ]}
                                />
                              </div>
                            </div>
                          )}
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </Col>
          <Col span={12}>
            <Button type="primary" onClick={handleStep} disabled={isLoading}>
              <FormattedMessage id="creditCardInfo.addNewCard" />
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row justify="space-between">
          <Col>
            <Button onClick={onClose} disabled={isLoading}>
              <FormattedMessage id="creditCardInfo.cancel" />
            </Button>
          </Col>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              disabled={!selectCard}
            >
              <FormattedMessage id="creditCardInfo.submit" />
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
