import { Col, Row, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { isDeviceMobile } from '../../utils';
import { SupportHistoryObject } from '../../services/apiService';
import { TotalSupportAmountBox } from '../TotalSupportAmountBox/TotalSupportAmountBox';
import { SupportHistoryListCard } from '../SupportHistoryListCard/SupportHistoryListCard';

import styles from './index.module.scss';

const { Title } = Typography;

export interface SupportHistoryProps {
  data: SupportHistoryObject[] | undefined;
  energy: number;
}
export const SupportHistoryList = ({ data, energy }: SupportHistoryProps) => {
  if (isDeviceMobile())
    return (
      <>
        <Row justify="center" gutter={20}>
          <Col xs={21} sm={21} md={8} lg={6} xl={4} xxl={3}>
            <TotalSupportAmountBox energy={energy} />
          </Col>
        </Row>
        <Row justify="center">
          <Col
            xs={24}
            sm={24}
            md={14}
            lg={12}
            xxl={8}
            className={styles.mobileCard}
          >
            <Title level={4}>
              <FormattedMessage id="supportHistory.title" />
            </Title>
            <SupportHistoryListCard data={data} />
          </Col>
        </Row>
      </>
    );
  return (
    <>
      <Row justify="center">
        <Col xs={23} sm={23} md={23} lg={14} xxl={11}>
          <div>
            <div className={styles.supportTitle}>
              <Title level={3}>
                <FormattedMessage id="supportHistory.title" />
              </Title>
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="center" gutter={20}>
        <Col xs={24} sm={24} md={14} lg={12} xxl={8}>
          <SupportHistoryListCard data={data} />
        </Col>
        <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={3}>
          <TotalSupportAmountBox energy={energy} />
        </Col>
      </Row>
    </>
  );
};
