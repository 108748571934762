/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Row } from 'antd';

import { FacilityResponse } from '../../../services/apiService';
import { SearchFacilityBox } from '../../../stories/SearchFacilityBox/SearchFacilityBox';

import styles from './index.module.scss';
export interface FacilityListBoxProps {
  facilities: FacilityResponse[] | undefined;
}

export const SupportDestination = ({ facilities }: FacilityListBoxProps) => {
  if (facilities)
    return (
      <Row gutter={20}>
        {facilities.map((item: any, index: number) => (
          <Col sm={24} xs={24} lg={12} md={12} xl={8} xxl={8} key={index}>
            <SearchFacilityBox item={item} />
          </Col>
        ))}
      </Row>
    );

  return <div className={styles.blankContent}>No data </div>;
};
