export function Square() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000282274 7.99902L0 7.99931L7.99967 15.999V7.99902H0.000282274Z"
        fill="#142D24"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.86513e-05 8L0 7.99993L7.99967 0.000262737V8H6.86513e-05Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9997 8L16 8.00027L8.00033 15.9999V8H15.9997Z"
        fill="#314C42"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9996 8L16 7.9996L8.00033 -6.86646e-05V8H15.9996Z"
        fill="#536C56"
      />
    </svg>
  );
}
