import { Card, Space } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';

import styles from './index.module.scss';

export const PrivacyPolicy = () => {
  return (
    <Card>
      <Paragraph className={styles.textCenter}>
        プライバシーポリシー 株式会社
      </Paragraph>
      <Paragraph className={styles.textIndent}>
        Astra（以下「当社」といいます。）は、当社の提供するサービス（以下「本サービ
        ス」といいます。）における、利用者及び児童養護施設の皆様についての個人情報を含む情報（以
        下「ユーザ情報」といいます。）の取扱いについて、以下のとおりプライバシーポリシー（以下
        「本ポリシー」といいます。）を定めます。
      </Paragraph>
      1. ユーザ情報の収集
      <Paragraph className={styles.textIndent}>
        本ポリシーにおいて、「ユーザ情報」とは、利用者及び児童養護施設の識別に係る情報、通信
        サービス上の行動履歴、その他利用者及び児童養護施設のスマートフォン、PC
        等の端末において
        利用者及び児童養護施設又は利用者及び児童養護施設の端末に関連して生成又は蓄積された情報
        であって、本ポリシーに基づき当社が収集するものを意味するものとします。本サービスにおい
        て当社が収集するユーザ情報は、以下のようなものがあります。
      </Paragraph>
      <Paragraph>
        <div>
          (1) 利用者及び児童養護施設が本サービスを利用するために提供した情報
        </div>
        <div>
          (2)
          利用者及び児童養護施設が本サービスを利用する際のログ情報、端末情報、位置情報、Cookie、トラッキング情報
        </div>
        <div>
          (3)
          本サービスの利用に係るエネルギー及びアイテムの購入・交換情報、購入・交換したアイテ
          ム及びアイテムの付与その他の取引履歴、他の利用者及び児童養護施設等とのコミュニケー
          ション内容等
        </div>
        <div>
          (4)
          利用者及び児童養護施設が本サービスの利用において、他のサービスと連携を許可すること
          により、当該他のサービスから提供される情報
        </div>
      </Paragraph>
      2. ユーザ情報の利用目的
      <Paragraph className={styles.textIndent}>
        当社は、収集したユーザ情報を以下の目的で利用します。また、当社は、ユーザ情報の利用目
        的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した
        場合には利用者に通知又は公表します。
      </Paragraph>
      <Paragraph>
        <div>
          (1) 本サービスの提供と維持向上、新しい機能とコンテンツの提供のため
        </div>
        <div>(2) 本人確認及び料金請求のため</div>
        <div>(3) アイテムの使用に際してのユーザ情報の伝達のため</div>
        <div>
          (4)
          利用者及び児童養護施設によるコメント、質問又はリクエストに応え、カスタマーサービス
          を提供するため
        </div>
        <div>
          (5)
          利用者及び児童養護施設に技術的通知、アップデート、セキュリティ情報、サポート、管理
          メッセージ又は Astra 利用規約及び Astra
          約款（児童養護施設様向け）その他の本サービス
          に関するガイドライン、ポリシー等（以下「本規約等」といいます。）の変更を送信するた
          め ‐2‐
        </div>
        <div>
          (6)
          製品、サービスその他利用者及び児童養護施設において興味があると考えられる情報をお知
          らせするため
        </div>
        <div>
          (7) 当社の提供するサービスに関連する利用や活動、動向の調査分析のため
        </div>
        <div>
          (8)
          本サービスに関する本規約等に違反する行為又は法令違反行為の発見、調査又は防止のため
        </div>
        <div>(9) その他上記各号に規定する利用目的に付随する目的のため</div>
      </Paragraph>
      3. ユーザ情報の提供
      <Paragraph className={styles.textIndent}>
        当社は、以下に定める場合には、ユーザ情報を第三者に提供することができるものとします。
      </Paragraph>
      <Paragraph>
        <div>
          (1)
          アイテムの使用及び活動報告等の実施に際して、利用者及び児童養護施設がそれぞれ相手方
          の入力した氏名又は住所、電話番号、担当者名等を閲覧する場合
        </div>
        <div>(2) 利用者及び児童養護施設ご本人の同意がある場合</div>
        <div>
          (3)
          国の機関若しくは地方公共団体又はその委託を受けた者から、ユーザ情報の開示を求められ
          た場合
        </div>
        <div>
          (4)
          エネルギーの購入代金の決済を行うために、金融機関、クレジットカード会社、決済代行業
          者その他の決済又はその代行を行う事業者に開示する場合
        </div>
        <div>(5) 当社が行う業務の全部又は一部を第三者に委託する場合</div>
        <div>
          (6) 合併その他の事由による事業の承継に伴ってユーザ情報が提供される場合
        </div>
        <div>(7) 個人情報保護法その他の法令により認められた場合</div>
        <div>(8) その他当社の各サービスにおいて個別に定める場合</div>
      </Paragraph>
      4. ユーザ情報を保護するための体制
      <Paragraph className={styles.textIndent}>
        当社は、ユーザ情報の紛失、破壊、改ざん及び漏洩などのリスクに対して、ユーザ情報の安全
        管理が図られるよう、当社の従業員に対し、必要かつ適切な監督を行います。また、当社は、ユ
        ーザ情報の取扱いの全部又は一部を委託する場合は、委託先においてユーザ情報の安全管理が図
        られるよう、必要かつ適切な監督を行います。{' '}
      </Paragraph>
      <Paragraph className={styles.textIndent}>
        利用者及び児童養護施設は、自己の個人情報について、修正・削除若しくは第三者への提供の
        停止（以下「個人情報の修正等」といいます。）を要求することができます。個人情報に関する
        要求・お問い合わせは、Astra サポートセンターへお問い合わせください。
      </Paragraph>
      <Paragraph className={styles.textIndent}>
        当社は、利用者及び児童養護施設より個人情報の修正等に関する要求・お問い合わせを受けた
        場合、個人を識別できる情報により利用者ご本人であることを確認します。利用者及び児童養護
        施設ご本人以外の第三者が個人を識別できる情報を入手し、利用者及び児童養護施設になりすま
        す等の目的で使用した場合、当社は、かかる行為による利用者及び児童養護施設の損害等につい
        て一切責任を負いません。
      </Paragraph>
      <Paragraph className={styles.textIndent}>
        個人情報の修正等に関する要求・お問い合わせを受けた場合、当社は、利用者及び児童養護施
        設ご本人であることが確認できた場合に限り、合理的な範囲内において速やかに対応いたします。
        なお、何らかの理由で既に個人情報を消去している場合がありますので、かかる個人情報の修正
        ‐3‐ 等に関する要求・お問い合わせは対象外とさせていただきます。
      </Paragraph>
      5. プライバシーポリシーの更新
      <Paragraph className={styles.textIndent}>
        当社は、必要に応じて、本ポリシーを変更します。ただし、法令上利用者及び児童養護施設の
        同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法
        で当該変更に同意した利用者及び児童養護施設に対してのみ適用されるものとします。
      </Paragraph>
      <Paragraph className={styles.textIndent}>
        なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当
        社の管理するウェブサイトの分かりやすい場所において周知し、又は利用者及び児童養護施設に
        通知します。
      </Paragraph>
      6. お問い合わせ
      <Paragraph className={styles.textIndent}>
        ユーザ情報の取扱いに関し、ご不明な点がございましたら、下記にご連絡ください。
      </Paragraph>
      <br />
      <br />
      <Paragraph>
        <Space size="large">
          <div>
            <div>担当部署:</div>
            <div>連絡先:</div>
            <div>営業時間</div>
          </div>
          <div>
            <div>Astra サポートセンター</div>
            <div>astrakk.info@gmail.com</div>
            <div>平日 10:00-16:00</div>
          </div>
        </Space>
      </Paragraph>
      <br />
      <br />
      <Paragraph>2022 年 1 月 11 日制定</Paragraph>
    </Card>
  );
};
