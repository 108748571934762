import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

const { Title } = Typography;

interface MemberHeaderProps {
  userDetail: any;
}

const MemberDetailHeader: React.FC<MemberHeaderProps> = ({ userDetail }) => {
  return (
    <div className={styles.memberTitle}>
      <div className={styles.userImage}>
        <img src={userDetail.image} alt={userDetail.image} />
      </div>
      <div className={styles.memberTextDetail}>
        <div className={styles.memberUserDetailMain}>
          <div className={styles.memberUserDetailMainInnerOne}>
            <Title className={styles.userName} level={3}>
              {userDetail.username}
            </Title>
            <span className={styles.userIdName}>{userDetail.userId}</span>
          </div>
          <div className={styles.memberUserDetailMainInnerSec}>
            <div className={styles.levelLabelMain}>
              <div className={styles.levelLabel}>
                <FormattedMessage id="memberDetailHeader.levelLabel" />
              </div>
              <Title className={styles.levelLabelInner} level={4}>
                {userDetail.level}
              </Title>
            </div>
            <div className={styles.rankTag}>
              <span className={styles.rankTagInner}>{userDetail.rank}</span>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.levelLabel}>
            <FormattedMessage id="memberDetailHeader.support" />
          </div>
          <div className={styles.totalAmountSupport}>
            <Title level={4}>{userDetail.TotalSupportAmount}</Title>
            <div className={styles.label}>
              <FormattedMessage id="memberDetailHeader.circle" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberDetailHeader;
