import { Card } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';

import styles from './index.module.scss';

export const TermsAndService = () => {
  return (
    <Card>
      <Paragraph className={styles.textCenter}>Astra 利用規約</Paragraph>
      <br />
      <Paragraph className={styles.textIndent}>
        この Astra 利用規約（以下「本規約」といいます。）は、株式会社
        Astra（以下「当社」といい
        ます。）が「Astra」の名称（サービス名を変更した場合には変更後の名称を含みます。）で提供
        する「子ども教育支援特化型
        SNS（ソーシャル・ネットワーキング・サービス）」（以下「本サー
        ビス」といいます。）における、本サービスの提供条件及び本サービスの利用に関する本サービ
        スを利用する皆様（以下「利用者」といいます。）と当社との間の権利義務関係を定めたもので
        す。
      </Paragraph>
      <Paragraph className={styles.textIndent}>
        利用者は、本規約をよく読んだうえで本サービスを利用するものとし、利用者は、本サービス
        の利用者登録申請をすることにより、本規約に同意したものとみなされます。
      </Paragraph>
      <br />
      <Paragraph>
        第 1 条 （適用
        <div className={styles.textIndent}>
          1.
          本規約は、本サービスの提供条件及び本サービスの利用に関する利用者と当社との間の権
          利義務関係を定めることを目的とし、利用者と当社との間の本サービスの利用に関わる一切
          の関係に適用されます。利用者は、本規約をよく読み、これを承諾の上、本サービスを利用
          することとします。
        </div>
        <div className={styles.textIndent}>
          2.
          当社が本サービス又はウェブサイト上で掲載する本サービスの利用に関するルールその他
          の定めは、本規約の一部を構成するものとします。
        </div>
        <div className={styles.textIndent}>
          3.
          本規約の内容と、前項の定めその他の本規約外における本サービスの説明等とが異なる場
          合は、本規約の規定が優先して適用されます
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 2 条 （本サービス）
        <div className={styles.textIndent}>
          1. 本サービスは、下記の理念に基づく「子ども教育支援特化型
          SNS（ソーシャル・ネットワ
          ーキング・サービス）」です。下記の理念に心から共鳴していただける方に、本サービスの
          利用者となっていただくことを想定しています。
        </div>
        <div className={styles.textCenter}>記 </div>
        <div className={styles.textIndent}>
          「Astra（アストラ）」はラテン語で「星」を意味します。私たちの住む地球も星です。
          まだ幼い子どもたちは、未来を明るく照らす小さな無数の星だと考えます。しかしその星
          である子どもたちの中には、恵まれない家庭環境の中で十分な教育を受けられないなどの、
          むずかしい課題を抱えている子どもたちも数多くいます。そのような子どもたちを守り、
          日々育む児童養護施設をサポートすることを通じ、子どもたちの本来の光を取り戻してあげ
          たいという思いで生まれたのが、「子ども教育支援特化型
          SNS（ソーシャル・ネットワーキ ング・サービス）・Astra」です。
          皆さんの優しさをエネルギーに変え、むずかしい課題と日々向き合う子どもたちが暮らす
          児童養護施設に届けるサービスを展開します。{' '}
        </div>
        <div className={styles.textIndent}>
          2. 利用者は、本サービスにおいて、エネルギー（第 9
          条（エネルギー）で定義します。以下
          同じです。）を支払うことによりアイテム（第 10
          条（アイテム）で定義します。以下同じで
          す。）を購入し、それを児童養護施設に対して使用することを通じ、「物」「事」を児童養
          護施設に届け、支援を行います。これを受けて、児童養護施設には、本サービスに対して活
          動報告やサンクスメール等（以下「活動報告等」といいます。）を行うことにより、本サー
          ビスを活性化してもらいます。このように、本サービスは、支援者と支援者同士、そして児
          童養護施設がつながる、優しさで結ばれたプラットフォームとなることを目指しています。{' '}
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 3 条 （利用者登録の申込等）
        <div className={styles.textIndent}>
          1.
          本サービスの利用者登録を希望する者（再登録を希望する者を含み、以下「登録希望者」
          といいます。）は、本規約をよく読んだ上でこれを遵守することに同意し、かつ、当社が本
          サービスの利用者登録のために定める一定の情報（以下「登録事項」といいます。）につき、
          当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用者登録を申
          請することができます。
        </div>
        <div className={styles.textIndent}>
          2.
          登録希望者が未成年者である場合には、本サービスの利用者登録の申込み及び本サービス
          の利用について、事前に親権者その他の法定代理人の同意を得るものとします。
        </div>
        <div className={styles.textIndent}>
          3.
          当社は、当社の基準に従って、前二項に基づいて登録申請を行った登録希望者（以下「登
          録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登
          録申請者に通知します。登録申請者の利用者登録は、当社が本項の通知を行ったことをもっ
          て完了とします。
        </div>
        <div className={styles.textIndent}>
          4.
          当社が、自らの裁量により、登録申請者が次の各号のいずれかの事由に該当するものと判
          断した場合には、登録及び再登録を拒否することができるものとします。登録及び再登録の
          可否の判断は、当社の完全な裁量に基づくものであり、当社はいかなる場合にもこれを応諾
          若しくは拒絶する義務を負うことはなく、また、その諾否の理由について開示する義務を負
          うこともないものとします。
          <div className={styles.textIndent}>
            (1) 実在が疑わしい者による登録申請である場合{' '}
          </div>
          <div className={styles.textIndent}>
            (2)
            当社に提供した登録事項の全部又は一部につき虚偽、誤記又は記載漏れがある場合
          </div>
          <div className={styles.textIndent}>
            (3)
            成年被後見人、被保佐人又は被補助人のいずれかであって、法定代理人、後見人、保佐
            人又は補助人の同意等を得ていない場合
          </div>
          <div className={styles.textIndent}>
            (4) 年齢が 16 歳未満の場合（親権者の同意の有無を問いません。）{' '}
          </div>
          <div className={styles.textIndent}>
            (5) 日本国外に居住している場合{' '}
          </div>
          <div className={styles.textIndent}>
            (6) 過去当社との契約に違反したことがあり、又はその関係者である場合{' '}
          </div>
          <div className={styles.textIndent}>
            (7) 過去に退会歴のある登録申請者が、当該退会日から起算し 3
            年を経過していない時点に おいて申込を行ったものである場合{' '}
          </div>
          <div className={styles.textIndent}>
            (8)
            反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者
            を意味します。以下同じです。）である、又は資金提供その他を通じて反社会的勢力等
            の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流
            若しくは関与を行っていると当社が判断した場合{' '}
          </div>
          <div className={styles.textIndent}>
            (9) 第 13 条（登録抹消等）に規定する措置を受けたことがある場合{' '}
          </div>
          <div className={styles.textIndent}>
            (10) 既に利用者登録がなされている場合{' '}
          </div>
          <div className={styles.textIndent}>
            (11) その他、当社が登録を適当でないと判断した場合{' '}
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 4 条 （登録事項の変更）
        <div className={styles.textIndent}>
          利用者は、登録事項に変更があった場合、当社の定める方法により当該変更内容を遅滞な
          く当社に通知しなければなりません。登録事項の変更の届出がないことによって利用者に生
          じた損害その他の不利益（経済的なものであるかどうかを問いません。）について、当社は
          一切責任を負いません。
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 5 条 （利用者 ID 及びパスワードの管理）
        <div className={styles.textIndent}>
          <div>
            1. 利用者は、自己の責任において、本サービスに関する利用者 ID
            及びパスワードを適切に管
            理及び保管するものとし、これを第三者に利用させ、又は貸与、譲渡、名義変更若しくは売
            買等をしないものとします。
          </div>
          <div>
            2.
            利用者は、パスワードの安全性を確保するため、自己の責任において、以下の項目を実行
            するものとします。 (1) 推測されにくいパスワードを使用すること (2)
            本サービスに登録するパスワードを他のサービスで利用しないこと
          </div>
          <div>
            3. 利用者 ID
            又はパスワードの管理不十分、使用上の過誤、第三者の使用等によって生じた損
            害に関する責任は利用者が負い、当社は一切の責任を負いません。
          </div>
          <div>
            4. 利用者は、自己の利用者 ID
            及びパスワードが第三者によって不正に使用されたことが判
            明した場合、直ちに当社にその旨を通報するものとします。
          </div>
          <div>
            5.
            当社は、利用者のパスワードが不正に利用されるおそれがある場合等、当社が必要と判断
            した場合には、利用者のパスワードを一方的に変更することができます。その場合、当社は、
            利用者に対し、当社の定める方法により、パスワードを変更したこと及び変更後のパスワー
            ドを通知します。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 6 条 （利用者情報の利用）
        <div>
          1.
          当社は、利用者の識別に係る情報、通信サービス上の行動履歴、その他利用者のスマート
          フォン、PC
          等の端末において利用者又は利用者の端末に関連して生成又は蓄積された情報で
          あって、当社のプライバシーポリシーに基づき当社が収集するもの（以下「利用者情報」と
          いいます。）につき、次の各号に掲げる目的により利用することができるものとします。
          <div className={styles.textIndent}>
            <div>
              (1) 本サービスの提供と維持向上、新しい機能とコンテンツの提供のため
            </div>
            <div>(2) 本人確認及び料金請求のため</div>
            <div>(3) アイテムの使用に際しての利用者情報の伝達のため</div>
            <div>
              (4)
              利用者によるコメント、質問又はリクエストに応え、カスタマーサービスを提供するた
              め
            </div>
            <div>
              (5)
              利用者に技術的通知、アップデート、セキュリティ情報、サポート、管理メッセージ又
              は本規約その他の本サービスに関するガイドライン、ポリシー等（以下「本規約等」と
              いいます。）の変更を送信するため
            </div>
            <div>
              (6)
              製品、サービスその他利用者において興味があると考えられる情報をお知らせするため
            </div>
            <div>
              (7)
              当社の提供するサービスに関連する利用や活動、動向の調査分析のため
            </div>
            <div>
              (8)
              本サービスに関する本規約等に違反する行為又は法令違反行為の発見、調査又は防止の
              ため
            </div>
            <div>(9) その他上記各号に規定する利用目的に付随する目的のため</div>
          </div>
        </div>
        <div>
          2.
          当社は、次の各号に掲げる場合には、利用者情報を第三者に提供することができるものと
          します。
        </div>
        <div>
          <div className={styles.textIndent}>
            <div>
              (1)
              アイテムの使用及び活動報告等の実施に際して、利用者と児童養護施設がそれぞれ相手
              方の入力した氏名又は住所、電話番号、担当者名等を閲覧する場合
            </div>
            <div>(2) 利用者本人の同意がある場合</div>
            <div>
              (3)
              国の機関若しくは地方公共団体又はその委託を受けた者から、利用者情報の開示を求め
              られた場合
            </div>
            <div>
              (4)
              エネルギーの購入代金の決済を行うために、金融機関、クレジットカード会社、決済代
              行業者その他の決済又はその代行を行う事業者に開示する場合
            </div>
            <div>(5) 当社が行う業務の全部又は一部を第三者に委託する場合</div>
            <div>
              (6)
              合併その他の事由による事業の承継に伴って利用者情報が提供される場合
            </div>
            <div>(7) 個人情報保護法その他の法令により認められた場合</div>
            <div>(8) その他当社の各サービスにおいて個別に定める場合</div>
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 7 条 （利用者コンテンツについて）
        <div className={styles.textIndent}>
          <div>
            1.
            本サービスでは、利用者が本サービス上でコンテンツ（以下「利用者コンテンツ」といい
            ます。）を送信、投稿、登録又は表示（以下「投稿等」といいます。）することができます。
          </div>
          <div>
            2.
            利用者は、利用者コンテンツに対する権利を自ら保有するものとします。
          </div>
          <div>
            3.
            利用者は、本サービス上に又は本サービスを介して利用者コンテンツを投稿等することに
            よって、当社があらゆる媒体又は配信方法を使ってこれらのコンテンツを使用、複製、処理、
            改変、修正、公表、送信、表示及び配信するための、世界的かつ非独占的なライセンスを、
            それらを可能とするサブライセンスとともに、当社に対して無償で許諾するものとします。
            このライセンスによって、利用者は、当社、児童養護施設及び他の利用者に対し、自身の投
            稿が世界中で閲覧可能となることに同意することになります。利用者は、本サービス上で又
            は本サービスを通じて投稿等される利用者コンテンツに関して、当社を含むいかなる者に対
            しても著作者人格権を行使しないものとします。
          </div>
          <div>
            4.
            利用者は、利用者コンテンツについて、自らが投稿等することについての適法な権利を有
            していること、及び利用者コンテンツが第三者の権利を侵害していないことについて、当社
            に対し表明し、保証するものとします。利用者は、自身が必要な許可を得ている又はその他
            の理由により素材を投稿等し、当社にライセンスを許諾することができる法的権限を有して
            いる場合を除き、利用者コンテンツが著作権その他の財産権の対象となる素材を含むもので
            はないことに同意するものとします。
          </div>
          <div>
            5.
            利用者は、本サービスの利用及び自身が本サービス上で又は本サービスを通じて投稿等す
            る利用者コンテンツに対して責任を負います。
          </div>
          <div>
            6.
            利用者は、投稿等するコンテンツが、他の利用者と共有して差し支えないものであること
            を確認します。
          </div>
          <div>
            7. 利用者は、次の各号に掲げる情報を投稿等しないものとします。 (1)
            他人の名誉又は信用を傷つけるもの (2)
            詐欺的、虚偽的、欺瞞的である、又は誤解を招くもの (3)
            個人又は団体に対して差別、偏見、人種差別、憎悪、嫌がらせ又は侵害を助長するもの
            (4)
            暴力的若しくは脅迫的である、又は他者に対して暴力的若しくは脅迫的な行為を助長す
            るもの (5) わいせつな表現又はヌード画像を含むもの (6)
            特許権、実用新案権、意匠権、商標権、著作権、肖像権その他の他人の権利を侵害する
            もの (7) コンピューターウィルスを含むもの (8) 異性交際を求めるもの
            (9) 異性交際の求めに応じるもの (10)
            異性交際に関する情報を媒介するもの (11) 公序良俗に反するもの (12)
            法令に違反するもの又は違反する行為を助長するもの (13)
            その他当社が不適当と判断したもの
          </div>
          <div>
            8.
            当社は、利用者コンテンツが本規約に違反する場合、その他当社が不適当と判断した場合
            には、利用者の同意を得ることなく利用者コンテンツを削除することができるものとします。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 8 条 （当社コンテンツ等）
        <div className={styles.textIndent}>
          <div>
            1.
            本サービス及び本サービスを提供するウェブサイト、アプリ等に関する知的財産権は全て
            当社又は当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの
            利用許諾は、本サービス又は本サービスを提供するウェブサイト、アプリ等に関する当社又
            は当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありませ
            ん。
          </div>
          <div>
            2.
            本サービス上で当社が販売するアイテムについて、利用者はエネルギーによって当社に支
            払を行うことにより、これらのアイテムを利用者が保有するものとして、当社所定の条件に
            より本サービス上で表示する権利を当社から許諾されるものとします（利用者が当社所定の
            条件によりアイテムを本サービス上で表示することができる状態を、本規約において「保有」
            といいます。）。
          </div>
          <div>
            3.
            本サービスに掲載している情報、コンテンツその他の商品価格、投稿データ等の無断収集・
            利用・販売・再販・営業を禁止します。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 9 条 （エネルギー）
        <div className={styles.textIndent}>
          <div>
            1.
            本サービスにおいて、エネルギーとは、本サービスにおいて有償で提供されるアイテムと
            交換可能な電子マネーをいいます。
          </div>
          <div>
            2.
            エネルギーは、本サービスにおいて当社から購入することにより、利用者に提供されます。
            エネルギーの購入単位、決済方法、その他のエネルギーの付与条件は、当社が定めて本サー
            ビスに表示します。
          </div>
          <div>
            3.
            エネルギーは、アイテム以外の、現金、財物その他の経済上の利益と交換することはでき
            ません。アイテムとの交換に必要なエネルギーの数、その他エネルギーの利用条件は、当社
            が定めて本サービスに表示します。
          </div>
          <div>
            4.
            エネルギーは、これを取得したアカウントでのみ利用できます。また、オペレーティング
            システムの異なる端末機器で提供されるエネルギーは、承継や合算はできません。
          </div>
          <div>
            5.
            エネルギーは、理由を問わず、一切払戻しを行いません。ただし、法令上必要な場合はこ
            の限りではありません。この場合におけるエネルギーの払戻方法は、法令に従って当社が定
            め、当社のウェブサイト等に表示します。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 10 条 （アイテム）
        <div className={styles.textIndent}>
          <div>
            1.
            本サービスにおいて、アイテムとは、本サービスにおいて児童養護施設に対して使用する
            ことにより、当該児童養護施設に対する支援を行うための電子アイテムをいいます。
          </div>
          <div>
            2.
            利用者は、本サービスに掲載された児童養護施設に対する支援のため、当社所定の方法に
            より、利用者が保有するアイテムを当該児童養護施設に対して使用することができるものと
            します。
          </div>
          <div>
            3.
            アイテムの使用は、児童養護施設に対する支援として行うものであり、使用された当該ア
            イテムの購入額に相当する金銭を当該児童養護施設に送金するものでは一切ありません。ア
            イテムの購入に使用されたエネルギーの購入額に相当する金銭を原資として、活動報告等を
            通じて本サービスを活性化する対価として当社が児童養護施設に支払う額は、当社と児童養
            護施設との間の合意によるものであり、利用者はこれに一切関与することができません。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 11 条 （活動報告等）
        <div className={styles.textIndent}>
          <div>
            1.
            アイテムの使用を受けた児童養護施設は、当該アイテムを使用した利用者に対し、本サー
            ビスを通じて、活動報告等を行います。ただし、当該活動報告等の内容や頻度等は、当該児
            童養護施設の裁量によって行われ、当社はこれに関与いたしません。
          </div>
          <div>
            2.
            児童養護施設が行う活動報告等は、アイテムの使用を通じて支援の意思を表示してくれた
            利用者に対し、無償で提供されるものです。当社は、児童養護施設に対し、活動報告等は、
            利用者の善意に対する真心として行うように依頼しており、アイテムを対価として活動報告
            等を「販売」するものとならないよう強く要請しています。この点につき利用者もご理解い
            ただき、利用者と児童養護施設双方において、十分に留意していただくようお願いいたしま
            す。
          </div>
          <div>
            3.
            当社は、児童養護施設に対し、活動報告等については児童の個人情報及びプライバシーに
            十分配慮して行うよう依頼しており、利用者においても児童の個人情報及びプライバシーを
            含む活動報告等を要求することのないよう求めます。本サービスの理念上、児童の福祉が最
            優先されるべきものであり、利用者と児童養護施設双方において、十分に留意していただく
            ようお願いいたします。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 12 条 （禁止事項）
        <div className={styles.textIndent}>
          <div>
            利用者は、本サービスの利用にあたり、次の各号のいずれかに該当する行為を行ってはな
            りません。
          </div>
          <div>(1) 本サービスの登録資格を持たない者による登録申請行為</div>
          <div>
            (2)
            法令に違反する行為、犯罪行為に関連する行為、又は公序良俗に反する行為
          </div>
          <div>(3) 本規約に違反する行為</div>
          <div>
            (4)
            当社、本サービスの他の利用者、児童養護施設、児童その他の第三者に対する詐欺又は
            脅迫行為
          </div>
          <div>
            (5)
            当社、本サービスの他の利用者、児童養護施設、児童その他の第三者の知的財産権、肖
            像権、プライバシー権その他の権利又は利益を侵害する行為
          </div>
          <div>
            (6)
            当社が提供するアプリケーション、当社が保有するサーバー及びこれらが生成する情報、
            通信内容等の解読、解析、逆コンパイル、逆アセンブル又はリバースエンジニアリング
          </div>
          <div>
            (7)
            他の利用者若しくは児童養護施設の情報又はコンテンツを違法、不適切に収集、開示そ
            の他利用する行為
          </div>
          <div>
            (8) 他の利用者又は児童養護施設の ID
            又はパスワードを入手しようとする行為
          </div>
          <div>
            (9)
            他の個人若しくは団体になりすまし、又は他の個人若しくは団体と関係があるように不
            当に見せかける行為
          </div>
          <div>(10) その他当社が不適当と判断する行為</div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 13 条 （登録抹消等）
        <div>
          <div>
            1.
            当社は、利用者が、次の各号のいずれかの事由に該当する場合は、事前に通知又は催告す
            ることなく、当社又は本サービスの他の利用者に送信したデータを削除し若しくは当該利用
            者について本サービスの利用を一時的に停止し、又は利用者としての登録を抹消、若しくは
            サービス利用契約を解除することができます。
            <div className={styles.textIndent}>
              <div>(1) 本規約のいずれかの条項に違反した場合</div>
              <div>(2) 登録事項に虚偽の事実があることが判明した場合</div>
              <div>
                (3)
                支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手
                続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
              </div>
              <div>(4) 1 年以上本サービスの利用がない場合</div>
              <div>
                (5) 当社が登録メールアドレスによる連絡が取れないと判断した場合
              </div>
              <div>
                (6)
                本サービスに関する料金の支払いなどの遅延その他の不履行があった場合
              </div>
              <div>
                (7)
                長時間の架電、同様の問い合わせの繰り返しを過度に行い、又は義務や理由のないこと
                を強要し、当社の業務が著しく支障を来たした場合
              </div>
              <div>
                (8)
                その他、当社が本サービスの利用又は利用者としての登録の継続を適当でないと判断し
                た場合
              </div>
            </div>
          </div>
        </div>
        <div>
          2.
          前項各号のいずれかの事由に該当した場合、利用者は、当社に対して負っている債務の一
          切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければ
          なりません。
        </div>
        <div>
          3.
          当社は、本条に基づき当社が行った行為により利用者に生じた損害について一切の責任を
          負いません。
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 14 条 （退会）
        <div className={styles.textIndent}>
          <div>
            1.
            利用者は、当社所定の方法で当社に通知することにより、本サービスから退会し、利用者
            としての自己の登録を抹消することができます。
          </div>
          <div>
            2.
            利用者は、退会時に本規約に別途定めるものを除き、本サービス利用に関する一切の権利、
            特典（エネルギー及びアイテムの保有を含みます。）を失うものとします。
          </div>
          <div>
            3.
            当社は、利用者の退会にあたり、購入済みのエネルギーの払戻しその他当社に対して支払
            った金銭を返還いたしません。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 15 条 （免責）
        <div className={styles.textIndent}>
          <div>
            1.
            当社は、本サービスの内容並びに利用者が本サービスを通じて入手したエネルギー、アイ
            テム及び情報等について、その完全性、正確性、確実性、有用性等につき、何ら保証するも
            のではなく、本サービスに中断、中止その他の障害が生じないことについても何ら保証しま
            せん。
          </div>
          <div>
            2.
            利用者は自らの責任に基づいて本サービスを利用するものとし、当社は本サービスにおけ
            る他の利用者及び児童養護施設の一切の行為について何らの責任を負いません。
          </div>
          <div>
            3.
            当社は、本サービスに起因して利用者に生じた損害について、当社に故意又は重過失があ
            る場合に限り、その利用者が直接被った損害を上限として損害賠償責任を負うものとします。
          </div>
          <div>
            4.
            利用者は、本サービスに関して、利用者同士、児童養護施設若しくはその他の第三者との
            間で発生した一切のトラブルについて、自らの責任で解決するものとし、当社はこれを関知
            しません。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 16 条 （本サービスの停止等）
        <div>
          1.
          当社は、次の各号のいずれかに該当する場合には、利用者にあらかじめ通知することなく、
          本サービスの全部又は一部を停止又は中断することができるものとします。
          <div className={styles.textIndent}>
            <div>
              (1)
              本サービスに係るコンピュータ・システムの点検又は保守作業を定期的又は緊急に行う
              場合
            </div>
            <div>
              (2) 通信回線、通信手段、コンピュータ等が障害により停止した場合
            </div>
            <div>
              (3)
              火災、停電、天災地変、戦争、テロ行為等の不可抗力により本サービスの運営ができな
              くなった場合
            </div>
            <div>
              (4) ハッキングその他の方法により当社の資産が盗難された場合
            </div>
            <div>(5) 本サービスの提供に必要なシステムの異常が生じた場合</div>
            <div>(6) アカウントの不正利用等の調査を行う場合</div>
            <div>
              (7)
              捜査機関等から本サービスの利用が詐欺等の犯罪行為に利用された旨の情報の提供があ
              ることその他の事情を勘案して犯罪行為が行われた疑いがある場合
            </div>
            <div>(8) その他、当社が停止を必要と判断した場合</div>
          </div>
        </div>
        <div>
          2.
          当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当
          社は利用者に事前に通知するものとします。
        </div>
        <div>
          3.
          当社は、本条に基づき当社が行った措置により利用者に生じた損害について一切の責任を
          負いません。
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 17 条 （本サービス内容の変更、廃止）
        <div className={styles.textIndent}>
          <div>
            1.
            当社は、当社の都合によりいつでも本サービスの内容を変更し、又は廃止することができ
            ます。当社が本サービスを廃止する場合、当社は利用者に事前に通知いたします。
          </div>
          <div>
            2.
            当社は、本条に基づき当社が行った措置に起因し又は関連して利用者に生じた損害につい
            て一切の責任を負いません。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 18 条 （通知）
        <div className={styles.textIndent}>
          当社から利用者に対する通知は、当社に登録されたメールアドレスにメールを送信するこ
          と又は当社が提供するアプリケーションの機能を用いた通知方法をもって行い、メール又は
          アプリケーションによる通知が通常到達すべきときに到達したものとします。{' '}
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 19 条 （本規約の変更）
        <div className={styles.textIndent}>
          <div>
            1. 当社は、1
            か月より先の効力発生日を定めて変更後の本規約を利用者に通知し、又は当社
            の管理するウェブサイトの分かりやすい場所に変更後の本規約を掲載することにより、効力
            発生日をもって本規約を変更することができるものとします。本規約の変更に同意しない利
            用者は、効力発生日までに本サービスの退会手続をとっていただきますようお願いいたしま
            す。
          </div>
          <div>
            2.
            当社は、本条に基づき当社が行った措置に基づき利用者に生じた損害について一切の責任
            を負いません。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 20 条 （秘密保持）
        <div className={styles.textIndent}>
          利用者は、本サービスに関連して当社が利用者に対して秘密に取り扱うことを求めて開示
          した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱
          うものとします。
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 21 条 （反社会的勢力の排除）
        <div>
          1.
          利用者は、当社に対し、利用者自身並びに利用者の役職員が、暴力団、暴力団員、暴力団
          員でなくなった時から 5
          年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、
          社会運動等標ぼうゴロ又は特殊知能暴力集団等、その他これらに準ずる者（以下これらを総
          称して「暴力団員等」といいます。）に該当する者ではないこと、及び次の各号のいずれに
          も該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。
          <div className={styles.textIndent}>
            <div>
              (1) 暴力団員等が経営を支配していると認められる関係を有すること
            </div>
            <div>
              (2)
              暴力団員等が経営に実質的に関与していると認められる関係を有すること
            </div>
            <div>
              (3)
              自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をも
              ってするなど、不当に暴力団員等を利用していると認められる関係を有すること
            </div>
            <div>
              (4)
              暴力団員等に対して資金等を提供し、又は便宜を供与するなどの関与をしていると認め
              られる関係を有すること
            </div>
            <div>
              (5)
              役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を
              有すること
            </div>
          </div>
        </div>
        <div>
          2.
          利用者は、自らであるか第三者を介してであるかを問わず、次の各号に該当する行為を行
          わないことを確約します。
          <div className={styles.textIndent}>
            <div>(1) 暴力的な要求行為</div>
            <div>(2) 法的な責任を超えた不当な要求行為</div>
            <div>(3) 取引に関して、脅迫的な言動をし、又は暴力を用いる行為</div>
            <div>
              (4)
              風説を流布し、偽計を用い又は威力を用いて当社の信用を毀損し、又は当社の業務を妨
              害する行為
            </div>
            <div>(5) その他前各号に準ずる行為</div>
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 22 条 （地位の譲渡等）
        <div className={styles.textIndent}>
          <div>
            1.
            利用者は、当社の書面による事前の承諾なく、本規約及び本規約上の利用者の地位又は権
            利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることは
            できません。
          </div>
          <div>
            2.
            当社は、本サービスに係る事業を他社に譲渡又は承継した場合には、当該譲渡又は承継に
            伴い本規約及び本規約上の利用者の地位、権利及び義務並びに利用者の登録情報その他の顧
            客情報等の一部又は全部を承継人に引き渡すことができるものとし、利用者は、これをあら
            かじめ承諾します。なお、本項に規定する事業の譲渡には、通常の事業譲渡のみならず、会
            社分割その他事業が移転するあらゆる場合を含むものとします。
          </div>
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 23 条 （分離可能性）
        <div className={styles.textIndent}>
          本規約等のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は
          執行不能と判断された場合であっても、一部が無効又は執行不能と判断された本規約等の規
          定の残りの部分は、継続して完全に効力を有し、当社及び利用者は、当該無効若しくは執行
          不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若
          しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるよ
          うに努めるものとします。
        </div>
      </Paragraph>
      <br />
      <Paragraph>
        第 24 条 （準拠法及び合意管轄）
        <div className={styles.textIndent}>
          本規約の準拠法は日本法とし、本規約に起因又は関連して発生する一切の紛争については、
          東京地方裁判所を第一審の専属的合意管轄裁判所とします。{' '}
        </div>
      </Paragraph>
      <br />
      <Paragraph className={styles.textRight}>以上</Paragraph>
      <br />
      <Paragraph className={styles.textLeft}>2022 年 1 月 11 日制定</Paragraph>
    </Card>
  );
};
