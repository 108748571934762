import { useState } from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';

import { withSettingLayout } from '../withSettingLayout';
import { AccountSettingTab } from '../../../../stories/AccountSettingTab/AccountSettingTab';

import styles from './index.module.scss';
import { UnsubscribeBox } from '../../../../stories/UnsubscribeBox/UnsubscribeBox';

const Unsubscribe = () => {
  const [isVisible, setIsVisible] = useState(false);
  const onUnsubscribed = () => {
    setIsVisible(true);
  };
  const onClose = () => {
    setIsVisible(false);
  };
  return (
    <AccountSettingTab
      title={<FormattedMessage id="donorAccountSetting.unsubscribed" />}
    >
      <UnsubscribeBox onMemberShipClick={onUnsubscribed} />
      <Modal
        visible={isVisible}
        title={<FormattedMessage id="donorAccountSetting.unsubscribed" />}
        centered
        closable={false}
        className={styles.unsubscribeModal}
        okButtonProps={{ danger: true, type: 'primary' }}
        onCancel={onClose}
        okText={<FormattedMessage id="accountSetting.withdraw" />}
        cancelText={<FormattedMessage id="donorHeader.cancel" />}
        onOk={onClose}
      >
        <p className={styles.modalTxt}>
          <FormattedMessage id="UnsubscribeTab.modalTxt" />
        </p>
      </Modal>
    </AccountSettingTab>
  );
};

export default withSettingLayout(Unsubscribe);
