/* eslint-disable react-hooks/exhaustive-deps */
import { Card, Space } from 'antd';
import { FormattedMessage } from 'react-intl';
import { isDeviceMobile } from '../../utils';

import { EnergyValue } from '../EnergyValue/EnergyValue';

import styles from './index.module.scss';

export interface TotalSupportAmountBoxProps {
  energy: number;
}

export function TotalSupportAmountBox({ energy }: TotalSupportAmountBoxProps) {
  if (isDeviceMobile())
    return (
      <>
        <div className={styles.totalAmountTitleMobile}>
          <FormattedMessage id="memberList.totalAmount" />
        </div>
        <Space align="center" className={styles.totalEnergyMain}>
          <div>
            <EnergyValue energy={energy} />
            <div className={styles.energyText}>
              <FormattedMessage id="energyChargePlan.energy" />
            </div>
          </div>
        </Space>
      </>
    );
  return (
    <>
      <Card className={styles.totalAmountBox}>
        <div className={styles.totalAmountTitle}>
          <FormattedMessage id="memberList.totalAmount" />
        </div>
        <Space align="center">
          <EnergyValue energy={energy} />
          <div className={styles.energyText}>
            <FormattedMessage id="energyChargePlan.energy" />
          </div>
        </Space>
      </Card>
    </>
  );
}
