import { FormattedMessage } from 'react-intl';
import { Row, Col, Form, Modal, Button } from 'antd';

import { Input } from '../../../stories/Input/Input';

import styles from './index.module.scss';

export interface ProfileSettingProps {
  open: boolean;
  isLoading: boolean | undefined;
  handleCancel: () => void;
  handleSubmit: (values: any) => void;
}

export function InvitesModal({
  open,
  isLoading,
  handleSubmit,
  handleCancel,
}: ProfileSettingProps) {
  const [form] = Form.useForm();
  const onClose = () => {
    form.resetFields();
    handleCancel();
  };

  return (
    <Modal centered footer={null} visible={open} onCancel={onClose}>
      <Row align="middle">
        <Col>
          <h2 className={styles.invitesTitle}>Invites</h2>
        </Col>
        <Col span={24} className={styles.descMain}>
          <span className={styles.invitesDesc}>
            {' '}
            Please enter the phone number of the person you want to invite.
          </span>
        </Col>
      </Row>
      <Form form={form} onFinish={handleSubmit}>
        <Row>
          <Col span={24}>
            <Input
              type="number"
              name="phoneNumber"
              rules={[{ required: true }]}
            />
          </Col>
        </Row>
        <Row justify="end" gutter={16}>
          <Col>
            <Button type="ghost" onClick={onClose}>
              <FormattedMessage id="supportCommentModel.cancel" />
            </Button>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              KEEP
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
