export function RankingBrown() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 0L37.3205 10V30L20 40L2.67949 30V10L20 0Z" fill="#9E5E10" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9982 40V0L2.67773 10V30L19.9982 40Z"
        fill="#C29053"
      />
      <g filter="url(#filter0_i_5731_10222)">
        <path
          d="M20 4L33.8564 12V28L20 36L6.14359 28V12L20 4Z"
          fill="#80400A"
        />
      </g>
      <path
        d="M15.3203 15.0605C15.8112 13.901 16.429 13.0081 17.1738 12.3818C17.9271 11.7471 18.8623 11.4297 19.9795 11.4297C21.359 11.4297 22.417 11.8783 23.1533 12.7754C23.7119 13.444 23.9912 14.1592 23.9912 14.9209C23.9912 16.1735 23.2041 17.4684 21.6299 18.8057C22.6878 19.2204 23.4876 19.8128 24.0293 20.583C24.571 21.3532 24.8418 22.2588 24.8418 23.2998C24.8418 24.7894 24.3678 26.0801 23.4199 27.1719C22.1842 28.5938 20.3942 29.3047 18.0498 29.3047C16.8903 29.3047 16.099 29.1608 15.6758 28.873C15.2611 28.5853 15.0537 28.2764 15.0537 27.9463C15.0537 27.7008 15.151 27.485 15.3457 27.2988C15.5488 27.1126 15.79 27.0195 16.0693 27.0195C16.2809 27.0195 16.4967 27.0534 16.7168 27.1211C16.8607 27.1634 17.1865 27.32 17.6943 27.5908C18.2021 27.8532 18.5534 28.0098 18.748 28.0605C19.0612 28.1536 19.3955 28.2002 19.751 28.2002C20.6143 28.2002 21.3633 27.8659 21.998 27.1973C22.6413 26.5286 22.9629 25.7373 22.9629 24.8232C22.9629 24.1546 22.8148 23.5029 22.5186 22.8682C22.2985 22.3942 22.0573 22.0345 21.7949 21.7891C21.431 21.4505 20.9316 21.1458 20.2969 20.875C19.6621 20.5957 19.0146 20.4561 18.3545 20.4561H17.9482V20.0752C18.6169 19.9906 19.2855 19.7493 19.9541 19.3516C20.6312 18.9538 21.1221 18.4756 21.4268 17.917C21.7314 17.3584 21.8838 16.7448 21.8838 16.0762C21.8838 15.2044 21.6087 14.502 21.0586 13.9688C20.5169 13.4271 19.8398 13.1562 19.0273 13.1562C17.7155 13.1562 16.6195 13.8587 15.7393 15.2637L15.3203 15.0605Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_i_5731_10222"
          x="6.14453"
          y="4"
          width="27.7109"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_5731_10222"
          />
        </filter>
      </defs>
    </svg>
  );
}
