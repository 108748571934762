import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';

export interface FacilityTabsProps {
  facilityId: string;
}

export const FacilityTabs: React.FC<FacilityTabsProps> = ({ facilityId }) => {
  const { TabPane } = Tabs;
  const history = useHistory();
  const pageKey = window.location.pathname.split('/').pop();

  const handleClick = (key: string) => {
    history.push(`/facility/${facilityId}/${key}`);
  };

  return (
    <Tabs defaultActiveKey={pageKey} onChange={handleClick}>
      <TabPane tab="Support Status" key="support-status" />
      <TabPane tab="Profile" key="basic-information" />
      <TabPane tab="Activity report" key="activity-report" />
      <TabPane tab="Thanks Message" key="thanks-message" />
    </Tabs>
  );
};
