import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';

import { isDeviceMobile } from '../../utils';
import { PlainBox } from '../PlainBox/PlainBox';
import { UserProfileForm } from './UserProfileForm';
import { CardBox } from '../../stories/CardBox/CardBox';

import styles from './index.module.scss';

export interface UserProfileComponentProps {
  isLoading: boolean;
  onFinish: (values: any) => void;
  error: FetchBaseQueryError | SerializedError | undefined;
}

export const UserProfileComponent: React.FC<UserProfileComponentProps> = ({
  error,
  isLoading,
  onFinish,
}) => {
  if (isDeviceMobile()) {
    return (
      <PlainBox title="User Profile">
        <UserProfileForm
          error={error}
          onFinish={onFinish}
          isLoading={isLoading}
        />
      </PlainBox>
    );
  }

  return (
    <CardBox className={styles.card} title="User Profile">
      <UserProfileForm
        error={error}
        onFinish={onFinish}
        isLoading={isLoading}
      />
    </CardBox>
  );
};
