/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row,
  Button,
  Col,
  Space,
  Progress,
  Avatar,
  Tabs,
  notification,
} from 'antd';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  PostType,
  useGetPostsQuery,
  useDeleteUserPostMutation,
} from '../../../../services/apiService';
import { RootState } from '../../../../store';
import { FollowerModal } from './FollowerModal';
import { Link } from '../../../../stories/Icons/Link/Link';
import { getAccessToken } from '../../../../getAccessToken';
import { setCounter } from '../../../../features/me/meSlice';
import { Medal } from '../../../../stories/Icons/Medal/Medal';
import { AddPost } from '../../../../stories/AddPost/AddPost';
import { UserPost } from '../../../../stories/UserPost/UserPost';
import { Square } from '../../../../stories/Icons/Square/Square';
import { dummyUserProfile, enableFeature } from '../../../../constant';
import { Location } from '../../../../stories/Icons/Location/Location';
import { setPostDetails } from '../../../../features/post/postListSlice';
import { DonorFooter } from '../../../../stories/DonorFooter/DonorFooter';
import { ProfileSettingModal } from '../../../InviteUser/ProfileSetting/ProfileSettingModal';

import styles from './index.module.scss';

const { TabPane } = Tabs;

const UserPostInformation = () => {
  let defaultActiveKey = '1';
  const history = useHistory();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [isFollowerVisible, setIsFollowerVisible] = useState(false);
  const [token, setToken] = useState<string | undefined>('');

  const [isPost, setIsPost] = useState(false);
  const me = useSelector((state: RootState) => state.me);
  const [paginateInfo, setPaginateInfo] = useState({
    page: 1,
    limit: 10,
    userId: me?.id,
  });

  const { data: postData } = useGetPostsQuery(paginateInfo);
  const [deleteUserPost, { isSuccess, error }] = useDeleteUserPostMutation();

  const handleProfileSetting = () => {
    setIsVisible(false);
    dispatch(setCounter());
  };
  const handleOpenProfileSetting = () => {
    setIsVisible(true);
  };

  const handleFollower = () => {
    setIsFollowerVisible(!isVisible);
  };

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [error]);
  const handleAddPost = () => {
    setIsPost(!isPost);
    dispatch(setPostDetails({ postDetails: null }));
  };

  return (
    <>
      <Row justify="center" className={styles.row} gutter={15}>
        <Col xs={24} sm={24} md={3} lg={2} xl={2} xxl={1}>
          <Avatar
            src={
              me?.profileImage?.id
                ? getUrl(me.profileImage.id)
                : dummyUserProfile
            }
            size={72}
            shape={'circle'}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={21}
          lg={17}
          xl={11}
          xxl={9}
          className={styles.userContentMainSec}
        >
          <Row gutter={0} className={styles.userContentMain}>
            <Col
              sm={24}
              md={14}
              lg={14}
              xl={14}
              xxl={14}
              className={styles.userContent}
            >
              <div className={styles.userNamePart}>
                <p className={styles.userNameText}>{me?.fullName}</p>
                <div className={styles.diamondImage}>
                  <Square />
                </div>
              </div>
              {enableFeature.userPostInfoLevel && (
                <>
                  <p className={styles.userNameLevalText}>Lv. 3</p>

                  <span className={styles.progressMain}>
                    <Progress
                      percent={30}
                      className={styles.userProgress}
                      showInfo={false}
                    />
                    <span className={styles.progressPer}>E</span>
                    <span className={styles.progressText}>1/5</span>
                  </span>
                </>
              )}
            </Col>
            <Col
              sm={24}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className={styles.userContentBtn}
            >
              <div>
                <Button
                  type="default"
                  shape="round"
                  size="large"
                  onClick={handleOpenProfileSetting}
                >
                  <Space size="large">
                    <FormattedMessage id="inviteUser.profileSetting" />
                  </Space>
                </Button>
              </div>
            </Col>
          </Row>
          <div className={styles.followerText}>{me?.intro}</div>
          <div className={styles.userContentTexts}>
            <div className={styles.userContentMain}>
              {enableFeature.userPostInfoFollower && (
                <div className={styles.followerMain} onClick={handleFollower}>
                  <p className={styles.followerCount}>100</p>
                  <p className={styles.followerText}> Follower</p>
                </div>
              )}
              {me?.area && (
                <div className={styles.followerMain}>
                  <div className={styles.locationImage}>
                    <Location />
                  </div>

                  <p className={styles.locationText}>{me.area}</p>
                </div>
              )}
            </div>
            <div className={styles.userContentMain}>
              {me?.link && (
                <div className={styles.followerMain}>
                  <div className={styles.locationImage}>
                    <Link />
                  </div>
                  <a
                    href={me?.link}
                    target="_blank"
                    className={styles.linkText}
                    rel="noreferrer"
                  >
                    {me.link}
                  </a>
                </div>
              )}
              {me?.title && (
                <div className={styles.followerMain}>
                  <div className={styles.medalImage}>
                    <Medal />
                  </div>
                  <p className={styles.locationText}>{me.title}</p>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="center" className={styles.row}>
        <Col xxl={10} xl={12} lg={18} sm={24} xs={24} md={24}>
          <Tabs defaultActiveKey={defaultActiveKey} className={styles.tabMain}>
            <TabPane tab={<FormattedMessage id="search.post" />} key="1">
              {postData?.data?.map((item: PostType, index: number) => (
                <UserPost
                  key={index}
                  item={item}
                  user={{
                    id: Number(me.id),
                    fullName: me.fullName,
                    profileImage: { id: Number(me?.profileImage?.id) },
                  }}
                  onCardClick={() => {
                    history.push(`/posts/${item.id}`);
                  }}
                  onUpdateClick={() => {
                    dispatch(setPostDetails({ postDetails: item }));
                    setIsPost(true);
                  }}
                  onDeleteClick={() => {
                    deleteUserPost(item.id);
                  }}
                  onCommentClick={() => {
                    history.push(`/posts/${item.id}`, { focus: true });
                  }}
                />
              ))}
            </TabPane>
            <TabPane
              tab={<FormattedMessage id="userPostInformation.howNice" />}
              key="2"
            >
              <div className={styles.blankContent}></div>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <ProfileSettingModal
        open={isVisible}
        handleCancel={handleProfileSetting}
      />
      <AddPost open={isPost} handleCancel={handleAddPost} />
      <FollowerModal open={isFollowerVisible} handleCancel={handleFollower} />
      <DonorFooter />
    </>
  );
};

export default UserPostInformation;
