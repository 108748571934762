import { Row, Space } from 'antd';
import { FormattedMessage } from 'react-intl';

import { SowSeeds } from '../Icons/SowSeeds/SowSeeds';
import { SupportType } from '../../services/apiService';
import { GiveWater } from '../Icons/GiveWater/GiveWater';
import { PourLight } from '../Icons/PourLight/PourLight';
import { SupportAmountSelected } from '../Icons/SupportAmountSelected/SupportAmountSelected';
import { BackgroundSupportAmount } from '../Icons/BackgroundSupportAmount/BackgroundSupportAmount';
import { SupportAmountUnselected } from '../Icons/SupportAmountUnselected/SupportAmountUnselected';

import styles from './index.module.scss';

export interface SupportAmountProps {
  energy: number;
  active: boolean;
  onClick: () => void;
  supportType: SupportType;
}

export const MobileSupportAmount = ({
  energy,
  active,
  onClick,
  supportType,
}: SupportAmountProps) => {
  const PaymentImage = () => {
    switch (supportType) {
      case SupportType.sowSeeds:
        return (
          <SowSeeds
            height={active ? 78 : 64}
            width={active ? 78 : 64}
            fill="#BDA35E"
          />
        );
      case SupportType.giveWater:
        return (
          <GiveWater
            height={active ? 78 : 64}
            width={active ? 78 : 64}
            fill="#BDA35E"
          />
        );
      default:
        return (
          <PourLight
            height={active ? 78 : 64}
            width={active ? 78 : 64}
            fill="#BDA35E"
          />
        );
    }
  };

  if (active) {
    return (
      <Row justify="space-around" className={styles.selectedContainer}>
        <div className={styles.backgroundImg}>
          <BackgroundSupportAmount />
        </div>
        <Row justify="space-around" className={styles.supportAmountBox}>
          <Row justify="center">
            <Space>
              <Row justify="center">
                <SupportAmountSelected />
              </Row>
              <div className={styles.title}>Support Amount</div>
            </Space>
          </Row>
          <Row justify="center">
            <PaymentImage />
          </Row>
          <Row justify="center">
            <Space>
              <div className={styles.amount}>{energy}</div>
              <div className={styles.text}>
                <FormattedMessage id="energyChargePlan.energy" />
              </div>
            </Space>
          </Row>
        </Row>
      </Row>
    );
  }

  return (
    <Row justify="space-around" className={styles.unSelectedContainer}>
      <Row justify="center">
        <Space>
          <Row justify="center">
            <SupportAmountUnselected />
          </Row>
          <div className={styles.title}>Support Amount</div>
        </Space>
      </Row>
      <Row justify="center">
        <PaymentImage />
      </Row>
      <Row justify="center">
        <Space>
          <div className={styles.amount}>{energy}</div>
          <div className={styles.text}>
            <FormattedMessage id="energyChargePlan.energy" />
          </div>
        </Space>
      </Row>
    </Row>
  );
};
