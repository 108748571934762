export function Star() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0582 22.0804L39.9002 19.9995L24.0582 17.9187L30.5885 9.40831L22.081 15.9395L20.0002 0.0996094L17.9163 15.9415L9.41186 9.40831L15.9431 17.9187L0.100159 19.9995L15.9431 22.0804L9.41186 30.5869L17.9192 24.0566L20.0002 39.8966L22.081 24.0566L30.5885 30.5869L24.0582 22.0804Z"
        fill="#CEC5B9"
      />
    </svg>
  );
}
