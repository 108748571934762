/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Carousel, Col, Divider, Row } from 'antd';

import { routes } from '../../constant';
import { AssistBtn } from '../AssistBtn/AssistBtn';
import { Possession } from '../Possession/Possession';
import { SupportOption, SupportType } from '../../services/apiService';
import { MobileSupportAmount } from '../MobileSupportAmount/MobileSupportAmount';

import styles from './index.module.scss';
import { FormattedMessage } from 'react-intl';

export interface SupportOptionProps {
  data: SupportOption[];
  energy: number;
  onSubmit: any;
}

export function MobileSupportAmountBox({
  data,
  energy,
  onSubmit,
}: SupportOptionProps) {
  const [selected, setSelected] = useState(1);

  function onChange(a: number) {
    setSelected(a);
  }
  const onClick = () => {
    onSubmit(data[selected]);
  };

  const getSupportType = (type: string) => {
    if (type === 'Sow Seed') return SupportType.sowSeeds;
    if (type === 'Give Water') return SupportType.giveWater;
    else return SupportType.pourLight;
  };
  return (
    <div className={styles.mobileSupportAmount}>
      <Carousel
        dots={false}
        centerMode
        speed={100}
        swipeToSlide
        variableWidth
        initialSlide={1}
        slidesToShow={1}
        afterChange={onChange}
      >
        {data.map((opt, index) => (
          <div className={styles.carouselItem} key={index}>
            <MobileSupportAmount
              energy={opt.energy}
              active={selected === index}
              onClick={() => {}}
              supportType={getSupportType(opt.type)}
            />
          </div>
        ))}
      </Carousel>
      <div className={styles.infoContent}>
        <Row justify="space-between" className={styles.info}>
          <Col span="12" className={styles.ownedEnergy}>
            <FormattedMessage id="mobileSupportAmount.ownedEnergy" />
          </Col>
          <Col span="4" className={styles.charge}>
            <Link to={routes.EnergyChargeUrl}>
              <FormattedMessage id="mobileSupportAmount.charge" />
            </Link>
          </Col>
        </Row>
        <Row>
          <Possession amount={energy} />
          <Divider className={styles.divider} />
        </Row>
        <Row justify="center">
          <Col span="18">
            <AssistBtn disabled={false} handleClick={onClick} />
          </Col>
        </Row>
      </div>
    </div>
  );
}
