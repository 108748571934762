import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MeType } from '../../services/apiService';

export type LanguageType = 'en' | 'jp';

interface MeState extends MeType {
  isDataLoaded: boolean;
  language: LanguageType;
  counter: number;
}

const language = (localStorage.getItem('userLanguage') as LanguageType) || 'jp';

const initialState: MeState = {
  dob: '',
  role: '',
  area: '',
  link: '',
  title: '',
  intro: '',
  email: '',
  gender: '',
  energy: 0,
  fullName: '',
  language: language,
  postalCode: '',
  phoneNumber: '',
  annualIncome: '',
  isDataLoaded: true,
  streetAddress: '',
  totalBoughtEnergy: 0,
  totalDonatedEnergy: 0,
  maxInvitationLimit: 2,
  ownedFacility: undefined,
  isProfileCompleted: false,
  counter: 0,
};

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    logout: (state) => {
      return initialState;
    },
    setMe: (
      state,
      action: PayloadAction<{
        data: MeType | undefined;
      }>
    ) => {
      const { data } = action.payload;
      if (data) {
        return {
          ...data,
          language: language,
          isDataLoaded: false,
          counter: state.counter + 1,
        };
      }
      return {
        ...state,
      };
    },
    setLanguage: (state, action: PayloadAction<{ language: LanguageType }>) => {
      localStorage.setItem('userLanguage', action.payload.language);
      return { ...state, language: action.payload.language };
    },
    setCounter: (state) => {
      return { ...state, counter: state.counter + 1 };
    },
  },
});

export const { setMe, logout, setLanguage, setCounter } = meSlice.actions;

export default meSlice.reducer;
