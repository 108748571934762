import { Tag } from 'antd';
import { TagIcon } from '../Icons/TagIcon/TagIcon';

import styles from './index.module.scss';

export interface TagBoxProps {
  name: string;
}

export const TagBox = ({ name }: TagBoxProps) => {
  return (
    <div className={styles.tagMainContent}>
      <Tag icon={<TagIcon />}>{name}</Tag>
    </div>
  );
};
