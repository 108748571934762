import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

export interface AssitBtnProps {
  handleClick: () => void;
  disabled: boolean | undefined;
}

export const AssistBtn = ({ disabled, handleClick }: AssitBtnProps) => {
  return (
    <Button
      block
      size="large"
      type="primary"
      disabled={disabled}
      onClick={handleClick}
      className={styles.assitBtn}
    >
      <FormattedMessage id="supportAmount.assistBtn" />
    </Button>
  );
};
