import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InquiryType {
  key: number;
  text: string;
  date: string;
  image: string;
  title: string;
  username: string;
}

export interface InquiryList {
  inquirysData: InquiryType[];
}

const initialState: InquiryList = {
  inquirysData: [],
};

export const inquiryListSlice = createSlice({
  name: 'inquirys',
  initialState,
  reducers: {
    setInquiryList: (
      state,
      action: PayloadAction<{ inquirysData: InquiryType[] }>
    ) => {
      state.inquirysData = action.payload.inquirysData;
    },
  },
});

export const { setInquiryList } = inquiryListSlice.actions;

export default inquiryListSlice.reducer;
