import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FacilityBlog {
  image: string;
  title: string;
  reportDate: string;
  description: string;
}

interface FacilityBlogData {
  facilityBlogs: FacilityBlog[];
}

const initialState: FacilityBlogData = {
  facilityBlogs: [],
};

export const facilityBlogSlice = createSlice({
  name: 'facility',
  initialState,
  reducers: {
    setFacilityBlogList: (
      state,
      action: PayloadAction<{ facilityBlogs: FacilityBlog[] }>
    ) => {
      state.facilityBlogs = action.payload.facilityBlogs;
    },
  },
});

export const { setFacilityBlogList } = facilityBlogSlice.actions;

export default facilityBlogSlice.reducer;
