export function BlackPlan() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 87 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M83.5658 19.284L78.9868 28.3588L87 38.568C86.6184 44.0507 84.825 56.8311 80.7039 64.091C72.4618 83.1481 51.7039 92.8279 43.5 95.2857V0C58.6105 0 77.2697 12.856 83.5658 19.284Z"
        fill="#536C56"
      />
      <path
        d="M3.43421 19.284L8.01316 28.3588L0 38.568C0.381579 44.0507 2.175 56.8311 6.29605 64.091C14.5382 83.1481 35.2961 92.8279 43.5 95.2857V0C28.3895 0 9.73026 12.856 3.43421 19.284Z"
        fill="#808080"
      />
      <path
        d="M83.5658 19.284L78.9868 28.3588L87 38.568C86.6184 44.0507 84.825 56.8311 80.7039 64.091C72.4618 83.1481 51.7039 92.8279 43.5 95.2857V0C58.6105 0 77.2697 12.856 83.5658 19.284Z"
        fill="#4D4D4D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M70.0805 29.0967L73.7873 21.7505C68.6905 16.5469 55.732 8.2862 43.4997 8.2862V85.4218C50.141 83.4322 66.9449 75.5962 73.617 60.169C76.9531 54.2921 78.4049 43.9461 78.7138 39.5077L70.0805 29.0967ZM16.9184 29.0969L13.2117 21.7507C18.3085 16.5471 31.267 8.28637 43.4992 8.28637V85.4219C36.858 83.4323 20.0541 75.5963 13.3819 60.1692C10.0459 54.2922 8.59405 43.9463 8.28516 39.5079L16.9184 29.0969Z"
        fill="url(#paint0_radial_1415_44387)"
      />
      <path
        d="M50.8557 45.1443C52.7573 45.5488 54.18 46.1962 55.124 47.0862C56.4322 48.3269 57.0862 49.8441 57.0862 51.6377C57.0862 52.9998 56.6547 54.3079 55.7916 55.5621C54.9285 56.8028 53.7417 57.7131 52.2313 58.293C50.7344 58.8594 48.4418 59.1426 45.3535 59.1426H32.4071V58.3941H33.4388C34.5851 58.3941 35.4077 58.03 35.9067 57.3018C36.2168 56.8298 36.3719 55.8251 36.3719 54.2877V36.5672C36.3719 34.868 36.1764 33.7959 35.7853 33.3509C35.2593 32.7575 34.4772 32.4608 33.4388 32.4608H32.4071V31.7123H44.2612C46.4728 31.7123 48.2462 31.8742 49.5813 32.1978C51.6042 32.6833 53.1483 33.5464 54.2137 34.7871C55.2791 36.0143 55.8118 37.4303 55.8118 39.0352C55.8118 40.4107 55.3937 41.6447 54.5576 42.737C53.7215 43.8159 52.4875 44.6183 50.8557 45.1443ZM40.2559 44.0519C40.7548 44.1463 41.3212 44.2205 41.9551 44.2744C42.6024 44.3149 43.3104 44.3351 44.0791 44.3351C46.048 44.3351 47.5247 44.1261 48.5092 43.708C49.5072 43.2765 50.2691 42.6224 50.7951 41.7458C51.321 40.8692 51.584 39.9117 51.584 38.8733C51.584 37.2685 50.9299 35.8997 49.6218 34.7669C48.3137 33.6341 46.4054 33.0677 43.897 33.0677C42.5485 33.0677 41.3347 33.216 40.2559 33.5127V44.0519ZM40.2559 57.1602C41.8202 57.5243 43.3644 57.7063 44.8883 57.7063C47.3292 57.7063 49.1902 57.1602 50.4714 56.0678C51.7526 54.962 52.3931 53.5999 52.3931 51.9816C52.3931 50.9162 52.1032 49.8913 51.5233 48.9068C50.9434 47.9223 49.9994 47.1469 48.6913 46.5805C47.3831 46.0141 45.7648 45.7309 43.8364 45.7309C43.0002 45.7309 42.2855 45.7444 41.6921 45.7713C41.0987 45.7983 40.62 45.8455 40.2559 45.9129V57.1602Z"
        fill="white"
      />
      <g clip-path="url(#clip0_1415_44387)">
        <path
          d="M24.0149 28.5206L27.9754 28.0004L24.0149 27.4802L25.6475 25.3526L23.5206 26.9854L23.0004 23.0254L22.4794 26.9859L20.3533 25.3526L21.9861 27.4802L18.0254 28.0004L21.9861 28.5206L20.3533 30.6472L22.4802 29.0146L23.0004 32.9746L23.5206 29.0146L25.6475 30.6472L24.0149 28.5206Z"
          fill="#F7F6F0"
        />
      </g>
      <g clip-path="url(#clip1_1415_44387)">
        <path
          d="M15.2177 58.6245L19.9703 58.0002L15.2177 57.376L17.1768 54.8229L14.6245 56.7822L14.0003 52.0303L13.3751 56.7828L10.8238 54.8229L12.7831 57.376L8.03027 58.0002L12.7831 58.6245L10.8238 61.1765L13.376 59.2174L14.0003 63.9694L14.6245 59.2174L17.1768 61.1765L15.2177 58.6245Z"
          fill="#F7F6F0"
        />
      </g>
      <path
        d="M40.6084 72.312L42.9846 71.9999L40.6084 71.6877L41.5879 70.4111L40.3118 71.3908L39.9996 69.0146L39.6871 71.3911L38.4114 70.4111L39.3911 71.6877L37.0146 71.9999L39.3911 72.312L38.4114 73.5881L39.6875 72.6085L39.9996 74.9847L40.3118 72.6085L41.5879 73.5881L40.6084 72.312Z"
        fill="#F7F6F0"
      />
      <path
        d="M65.6084 62.3118L67.9846 61.9996L65.6084 61.6875L66.5879 60.411L65.3118 61.3906L64.9996 59.0146L64.6871 61.3909L63.4114 60.411L64.3911 61.6875L62.0146 61.9996L64.3911 62.3118L63.4114 63.5877L64.6875 62.6082L64.9996 64.9842L65.3118 62.6082L66.5879 63.5877L65.6084 62.3118Z"
        fill="#F7F6F0"
      />
      <g clip-path="url(#clip2_1415_44387)">
        <path
          d="M62.6233 23.8324L68.96 23L62.6233 22.1677L65.2354 18.7635L61.8324 21.376L61 15.04L60.1665 21.3768L56.7647 18.7635L59.3772 22.1677L53.04 23L59.3772 23.8324L56.7647 27.235L60.1677 24.6228L61 30.9588L61.8324 24.6228L65.2354 27.235L62.6233 23.8324Z"
          fill="#F7F6F0"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_1415_44387"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(43.4995 46.854) rotate(90) scale(38.5679 35.2143)"
        >
          <stop stop-color="#464646" />
          <stop offset="1" stop-color="#222222" />
        </radialGradient>
        <clipPath id="clip0_1415_44387">
          <rect
            width="10"
            height="9.99924"
            fill="white"
            transform="translate(18 23)"
          />
        </clipPath>
        <clipPath id="clip1_1415_44387">
          <rect
            width="12"
            height="11.9991"
            fill="white"
            transform="translate(8 52)"
          />
        </clipPath>
        <clipPath id="clip2_1415_44387">
          <rect
            width="16"
            height="15.9988"
            fill="white"
            transform="translate(53 15)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
