import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { isDeviceMobile } from '../../utils';
import { PlainBox } from '../PlainBox/PlainBox';
import { CardBox } from '../../stories/CardBox/CardBox';
import { ForgotPasswordRequestOtpForm } from './ForgotPasswordRequestOtpForm';

export interface ForgotPasswordRequestOtpComponentProps {
  onFinish: (values: any) => void;
}

export const ForgotPasswordRequestOtpComponent: React.FC<ForgotPasswordRequestOtpComponentProps> =
  ({ onFinish }) => {
    const { Text } = Typography;

    if (isDeviceMobile()) {
      return (
        <PlainBox title="Forgot Password">
          <ForgotPasswordRequestOtpForm onFinish={onFinish} />
        </PlainBox>
      );
    }

    return (
      <CardBox
        title="Forgot Password"
        subtitle={
          <Text>
            <FormattedMessage id="login.subTitle" />
          </Text>
        }
      >
        <ForgotPasswordRequestOtpForm onFinish={onFinish} />
      </CardBox>
    );
  };
