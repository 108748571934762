import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { LoginForm } from './LoginForm';
import { isDeviceMobile } from '../../utils';
import { CardBox } from '../../stories/CardBox/CardBox';
import { PlainBox } from '../PlainBox/PlainBox';

export interface LoginComponentProps {
  loading: boolean;
  onFinish: (values: any) => void;
}

export const LoginComponent: React.FC<LoginComponentProps> = ({
  loading,
  onFinish,
}) => {
  const { Text } = Typography;

  if (isDeviceMobile()) {
    return (
      <PlainBox title="Login">
        <LoginForm loading={loading} onFinish={onFinish} />
      </PlainBox>
    );
  }

  return (
    <CardBox
      title="Login"
      subtitle={
        <Text>
          <FormattedMessage id="login.subTitle" />
        </Text>
      }
    >
      <LoginForm loading={loading} onFinish={onFinish} />
    </CardBox>
  );
};
