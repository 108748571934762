import { Col, Card, Form } from 'antd';
import { FormattedMessage } from 'react-intl';

import { Input } from '../../../../../stories/Input/Input';

import styles from './index.module.scss';

const UserInformation = () => {
  return (
    <Card title={<FormattedMessage id="userInformation.userInformation" />}>
      <Form>
        <div className={styles.input}>
          <Col span={6}>
            <Input
              type="date"
              name="birthDate"
              placeholder="Birth Date"
              rules={[{ required: true }]}
            />
          </Col>
        </div>
        <div className={styles.input}>
          <Col span={6}>
            <Input
              type="number"
              name="phoneNumber"
              placeholder="Phone Number"
              rules={[{ required: true }]}
            />
          </Col>
          <Col span={18}>
            <Input
              type="email"
              name="email"
              placeholder="Email Address"
              rules={[{ type: 'email', required: true }]}
            />
          </Col>
        </div>
        <div className={styles.input}>
          <Col span={6}>
            <Input
              type="number"
              name="postalCode"
              placeholder="Postal Code"
              rules={[{ required: true }]}
            />
          </Col>
          <Col span={18}>
            <Input
              type="text"
              name="streetAddress"
              placeholder="Street Address"
              rules={[{ required: true }]}
            />
          </Col>
        </div>
      </Form>
    </Card>
  );
};

export default UserInformation;
