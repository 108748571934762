import { combineReducers } from 'redux';
import { useDispatch } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import meReducer from './features/me/meSlice';
import postListReducer from './features/post/postListSlice';
import memberListReducer from './features/member/memberListSlice';
import InquiryListReducer from './features/Inquiry/InquiryListSlice';
import thanksMessageReducer from './features/facility/thanksMsgSlice';
import facilityUserReducer from './features/facility/facilityUserSlice';
import facilityBlogReducer from './features/facility/facilityBlogSlice';
import EnergyChargeReducer from './features/EnergyCharge/EnergyChargeSlice';
import cognitoUserDetailReducer from './features/cognitoUserDetail/cognitoUserDetail';

import { apiService } from './services/apiService';

const RootReducer = combineReducers({
  me: meReducer,
  postList: postListReducer,
  membersList: memberListReducer,
  inquiryList: InquiryListReducer,
  facilityUser: facilityUserReducer,
  facilityBlogs: facilityBlogReducer,
  thanksMessage: thanksMessageReducer,
  energy: EnergyChargeReducer,
  cognitoUserDetail: cognitoUserDetailReducer,
  [apiService.reducerPath]: apiService.reducer,
});

const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiService.middleware),
});

export default store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof RootReducer>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
