import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Row, Col, Tabs } from 'antd';

import { routes } from '../../../constant';
import { DonorFooter } from '../../../stories/DonorFooter/DonorFooter';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsAndService } from './TermsAndService';
import { CommercialLaw } from './CommercialLaw';

import styles from './index.module.scss';

const { TabPane } = Tabs;

const InfoPages = () => {
  const location = useLocation();

  let defaultActiveKey = '1';
  switch (location.pathname) {
    case routes.CommercialLawUrl:
      defaultActiveKey = '3';
      break;
    case routes.TermsAndServiceUrl:
      defaultActiveKey = '2';
      break;
    default:
      defaultActiveKey = '1';
  }

  return (
    <>
      <Row justify="center">
        <Col
          xxl={12}
          xl={12}
          md={18}
          sm={24}
          xs={24}
          className={styles.mainCol}
        >
          <Tabs defaultActiveKey={defaultActiveKey} className={styles.tabMain}>
            <TabPane
              tab={<FormattedMessage id="donorFooter.privacyPolicy" />}
              key="1"
            >
              <PrivacyPolicy />
            </TabPane>
            <TabPane tab={<FormattedMessage id="donorFooter.tos" />} key="2">
              <TermsAndService />
            </TabPane>
            <TabPane tab={<FormattedMessage id="donorFooter.law" />} key="3">
              <CommercialLaw />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <DonorFooter />
    </>
  );
};

export default InfoPages;
