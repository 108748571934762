/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Avatar as AntdAvatar } from 'antd';

import { dummyUserProfile } from '../../constant';
import { useGetUrlById } from '../../useGetUrlById';

export interface AvatarImageProps {
  urlId: number | undefined;
  size?: number;
  shape?: 'circle' | 'square';
}
export const Avatar = ({ urlId, size, shape = 'circle' }: AvatarImageProps) => {
  const url = useGetUrlById(urlId);

  return (
    <AntdAvatar src={url || dummyUserProfile} size={size || 40} shape={shape} />
  );
};
