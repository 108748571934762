import { Row, Col, Form, Modal, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

import { Input } from '../Input/Input';
import { SowSeeds } from '../Icons/SowSeeds/SowSeeds';
import { SupportType } from '../../services/apiService';
import { GiveWater } from '../Icons/GiveWater/GiveWater';
import { PourLight } from '../Icons/PourLight/PourLight';

import styles from './index.module.scss';

export interface SupportCommentModalProps {
  open: boolean;
  energy: number;
  handleCancel: () => void;
  supportType: SupportType;
  isLoading: boolean | undefined;
  handleSubmit: (values: any) => void;
  supportTypeText: (supportType: SupportType) => JSX.Element;
}

export function SupportCommentModal({
  open,
  energy,
  isLoading,
  supportType,
  supportTypeText,
  handleCancel,
  handleSubmit,
}: SupportCommentModalProps) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const PaymentImage = () => {
    switch (supportType) {
      case SupportType.sowSeeds:
        return <SowSeeds height={48} width={48} fill="#BDA35E" />;
      case SupportType.giveWater:
        return <GiveWater height={48} width={48} fill="#BDA35E" />;
      default:
        return <PourLight height={48} width={48} fill="#BDA35E" />;
    }
  };

  const onClose = () => {
    form.resetFields();
    handleCancel();
  };

  const handleFinish = (values: {
    comment: string;
    donationUnitBoost: number;
  }) => {
    handleSubmit(values);
  };

  return (
    <Modal
      centered
      footer={null}
      visible={open}
      onCancel={onClose}
      className={styles.paymentModal}
    >
      <Row justify="center" align="middle" className={styles.header}>
        <Col>
          <PaymentImage />
        </Col>
        <Col className={styles.supportType}>{supportTypeText(supportType)}</Col>
        <Col className={styles.paymentAmount}>
          {energy}{' '}
          <FormattedMessage id="supportCommentModel.energyConsumption" />
        </Col>
      </Row>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={handleFinish}
        initialValues={{
          donationUnitBoost: 1,
        }}
      >
        <Row>
          <Col span="6">
            <Input
              type="number"
              label={intl.formatMessage({
                id: 'supportCommentModel.noOfTimes',
              })}
              name="donationUnitBoost"
              rules={[
                { required: true, message: 'is required' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.resolve();
                    }

                    if (value > 1000) {
                      return Promise.reject(
                        new Error('should be less than 1000')
                      );
                    }
                    if (value < 0)
                      return Promise.reject(new Error('should be atleast one'));
                    return Promise.resolve();
                  },
                }),
              ]}
            />
          </Col>
        </Row>
        <Row className={styles.descriptionComment}>
          <Input
            rows={5}
            showCount
            name="comment"
            type="textarea"
            label={intl.formatMessage({
              id: 'supportCommentModel.postComment',
            })}
            maxLength={120}
          />
        </Row>
        <Row justify="end" gutter={16}>
          <Col>
            <Button type="ghost" onClick={onClose}>
              <FormattedMessage id="supportCommentModel.cancel" />
            </Button>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              {supportTypeText(supportType)}
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
