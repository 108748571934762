import { Avatar, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import { dummyUserProfile } from '../../constant';
import { getAccessToken } from '../../getAccessToken';

import styles from './index.module.scss';

const { Title } = Typography;

export interface UserStoryProps {
  type?: string;
  user: {
    id: number;
    fullName: string;
    profileImage: { id: number | undefined };
  };
  onUserClick?: () => void;
}

export const UserStory = ({ type, user, onUserClick }: UserStoryProps) => {
  const [token, setToken] = useState<string | undefined>('');
  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };
  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  if (type === 'user')
    return (
      <div onClick={onUserClick}>
        <div className={styles.mainUser}>
          <Avatar
            src={
              user.profileImage?.id
                ? getUrl(user.profileImage.id)
                : dummyUserProfile
            }
            size={76}
            shape={'circle'}
          />
          <PlusOutlined className={styles.userPlusIcon} />
        </div>
        <div className={styles.mainContent}>
          <Title level={5}>{user?.fullName}</Title>
        </div>
      </div>
    );

  if (type === 'active')
    return (
      <div onClick={onUserClick}>
        <div className={styles.activeUser}>
          <Avatar
            src={
              user.profileImage?.id
                ? getUrl(user.profileImage.id)
                : dummyUserProfile
            }
            size={70}
            shape={'circle'}
          />
        </div>
        <div className={styles.mainContent}>
          <Title level={5} className={styles.activeUserName}>
            {user?.fullName}
          </Title>
        </div>
      </div>
    );

  return (
    <div onClick={onUserClick}>
      <div className={styles.seenUser}>
        <Avatar
          src={
            user.profileImage?.id
              ? getUrl(user.profileImage.id)
              : dummyUserProfile
          }
          size={70}
          shape={'circle'}
        />
      </div>
      <div className={styles.mainContent}>
        <Title level={5} className={styles.activeUserName}>
          {user?.fullName}
        </Title>
      </div>
    </div>
  );
};
