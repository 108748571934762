import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { routes } from './constant';
import { RootState } from './store';

const PublicRoute: React.FC<{
  component: React.FC;
  path: string;
  exact: boolean;
}> = (props) => {
  const { authenticated } = useSelector(
    (state: RootState) => state.cognitoUserDetail
  );

  if (authenticated) {
    return <Redirect to={routes.FacilityListUrl} />;
  }

  return (
    <Route path={props.path} exact={props.exact} component={props.component} />
  );
};

export default PublicRoute;
