import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { Row, Col, Form, Card, notification, Button } from 'antd';

import { Input } from '../../../../../stories/Input/Input';
import {
  ActivityReportType,
  useCreateImageMutation,
  useUpdateImageMutation,
} from '../../../../../services/apiService';
import { getAccessToken } from '../../../../../getAccessToken';
import { ImageUploader } from '../../../../../stories/ImageUploader/ImageUploader';
import { getUrl } from '../../../../../utils';

interface ActivityFormProps extends ActivityReportType {}

export interface ActivityReportFormProps {
  isLoading: boolean;
  actionType: 'create' | 'update';
  activityReport?: ActivityReportType;
  onFinish: (activityReportForm: ActivityFormProps) => Promise<void>;
}

export const ActivityReportForm = ({
  onFinish,
  isLoading,
  actionType,
  activityReport,
}: ActivityReportFormProps) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const history = useHistory();
  const { id: facilityId } = useParams<{ id: string }>();
  const [token, setToken] = useState<string | undefined>('');
  const [coverImageId, setCoverImageId] = useState<number | undefined>(
    activityReport?.coverImageId
  );

  const [postImage] = useCreateImageMutation();
  const [patchImage] = useUpdateImageMutation();

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  const handleClick = () => {
    history.goBack();
  };

  const uploadImage = async (
    imageUri: string,
    subType: string,
    imageId?: number
  ) => {
    const data = new FormData();
    data.append('file', imageUri);

    if (actionType === 'create') {
      data.append('subType', subType);
      data.append('imageableType', 'ActivityReport');

      postImage(data).then((d: any) => {
        const responseImageId = d.data;
        setCoverImageId(responseImageId);
        form.setFieldsValue({ coverImageId: responseImageId });
      });
    } else {
      await patchImage({ id: imageId!, imageUri: data });
    }

    notification.success({
      message: intl.formatMessage({ id: 'notification.photoUploadSuccess' }),
      placement: 'topRight',
      duration: 5,
    });
  };

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={activityReport}
        onFinish={(value: ActivityReportType) => {
          onFinish({ ...value, facilityId: parseInt(facilityId) });
        }}
      >
        <Row gutter={[0, 8]} justify="end">
          <Col span="24">
            <ImageUploader
              size="large"
              boxShape="rect"
              imageUrl={coverImageId ? getUrl(coverImageId, token) : ''}
              name="coverImageId"
              label={<FormattedMessage id="facilityForm.uploadCoverPhoto" />}
              upload={(imgUri: string) =>
                uploadImage(imgUri, 'coverImage', activityReport?.coverImageId)
              }
              required
            />
          </Col>
          <Col span="24">
            <Input
              name="title"
              type="string"
              placeholder=""
              label={'Title'}
              rules={[{ required: true }]}
            />
          </Col>
          <Col span="24">
            <Input
              type="editor"
              placeholder="..."
              name="description"
              rules={[{ required: true }]}
            />
          </Col>
          <Col span="24">
            <Row gutter={[18, 0]} justify="end">
              <Col>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                >
                  {isLoading ? 'Posting...' : 'Post'}
                </Button>
              </Col>
              <Col>
                <Button size="large" type="default" onClick={handleClick}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
