export function RankingSilver() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M20 0L37.3205 10V30L20 40L2.67949 30V10L20 0Z" fill="#D2D3D3" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9982 40V0L2.67773 10V30L19.9982 40Z"
        fill="#E7E9E9"
      />
      <g filter="url(#filter0_i_5731_16331)">
        <path
          d="M20 4L33.8564 12V28L20 36L6.14359 28V12L20 4Z"
          fill="#CACBCB"
        />
      </g>
      <path
        d="M25.9209 25.6865L24.7148 29H14.5586V28.5303C17.5462 25.805 19.6494 23.5791 20.8682 21.8525C22.0869 20.126 22.6963 18.5475 22.6963 17.1172C22.6963 16.0254 22.362 15.1283 21.6934 14.4258C21.0247 13.7233 20.2249 13.3721 19.2939 13.3721C18.4476 13.3721 17.6859 13.6217 17.0088 14.1211C16.3402 14.612 15.8451 15.3356 15.5234 16.292H15.0537C15.2653 14.7262 15.807 13.5244 16.6787 12.6865C17.5589 11.8486 18.6549 11.4297 19.9668 11.4297C21.3633 11.4297 22.527 11.8783 23.458 12.7754C24.3975 13.6725 24.8672 14.7305 24.8672 15.9492C24.8672 16.821 24.6641 17.6927 24.2578 18.5645C23.6315 19.9355 22.6159 21.387 21.2109 22.9189C19.1035 25.221 17.7874 26.609 17.2627 27.083H21.7568C22.6709 27.083 23.3099 27.0492 23.6738 26.9814C24.0462 26.9137 24.3805 26.7783 24.6768 26.5752C24.973 26.3636 25.2311 26.0674 25.4512 25.6865H25.9209Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_i_5731_16331"
          x="6.14453"
          y="4"
          width="27.7109"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_5731_16331"
          />
        </filter>
      </defs>
    </svg>
  );
}
