import { Card, Table } from 'antd';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

const CompanyProfile = () => {
  const companyDetail = [
    {
      title: <FormattedMessage id="companyProfile.name" />,
      value: <FormattedMessage id="companyProfile.companyName" />,
    },
    {
      title: <FormattedMessage id="companyProfile.Establishment" />,
      value: <FormattedMessage id="companyProfile.EstablishmentDate" />,
    },
    {
      title: <FormattedMessage id="companyProfile.Capital" />,
      value: <FormattedMessage id="companyProfile.CapitalPrice" />,
    },
    {
      title: <FormattedMessage id="companyProfile.Business" />,
      value: <FormattedMessage id="companyProfile.BusinessContent" />,
    },
    {
      title: <FormattedMessage id="companyProfile.Representative" />,
      value: <FormattedMessage id="companyProfile.RepresentativeValue" />,
    },
    {
      title: <FormattedMessage id="companyProfile.location" />,
      value: <FormattedMessage id="companyProfile.locationDetail" />,
    },
  ];

  const columns = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      render: (text: number, record: any, index: number) => (
        <div key={index}>
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'value',
      key: 'value',
      render: (text: string) => (
        <div>
          <p>{text}</p>
        </div>
      ),
    },
  ];

  return (
    <Card className={styles.cardContent}>
      <Table
        dataSource={companyDetail}
        columns={columns}
        pagination={false}
        className={styles.tableMainContent}
      />
    </Card>
  );
};

export default CompanyProfile;
