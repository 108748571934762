import { Space, Statistic, Tag } from 'antd';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getAccessToken } from '../../getAccessToken';
import { FacilityResponse } from '../../services/apiService';
import EnergyIcon from '../../assets/images/energy_symbol.png';

import styles from './index.module.scss';

const BASE_URL = process.env.REACT_APP_BASE_URL;

interface FacilityBoxProps {
  data: FacilityResponse;
  index?: number | undefined;
}
export const FacilityBox = ({ data, index }: FacilityBoxProps) => {
  const history = useHistory();
  const [token, setToken] = useState<string | undefined>('');

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };

  const handleClick = () => {
    history.push(`/facility-detail/${data.id}`);
  };
  const getColor = (i: number | undefined) => {
    switch (i) {
      case 0:
        return styles.color0;
      case 1:
        return styles.color1;
      case 2:
        return styles.color2;
      case 3:
        return styles.color3;
      case 4:
        return styles.color4;
      case 5:
        return styles.color5;
      case 6:
        return styles.color6;
      case 7:
        return styles.color7;
      case 8:
        return styles.color8;
      default:
        return styles.color9;
    }
  };

  return (
    <div className={styles.facility} onClick={handleClick}>
      <div className={styles.imageContainer}>
        <img
          alt="city images"
          className={styles.image}
          src={getUrl(data?.coverImage?.id)}
        />
        <div className={styles.facilityDesc}>
          <Space direction="vertical">
            <Tag className={styles.tags}>{data.area}</Tag>
            <div className={`${styles.childrenMain} ${getColor(index)}`}>
              {data.name}
            </div>
          </Space>
        </div>
      </div>
      <div className={styles.facilityDetail}>
        <div className={styles.targetAmount}>
          <div className={styles.totalEnergyText}>
            <FormattedMessage id="facilityBox.supportEnergyText" />
          </div>
          <div className={styles.targetAmountDesc}>
            <div className={styles.targetAmountDescIcon}>
              <img src={EnergyIcon} alt="Energy Icon" />
            </div>
            <div className={styles.targetAmountDescPrice}>
              <Statistic value={data.totalDonatedEnergy} />
            </div>
          </div>
        </div>
        <div className={styles.targetAmount}>
          <div className={styles.totalSupportEnergyText}>
            <FormattedMessage id="facilityBox.supporters" /> :
            <Statistic value={data.totalDonors} suffix="人" />
          </div>
        </div>
      </div>
    </div>
  );
};
