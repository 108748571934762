export function RankingGold() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_5731_10365)">
        <path
          d="M20 0L37.3205 10V30L20 40L2.67949 30V10L20 0Z"
          fill="#B89A4F"
        />
      </g>
      <g filter="url(#filter1_i_5731_10365)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.9982 40V0L2.67773 10V30L19.9982 40Z"
          fill="#BFA973"
        />
      </g>
      <g filter="url(#filter2_i_5731_10365)">
        <path
          d="M20 4L33.8564 12V28L20 36L6.14359 28V12L20 4Z"
          fill="#86681D"
        />
      </g>
      <path
        d="M16.0469 13.4736L20.2363 11.4297H20.6553V25.9658C20.6553 26.9307 20.6934 27.5316 20.7695 27.7686C20.8542 28.0055 21.0234 28.1875 21.2773 28.3145C21.5312 28.4414 22.0475 28.5133 22.8262 28.5303V29H16.3516V28.5303C17.1641 28.5133 17.6888 28.4456 17.9258 28.3271C18.1628 28.2002 18.3278 28.0352 18.4209 27.832C18.514 27.6204 18.5605 26.9984 18.5605 25.9658V16.6729C18.5605 15.4202 18.5182 14.6162 18.4336 14.2607C18.3743 13.9899 18.2643 13.791 18.1035 13.6641C17.9512 13.5371 17.765 13.4736 17.5449 13.4736C17.2318 13.4736 16.7959 13.6048 16.2373 13.8672L16.0469 13.4736Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_i_5731_10365"
          x="2.67969"
          y="0"
          width="34.6406"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_5731_10365"
          />
        </filter>
        <filter
          id="filter1_i_5731_10365"
          x="2.67773"
          y="0"
          width="17.3203"
          height="40"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_5731_10365"
          />
        </filter>
        <filter
          id="filter2_i_5731_10365"
          x="6.14453"
          y="4"
          width="27.7109"
          height="32"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_5731_10365"
          />
        </filter>
      </defs>
    </svg>
  );
}
