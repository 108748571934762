/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormattedMessage } from 'react-intl';
import { Row, Col, Radio, Button, Divider } from 'antd';

import styles from './index.module.scss';
import { useState } from 'react';

interface PaymentTypeProps {
  setStep: Function;
  cardCount: number | undefined;
  closeModal: Function;
  setPaymentType: Function;
}

export const paymentMethods = [
  {
    id: 1,
    title: 'Credit Card List',
  },
];

export const PaymentType: React.FC<PaymentTypeProps> = ({
  setStep,
  cardCount,
  closeModal,
  setPaymentType,
}) => {
  const [selectType, setSelectType] = useState(1);
  const handleClick = () => {
    if (cardCount) {
      setStep(1);
    } else {
      setStep(2);
    }
  };

  return (
    <div className={styles.paymentType}>
      <Radio.Group defaultValue={1}>
        {paymentMethods.map((data: any, i) => {
          return (
            <div className={styles.paymentOption} key={i}>
              <Radio
                key={i}
                value={data.id}
                onChange={() => {
                  setPaymentType(data.id);
                  setSelectType(data.id);
                }}
              >
                {data.title}
              </Radio>
            </div>
          );
        })}
      </Radio.Group>
      <Divider />
      <Row justify="space-between">
        <Col>
          <Button onClick={() => closeModal(false)}>
            <FormattedMessage id="paymentType.cancel" />
          </Button>
        </Col>
        <Col>
          <Button type="primary" onClick={handleClick}>
            <FormattedMessage id="paymentType.cardInformationInout" />
          </Button>
        </Col>
      </Row>
    </div>
  );
};
