import { SearchUserBox } from '../../../stories/SearchUserBox/SearchUserBox';

export const User = () => {
  const dataSource = [...Array(10)].map((_, i) => {
    return {
      ranking: i + 1,
      username: 'Astra Team',
      day: 10,
      totalSupport: 10,
      level: 10,
      rank: 'D',
    };
  });

  return (
    <div>
      {dataSource.map((item, index) => (
        <SearchUserBox key={index} />
      ))}
    </div>
  );
};
