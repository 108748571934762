/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Button, Col, Space, Progress, Avatar, notification } from 'antd';

import {
  useGetSentInvitationsQuery,
  useInviteDonorMutation,
} from '../../services/apiService';
import { RootState } from '../../store';
import { AwsInviteUser } from './AwsInviteUser';
import { InvitedUserList } from './InvitedUserList';
import { Link } from '../../stories/Icons/Link/Link';
import { getAccessToken } from '../../getAccessToken';
import { setCounter } from '../../features/me/meSlice';
import { Medal } from '../../stories/Icons/Medal/Medal';
import { InvitesModal } from './InvitesModal/InvitesModal';
import { Square } from '../../stories/Icons/Square/Square';
import { dummyUserProfile, enableFeature } from '../../constant';
import { Location } from '../../stories/Icons/Location/Location';
import { ProfileSettingModal } from './ProfileSetting/ProfileSettingModal';

import styles from './index.module.scss';

const InviteUser = () => {
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  const [isInviteVisible, setIsInviteVisible] = useState(false);
  const [token, setToken] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);

  const me = useSelector((state: RootState) => state.me);
  const { data: sentInvitation } = useGetSentInvitationsQuery({});
  const [sendInvitation, { isError, isSuccess }] = useInviteDonorMutation();

  const handleInviteModal = () => {
    setIsInviteVisible(!isInviteVisible);
  };

  const handleProfileSetting = () => {
    setIsVisible(false);
    dispatch(setCounter());
  };
  const handleOpenProfileSetting = () => {
    setIsVisible(true);
  };

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  useEffect(() => {
    if (isError) {
      setLoading(false);

      notification.error({
        placement: 'topRight',
        message: intl.formatMessage({ id: 'notification.requestFailed' }),
      });
    }
    if (isSuccess) {
      setLoading(false);

      notification.success({
        message: intl.formatMessage({ id: 'notification.invitationCancel' }),
        placement: 'topRight',
      });
    }
  }, [isError, isSuccess]);

  const onFinish = async ({ phoneNumber }: { phoneNumber: string }) => {
    setLoading(true);

    AwsInviteUser({
      phoneNumber,
      errorCallback: () => {
        setLoading(false);
      },
      callback: () => {
        setIsInviteVisible(!isInviteVisible);
        setLoading(false);
        const countryCode = process.env.REACT_APP_PHONE_CODE;
        sendInvitation(countryCode + phoneNumber);
      },
    });
  };
  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };
  return (
    <>
      <Row justify="center" className={styles.row} gutter={15}>
        <Col xs={24} sm={24} md={3} lg={2} xl={2} xxl={1}>
          <Avatar
            src={
              me?.profileImage?.id
                ? getUrl(me.profileImage.id)
                : dummyUserProfile
            }
            size={72}
            shape={'circle'}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={21}
          lg={17}
          xl={11}
          xxl={9}
          className={styles.userContentMainSec}
        >
          <Row gutter={0} className={styles.userContentMain}>
            <Col
              sm={24}
              md={14}
              lg={14}
              xl={14}
              xxl={14}
              className={styles.userContent}
            >
              <div className={styles.userNamePart}>
                <p className={styles.userNameText}>{me?.fullName}</p>
                <div className={styles.diamondImage}>
                  <Square />
                </div>
              </div>
              {enableFeature.inviteUserLevel && (
                <p className={styles.userNameLevelText}>Lv. 3</p>
              )}
              {enableFeature.inviteUserLevel && (
                <span className={styles.progressMain}>
                  <Progress
                    percent={30}
                    className={styles.userProgress}
                    showInfo={false}
                  />
                  <span className={styles.progressPer}>E</span>
                  <span className={styles.progressText}>1/5</span>
                </span>
              )}
            </Col>
            <Col
              sm={24}
              md={10}
              lg={10}
              xl={10}
              xxl={10}
              className={styles.userContentBtn}
            >
              <div>
                <Button
                  type="default"
                  shape="round"
                  size="large"
                  onClick={handleOpenProfileSetting}
                >
                  <Space size="large">
                    <FormattedMessage id="inviteUser.profileSetting" />
                  </Space>
                </Button>
              </div>
              <div>
                <Button
                  type="default"
                  shape="round"
                  size="large"
                  onClick={() => handleInviteModal()}
                  disabled={
                    me?.maxInvitationLimit === sentInvitation?.length
                      ? true
                      : false
                  }
                >
                  <Space size="small">
                    <FormattedMessage id="inviteUser.invite" />
                  </Space>
                </Button>
              </div>
            </Col>
          </Row>
          <div className={styles.followerText}>{me?.intro}</div>
          <div className={styles.userContentTexts}>
            <div className={styles.userContentMain}>
              {enableFeature.inviteUserFollower && (
                <div className={styles.followerMain}>
                  <p className={styles.followerCount}>100</p>
                  <p className={styles.followerText}> Follower</p>
                </div>
              )}
              {me?.area && (
                <div className={styles.followerMain}>
                  <div className={styles.locationImage}>
                    <Location />
                  </div>

                  <p className={styles.locationText}>{me.area}</p>
                </div>
              )}
            </div>
            <div className={styles.userContentMain}>
              {me?.link && (
                <div className={styles.followerMain}>
                  <div className={styles.locationImage}>
                    <Link />
                  </div>
                  <a
                    href={me?.link}
                    target="_blank"
                    className={styles.linkText}
                    rel="noreferrer"
                  >
                    {me.link}
                  </a>
                </div>
              )}
              {me?.title && (
                <div className={styles.followerMain}>
                  <div className={styles.medalImage}>
                    <Medal />
                  </div>
                  <p className={styles.locationText}>{me.title}</p>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <ProfileSettingModal
        open={isVisible}
        handleCancel={handleProfileSetting}
      />
      <InvitesModal
        open={isInviteVisible}
        handleCancel={handleInviteModal}
        handleSubmit={onFinish}
        isLoading={loading}
      />
      <Row justify="center" className={styles.row}>
        <InvitedUserList sentInvitation={sentInvitation} />
      </Row>
    </>
  );
};

export default InviteUser;
