/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Tabs, Typography, Button, notification, Spin } from 'antd';

import { User } from './User';
import {
  PostType,
  useGetPostsQuery,
  useGetTagsQuery,
  useDeleteUserPostMutation,
  useGetFacilitiesQuery,
} from '../../../services/apiService';
import { RootState } from '../../../store';
import { enableFeature } from '../../../constant';
import { TagBox } from '../../../stories/TagBox/TagBox';
import { SupportDestination } from './SupportDestination';
import { AddPost } from '../../../stories/AddPost/AddPost';
import { UserPost } from '../../../stories/UserPost/UserPost';
import { setPostDetails } from '../../../features/post/postListSlice';
import { DonorFooter } from '../../../stories/DonorFooter/DonorFooter';

import styles from './index.module.scss';

const { Title } = Typography;

const { TabPane } = Tabs;

const SearchPages = ({ location }: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const me = useSelector((state: RootState) => state.me);

  const { data: tagData } = useGetTagsQuery({});
  const [isPost, setIsPost] = useState(false);
  const [defaultActiveKey, setDefaultActiveKey] = useState('1');
  const [paginateInfo, setPaginateInfo] = useState({
    page: 1,
    limit: 10,
    userId: me?.id,
  });
  const [paginate1Info, setPaginate1Info] = useState({
    page: 1,
    limit: 10,
    search: location?.state?.search,
    orderDirection: 'ASC',
    orderField: 'createdAt',
  });

  const { isLoading: isFacilitiesLoading, data: facilities } =
    useGetFacilitiesQuery(paginate1Info);

  const { data: postData, isLoading } = useGetPostsQuery(paginateInfo);
  const [deleteUserPost, { isSuccess, error }] = useDeleteUserPostMutation();

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [error]);
  const handleAddPost = () => {
    setIsPost(!isPost);
    dispatch(setPostDetails({ postDetails: null }));
  };

  const onChange = (key: string) => {
    setDefaultActiveKey(key);
  };

  return (
    <>
      <Row justify="center" className={styles.mainSearch}>
        <Col xxl={15} xl={20} lg={15} md={20} sm={23} xs={23}>
          <Title level={5}>
            <FormattedMessage id="search.searchResult" />
            {'  ' + location?.state?.search}
          </Title>
          <div className={styles.tabMainContent}>
            <Tabs defaultActiveKey={defaultActiveKey} onChange={onChange}>
              {enableFeature.searchUserTab && (
                <TabPane tab={<FormattedMessage id="search.user" />} key="1">
                  <User />
                </TabPane>
              )}
              {enableFeature.supportDestinationTab && (
                <TabPane
                  tab={<FormattedMessage id="search.supportDestionation" />}
                  key="2"
                >
                  <SupportDestination facilities={facilities?.data} />
                </TabPane>
              )}

              <TabPane tab={<FormattedMessage id="search.post" />} key="3">
                {isLoading ? (
                  <div className={styles.blankContent}>
                    <Spin size="large" />
                  </div>
                ) : (
                  postData?.data?.map((item: PostType, index: number) => (
                    <UserPost
                      key={JSON.stringify(index)}
                      item={item}
                      user={{
                        id: Number(me.id),
                        fullName: me.fullName,
                        profileImage: { id: Number(me?.profileImage?.id) },
                      }}
                      onCardClick={() => {
                        history.push(`/posts/${item.id}`);
                      }}
                      onUpdateClick={() => {
                        dispatch(setPostDetails({ postDetails: item }));
                        setIsPost(true);
                      }}
                      onDeleteClick={() => {
                        deleteUserPost(item.id);
                      }}
                      onCommentClick={() => {
                        history.push(`/posts/${item.id}`, { focus: true });
                      }}
                    />
                  ))
                )}
              </TabPane>
              <TabPane tab={<FormattedMessage id="search.tag" />} key="4">
                <div className={styles.tagListContent}>
                  {tagData?.map((item: any, index: number) => (
                    <TagBox name={item.name} key={JSON.stringify(index)} />
                  ))}
                </div>
                <div className={styles.blankContent}></div>
              </TabPane>
              <Button
                type="primary"
                shape="round"
                className={styles.addPostBtn}
                onClick={handleAddPost}
              >
                Add Post
              </Button>
            </Tabs>
          </div>
        </Col>
      </Row>
      <AddPost open={isPost} handleCancel={handleAddPost} />
      <DonorFooter />
    </>
  );
};

export default SearchPages;
