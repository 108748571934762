/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { Elements, useStripe } from '@stripe/react-stripe-js';

import {
  useGetMeQuery,
  useBuyEnergyMutation,
  useCreateCardMutation,
  useGetAmountEnergyQuery,
  usePaymentConfirmMutation,
  useGetCardQuery,
} from '../../../../services/apiService';
import {
  Payment,
  EnergyChargePlanType,
} from '../../../../stories/Payment/Payment';
import { RootState } from '../../../../store';
import { Loader } from '../../../../stories/Loader/Loader';
import {
  setPlan,
  setModal,
  setStatus,
} from '../../../../features/EnergyCharge/EnergyChargeSlice';
import { DonorFooter } from '../../../../stories/DonorFooter/DonorFooter';
import { EnergyChargePlan } from '../../../../stories/EnergyChargePlan/EnergyChargePlan';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const StripePayment: React.FC = () => {
  const intl = useIntl();
  const stripe: any = useStripe();
  const dispatch = useDispatch();
  const { status, isModal, selectedPlan } = useSelector(
    (state: RootState) => state.energy
  );
  const me = useSelector((state: RootState) => state.me);
  const [cvc, setCvc] = useState<any>(null);
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(false);
  const [buyBoost, setBuyBoost] = useState<number>(1);
  const [energyChargePlan, setEnergyChargePlan] =
    useState<EnergyChargePlanType | null>(selectedPlan);

  const { energy } = useSelector((state: RootState) => state.me);
  const { refetch } = useGetMeQuery({});
  const { data: creditCardList } = useGetCardQuery({});
  const [createCard, { isError: isCardError }] = useCreateCardMutation();

  const [
    buyEnergy,
    {
      isError,
      isSuccess,
      data: buyData,
      reset: resetBuyEnergy,
      isLoading: buyApiLoading,
    },
  ] = useBuyEnergyMutation();

  const [
    paymentConfirm,
    {
      data: paymentConfirmData,
      isError: paymentConfirmError,
      isSuccess: paymentConfirmSuccess,
      isLoading: paymentConfirmLoading,
    },
  ] = usePaymentConfirmMutation();

  const { data: energyChargePlans, isLoading: isGetEnergyPlanLoading } =
    useGetAmountEnergyQuery({});

  useEffect(() => {
    setEnergyChargePlan(selectedPlan);
  }, [selectedPlan]);

  useEffect(() => {
    if (isSuccess) {
      setCvc(null);
      setIsStripeLoading(false);

      if (buyData?.status === 'succeeded') {
        dispatch(setStatus({ status: 'success' }));
      }

      if (buyData?.status === 'requires_action') {
        setIsStripeLoading(true);
        stripe.handleCardAction(buyData.client_secret).then((result: any) => {
          if (result.error) {
            dispatch(setStatus({ status: 'failed' }));
            setIsStripeLoading(false);
          } else {
            if (result.paymentIntent.status === 'requires_confirmation') {
              paymentConfirm({
                paymentIntentId: buyData?.stripePaymentIntentId,
              });
            }
          }
        });
      }
    }

    if (isError) {
      setCvc(null);
      setIsStripeLoading(false);

      dispatch(setStatus({ status: 'failed' }));
    }
  }, [buyData, isError, isSuccess]);

  useEffect(() => {
    if (paymentConfirmSuccess) {
      dispatch(setStatus({ status: 'success' }));
    }

    if (paymentConfirmError) {
      dispatch(setStatus({ status: 'failed' }));
    }
  }, [paymentConfirmData, paymentConfirmError, paymentConfirmSuccess]);

  useEffect(() => {
    if (isCardError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.requestFailed' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isCardError]);

  const handleSettlement = () => {
    setIsStripeLoading(true);
    stripe.createToken('cvc_update', cvc).then(function (result: any) {
      if (result.error) {
        dispatch(setStatus({ status: 'failed' }));
        setIsStripeLoading(false);
      } else {
        if (result.token) {
          if (energyChargePlan) {
            const resObj = {
              buyBoost: buyBoost,
              cvcToken: result.token.id,
              paymentMethodId: paymentMethodId,
              amountToEnergyId: energyChargePlan.id,
              amount: energyChargePlan.amount * buyBoost,
            };
            buyEnergy(resObj);
          }
        }
      }
    });
  };

  const handleChangeMethod = () => {
    dispatch(setStatus({ status: 'pending' }));
  };

  const handleBuyEnergy = async (paymentMethodId: string, cvc: any) => {
    setPaymentMethodId(paymentMethodId);
    setCvc(cvc);
    dispatch(setStatus({ status: 'settlement' }));
  };

  const handleClose = () => {
    if (status === 'success') {
      refetch();
    }
    resetBuyEnergy();
    setEnergyChargePlan(null);
    dispatch(setModal({ isModal: false }));
    dispatch(setStatus({ status: 'pending' }));
    dispatch(setPlan({ selectedPlan: null }));
  };
  const changeEnergyChargePlan = (data: EnergyChargePlanType) => {
    dispatch(setPlan({ selectedPlan: data }));
  };
  const handlePayment = (buyBoost: number) => {
    setBuyBoost(Number(buyBoost));
    dispatch(setModal({ isModal: true }));
  };

  const handleAddCard = async (paymentMethodId: string) => {
    if (isSaved) createCard({ paymentMethodId: paymentMethodId });
    if (energyChargePlan) {
      const reqObj = {
        amount: energyChargePlan.amount * buyBoost,
        paymentMethodId: paymentMethodId,
        buyBoost: buyBoost,
        amountToEnergyId: energyChargePlan.id,
      };
      buyEnergy(reqObj);
    }
  };

  if (isGetEnergyPlanLoading) return <Loader />;

  return (
    <>
      <EnergyChargePlan
        possessedEnergy={energy}
        energyChargePlan={selectedPlan}
        handlePayment={handlePayment}
        energyChargePlans={energyChargePlans}
        setEnergyChargePlan={changeEnergyChargePlan}
      />
      <Payment
        user={me}
        status={status}
        buyBoost={buyBoost}
        isVisible={isModal}
        setIsSaved={setIsSaved}
        handleClose={handleClose}
        selectedPlan={selectedPlan!}
        handleAddCard={handleAddCard}
        creditCardList={creditCardList?.data}
        callDonateApi={handleBuyEnergy}
        handleSettlement={handleSettlement}
        handleChangeMethod={handleChangeMethod}
        donateApiLoading={buyApiLoading || isStripeLoading}
      />
      <DonorFooter />
    </>
  );
};

const EnergyCharge = () => {
  return (
    <Elements stripe={stripePromise}>
      <StripePayment />
    </Elements>
  );
};

export default EnergyCharge;
