import { Form } from 'antd';

import { Input } from '../Input/Input';
import { Button } from '../Button/Button';
import { Avatar } from '../Avatar/Avatar';

import styles from './index.module.scss';
interface initialProps {
  comment?: any;
}

export interface CommentInputBoxProps {
  disable: boolean;
  isLoading: boolean;
  onChange: (Text: any) => void;
  initialValue: initialProps | undefined;
  user: {
    id: number;
    fullName: string;
    profileImage: { id: number | undefined };
  };
  onFinish: ({ comment }: { comment: string }) => void;
}

export const CommentInputBox = ({
  user,
  disable,
  onChange,
  onFinish,
  isLoading,
  initialValue,
}: CommentInputBoxProps) => {
  const [form] = Form.useForm();

  const onFinishForm = ({ comment }: { comment: string }) => {
    onFinish({ comment });
    form.resetFields();
  };
  return (
    <div className={styles.commentMainContent}>
      <Avatar urlId={user.profileImage?.id} />
      <Form
        form={form}
        onFinish={onFinishForm}
        initialValues={initialValue}
        className={styles.inputSection}
      >
        <div className={styles.commentUserContent}>
          <div className={styles.inputContent}>
            <Input
              name="comment"
              type="textarea"
              placeholder="Enter a comment"
              onChange={onChange}
            />
          </div>
          <div className={styles.commentSubmitBtn}>
            <Button
              type="ghost"
              label={'Comment'}
              submit="submit"
              disable={disable}
              loading={isLoading}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};
