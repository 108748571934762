export function MobileMenu() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        transform="translate(0.997314)"
        fill="#FAF9F3"
      />
      <rect x="2.99731" y="5" width="20" height="2" fill="#312B2B" />
      <rect x="2.99731" y="11" width="20" height="2" fill="#312B2B" />
      <rect x="2.99731" y="17" width="20" height="2" fill="#312B2B" />
    </svg>
  );
}
