import { Auth } from 'aws-amplify';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Form, Space, Button, notification } from 'antd';

import {
  sendOtp,
  otpVerification,
  updateEmailOnCognito,
} from './AuthEmailUpdate';
import { RootState } from '../../../../store';
import { setMe } from '../../../../features/me/meSlice';
import { Input } from '../../../../stories/Input/Input';
import { withSettingLayout } from '../withSettingLayout';
import { useUpdateMeMutation } from '../../../../services/apiService';
import { TrueMark } from '../../../../stories/Icons/TrueMark/TrueMark';
import { FalseMark } from '../../../../stories/Icons/FalseMark/FalseMark';
import { AccountSettingTab } from '../../../../stories/AccountSettingTab/AccountSettingTab';
import { setCognitoUserDetail } from '../../../../features/cognitoUserDetail/cognitoUserDetail';

import styles from './index.module.scss';

export const UpdateEmailAddress = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [otpForm] = Form.useForm();
  const [step, setStep] = useState(0);
  const me = useSelector((state: RootState) => state.me);
  const { cognitoUser } = useSelector(
    (state: RootState) => state.cognitoUserDetail
  );

  const [updateUser, { data, isLoading, isSuccess, error }] =
    useUpdateMeMutation();

  useEffect(() => {
    if (isSuccess && data) {
      updateEmailOnCognito(data.email, intl);
      setStep(2);
    }
  }, [data, intl, isSuccess]);

  useEffect(() => {
    if (error) {
      const { message } = (error as any).data;
      const fields = Object.keys(message).map((fieldName) => {
        return { name: fieldName, errors: message[fieldName] };
      });
      form.setFields(fields);
    }
  }, [form, error]);

  const handleRequestEmailChange = async ({ email }: { email: string }) => {
    updateUser({ ...me, dob: moment(me.dob).format(), email });
    dispatch(
      setMe({
        data: { ...me, email },
      })
    );
  };

  const handleVerifyEmail = () => {
    sendOtp(me.email, intl);
    setStep(2);
  };

  const handleOtpSubmit = ({ otp }: { otp: string }) => {
    otpVerification(me.email, otp)
      .then(() => {
        notification.success({
          message: intl.formatMessage({ id: 'notification.emailVerified' }),
          placement: 'topRight',
          duration: 5,
        });
        Auth.currentUserInfo().then((cognitoUser) => {
          const { email_verified, phone_number_verified, phone_number, email } =
            cognitoUser?.attributes || {};
          dispatch(
            setCognitoUserDetail({
              data: {
                email,
                phoneNumber: phone_number,
                emailVerified: email_verified as boolean,
                role: cognitoUser?.attributes['custom:role'],
                phoneNumberVerified: phone_number_verified as boolean,
                facilityId: cognitoUser?.attributes['custom:facilityId'],
              },
            })
          );
        });
        setStep(0);
      })
      .catch((err) => {
        const fields = [{ name: 'otp', errors: [err.message] }];
        otpForm.setFields(fields);
      });
  };

  const NewEmail = () => {
    return (
      <Row>
        <Col>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={handleRequestEmailChange}
          >
            <Input
              name="email"
              type="string"
              placeholder=""
              label="New Email"
              rules={[{ type: 'email', required: true }]}
            />
            <Space>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Update
              </Button>
              <Button
                type="primary"
                htmlType="button"
                onClick={() => setStep(0)}
              >
                Cancel
              </Button>
            </Space>
          </Form>
        </Col>
      </Row>
    );
  };

  const CurrentEmail = () => {
    return (
      <Row>
        <Col>
          <p className={styles.iconContent}>
            <FormattedMessage id="updateEmailAddress.currentEmail" /> :
            {' ' + cognitoUser?.email + '   '}
            {!cognitoUser?.emailVerified ? <TrueMark /> : <FalseMark />}
          </p>
          <Row justify="space-between">
            <div className={styles.unverifiedContent}>
              <b>
                {cognitoUser.emailVerified ? (
                  <FormattedMessage id="updateEmailAddress.verified" />
                ) : (
                  <FormattedMessage id="updateEmailAddress.unverified" />
                )}
              </b>
              <span className={styles.iconContent}>
                {me.email} <FalseMark />
              </span>
              {!cognitoUser.emailVerified && (
                <Button
                  type="primary"
                  onClick={handleVerifyEmail}
                  className={styles.verifyBtn}
                >
                  <FormattedMessage id="updateEmailAddress.verifyEmail" />
                </Button>
              )}
            </div>
          </Row>
          <br />
          <Button type="primary" onClick={() => setStep(1)}>
            <FormattedMessage id="updateEmailAddress.changeEmail" />
          </Button>
        </Col>
      </Row>
    );
  };

  const VerifyEmail = () => {
    return (
      <Row>
        <Col>
          <Form
            form={otpForm}
            layout="vertical"
            requiredMark={false}
            wrapperCol={{ span: 24 }}
            onFinish={handleOtpSubmit}
          >
            <Input
              name="otp"
              type="string"
              label={
                <FormattedMessage id="updateEmailAddress.authenticationCode" />
              }
              placeholder="Code"
              rules={[{ required: true }]}
            />
            <div className={styles.btnContent}>
              <Button block size="large" type="primary" htmlType="submit">
                <FormattedMessage id="updateEmailAddress.verify" />
              </Button>

              <Button
                block
                size="large"
                type="primary"
                onClick={() => {
                  setStep(0);
                }}
              >
                <FormattedMessage id="phoneNumber.cancel" />
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    );
  };

  const Step = () => {
    switch (step) {
      case 1:
        return <NewEmail />;
      case 2:
        return <VerifyEmail />;
      default:
        return <CurrentEmail />;
    }
  };

  return (
    <AccountSettingTab
      title={<FormattedMessage id="updateEmailAddress.emailTitle" />}
    >
      <Step />
    </AccountSettingTab>
  );
};

export default withSettingLayout(UpdateEmailAddress);
