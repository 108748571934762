import { Link } from 'react-router-dom';
import { Menu as AntdMenu } from 'antd';

import { routes } from '../../constant';
import Member from '../Icons/Member/Member';
import Inquiry from '../Icons/Inquiry/Inquiry';
import Facility from '../../stories/Icons/Facility/Facility';

import styles from './index.module.scss';

export const AstraAdminHeader = () => {
  const adminPanel = [
    {
      id: 1,
      image: Facility,
      title: 'Facility',
      path: routes.FacilityListUrl,
    },
    {
      id: 2,
      image: Member,
      title: 'Members',
      path: routes.MembersListUrl,
    },
    {
      id: 3,
      image: Inquiry,
      title: 'Inquiry',
      path: routes.InquiryListUrl,
    },
  ];

  return (
    <AntdMenu
      multiple={true}
      theme="dark"
      mode="horizontal"
      defaultSelectedKeys={['1']}
    >
      {adminPanel.map((d, i) => {
        const IconsSvg = d.image;
        return (
          <AntdMenu.Item key={d.id}>
            <div className={styles.menuItem}>
              <div>
                <IconsSvg />
              </div>
              <Link to={d.path}>
                <span className={styles.menuTitle}>{d.title}</span>
              </Link>
            </div>
          </AntdMenu.Item>
        );
      })}
    </AntdMenu>
  );
};
