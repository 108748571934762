import { routes } from '../constant';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export default function PageNotFound() {
  return (
    <Result
      status="404"
      title="404"
      subTitle={<FormattedMessage id="pageNotFound.subTitle" />}
      extra={
        <Button>
          <Link to={routes.LoginUrl}>
            <FormattedMessage id="pageNotFound.goBack" />
          </Link>
        </Button>
      }
    />
  );
}
