import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { notification, Spin } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { routes } from '../../constant';
import { RootState } from '../../store';
import { setMe } from '../../features/me/meSlice';
import { MeType, useUpdateMeMutation } from '../../services/apiService';

import { UserProfileComponent } from '../../stories/UserProfileComponent/UserProfileComponent';

const UserProfile = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const meId = useSelector((state: RootState) => state.me.id);
  const [updateUser, { data, isLoading, isSuccess, error }] =
    useUpdateMeMutation();

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setMe({ data }));
      notification.success({
        message: intl.formatMessage({ id: 'notification.userProfile' }),
        placement: 'topRight',
        duration: 5,
      });

      Auth.currentAuthenticatedUser().then((resp) => {
        Auth.updateUserAttributes(resp, {
          email: data.email,
        });
        const url =
          data.role === 'Donor' ? routes.HomeUrl : routes.FacilityListUrl;
        history.push(url);
      });
    }
  }, [intl, data, history, dispatch, isSuccess]);

  const onFinish = async (userProfile: Omit<MeType, 'phoneNumber' | 'id'>) => {
    updateUser({
      id: meId,
      ...userProfile,
      dob: (userProfile.dob as any).format(),
    });
  };

  if (isSuccess) {
    return (
      <div className="space-align-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <UserProfileComponent
      error={error}
      onFinish={onFinish}
      isLoading={isLoading}
    />
  );
};

export default UserProfile;
