import { Card } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';

import styles from './index.module.scss';

export const PrivacyPolicy = () => {
  return (
    <Card className={styles.cardContent}>
      <Paragraph className={styles.textIndent}>
        Astra (hereinafter referred to as "our company") has the following
        privacy policy (hereinafter referred to as "this service") regarding the
        handling of user's personal information in the service provided on this
        website (hereinafter referred to as "this service"). Hereinafter
        referred to as "this policy").
      </Paragraph>
      <Paragraph className={styles.textTitle}>第1条（個人情報）</Paragraph>
      <Paragraph className={styles.textIndent}>
        「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第2条（個人情報の収集方法）
      </Paragraph>
      <Paragraph className={styles.textIndent}>
        当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第2条（個人情報の収集方法）
      </Paragraph>
      <Paragraph className={styles.textIndent}>
        当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第3条（個人情報を収集・利用する目的）
      </Paragraph>
      <Paragraph>
        当社が個人情報を収集・利用する目的は，以下のとおりです。
      </Paragraph>
      <Paragraph>
        <div>1. 当社サービスの提供・運営のため</div>
        <div>
          2.
          ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）
        </div>
        <div>
          3.
          ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
        </div>
        <div>4. メンテナンス，重要なお知らせなど必要に応じたご連絡のため</div>
        <div>
          5.
          利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため
        </div>
        <div>
          6.
          ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため{' '}
        </div>
        <div>7. 有料サービスにおいて，ユーザーに利用料金を請求するため</div>
        <div>8. 上記の利用目的に付随する目的</div>
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第4条（利用目的の変更）
      </Paragraph>
      <Paragraph>
        <div>
          1.
          当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。
        </div>
        <div>
          2.
          利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
        </div>
      </Paragraph>
      <Paragraph className={styles.text1}>
        <div>
          1. When it is necessary to protect the life, body or property of a
          person and it is difficult to obtain the consent of the person.
        </div>
        <div>
          2. When it is particularly necessary to improve public health or
          promote the sound development of children, and it is difficult to
          obtain the consent of the person.
        </div>
        <div>
          3. When it is necessary for a national institution or a local public
          body or a person entrusted with it to cooperate in carrying out the
          affairs stipulated by laws and regulations, obtaining the consent of
          the person will hinder the performance of the affairs. When there is a
          risk of exerting
        </div>
        <div>
          4. When the following matters are announced or announced in advance
          and the Company notifies the Personal Information Protection
          Commission
        </div>
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第5条（個人情報の第三者提供）
      </Paragraph>
      <Paragraph>
        <div>
          1.
          当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
        </div>
        <div>
          2.
          当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
        </div>
      </Paragraph>
      <Paragraph className={styles.text1}>
        <div>1. Include provision to third parties in the purpose of use</div>
        <div>2. Items of data provided to third parties</div>
        <div>3. Means or method of provision to a third party</div>
        <div>
          4. Stop providing personal information to third parties at the request
          of the person
        </div>
        <div>5. How to accept the request of the person</div>
      </Paragraph>
      <Paragraph className={styles.text1}>
        <div>
          1. When we outsource all or part of the handling of personal
          information to the extent necessary to achieve the purpose of use
        </div>
        <div>
          2. When personal information is provided due to business succession
          due to merger or other reasons
        </div>
        <div>
          3. When personal information is shared with a specific person, the
          fact, the items of personal information used jointly, the range of
          people who use it jointly, the purpose of use of the person who uses
          it, and the purpose of use of the person who uses it. When the name or
          name of the person responsible for the management of the personal
          information is notified to the person in advance or placed in a state
          where the person can easily know.
        </div>
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第6条（個人情報の開示）
      </Paragraph>
      <Paragraph>
        <div>
          1.
          当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。
        </div>
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第7条（個人情報の訂正および削除）
      </Paragraph>
      <Paragraph>
        <div>
          1.
          ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
        </div>
        <div>
          2.
          当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
        </div>
        <div>
          3.
          当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
        </div>
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第8条（個人情報の利用停止等）
      </Paragraph>
      <Paragraph>
        <div>
          1.
          当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
        </div>
        <div>
          2.
          前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
        </div>
        <div>
          3.
          当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
        </div>
        <div>
          4.
          前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
        </div>
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第9条（プライバシーポリシーの変更）
      </Paragraph>
      <Paragraph>
        <div>
          1.
          本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
        </div>
        <div>
          2.
          当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
        </div>
      </Paragraph>
      <Paragraph className={styles.textTitle}>
        第10条（お問い合わせ窓口）
      </Paragraph>
      <Paragraph>
        本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
        <div>住所：</div>
        <div>社名：</div>
        <div>担当部署：</div>
        <div>Eメールアドレス:</div>
        <br />
        <br />
        <br />
        <br />
      </Paragraph>
    </Card>
  );
};
