/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { PaymentFailed } from './PaymentFailed';
import { CreditCardList } from './CreditCardList';
import { CreditCardInfo } from './CreditCardInfo';
import { PaymentCompleted } from './PaymentCompleted';
import { PaymentConfirmation } from './PaymentConfirmation';
import { CreditCardType, MeType } from '../../services/apiService';

import styles from './index.module.scss';

export type Status = 'pending' | 'settlement' | 'success' | 'failed';

export type EnergyChargePlanType = {
  id: number;
  amount: number;
  energy: number;
};

export interface PaymentMethodComponentProps {
  user: MeType;
  status: string;
  buyBoost: number;
  isVisible: boolean;
  setIsSaved: Function;
  handleClose: () => void;
  handleAddCard: Function;
  donateApiLoading: boolean;
  creditCardList: CreditCardType[] | undefined;
  selectedPlan: EnergyChargePlanType;
  callDonateApi: (creditCardInfo: any, cvc: any) => void;
  handleSettlement: Function;
  handleChangeMethod: () => void;
}

export const Payment = ({
  user,
  status,
  buyBoost,
  isVisible,
  setIsSaved,
  handleClose,
  selectedPlan,
  handleAddCard,
  callDonateApi,
  creditCardList,
  donateApiLoading,
  handleSettlement,
  handleChangeMethod,
}: PaymentMethodComponentProps) => {
  const [step, setStep] = useState(0);
  const [counter, setCounter] = useState(0);
  const [paymentType, setPaymentType] = useState<number>(1);
  const [stripePaymentMethodId, setStripePaymentMethodId] = useState('');

  useEffect(() => {
    if (step === 0) {
      setPaymentType(1);
      setStripePaymentMethodId('');
    }
  }, [step]);

  useEffect(() => {
    setCounter(counter + 1);
    if (creditCardList?.length === 0) {
      setStep(1);
    }
  }, [isVisible]);

  useEffect(() => {
    if (status === 'settlement') {
      setStep(2);
    }
    if (status === 'success') {
      setStep(3);
    }

    if (status === 'failed') {
      setStep(4);
    }
  }, [status]);

  const closeModal = () => {
    setStep(0);
    handleClose();
  };

  const handlePayment = async (cvc: any) => {
    callDonateApi(stripePaymentMethodId, cvc);
  };

  const handleConfirm = () => {
    handleSettlement();
  };
  const handleMethod = () => {
    if (creditCardList?.length === 0) setStep(1);
    else setStep(0);
    handleChangeMethod();
  };
  const steps = [
    // {
    //   title: <FormattedMessage id="payment.paymentMethods" />,
    //   content: (
    //     <PaymentType
    //       setStep={setStep}
    //       closeModal={closeModal}
    //       setPaymentType={setPaymentType}
    //       cardCount={creditCardList?.length}
    //     />
    //   ),
    // },

    {
      title: <FormattedMessage id="payment.creditCardList" />,
      content: (
        <CreditCardList
          key={counter}
          setStep={setStep}
          closeModal={closeModal}
          isLoading={donateApiLoading}
          handlePayment={handlePayment}
          creditCardList={creditCardList}
          setStripePaymentMethodId={setStripePaymentMethodId}
        />
      ),
    },
    {
      title: <FormattedMessage id="payment.creditCardInfo" />,
      content: (
        <CreditCardInfo
          step={step}
          setIsSaved={setIsSaved}
          closeModal={closeModal}
          handleAddCard={handleAddCard}
          donateApiLoading={donateApiLoading}
          setStripePaymentMethodId={setStripePaymentMethodId}
        />
      ),
    },
    {
      title: <FormattedMessage id="payment.confirmPaymentDetail" />,
      content: (
        <PaymentConfirmation
          setStep={setStep}
          buyBoost={buyBoost}
          closeModal={closeModal}
          paymentType={paymentType!}
          selectedPlan={selectedPlan}
          handlePayment={handleConfirm}
          handleChangeMethod={handleMethod}
          donateApiLoading={donateApiLoading}
        />
      ),
    },
    {
      title: <FormattedMessage id="payment.paymentCompleted" />,
      content: (
        <PaymentCompleted
          buyBoost={buyBoost}
          closeModal={closeModal}
          selectedPlan={selectedPlan}
        />
      ),
    },
    {
      title: <FormattedMessage id="payment.paymentFaild" />,
      content: <PaymentFailed closeModal={closeModal} />,
    },
  ];

  return (
    <Modal
      width={380}
      footer={null}
      closable={false}
      visible={isVisible}
      title={steps[step].title}
    >
      {steps.map(({ title, content }, i) => (
        <div key={i} className={i === step ? styles.showStep : styles.hideStep}>
          {content}
        </div>
      ))}
    </Modal>
  );
};
