import { PostType } from './../../services/apiService';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PostList {
  postData: PostType[];
  postDetails: PostType | null;
}

const initialState: PostList = {
  postData: [],
  postDetails: null,
};

export const postListSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setPostList: (state, action: PayloadAction<{ postData: PostType[] }>) => {
      state.postData = action.payload.postData;
    },
    setPostDetails: (
      state,
      action: PayloadAction<{ postDetails: PostType | null }>
    ) => {
      state.postDetails = action.payload.postDetails;
    },
  },
});

export const { setPostList, setPostDetails } = postListSlice.actions;

export default postListSlice.reducer;
