import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Card, Form, Select } from 'antd';

import { Input } from '../../../../../stories/Input/Input';

import styles from './index.module.scss';

const { Option } = Select;

const Profile = () => {
  const cityList = [
    'Tokyo',
    'Kyoto',
    'Osaka',
    'Nagoya',
    'Kobe',
    'Hiroshima',
    'Yokohama',
  ];
  const [selectedCity, setSelectedCity] = useState(cityList[0]);
  const [selectedGender, setSelectedGender] = useState('male');

  const onChangeCity = (value: string) => {
    setSelectedCity(value);
  };

  const onChangeGnder = (value: string) => {
    setSelectedGender(value);
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title={<FormattedMessage id="profile.profileInformation" />}
          className={styles.form}
        >
          <Form>
            <Col span={24}>
              <Row justify={'space-between'}>
                <Col span={11}>
                  <Select
                    showSearch
                    size={'large'}
                    value={selectedGender}
                    onChange={onChangeGnder}
                    className={styles.formSelectInput}
                  >
                    <Option value={'male'} key={'0'}>
                      <FormattedMessage id="profile.male" />
                    </Option>
                    <Option value={'female'} key={'1'}>
                      <FormattedMessage id="profile.female" />
                    </Option>
                  </Select>
                </Col>
                <Col span={12}>
                  <Select
                    showSearch
                    size={'large'}
                    value={selectedCity}
                    placeholder="Select a area"
                    onChange={onChangeCity}
                    className={styles.formSelectInput}
                  >
                    {cityList.map((d: string, i: number) => {
                      return (
                        <Option value={d} key={i}>
                          {d}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
            </Col>
            <div className={styles.input}>
              <Col span={24}>
                <Row justify={'space-between'}>
                  <Col span={11}>
                    <Input
                      type="text"
                      name="title"
                      placeholder="Title"
                      rules={[{ required: true }]}
                    />
                  </Col>
                  <Col span={12}>
                    <Input
                      type="text"
                      name="link"
                      placeholder="Link"
                      rules={[{ required: true }]}
                    />
                  </Col>
                </Row>
              </Col>
            </div>
            <Col span={24}>
              <Input
                name="introduction"
                type="textarea"
                placeholder="Self Introduction"
                rules={[{ required: true }]}
              />
            </Col>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Profile;
