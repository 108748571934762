import { Col, Row, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { routes } from '../../constant';
import { Star } from '../Icons/Star/Star';
import astraLogoLightGreen from '../../assets/images/AstraLogoLightGreen.png';
import { Footer, Content } from 'antd/lib/layout/layout';

import styles from './index.module.scss';

export interface FooterProps {}

export const DonorFooter: React.FC<FooterProps> = () => {
  const footerMenu = [
    {
      id: 1,
      href: routes.CompanyProfileUrl,
      title: <FormattedMessage id="donorFooter.companyProfile" />,
    },
    {
      id: 2,
      external: true,
      href: 'https://www.astrakk.com/#concept',
      title: <FormattedMessage id="donorFooter.whatAstra" />,
    },
    {
      id: 3,
      href: routes.PrivacyPolicyUrl,
      title: <FormattedMessage id="donorFooter.privacyPolicy" />,
    },
    {
      id: 4,
      href: routes.TermsAndServiceUrl,
      title: <FormattedMessage id="donorFooter.tos" />,
    },
    {
      id: 5,
      href: routes.CommercialLawUrl,
      title: <FormattedMessage id="donorFooter.law" />,
    },
  ];

  return (
    <Row>
      <Col span={24}>
        <div className={styles.footer}>
          <Footer>
            <Content>
              <Star />
            </Content>
            <div className={styles.sub}>
              <Link to={routes.HomeUrl}>
                <img
                  className={styles.logo}
                  src={astraLogoLightGreen}
                  width="100px"
                  alt="Astra"
                />
              </Link>
              {footerMenu.map((d, i) => {
                return (
                  <Menu
                    key={i}
                    theme="dark"
                    mode="inline"
                    className={styles.menu}
                  >
                    <Menu.Item key={i}>
                      {d.external ? (
                        <a href={d.href} target="_">
                          {d.title}
                        </a>
                      ) : (
                        <Link to={d.href}>{d.title}</Link>
                      )}
                    </Menu.Item>
                  </Menu>
                );
              })}
            </div>

            <hr color="#CEC5B9" />

            <Content>
              <div className={styles.fortheuture}>
                <FormattedMessage id="donorFooter.fortheuture" />
              </div>
              <div className={styles.footerText}>
                <div className={styles.of}>
                  <FormattedMessage id="donorFooter.of" />
                </div>
                <div className={styles.children}>
                  <FormattedMessage id="donorFooter.children" />
                </div>
              </div>
            </Content>
            <Content>
              <div className={styles.japanesFooterText}>
                <FormattedMessage id="donorFooter.japanesFooterText" />
              </div>
            </Content>
            <Content>
              <div className={styles.copyrightText}>
                <FormattedMessage id="donorFooter.Copyright" />
                {new Date().getFullYear()}
                <FormattedMessage id="donorFooter.CopyrightText" />
              </div>
            </Content>
          </Footer>
        </div>
      </Col>
    </Row>
  );
};
