export function Link() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.80434 11.671L5.15465 13.3207C4.47072 14.0047 3.36316 14.0047 2.6798 13.3209C1.99631 12.6374 1.99631 11.5297 2.67967 10.8463L5.97962 7.54639C6.66297 6.86301 7.77065 6.86301 8.454 7.54639C8.6818 7.7742 9.05117 7.7742 9.27897 7.54639C9.50677 7.31859 9.50677 6.94923 9.27897 6.72143C8.13998 5.58244 6.29364 5.58244 5.15465 6.72143L1.85473 10.0213C0.715742 11.1603 0.715742 13.0067 1.85473 14.1457C2.99358 15.2853 4.84006 15.2853 5.97965 14.1457L7.62933 12.496C7.85714 12.2682 7.85714 11.8988 7.62933 11.671C7.40153 11.4432 7.03214 11.4432 6.80434 11.671Z"
        fill="#666666"
      />
      <path
        d="M14.1464 1.85424C13.0074 0.715254 11.1604 0.715254 10.0215 1.85424L8.04219 3.8335C7.81439 4.06131 7.81439 4.43067 8.04219 4.65847C8.27 4.88627 8.63936 4.88627 8.86716 4.65847L10.8464 2.67921C11.5298 1.99583 12.638 1.99583 13.3214 2.67921C14.0048 3.36256 14.0048 4.47024 13.3214 5.15359L9.69188 8.78314C9.0085 9.46652 7.90085 9.46652 7.2175 8.78314C6.9897 8.55534 6.62033 8.55534 6.39253 8.78314C6.16473 9.01095 6.16473 9.38031 6.39253 9.60811C7.53152 10.7471 9.37786 10.7471 10.5168 9.60811L14.1464 5.97858C15.2854 4.8396 15.2854 2.99322 14.1464 1.85424Z"
        fill="#666666"
      />
    </svg>
  );
}
