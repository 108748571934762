/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Form, notification } from 'antd';

import { RootState } from '../../../../store';
import { Input } from '../../../../stories/Input/Input';
import { withSettingLayout } from '../withSettingLayout';
import { useUpdateMeMutation } from '../../../../services/apiService';
import { AccountSettingTab } from '../../../../stories/AccountSettingTab/AccountSettingTab';

import styles from './index.module.scss';
const PersonalInformation = () => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const gender = {
    [intl.formatMessage({ id: 'personalInformation.gender.male' })]: 'Male',
    [intl.formatMessage({ id: 'personalInformation.gender.female' })]: 'Female',
  };
  const [updateUser, { isSuccess, isLoading, isError }] = useUpdateMeMutation();

  const me = useSelector((state: RootState) => state.me);

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.profileUpdated' }),
        placement: 'topRight',
        duration: 5,
      });
    }
    if (isError) {
      notification.error({
        message: intl.formatMessage({ id: 'notification.somethingWrong' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [isSuccess, isError]);

  const onFinish = ({
    dob,
    fullName,
    streetAddress,
    postalCode,
    annualIncome,
    gender,
  }: {
    dob: string;
    fullName: string;
    streetAddress: string;
    postalCode: string;
    annualIncome: string;
    gender: string;
    email: string;
  }) => {
    updateUser({
      id: me.id,
      dob: dob,
      fullName: fullName,
      streetAddress: streetAddress,
      postalCode: postalCode,
      annualIncome: annualIncome,
      gender: gender,
      email: me.email,
    });
  };

  return (
    <AccountSettingTab
      title={<FormattedMessage id="personalInformation.personalInformation" />}
    >
      <Form
        className={styles.mainForm}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          dob: moment(me.dob),
          fullName: me.fullName,
          streetAddress: me.streetAddress,
          postalCode: me.postalCode,
          annualIncome: me.annualIncome,
          gender: me.gender,
        }}
      >
        <Input
          name="dob"
          label={<FormattedMessage id="personalInformation.birthday" />}
          type="date"
          placeholder=""
        />
        <Input
          name="fullName"
          label={<FormattedMessage id="personalInformation.fullName" />}
          type="string"
          placeholder=""
        />
        <Input
          name="postalCode"
          label={<FormattedMessage id="personalInformation.postalCode" />}
          type="string"
          placeholder=""
        />
        <Input
          name="streetAddress"
          label={<FormattedMessage id="personalInformation.address" />}
          type="string"
          placeholder=""
        />
        <Input
          name="annualIncome"
          label={<FormattedMessage id="personalInformation.annualIncome" />}
        />
        <Input
          name="gender"
          label={<FormattedMessage id="personalInformation.gender" />}
          options={gender}
          type="select"
        />
        <Col span={2}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            <FormattedMessage id="personalInformation.keep" />
          </Button>
        </Col>
      </Form>
    </AccountSettingTab>
  );
};

export default withSettingLayout(PersonalInformation);
