import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Button, Table } from 'antd';

import { withSettingLayout } from '../withSettingLayout';
import { PlanBox } from '../../../../stories/PlanBox/PlanBox';
import { SelectedPlan } from '../../../../stories/SelectedPlan/SelectedPlan';
import { AccountSettingTab } from '../../../../stories/AccountSettingTab/AccountSettingTab';
import { planChargeData } from '../../../../FakeData';
import styles from './index.module.scss';

const Plan = () => {
  const planArr = [
    {
      planType: 'Gold',
      price: 660,
      planText: <FormattedMessage id="Plan.mostPopular" />,
    },
    {
      planType: 'Platinum',
      price: 6600,
      planText: <FormattedMessage id="Plan.StandardPlan" />,
    },
    {
      planType: 'Black',
      price: 66000,
      planText: <FormattedMessage id="Plan.mostPopular" />,
    },
  ];

  const [activePlan, setActivePlan] = useState();

  const columns = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      render: (text: number, record: any, index: number) => (
        <div key={index}>
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Plan.Regular" />,
      dataIndex: 'regular',
      align: 'center' as any,
      key: 'regular',
      render: (text: string) => (
        <div className={styles.usernameContent}>
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="Plan.Gold" />,
      dataIndex: 'gold',
      align: 'center' as any,
      key: 'gold',
      render: (text: number) => (
        <p>
          <span>{text}</span>
        </p>
      ),
    },
    {
      title: <FormattedMessage id="Plan.Platinum" />,
      dataIndex: 'platinum',
      align: 'center' as any,
      key: 'platinum',
      render: (text: number) => (
        <p>
          <span>{text}</span>
        </p>
      ),
    },
    {
      title: <FormattedMessage id="Plan.Black" />,
      dataIndex: 'black',
      align: 'center' as any,
      key: 'black',
      render: (text: number) => (
        <p>
          <span>{text}</span>
        </p>
      ),
    },
  ];

  return (
    <AccountSettingTab
      title={<FormattedMessage id="donorAccountSetting.plan" />}
    >
      <h3 className={styles.planTxt}>
        <FormattedMessage id="Plan.CurrentlySelectedPlan" />
      </h3>

      <SelectedPlan />

      <h3 className={styles.planChangeTitle}>
        <FormattedMessage id="Plan.PlanChange" />
      </h3>

      <Row justify="space-between">
        {planArr.map((item: any, index: number) => {
          return (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={24}
              lg={11}
              xl={7}
              xxl={7}
              onClick={() => {
                setActivePlan(item);
              }}
            >
              <PlanBox
                planType={item.planType}
                price={item.price}
                planText={item.planText}
                active={item === activePlan}
              />
            </Col>
          );
        })}
      </Row>
      <Button
        className={`${styles.changeBtn} ${activePlan ? styles.activeBtn : ''}`}
      >
        <FormattedMessage id="Plan.Change" />
      </Button>

      <h3 className={styles.descPlan}>Description of the plan</h3>
      <h4 className={styles.subDescPlan}>
        The type of badge given to your account varies depending on the plan you
        subscribe to. In addition, there are restrictions depending on the
        function.
      </h4>
      <Table
        dataSource={planChargeData}
        columns={columns}
        pagination={false}
        className={styles.tableMainContent}
      />
    </AccountSettingTab>
  );
};

export default withSettingLayout(Plan);
