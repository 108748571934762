import { Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';

import { Square } from '../Icons/Square/Square';

import styles from './index.module.scss';

export interface SelectedPlanProps {}

export const SelectedPlan: React.FC<SelectedPlanProps> = () => {
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={11} xl={8} xxl={7}>
        <div className={styles.planMainBox}>
          <div className={styles.planBox}>
            <Square />
            <p>
              <FormattedMessage id="Plan.Regular" />
            </p>
          </div>
          <div className={styles.planSecContent}>
            <p>
              <FormattedMessage id="Plan.Regular" /> {'    '}
              <FormattedMessage id="donorAccountSetting.plan" />
            </p>
            <p>
              <FormattedMessage id="Plan.Free" />{' '}
              <p>
                /<FormattedMessage id="Plan.Moon" />
              </p>
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};
