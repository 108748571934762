/* eslint-disable react-hooks/exhaustive-deps */
import { useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, Spin, Button, Pagination, notification } from 'antd';

import { withTabs } from '../../withTabs';
import { getAccessToken } from '../../../../../getAccessToken';
import { ActivityReportBox } from '../../../../../stories/ActivityReportBox/ActivityReportBox';
import {
  useGetActivityReportsQuery,
  useDeleteActivityReportMutation,
} from '../../../../../services/apiService';

const ActivityReport = () => {
  const intl = useIntl();
  const history = useHistory();
  const { id: facilityId } = useParams<{ id: string }>();
  const [token, setToken] = useState<string | undefined>('');

  const recordsEachPage = 10;
  const [pageNum, setPageNum] = useState(1);
  const { isLoading, data: apiResponse } = useGetActivityReportsQuery({
    page: pageNum,
    limit: recordsEachPage,
    facilityId: facilityId,
  });

  const [deleteAcitivityReport, { isSuccess: deletedSuccessfully }] =
    useDeleteActivityReportMutation();

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  useEffect(() => {
    if (deletedSuccessfully) {
      notification.success({
        message: intl.formatMessage({
          id: 'notification.ActivityReportDelete',
        }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [deletedSuccessfully]);

  const handlePagination = (value: number) => {
    setPageNum(value);
  };

  const handleAddClick = () => {
    history.push(`/facility/${facilityId}/new-activity-report`);
  };

  if (isLoading) {
    return (
      <Row justify="center">
        <div className="space-align-container">
          <Spin size="large" />
        </div>
      </Row>
    );
  }

  if (apiResponse?.data?.length === 0) {
    return (
      <Row gutter={[0, 32]} justify="center">
        <Col span="3" offset="21">
          <Button type="primary" onClick={handleAddClick}>
            Add New
          </Button>
        </Col>
        <Col span="24">
          <h1>No Activity Reports</h1>{' '}
        </Col>
      </Row>
    );
  }

  return (
    <Row gutter={[0, 32]} justify="center">
      <Col span="3" offset="21">
        <Button type="primary" onClick={handleAddClick}>
          Add New
        </Button>
      </Col>
      <Col span="24">
        {apiResponse?.data?.map((ar: any, i: number) => {
          return (
            <ActivityReportBox
              key={ar.id}
              token={token}
              activityReport={ar}
              deleteAcitivityReport={deleteAcitivityReport}
            />
          );
        })}
      </Col>
      <Col>
        <Pagination
          defaultCurrent={pageNum}
          onChange={handlePagination}
          total={apiResponse?.totalCount}
          defaultPageSize={recordsEachPage}
        />
      </Col>
    </Row>
  );
};

export default withTabs(ActivityReport);
