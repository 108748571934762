import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Form, Button, Typography } from 'antd';

import { Input } from '../../stories/Input/Input';

import styles from './index.module.scss';

export interface ForgotPasswordOtpVerifyComponentProps {
  loading: boolean;
  phoneNumber: string;
  resendCode: () => void;
  onFinish: (values: any) => void;
}

export const ForgotPasswordOtpVerifyForm = ({
  loading,
  onFinish,
  resendCode,
  phoneNumber,
}: ForgotPasswordOtpVerifyComponentProps) => {
  const { Text } = Typography;

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      requiredMark={false}
      wrapperCol={{ span: 24 }}
      className={styles.form}
    >
      <Input
        name="otp"
        type="string"
        label={
          <FormattedMessage id="forgotPasswordOtpVerify.authenticationCode" />
        }
        placeholder="Code"
        rules={[{ required: true }]}
      />
      <div className={styles.resendLink}>
        <Typography.Link underline onClick={resendCode}>
          <FormattedMessage id="forgotPasswordOtpVerify.resendCode" />
        </Typography.Link>
      </div>
      <Input
        type="password"
        name="password"
        label={<FormattedMessage id="forgotPasswordOtpVerify.newPassword" />}
        placeholder="New Password"
        rules={[
          {
            required: true,
          },
          {
            pattern:
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/,
            message:
              'Must have atleast 6 character with one lowercase, uppercase, number and special character',
          },
        ]}
      />
      <Button
        block
        size="large"
        type="primary"
        htmlType="submit"
        loading={loading}
      >
        <FormattedMessage id="forgotPasswordOtpVerify.resetPassword" />
      </Button>
      <div>
        <Text className={styles.or}>
          <FormattedMessage id="forgotPasswordOtpVerify.or" />
        </Text>
        <Link to="/">
          <Text underline>
            <FormattedMessage id="forgotPasswordOtpVerify.login" />
          </Text>
        </Link>
      </div>
    </Form>
  );
};
