export function SowSeeds(props: {
  height?: number;
  width?: number;
  fill?: string;
}) {
  return (
    <svg
      viewBox="0 0 120 120"
      width="120"
      height="120"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.069 99.485c-15.545 8.975-33.183 7.526-39.396-3.236-6.213-10.761 1.351-26.76 16.896-35.735 15.544-8.975 33.183-7.526 39.396 3.235 6.213 10.762-1.352 26.761-16.896 35.736zm-3.75-6.495c-6.618 3.82-13.406 5.25-18.833 4.803-5.463-.448-8.79-2.647-10.318-5.294-1.528-2.646-1.769-6.626.574-11.582.17-.36.73-.198.736.2.03 1.98.506 3.86 1.48 5.545 4.487 7.772 17.818 8.476 29.775 1.573 1.27-.733 2.584.536 1.44 1.45a39.136 39.136 0 01-4.854 3.305zm8.104-54.24c.69 0 1.25.56 1.25 1.25v30h-2.5V40c0-.69.56-1.25 1.25-1.25zm0 32.5c-.69 0-1.25-.56-1.25-1.25h2.5c0 .69-.56 1.25-1.25 1.25z"
        fill={props.fill}
      />
      <path
        d="M35.928 15h10c13.807 0 25 11.193 25 25h-10c-13.807 0-25-11.193-25-25z"
        fill={props.fill}
      />
      <path
        d="M100.928 15h-10c-13.807 0-25 11.193-25 25h10c13.807 0 25-11.193 25-25z"
        fill={props.fill}
      />
    </svg>
  );
}
