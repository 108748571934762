import { Row, Col } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Inquiry from './Inquiry';
import Law from './Law';
import CompanyProfile from './CompanyProfile';
import { TermsAndService } from './TermsAndService';
import { PrivacyPolicy } from './PrivacyPolicy';
import { DonorFooter } from '../../../stories/DonorFooter/DonorFooter';

import styles from './index.module.scss';

const CompanyProfilePages = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const companyTabArr = [
    {
      title: <FormattedMessage id="donorFooter.companyProfile" />,
      content: <CompanyProfile />,
    },
    {
      title: <FormattedMessage id="donorFooter.privacyPolicy" />,
      content: <PrivacyPolicy />,
    },
    {
      title: <FormattedMessage id="donorFooter.tos" />,
      content: <TermsAndService />,
    },
    {
      title: <FormattedMessage id="donorFooter.lawTitle" />,
      content: <Law />,
    },
    {
      title: <FormattedMessage id="donorFooter.inquiry" />,
      content: <Inquiry />,
    },
  ];

  return (
    <>
      <Row justify="center">
        <Col
          xxl={12}
          xl={12}
          md={18}
          sm={24}
          xs={24}
          className={styles.mainCol}
        >
          <div className={styles.titleContent}>
            <p>{companyTabArr[currentIndex].title}</p>
          </div>
          <div className={styles.tabMain}>
            {companyTabArr.map((i, index) => (
              <div onClick={() => setCurrentIndex(index)}>
                <p
                  className={` ${
                    index === currentIndex
                      ? styles.activeTabTitle
                      : styles.tabTitle
                  }`}
                >
                  {i.title}
                </p>
              </div>
            ))}
          </div>
          <div>{companyTabArr[currentIndex].content}</div>
        </Col>
      </Row>
      <DonorFooter />
    </>
  );
};

export default CompanyProfilePages;
