export function EnergySymbol() {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.99988 0L-0.00012207 9H2.99988L0.999878 16L7.99988 7H4.99988L6.99988 0Z"
        fill="#BDA35E"
      />
    </svg>
  );
}
