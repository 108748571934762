import { Story, Meta } from '@storybook/react';

import { EnergySymbol } from './EnergySymbol';

export default {
  title: 'Core/Icons',
  component: EnergySymbol,
  argTypes: {
    backgroundColor: {
      control: 'color',
    },
  },
} as Meta;

const Template: Story = (args) => <EnergySymbol />;

export const EnergySymbolIcon = Template.bind({});
