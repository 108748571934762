import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';

import passfather from 'passfather';

import { getUsername } from '../../utils';

export const AwsInviteUser = async ({
  callback,
  facilityId,
  phoneNumber,
  errorCallback,
}: {
  facilityId?: number;
  phoneNumber: string;
  callback: () => void;
  errorCallback?: () => void;
}) => {
  const {
    REACT_APP_PHONE_CODE,
    REACT_APP_AWS_REGION,
    REACT_APP_AWS_USER_POOL_ID,
    REACT_APP_AWS_IDENTITY_POOL_ID,
  } = process.env;

  const password = passfather({
    length: 8,
  });
  const role = facilityId ? 'FacilityAdmin' : 'Donor';

  let params = {
    TemporaryPassword: password,
    Username: getUsername(phoneNumber),
    UserPoolId: REACT_APP_AWS_USER_POOL_ID as string,
    UserAttributes: [
      { Name: 'custom:role', Value: role },
      { Name: 'phone_number_verified', Value: 'true' },
      {
        Name: 'phone_number',
        Value: (REACT_APP_PHONE_CODE as string) + phoneNumber,
      },
    ],
  };

  if (facilityId) {
    params.UserAttributes.push({
      Name: 'custom:facilityId',
      Value: `${facilityId}`,
    });
  }

  const user = await Auth.currentAuthenticatedUser();
  const login: { [key: string]: string } = {};
  const awsClientId = `cognito-idp.${REACT_APP_AWS_REGION}.amazonaws.com/${REACT_APP_AWS_USER_POOL_ID}`;
  const idToken = user.signInUserSession.idToken.getJwtToken();
  login[awsClientId] = idToken;
  AWS.config.region = REACT_APP_AWS_REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    Logins: login,
    IdentityPoolId: REACT_APP_AWS_IDENTITY_POOL_ID as string,
  });

  let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
  cognitoidentityserviceprovider.adminCreateUser(
    params,
    function (err: any, result: any) {
      if (err) {
        notification.error({
          message: err.message,
          placement: 'topRight',
        });
        if (errorCallback) {
          errorCallback();
        }
      }
      if (result?.User) {
        callback();
      }
    }
  );
};

export const AwsDeleteUser = async ({
  callback,
  setVisible,
  phoneNumber,
}: {
  phoneNumber: string;
  callback: () => void;
  setVisible: (state: boolean) => void;
}) => {
  const {
    REACT_APP_AWS_REGION,
    REACT_APP_AWS_USER_POOL_ID,
    REACT_APP_AWS_IDENTITY_POOL_ID,
  } = process.env;

  const user = await Auth.currentAuthenticatedUser();
  const login: { [key: string]: string } = {};
  const awsClientId = `cognito-idp.${REACT_APP_AWS_REGION}.amazonaws.com/${REACT_APP_AWS_USER_POOL_ID}`;
  const idToken = user.signInUserSession.idToken.getJwtToken();
  login[awsClientId] = idToken;
  AWS.config.region = REACT_APP_AWS_REGION;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    Logins: login,
    IdentityPoolId: REACT_APP_AWS_IDENTITY_POOL_ID as string,
  });
  const cognitoidentityserviceprovider =
    new AWS.CognitoIdentityServiceProvider();

  await cognitoidentityserviceprovider.adminDeleteUser(
    {
      UserPoolId: REACT_APP_AWS_USER_POOL_ID as string,
      Username: getUsername(phoneNumber),
    },
    function (err: any, result: any) {
      if (err) {
        notification.error({
          message: err.message,
          placement: 'topRight',
        });
        setVisible(false);
      } else {
        callback();
      }
    }
  );
};
