/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Table, Space, Tabs, Row, Col, notification } from 'antd';

import { withTabs } from '../withTabs';
import {
  ThanksMessageResponse,
  useGetThanksMessageQuery,
  useDeleteThanksMessageMutation,
} from '../../../../services/apiService';
import { SorterResult } from 'antd/lib/table/interface';
import { Input } from '../../../../stories/Input/Input';
import { Loader } from '../../../../stories/Loader/Loader';
import { ThanksMessageDetail } from '../../../../stories/ThanksMessageDetail/ThanksMessageDetail';

import styles from './index.module.scss';

const { TabPane } = Tabs;

const ThanksMessageComponent = () => {
  const [paginateInfo, setPaginateInfo] = useState({
    page: 1,
    search: '',
    limit: 10,
    orderDirection: 'ASC',
    orderField: 'createdAt',
  });

  const { data: thanksData, isLoading } =
    useGetThanksMessageQuery(paginateInfo);
  const [messages, setMessages] = useState<ThanksMessageResponse[]>();
  const [isDetail, setIsDetail] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const [deleteThanksMessage, { isSuccess, error }] =
    useDeleteThanksMessageMutation();

  useEffect(() => {
    if (error) {
      notification.error({
        message: error,
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [error]);

  useEffect(() => {
    setMessages(thanksData?.data);
  }, [thanksData]);

  const onSearch = (e: any) => {
    setPaginateInfo({
      page: 1,
      search: e.target.value,
      limit: 10,
      orderDirection: 'ASC',
      orderField: 'createdAt',
    });
  };

  const handleTableChange = (current: any, field: any, orderBy: any) => {
    setPaginateInfo({
      ...paginateInfo,
      page: current,
      orderField: field || 'createdAt',
      orderDirection: orderBy === 'ascend' ? 'ASC' : 'DESC',
    });
  };
  const onDataClick = (index: number) => {
    setIsDetail(true);
    setIndex(index);
  };

  const onDeleteMessage = () => {
    if (messages && messages?.length > 0)
      deleteThanksMessage(messages[index]?.id);
    setIsDetail(false);
  };

  const onBackArrowClick = () => {
    setIsDetail(false);
  };

  const columns = [
    {
      title: 'Message title',
      dataIndex: 'title',
      render: (text: string, record: any, index: number) => (
        <div
          className={styles.title}
          onClick={() => {
            onDataClick(index);
          }}
        >
          <p>{text}</p>
        </div>
      ),
      width: 610,
    },
    {
      title: 'Creation date and time',
      dataIndex: 'createdAt',
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
      render: (text: string, record: any, index: number) => (
        <div
          className="table-column"
          onClick={() => {
            onDataClick(index);
          }}
        >
          <p>{moment(text).format('YYYY/MM/DD hh:mm')}</p>
        </div>
      ),
      width: 300,
    },
    {
      title: 'Scheduled transmission date and time',
      dataIndex: 'scheduledAt',
      sorter: (a: any, b: any) => a.scheduledAt.localeCompare(b.scheduledAt),
      render: (text: string, record: any, index: number) => (
        <div
          className="table-column"
          onClick={() => {
            onDataClick(index);
          }}
        >
          <p>{moment(text).format('YYYY/MM/DD hh:mm')}</p>
        </div>
      ),
      width: 300,
    },
  ];

  if (isLoading) return <Loader />;

  if (isDetail)
    return (
      <ThanksMessageDetail
        messages={messages}
        index={index}
        setIndex={setIndex}
        onBackArrowClick={onBackArrowClick}
        onDeleteMessage={onDeleteMessage}
      />
    );

  return (
    <Row>
      <Col className={styles.message}>
        <div className={styles.table}>
          <Tabs defaultActiveKey={'0'} className={styles.tableContent}>
            <TabPane tab={'Scheduled to be sent'} key={0}>
              <Space align={'end'}>
                <Input
                  name="search"
                  type="search"
                  onSearchEnter={onSearch}
                  placeholder={'Search'}
                />
              </Space>

              <Table
                columns={columns}
                dataSource={messages}
                onChange={(pagination, filters, sorter) => {
                  const { field, order } = sorter as SorterResult<any>;
                  handleTableChange(pagination.current, field, order);
                }}
                pagination={{
                  total: 0,
                }}
              />
            </TabPane>
            <TabPane tab={'Sent'} key={1}>
              <Space align={'end'}>
                <Input
                  name="search"
                  type="search"
                  onSearchEnter={onSearch}
                  placeholder={'Search'}
                />
              </Space>

              <Table
                columns={columns}
                dataSource={[]}
                onChange={(pagination, filters, sorter) => {
                  const { field, order } = sorter as SorterResult<any>;
                  handleTableChange(pagination.current, field, order);
                }}
                pagination={{
                  total: 0,
                }}
              />
            </TabPane>
          </Tabs>
        </div>
      </Col>
    </Row>
  );
};

export default withTabs(ThanksMessageComponent);
