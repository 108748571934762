import { Spin } from 'antd';
import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from './Header';
import { RootState } from './store';
import { routes } from './constant';
import useGaTracker from './useGaTracker';
import { withLayout } from './withLayout';
import { setMe } from './features/me/meSlice';
import { UserRole } from './services/apiService';
import { useGetMeQuery } from './services/apiService';

const PrivateRoute: React.FC<{
  path: string;
  exact: boolean;
  roles?: Array<UserRole>;
  component: React.FC;
}> = ({ path, roles = [], exact, component }) => {
  const dispatch = useDispatch();
  const { data, isFetching } = useGetMeQuery({});
  const { authenticated } = useSelector(
    (state: RootState) => state.cognitoUserDetail
  );
  const { id, role, ownedFacility, isDataLoaded, isProfileCompleted } =
    useSelector((state: RootState) => state.me);

  useGaTracker();

  useEffect(() => {
    if (!isFetching) {
      dispatch(setMe({ data: data! }));
    }
  }, [data, isFetching, dispatch]);

  if (!authenticated) {
    return <Redirect to={routes.LoginUrl} />;
  }

  if (isDataLoaded) {
    return (
      <div className="space-align-container">
        <Spin size="large" />
      </div>
    );
  }

  if (!isProfileCompleted) {
    if (path === routes.UserProfileUrl) {
      return (
        <Route
          path={routes.UserProfileUrl}
          exact={exact}
          component={component}
        />
      );
    }

    return <Redirect to={routes.UserProfileUrl} />;
  }

  if (roles.includes(role)) {
    return (
      <>
        <Header facilityId={ownedFacility?.id} />
        <Route path={path} exact={exact} component={withLayout(component)} />
      </>
    );
  }

  return (
    <Redirect
      to={
        role === 'Donor'
          ? routes.HomeUrl
          : role === 'AstraAdmin'
          ? routes.FacilityListUrl
          : `/facility/${id}/support-status`
      }
    />
  );
};

export default PrivateRoute;
