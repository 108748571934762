import { FormattedMessage } from 'react-intl';
import { Avatar, Col, Row, Table, Typography } from 'antd';

import { dummyUserProfile } from '../../../../constant';
import { RankingGold } from '../../../../stories/Icons/RankingGold/RankingGold';
import { RankingBrown } from '../../../../stories/Icons/RankingBrown/RankingBrown';
import { RankingSilver } from '../../../../stories/Icons/RankingSilver/RankingSilver';

import styles from './index.module.scss';

const { Title } = Typography;

const Ranking = () => {
  const dataSource = [...Array(10)].map((_, i) => {
    return {
      ranking: i + 1,
      username: 'Astra Team',
      day: 10,
      totalSupport: 10,
      level: 10,
      rank: 'D',
    };
  });

  const columns = [
    {
      title: <FormattedMessage id="ranking.ranking" />,
      dataIndex: 'ranking',
      key: 'ranking',
      align: 'center' as any,
      fixed: 'left' as any,
      render: (text: number, record: any, index: number) => (
        <div key={index}>
          {text === 1 ? (
            <RankingGold />
          ) : text === 2 ? (
            <RankingSilver />
          ) : text === 3 ? (
            <RankingBrown />
          ) : (
            <p>{text}</p>
          )}
        </div>
      ),
    },
    {
      title: <FormattedMessage id="inquiryList.userName" />,
      dataIndex: 'username',
      key: 'username',
      render: (text: string) => (
        <div className={styles.usernameContent}>
          <Avatar src={dummyUserProfile} size={40} shape={'circle'} />
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="ranking.supportDay" />,
      dataIndex: 'day',
      key: 'day',
      render: (text: number) => (
        <p>
          <span>{text}</span> day
        </p>
      ),
    },
    {
      title: <FormattedMessage id="ranking.totalSupportNumber" />,
      dataIndex: 'totalSupport',
      key: 'totalSupport',
      render: (text: number) => (
        <p>
          <span>{text}</span> times
        </p>
      ),
    },
    {
      title: <FormattedMessage id="ranking.leval" />,
      dataIndex: 'level',
      key: 'level',
      render: (text: number) => (
        <p>
          Lv. <span>{text}</span>
        </p>
      ),
    },
    {
      title: <FormattedMessage id="ranking.rank" />,
      dataIndex: 'rank',
      key: 'rank',
      render: (text: number) => (
        <p>
          Rank <span>{text}</span>
        </p>
      ),
    },
  ];
  return (
    <div>
      <Row justify="center">
        <Col xs={23} sm={23} md={23} lg={18} xxl={20}>
          <div>
            <div className={styles.supportTitle}>
              <Title level={3}>
                <FormattedMessage id="ranking.title" />
              </Title>
            </div>
          </div>
        </Col>
      </Row>
      <Row justify="center" className={styles.rankingTable}>
        <Col xs={23} sm={23} md={23} lg={18} xxl={20}>
          <Table dataSource={dataSource} columns={columns} />
        </Col>
      </Row>
    </div>
  );
};

export default Ranking;
