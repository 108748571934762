export const BookmarkStar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={15}
    viewBox="0 0 16 16"
  >
    <path
      style={{
        fillRule: 'nonzero',
        fill: '#f2c449',
        fillOpacity: 1,
        strokeWidth: 0.998073,
        strokeLinecap: 'butt',
        strokeLinejoin: 'miter',
        stroke: '#a99c8b',
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      d="m12 18.664.256.155 5.975 3.57a.77.77 0 0 0 .85-.036.775.775 0 0 0 .306-.802Zm0 0-.256.155-5.975 3.57a.75.75 0 0 1-.85-.036.775.775 0 0 1-.306-.802l1.55-6.805.062-.287L6 14.26.763 9.673a.792.792 0 0 1-.226-.826c.094-.3.357-.51.67-.533l6.924-.63.3-.03.113-.275L11.287.97a.775.775 0 0 1 1.425 0l2.744 6.41.113.275.3.03 6.925.629a.759.759 0 0 1 .668.533.792.792 0 0 1-.224.826L18 14.261l-.225.198.069.287 1.543 6.805Zm0 0"
      transform="scale(.625 .65217)"
    />
  </svg>
);
