import { Image } from 'antd';

import { getUrl } from '../../utils';

export interface MobileMissionCardProps {
  missionImage: { id: number };
  mission: string;
  token?: string;
}

export function MobileMissionCard({
  missionImage,
  mission,
  token,
}: MobileMissionCardProps) {
  return (
    <div>
      <Image
        placeholder
        width={'100%'}
        src={
          missionImage?.id
            ? getUrl(missionImage?.id, token)
            : 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
        }
      />
      <p>{mission}</p>
    </div>
  );
}
