import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

interface UnsubscribeBoxProps {
  onMemberShipClick: () => void;
}
export const UnsubscribeBox = ({ onMemberShipClick }: UnsubscribeBoxProps) => {
  return (
    <>
      <h5 className={styles.titleTxt}>
        <FormattedMessage id="UnsubscribeTab.headTxt" />
      </h5>
      <p className={styles.subTxt}>
        1. {<FormattedMessage id="UnsubscribeTab.subPoint1" />}
      </p>
      <p className={styles.subTxt}>
        2. {<FormattedMessage id="UnsubscribeTab.subPoint2" />}
      </p>
      <p className={styles.subTxt}>
        3. {<FormattedMessage id="UnsubscribeTab.subPoint3" />}
      </p>

      <Button
        type="primary"
        danger
        className={styles.unsubscribeBtn}
        onClick={onMemberShipClick}
      >
        <FormattedMessage id="UnsubscribeTab.cancelMembership" />
      </Button>
    </>
  );
};
