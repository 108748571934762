import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { routes } from '../../constant';
import { getUsername } from '../../utils';
import { logout } from '../../features/me/meSlice';
import { ForceChangePassword } from './ForceChangePassword';
import { LoginComponent } from '../../stories/LoginComponent/LoginComponent';
import { setCognitoUserDetail } from '../../features/cognitoUserDetail/cognitoUserDetail';

const Login = () => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [invitedUserAttrs, setInvitedUserAttrs] = useState({
    isInvitedUser: false,
    cognitoUser: {} as CognitoUser,
  });
  const [loading, setLoading] = useState(false);

  const onFinish = ({
    password,
    phoneNumber,
  }: {
    password: string;
    phoneNumber: string;
  }) => {
    setLoading(true);
    Auth.signIn(getUsername(phoneNumber), password)
      .then((user) => {
        if (user.challengeName) {
          setInvitedUserAttrs({
            cognitoUser: user,
            isInvitedUser: true,
          });

          notification.success({
            message: intl.formatMessage({
              id: 'notification.pleaseChangePassword',
            }),
            placement: 'topRight',
            duration: 5,
          });
          setLoading(false);
        } else {
          notification.success({
            message: intl.formatMessage({
              id: 'notification.loginSuccess',
            }),
            placement: 'topRight',
            duration: 5,
          });
          setLoading(false);
          const { email_verified, phone_number_verified, phone_number, email } =
            user?.attributes || {};
          dispatch(
            setCognitoUserDetail({
              data: {
                email,
                phoneNumber: phone_number,
                role: user?.attributes['custom:role'],
                emailVerified: email_verified as boolean,
                facilityId: user?.attributes['custom:facilityId'],
                phoneNumberVerified: phone_number_verified as boolean,
              },
            })
          );
          history.push(routes.FacilityListUrl);
        }
      })
      .catch((err) => {
        setLoading(false);
        dispatch(logout());
        notification.error({
          message: err.message,
          placement: 'topRight',
        });
      });
  };

  if (invitedUserAttrs.isInvitedUser) {
    return <ForceChangePassword cognitoUser={invitedUserAttrs.cognitoUser} />;
  }

  return <LoginComponent loading={loading} onFinish={onFinish} />;
};

export default Login;
