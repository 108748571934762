import axios from 'axios';

import { getAccessToken } from './getAccessToken';

axios.interceptors.request.use(function (config) {
  return new Promise(async (resolve, reject) => {
    const token = await getAccessToken();
    config.headers.Authorization = 'Bearer ' + token;
    resolve(config);
  });
});
