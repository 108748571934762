import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Input } from '../../../../stories/Input/Input';
import { withSettingLayout } from '../withSettingLayout';
import { Button } from '../../../../stories/Button/Button';
import { AccountSettingTab } from '../../../../stories/AccountSettingTab/AccountSettingTab';

import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';

const PhoneNumber = () => {
  const [codeCard, setCodeCard] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const currentPhoneNumber = useSelector(
    (state: RootState) => state.cognitoUserDetail.cognitoUser.phoneNumber
  );

  const handleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleCancel = () => {
    setIsVisible(false);
    setCodeCard(false);
  };

  const handleChangeCardClick = () => {
    setCodeCard(!codeCard);
  };

  if (!isVisible && !codeCard) {
    return (
      <AccountSettingTab
        title={<FormattedMessage id="phoneNumber.teleHeading" />}
      >
        <div className={styles.mainDiv}>
          <label>
            <FormattedMessage id="phoneNumber.phoneNumber" />:
            {currentPhoneNumber}
          </label>
        </div>
        <div className={styles.keepBtn}>
          <Button
            type="primary"
            label={<FormattedMessage id="phoneNumber.changeNumber" />}
            onClick={handleClick}
          />
        </div>
      </AccountSettingTab>
    );
  }

  if (isVisible && !codeCard) {
    return (
      <AccountSettingTab
        title={<FormattedMessage id="phoneNumber.changeNumber" />}
      >
        <div className={styles.mainDiv}>
          <label>
            <FormattedMessage id="phoneNumber.phoneNumber" />:
            {currentPhoneNumber}
          </label>
          <p className={styles.inputAns}></p>
        </div>
        <div className={styles.mainDiv}>
          <label className={styles.noteLable}>
            <FormattedMessage id="phoneNumber.noteFirst" />
          </label>
          <p className={styles.note}>
            <FormattedMessage id="phoneNumber.notesecond" />
          </p>
        </div>
        <div className={styles.inputDiv}>
          <Input
            name="phone"
            type="string"
            addonBefore="0"
            placeholder="XX-XXXX-XXXX"
            label={<FormattedMessage id="phoneNumber.teleHeading" />}
          />
        </div>
        <div className={styles.keepBtn}>
          <Button
            type="primary"
            label={<FormattedMessage id="phoneNumber.sendCodeBtn" />}
            onClick={handleChangeCardClick}
          />
          <Button
            type="ghost"
            label={<FormattedMessage id="phoneNumber.cancel" />}
            onClick={handleCancel}
          />
        </div>
      </AccountSettingTab>
    );
  }

  if (codeCard) {
    return (
      <AccountSettingTab
        title={<FormattedMessage id="phoneNumber.teleHeading" />}
      >
        <div className={styles.mainDiv}>
          <label className={styles.noteLable}>
            <FormattedMessage id="phoneNumber.codeNote" />
          </label>
          <p className={styles.note}>
            <FormattedMessage id="phoneNumber.codeNotsecond" />
          </p>
        </div>
        <div className={styles.inputDiv}>
          <Input
            name="phone"
            type="string"
            placeholder="4-digit verification code"
            label={<FormattedMessage id="phoneNumber.authentication" />}
          />
        </div>
        <div className={styles.keepBtn}>
          <Button
            type="primary"
            label={<FormattedMessage id="phoneNumber.sendCodeBtn" />}
            onClick={handleClick}
          />
          <Button
            type="ghost"
            label={<FormattedMessage id="phoneNumber.cancel" />}
            onClick={handleCancel}
          />
        </div>
      </AccountSettingTab>
    );
  }

  return (
    <AccountSettingTab
      title={<FormattedMessage id="phoneNumber.teleHeading" />}
    >
      <div className={styles.mainDiv}>
        <label>
          <FormattedMessage id="phoneNumber.phoneNumber" />:{currentPhoneNumber}
        </label>
        <p className={styles.inputAns}></p>
      </div>
      <div className={styles.keepBtn}>
        <Button
          type="primary"
          label={<FormattedMessage id="phoneNumber.changeNumber" />}
          onClick={handleClick}
        />
      </div>
    </AccountSettingTab>
  );
};

export default withSettingLayout(PhoneNumber);
