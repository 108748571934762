import moment from 'moment';
import { Modal, Button, Avatar, List } from 'antd';

import { isDeviceMobile } from '../../utils';
import { Square } from '../Icons/Square/Square';
import { dummyUserProfile } from '../../constant';

import styles from './index.module.scss';

export interface PostLikeModalProps {
  open: boolean;
  handleCancel: () => void;
  dataSource?: any;
}

export function PostLikeModal({
  open,
  handleCancel,
  dataSource,
}: PostLikeModalProps) {
  return (
    <Modal
      centered
      footer={null}
      visible={open}
      onCancel={handleCancel}
      title={'Like'}
      className={styles.likeModal}
    >
      <List
        itemLayout="horizontal"
        dataSource={dataSource}
        className={styles.listMainContent}
        renderItem={(item: any) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  src={dummyUserProfile}
                  size={isDeviceMobile() ? 40 : 56}
                  shape={'circle'}
                />
              }
              title={
                <div className={styles.listTitle}>
                  <span>{item?.title}</span>
                  <Square />
                </div>
              }
              description={
                <>
                  <span className={styles.listDescription}>
                    {moment(item?.createdAt).format('YYYY/MM/DD hh:mm')}
                  </span>
                </>
              }
            />

            <Button shape="round">Follow</Button>
          </List.Item>
        )}
      />
    </Modal>
  );
}
