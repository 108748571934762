import { FormattedMessage } from 'react-intl';
import { Card, Avatar, Button, Space } from 'antd';

import { isDeviceMobile } from '../../utils';
import { Square } from '../Icons/Square/Square';
import { dummyUserProfile } from '../../constant';

import styles from './index.module.scss';
export interface SearchUserBoxProps {
  onCardClick?: () => void;
}

export const SearchUserBox = ({ onCardClick }: SearchUserBoxProps) => {
  return (
    <Card className={styles.mainCard} onClick={onCardClick}>
      <div className={styles.subFirstContent}>
        <Avatar
          src={dummyUserProfile}
          size={isDeviceMobile() ? 40 : 56}
          shape={'circle'}
        />
      </div>
      <div>
        <div className={styles.titleContent}>
          <p>Astra Team</p>
          <Square />
        </div>
        <p>@astra001</p>
        <p>
          Maximum 150 characters Contains self-introduction text Contains
          self-introduction text Up to 150 characters Contains a sentence Enters
          a self-introduction sentence Contains a self-introduction sentence
          Contains a self-introduction sentence Contains a self-introduction
          sentence Contains a self-introduction sentence
        </p>
      </div>
      <div className={styles.subThirdContent}>
        <Button type="default" shape="round" size="large">
          <Space size="small">
            <FormattedMessage id="search.follow" />
          </Space>
        </Button>
      </div>
    </Card>
  );
};
