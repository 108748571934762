import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, Button, notification } from 'antd';

import { RootState } from '../../../../store';
import { Input } from '../../../../stories/Input/Input';
import { withSettingLayout } from '../withSettingLayout';
import { LanguageType, setLanguage } from '../../../../features/me/meSlice';
import { AccountSettingTab } from '../../../../stories/AccountSettingTab/AccountSettingTab';

import styles from './index.module.scss';

const SetLanguage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { language } = useSelector((state: RootState) => state.me);
  const onFinish = ({ language }: { language: LanguageType }) => {
    dispatch(setLanguage({ language }));
    notification.success({
      message: intl.formatMessage({ id: 'notification.languageChanged' }),
      placement: 'topRight',
      duration: 5,
    });
  };

  return (
    <AccountSettingTab
      title={<FormattedMessage id="accountSetting.setLanguage.header" />}
    >
      <Form
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        wrapperCol={{ span: 24 }}
        className={styles.formMain}
        initialValues={{ language: language }}
      >
        <Row>
          <Col>
            <Input
              name="language"
              type="select"
              label={
                <FormattedMessage id="accountSetting.setLanguage.language" />
              }
              placeholder=""
              rules={[{ required: true }]}
              options={{
                [intl.formatMessage({
                  id: 'donorAccountSetting.language.english',
                })]: 'en',
                [intl.formatMessage({
                  id: 'donorAccountSetting.language.japanese',
                })]: 'jp',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Button className={styles.keepBtn} type="primary" htmlType="submit">
            <FormattedMessage id="accountSetting.setLanguage.keep" />
          </Button>
        </Row>
      </Form>
    </AccountSettingTab>
  );
};

export default withSettingLayout(SetLanguage);
