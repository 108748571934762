/* eslint-disable react-hooks/exhaustive-deps */
import { Card, notification } from 'antd';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { supportTypeText } from '../../constant';
import { AssistBtn } from '../AssistBtn/AssistBtn';
import { SupportAmount } from '../supportAmount/SupportAmount';
import { SupportOption, SupportType } from '../../services/apiService';
import { SupportCommentModal } from '../SupportCommentModal/SupportCommentModal';

import styles from './index.module.scss';

export interface SupportAmountBoxProps {
  error: any;
  isError?: boolean;
  isLoading?: boolean;
  isSuccess?: boolean;
  data: SupportOption[];
  handleDonateEnergy: ({
    comment,
    supportUnitId,
    supportUnitBoost,
  }: {
    comment: string;
    supportUnitId: number;
    supportUnitBoost: number;
  }) => void;
}

export function SupportAmountBox({
  data,
  error,
  isError,
  isSuccess,
  isLoading,
  handleDonateEnergy,
}: SupportAmountBoxProps) {
  const intl = useIntl();
  const [modalVisible, setModalVisible] = useState(false);
  const [supportDetail, setSupportType] = useState<SupportOption>({
    id: 1,
    energy: 2,
    type: SupportType.sowSeeds,
  });

  useEffect(() => {
    if (isSuccess) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.energyDonated' }),
        placement: 'topRight',
        duration: 5,
      });
      setSupportType({
        id: 1,
        energy: 2,
        type: SupportType.sowSeeds,
      });
      setModalVisible(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const errMsg = (error as any)?.data?.message?.energy[0];
      notification.error({
        message: errMsg,
        placement: 'topRight',
        duration: 10,
      });
      setSupportType({
        id: 0,
        energy: 0,
        type: SupportType.sowSeeds,
      });
      setModalVisible(false);
    }
  }, [error, isError]);

  return (
    <Card className={styles.supportAmountBox}>
      <div className={styles.header}>
        <FormattedMessage id="supportAmount.selectItem" />
      </div>
      <div className={styles.options}>
        {data.map((opt) => (
          <SupportAmount
            key={opt.type}
            energy={opt.energy}
            supportType={opt.type}
            supportTypeText={(type) => (
              <FormattedMessage id={supportTypeText(type)} />
            )}
            onClick={() => setSupportType(opt)}
            active={supportDetail.type === opt.type}
          />
        ))}
      </div>
      <AssistBtn
        disabled={!supportDetail.type}
        handleClick={() => setModalVisible(true)}
      />
      <SupportCommentModal
        open={modalVisible}
        isLoading={isLoading}
        energy={supportDetail.energy}
        supportType={supportDetail.type}
        supportTypeText={(type) => (
          <FormattedMessage id={supportTypeText(type)} />
        )}
        handleCancel={() => setModalVisible(false)}
        handleSubmit={(val) =>
          handleDonateEnergy({
            comment: val.comment,
            supportUnitId: supportDetail.id,
            supportUnitBoost: val.donationUnitBoost,
          })
        }
      />
    </Card>
  );
}
