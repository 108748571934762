import moment from 'moment';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Tag, Card, Space, Image, Button, Typography } from 'antd';

import {
  FacilityResponse,
  ActivityReportsResponse,
} from '../../services/apiService';
import { getUrl } from '../../utils';
import { getAccessToken } from '../../getAccessToken';
import { EnergyValue } from '../EnergyValue/EnergyValue';
import { BookmarkStar } from '../Icons/BookmarkStar/BookmarkStar';

import styles from './index.module.scss';

export interface DonorFacilityDetailModelProps {
  facilityData: FacilityResponse;
  activityReports?: ActivityReportsResponse;
}

export const DonorFacilityDetailModel = ({
  facilityData,
  activityReports,
}: DonorFacilityDetailModelProps) => {
  const { Text, Title } = Typography;

  const [token, setToken] = useState<string | undefined>('');

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  return (
    <Card className={styles.facilitiesDetail} bodyStyle={{ padding: 24 }}>
      <Image
        placeholder
        width="100%"
        src={getUrl(facilityData.coverImage.id, token)}
      />
      <Row
        justify="space-between"
        align="middle"
        className={styles.facilityArea}
      >
        <Col>
          <Space align="baseline">
            <Tag className={styles.cityName}>{facilityData.area}</Tag>
            <Text type="secondary">{facilityData.streetAddress}</Text>
          </Space>
        </Col>
        <Col>
          <Button
            type="default"
            shape="round"
            size="middle"
            className={styles.bookmarkBtn}
          >
            <Space size="small">
              <FormattedMessage id="donorFacilityDetail.bookmark" />
              <BookmarkStar />
            </Space>
          </Button>
        </Col>
      </Row>
      <Row className={styles.facilityInfo}>
        <Col flex={1}>
          <Title level={2} className={styles.facilityName}>
            {facilityData.name}
          </Title>
          <div>
            <Text type="secondary">
              <div>
                <p className={styles.facilityText}>
                  <FormattedMessage id="donorFacilityDetail.children" />:{' '}
                  <b>{facilityData.children}</b>
                </p>
                <p className={styles.facilityText}>
                  <FormattedMessage id="donorFacilityDetail.totalDonors" />:{' '}
                  <b>{facilityData.totalDonors}</b>
                </p>
              </div>
            </Text>
          </div>
        </Col>
        <Col>
          <Space size="large">
            <Space direction="vertical">
              <Text type="secondary">
                <FormattedMessage id="donorFacilityDetail.supportEnergy" />
              </Text>
              <EnergyValue energy={facilityData.totalDonatedEnergy!} />
            </Space>
          </Space>
        </Col>
      </Row>
      <Row>
        <p className={styles.reportTitle}>
          <FormattedMessage id="donorFacilityDetail.activityReports" />
        </p>
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {activityReports?.data.map(
            ({ title, reportDate, coverImage, description, createdAt }) => {
              return (
                <Space
                  key={title}
                  direction="vertical"
                  size="small"
                  style={{ width: '100%' }}
                >
                  <div>
                    <Title level={3} className={styles.facilityName}>
                      {title}
                    </Title>
                    {moment(createdAt).format('YYYY/MM/DD hh:mm')}
                  </div>
                  <Image
                    placeholder
                    width="100%"
                    height="290px"
                    src={getUrl(coverImage!.id, token)}
                  />
                  <div
                    className={styles.text}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </Space>
              );
            }
          )}
        </Space>
      </Row>
    </Card>
  );
};
