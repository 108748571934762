import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from './store';
import { logout } from './features/me/meSlice';
import { AdminHeader } from './stories/AdminHeader/AdminHeader';
import { DonorHeader } from './stories/DonorHeader/DonorHeader';
import { setCognitoUserDetail } from './features/cognitoUserDetail/cognitoUserDetail';

interface HeaderProps {
  facilityId: string | undefined;
}

export const Header = ({ facilityId }: HeaderProps) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { email, role, energy, profileImage, counter } = useSelector(
    (state: RootState) => state.me
  );

  const handleLogout = () => {
    Auth.signOut();
    dispatch(
      setCognitoUserDetail({
        data: {
          role: '',
          email: '',
          facilityId: '',
          phoneNumber: '',
          emailVerified: false,
          phoneNumberVerified: false,
        },
      })
    );
    dispatch(logout());

    notification.success({
      message: intl.formatMessage({ id: 'notification.logOut' }),
      placement: 'topRight',
      duration: 5,
    });
    history.push('/');
  };

  switch (role) {
    case 'Donor':
      return (
        <DonorHeader
          logout={handleLogout}
          energy={energy}
          key={counter}
          profileImage={profileImage}
        />
      );
    case 'FacilityAdmin':
      return (
        <AdminHeader
          name="Admin"
          email={email}
          role="FacilityAdmin"
          logout={handleLogout}
          facilityId={facilityId}
        />
      );
    case 'AstraAdmin':
      return (
        <AdminHeader
          name="Admin"
          email={email}
          role="AstraAdmin"
          logout={handleLogout}
          facilityId={facilityId}
        />
      );
    default:
      return <></>;
  }
};
