/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Modal } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button } from '../Button/Button';
import { ImageBox } from '../ImageBox/ImageBox';

import styles from './index.module.scss';

export interface AddStoryModalProps {
  open: boolean;
  handleCancel: () => void;
  handleSubmit: (imageUrl: string) => void;
}

export function AddStoryModal({
  open,
  handleCancel,
  handleSubmit,
}: AddStoryModalProps) {
  const [imageUrl, setImageUrl] = useState<any>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onClose = () => {
    setImageUrl('');
    handleCancel();
  };

  const handleChange = (event: any) => {
    setIsLoading(true);
    setImageUrl(URL.createObjectURL(event.target.files[0]));
    setIsLoading(false);
  };

  const onSubmit = () => {
    handleSubmit(imageUrl);
    setImageUrl('');
  };

  return (
    <Modal
      title={<FormattedMessage id="UserStory.addStory" />}
      centered
      footer={null}
      visible={open}
      onCancel={onClose}
      className={styles.addPostModal}
    >
      <div className={styles.postMainContent}>
        <div className={styles.inputSelection}>
          <ImageBox
            type="rect"
            size="large"
            label={<FormattedMessage id="imageUploader.upload" />}
            imageUrl={imageUrl}
            loading={isLoading}
          />
          <input
            type="file"
            name="images"
            className={styles.inputStyle}
            onChange={(event) => {
              handleChange(event);
            }}
          />
        </div>
        <div className={styles.btnContent}>
          <Button
            type="primary"
            label="Post"
            onClick={onSubmit}
            disable={!imageUrl}
          >
            Post
          </Button>
        </div>
      </div>
    </Modal>
  );
}
