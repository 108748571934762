import { Spin } from 'antd';
import { ReactNode } from 'react';

import { Camera } from '../Icons/Camera';

import styles from './index.module.scss';

export interface ImageBoxProps {
  loading: boolean;
  imageUrl: string;
  type: 'round' | 'rect';
  label: string | ReactNode;
  size: 'regular' | 'large';
}

export function ImageBox({
  type,
  size,
  label,
  loading,
  imageUrl,
}: ImageBoxProps) {
  const BoxContent = () => {
    if (loading) {
      return <Spin />;
    }

    if (imageUrl) {
      return (
        <img src={imageUrl} className={styles.image} alt="facilityImage" />
      );
    }

    return (
      <>
        <div>
          <Camera />
          <br />
          <span className={styles.text}>{label}</span>
        </div>
      </>
    );
  };

  const Rectbox = () => {
    return (
      <div
        className={
          size === 'regular'
            ? styles.imageBoxRegularSize
            : styles.imageBoxLargeSize
        }
      >
        <div className={styles.outterBoxRect}>
          <div className={styles.innerBoxRect}>
            <BoxContent />
          </div>
        </div>
      </div>
    );
  };

  const Roundbox = () => {
    return (
      <div className={styles.outterBoxRound}>
        <div className={styles.innerBoxRound}>
          <BoxContent />
        </div>
      </div>
    );
  };

  return (
    <div className={styles.imageBox}>
      {type === 'rect' ? <Rectbox /> : <Roundbox />}
    </div>
  );
}
