export function GoldPlan() {
  return (
    <svg width={35} height={38} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m1.352 8.071 1.803 3.574L0 15.665c.15 2.159.856 7.192 2.48 10.05C5.724 33.22 13.898 37.033 17.13 38V.478C11.18.478 3.832 5.54 1.352 8.07Z"
        fill="#C3AB6B"
      />
      <path
        d="m32.91 8.072-1.803 3.574 3.155 4.02c-.15 2.159-.856 7.192-2.479 10.05-3.245 7.505-11.42 11.317-14.65 12.285V.479c5.95 0 13.298 5.062 15.777 7.593Z"
        fill="#536C56"
      />
      <path
        d="m32.91 8.071-1.804 3.574 3.156 4.02c-.15 2.159-.857 7.192-2.48 10.05C28.537 33.22 20.362 37.033 17.132 38V.478c5.95 0 13.298 5.062 15.777 7.593Z"
        fill="#B89A4F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m6.665 11.936-1.46-2.893c2.008-2.049 7.11-5.302 11.927-5.302v30.375c-2.615-.783-9.232-3.87-11.86-9.944-1.313-2.314-1.885-6.389-2.006-8.136l3.4-4.1Zm20.935 0 1.46-2.892c-2.007-2.05-7.11-5.303-11.927-5.303v30.375c2.615-.783 9.232-3.869 11.86-9.944 1.314-2.314 1.885-6.388 2.007-8.136l-3.4-4.1Z"
        fill="url(#a)"
      />
      <path
        d="m22.598 9.854.41 5.016h-.41c-.414-1.25-.95-2.191-1.606-2.824-.945-.914-2.16-1.371-3.644-1.371-2.024 0-3.563.8-4.617 2.402-.883 1.352-1.325 2.961-1.325 4.828 0 1.516.293 2.899.88 4.149.585 1.25 1.35 2.168 2.296 2.754.953.578 1.93.867 2.93.867.586 0 1.152-.074 1.7-.223a6.775 6.775 0 0 0 1.581-.656v-4.594c0-.797-.062-1.316-.187-1.558a1.158 1.158 0 0 0-.563-.563c-.25-.133-.695-.2-1.336-.2v-.445h6.153v.446h-.293c-.61 0-1.028.203-1.254.61-.157.288-.235.859-.235 1.71v4.863a11.9 11.9 0 0 1-2.66 1.067c-.875.226-1.848.34-2.918.34-3.07 0-5.402-.985-6.996-2.953-1.195-1.477-1.793-3.18-1.793-5.11 0-1.398.336-2.738 1.008-4.02.797-1.523 1.89-2.695 3.281-3.515 1.164-.68 2.54-1.02 4.125-1.02.578 0 1.102.047 1.57.141.477.094 1.149.3 2.016.621.438.164.73.246.879.246a.457.457 0 0 0 .375-.199c.11-.14.176-.41.2-.809h.433Z"
        fill="#fff"
      />
      <g clipPath="url(#b)">
        <path
          d="m9.456 11.709 1.56-.205-1.56-.205.643-.838-.837.643-.205-1.56-.205 1.56-.838-.643.643.838-1.56.205 1.56.205-.643.837.838-.643.205 1.56.205-1.56.837.643-.643-.837Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#c)">
        <path
          d="m5.99 23.564 1.872-.246-1.872-.246.772-1.005-1.005.771-.246-1.871-.246 1.871-1.005-.771.772 1.005-1.872.246 1.872.245-.772 1.005 1.005-.771.246 1.871.246-1.871 1.005.771-.772-1.005Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#d)">
        <path
          d="m15.993 28.953.936-.123-.936-.123.386-.503-.503.386-.122-.936-.123.936-.503-.386.386.503-.936.123.936.123-.386.502.503-.385.123.935.123-.935.502.385-.386-.502Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#e)">
        <path
          d="m25.837 25.015.936-.123-.936-.123.386-.502-.503.385-.123-.935-.123.936-.502-.386.386.502-.936.123.936.123-.386.503.502-.386.123.935.123-.935.503.386-.386-.503Z"
          fill="#F7F6F0"
        />
      </g>
      <g clipPath="url(#f)">
        <path
          d="m25.657 8.863 2.495-.328-2.495-.328 1.028-1.34-1.34 1.028-.328-2.495-.328 2.496-1.34-1.03 1.03 1.341-2.496.328 2.495.328-1.029 1.34 1.34-1.03.328 2.496.328-2.495 1.34 1.029-1.028-1.34Z"
          fill="#F7F6F0"
        />
      </g>
      <defs>
        <clipPath id="b">
          <path
            fill="#fff"
            transform="translate(7.09 9.535)"
            d="M0 0h3.938v3.938H0z"
          />
        </clipPath>
        <clipPath id="c">
          <path
            fill="#fff"
            transform="translate(3.148 20.955)"
            d="M0 0h4.725v4.725H0z"
          />
        </clipPath>
        <clipPath id="d">
          <path
            fill="#fff"
            transform="translate(14.57 27.648)"
            d="M0 0h2.363v2.363H0z"
          />
        </clipPath>
        <clipPath id="e">
          <path
            fill="#fff"
            transform="translate(24.418 23.71)"
            d="M0 0h2.363v2.363H0z"
          />
        </clipPath>
        <clipPath id="f">
          <path
            fill="#fff"
            transform="translate(21.867 5.385)"
            d="M0 0h6.301v6.3H0z"
          />
        </clipPath>
        <radialGradient
          id="a"
          cx={0}
          cy={0}
          r={1}
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(0 15.1875 -13.8671 0 17.133 18.929)"
        >
          <stop stopColor="#BCAA7B" />
          <stop offset={1} stopColor="#9F8645" />
        </radialGradient>
      </defs>
    </svg>
  );
}
