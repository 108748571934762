import { ComponentType } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState } from '../../../store';
import { FacilityTabs } from '../../../stories/FacilityTabs/FacilityTabs';

export function withTabs<T>(Component: ComponentType<T>) {
  return (hocProps: T) => {
    const { id: facilityId } = useParams<{ id: string }>();
    const role = useSelector((state: RootState) => state.me.role);

    if (role === 'AstraAdmin') {
      return (
        <>
          <FacilityTabs facilityId={facilityId} />
          <Component {...(hocProps as T)} />
        </>
      );
    }

    return <Component {...(hocProps as T)} />;
  };
}
