import { Spin } from 'antd';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import Amplify, { Auth } from 'aws-amplify';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import Login from './pages/Login/Login';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import Home from './pages/Donor/Home/Home';
import InfoPages from './pages/Donor/Info';
import Company from './pages/Donor/Company';
import InviteUser from './pages/InviteUser';
import UserProfile from './pages/UserProfile';
import SearchPages from './pages/Donor/Search';
import PageNotFound from './pages/PageNotFound';
import Ranking from './pages/Donor/Home/Ranking';
import TimeLine from './pages/Donor/Home/TimeLine';
import ForgotPassword from './pages/ForgotPassword';
import Plan from './pages/Donor/AccountSetting/Plan/Plan';
import NewFacility from './pages/Admin/Facility/NewFacility';
import SupportHistory from './pages/InviteUser/SupportHistory';
import FacilityList from './pages/Admin/Facility/FacilityList';
import MembersList from './pages/Admin/Members/MembersList/MembersList';
import InquiryList from './pages/Admin/Inquiry/InquiryList/InquiryList';
import Profile from './pages/Admin/Members/MemberDetails/Profile/Profile';
import EnergyCharge from './pages/Donor/Home/EnergyCharge/Energycharge';
import UserPostInformation from './pages/Donor/Search/UserPostInformation';
import PasswordSettings from './pages/Donor/AccountSetting/PasswordSettings';
import MemberDetails from './pages/Admin/Members/MemberDetails/MemberDetails';
import Unsubscribe from './pages/Donor/AccountSetting/Unsubscribe/Unsubscribe';
import PhoneNumber from './pages/Donor/AccountSetting/PhoneNumber/PhoneNumber';
import SetLanguage from './pages/Donor/AccountSetting/SetLanguage/SetLanguage';
import SupportStatus from './pages/Admin/Facility/SupportStatus/SupportStatus';
import ThanksMessages from './pages/Admin/Facility/ThanksMessages/ThanksMessages';
import UserPostDetails from './pages/Donor/Search/UserPostInformation/UserPostDetails';
import BasicInformation from './pages/Admin/Facility/BasicInformation/BasicInformation';
import DonorFacilityDetail from './pages/Donor/Home/DonorFacilityDetail/DonorFacilityDetail';
import ActivityReport from './pages/Admin/Facility/ActivityReport/ActivityReport/ActivityReport';
import UpdateEmailAddress from './pages/Donor/AccountSetting/UpdateEmailAddress/UpdateEmailAddress';
import PersonalInformation from './pages/Donor/AccountSetting/PersonalInformation/PersonalInformation';
import NewActivityReport from './pages/Admin/Facility/ActivityReport/NewActivityReport/NewActivityReport';
import EditActivityReport from './pages/Admin/Facility/ActivityReport/EditActivityReport/EditActivityReport';

import { enableFeature, routes } from './constant';
import { useAppDispatch, RootState } from './store';
import { LanguageType } from './features/me/meSlice';
import { setCognitoUserDetail } from './features/cognitoUserDetail/cognitoUserDetail';

import messages_jp from './translations/jp.json';
import messages_en from './translations/en.json';

import './antd.css';
import './App.scss';

const awsconfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_region: process.env.REACT_APP_AWS_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  oauth: {},
};
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const App = () => {
  const messages: { [key in LanguageType]: { [key: string]: string } } = {
    jp: messages_jp,
    en: messages_en,
  };
  const dispatch = useAppDispatch();
  const language = useSelector((state: RootState) => state.me.language);
  const { isFetching }: { isFetching: boolean } = useSelector(
    (state: RootState) => state.cognitoUserDetail
  );

  useEffect(() => {
    async function getCognitoUser() {
      const cognitoUser = await Auth.currentUserInfo();

      const attrs = cognitoUser?.attributes || {};
      const { email_verified, phone_number_verified, phone_number, email } =
        attrs;
      dispatch(
        setCognitoUserDetail({
          data: {
            email,
            phoneNumber: phone_number,
            role: attrs && attrs['custom:role'],
            emailVerified: email_verified as boolean,
            facilityId: attrs && attrs['custom:facilityId'],
            phoneNumberVerified: phone_number_verified as boolean,
          },
        })
      );
    }

    getCognitoUser();
  }, [dispatch]);

  if (isFetching) {
    return (
      <div className="space-align-container">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <IntlProvider
      messages={messages[language ? language : 'jp']}
      locale="en"
      defaultLocale="jp"
    >
      <Router>
        <Switch>
          <PrivateRoute
            exact
            roles={['AstraAdmin', 'Donor']}
            component={EnergyCharge}
            path={routes.EnergyChargeUrl}
          />
          <PrivateRoute
            exact
            component={InviteUser}
            path={routes.InviteUserUrl}
            roles={['AstraAdmin', 'FacilityAdmin', 'Donor']}
          />
          <PrivateRoute
            exact
            roles={[]}
            component={UserProfile}
            path={routes.UserProfileUrl}
          />
          <PrivateRoute
            exact
            component={FacilityList}
            path={routes.FacilityListUrl}
            roles={['AstraAdmin']}
          />
          <PrivateRoute
            exact
            component={BasicInformation}
            path={routes.BasicInformationUrl}
            roles={['AstraAdmin', 'FacilityAdmin']}
          />
          <PrivateRoute
            exact
            component={SupportStatus}
            path={routes.SupportStatusUrl}
            roles={['AstraAdmin', 'FacilityAdmin']}
          />
          <PrivateRoute
            exact
            component={ThanksMessages}
            path={routes.ThanksMessageUrl}
            roles={['AstraAdmin', 'FacilityAdmin']}
          />
          <PrivateRoute
            exact
            component={ActivityReport}
            path={routes.ActivityReportUrl}
            roles={['AstraAdmin', 'FacilityAdmin']}
          />
          <PrivateRoute
            exact
            component={NewActivityReport}
            path={routes.NewActivityReportUrl}
            roles={['AstraAdmin', 'FacilityAdmin']}
          />
          <PrivateRoute
            exact
            roles={['FacilityAdmin', 'AstraAdmin']}
            component={EditActivityReport}
            path={routes.EditActivityReport}
          />
          <PrivateRoute
            exact
            component={DonorFacilityDetail}
            path={routes.FacilityDetailUrl}
            roles={['AstraAdmin', 'Donor']}
          />
          <PrivateRoute
            exact
            component={NewFacility}
            path={routes.NewFacilityUrl}
            roles={['AstraAdmin']}
          />
          <PrivateRoute
            exact
            component={MembersList}
            path={routes.MembersListUrl}
            roles={['AstraAdmin']}
          />
          <PrivateRoute
            exact
            component={InquiryList}
            path={routes.InquiryListUrl}
            roles={['AstraAdmin']}
          />
          <PrivateRoute
            exact
            roles={['AstraAdmin']}
            path={routes.MemberDetailsUrl}
            component={MemberDetails}
          />

          <PrivateRoute
            exact
            roles={['Donor']}
            component={Profile}
            path={routes.ProfileUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={PersonalInformation}
            path={routes.PersonalInformationUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={PhoneNumber}
            path={routes.PhoneNumberUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={UpdateEmailAddress}
            path={routes.UpdateEmailAddressUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={SetLanguage}
            path={routes.SetLanguageUrl}
          />
          {enableFeature.planPage && (
            <PrivateRoute
              exact
              roles={['Donor']}
              component={Plan}
              path={routes.PlanUrl}
            />
          )}
          <PrivateRoute
            exact
            roles={['Donor']}
            component={PasswordSettings}
            path={routes.PasswordSettingsUrl}
          />
          {enableFeature.unsubscribePage && (
            <PrivateRoute
              exact
              roles={['Donor']}
              component={Unsubscribe}
              path={routes.UnsubscribeUrl}
            />
          )}
          <PrivateRoute
            exact
            roles={['Donor']}
            component={Home}
            path={routes.HomeUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={InfoPages}
            path={routes.PrivacyPolicyUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={SearchPages}
            path={routes.SearchUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={InfoPages}
            path={routes.TermsAndServiceUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={InfoPages}
            path={routes.CommercialLawUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={SupportHistory}
            path={routes.SupportHistoryUrl}
          />
          <PrivateRoute
            exact
            roles={['Donor']}
            component={Ranking}
            path={routes.RankingUrl}
          />

          <PrivateRoute
            exact
            roles={['Donor']}
            component={UserPostDetails}
            path={routes.UserPostDetailsUrl}
          />

          <PrivateRoute
            exact
            roles={['Donor']}
            component={UserPostInformation}
            path={routes.UserPostInformationUrl}
          />

          {enableFeature.timeLinePage && (
            <PrivateRoute
              exact
              roles={['Donor']}
              component={TimeLine}
              path={routes.TimeLineUrl}
            />
          )}
          <PrivateRoute
            exact
            roles={['Donor']}
            component={Company}
            path={routes.CompanyProfileUrl}
          />

          <PublicRoute
            exact
            component={ForgotPassword}
            path={routes.ForgotPasswordUrl}
          />

          <PublicRoute exact component={Login} path={routes.LoginUrl} />

          <Route component={PageNotFound} path="*" />
        </Switch>
      </Router>
    </IntlProvider>
  );
};

export default App;
