/* eslint-disable no-useless-escape*/
import { FormattedMessage } from 'react-intl';
import { Row, Col, Space, Typography, Statistic, Button, Form } from 'antd';

import { Input } from '../Input/Input';
import { EnergyChargePlanType } from '../Payment/Payment';
import { EnergySymbol } from '../Icons/EnergySymbol/EnergySymbol';
import { EnergyChargeBox } from '../EnergyChargeBox/EnergyChargeBox';

import styles from './index.module.scss';

const { Title } = Typography;

export interface EnergyChargePlanProps {
  possessedEnergy: number;
  setEnergyChargePlan: Function;
  energyChargePlan: EnergyChargePlanType | null;
  energyChargePlans: EnergyChargePlanType[] | undefined;
  handlePayment: (buyBoost: number) => void;
}

export const EnergyChargePlan: React.FC<EnergyChargePlanProps> = ({
  handlePayment,
  possessedEnergy,
  energyChargePlan,
  energyChargePlans,
  setEnergyChargePlan,
}) => {
  const [form] = Form.useForm();

  const onFinish = ({ buyBoost }: { buyBoost: number }) => {
    handlePayment(buyBoost);
  };

  return (
    <div className={styles.energy}>
      <div className={styles.energyTitle}>
        <Title level={3}>
          <FormattedMessage id="energyChargePlan.energyCharge" />
        </Title>
      </div>
      <div className={styles.possession}>
        <span className={styles.mg0}>
          <FormattedMessage id="energyChargePlan.energyPossession" />
        </span>
        <div className={styles.possessionValue}>
          <Space>
            <span className={styles.possessionValueSymbol}>
              <EnergySymbol />
            </span>
            <Statistic
              value={possessedEnergy}
              valueStyle={{
                fontFamily: 'Times New Roman',
                fontSize: 32,
                marginRight: 5,
                color: '#142d24',
              }}
            />
            <div className={styles.possessionValueLabel}>
              <FormattedMessage id="energyChargePlan.energy" />
            </div>
          </Space>
        </div>
      </div>
      <Row gutter={[16, 16]} className={styles.energyBoxes}>
        {energyChargePlans?.map((data, i) => {
          const active =
            energyChargePlan?.amount === data.amount ? styles.activeBox : '';
          return (
            <Col
              sm={24}
              md={24}
              lg={12}
              xl={8}
              className={styles.energyBoxes}
              key={data.id}
            >
              <EnergyChargeBox
                data={data}
                active={active}
                setEnergyChargePlan={setEnergyChargePlan}
              />
            </Col>
          );
        })}
      </Row>
      <Col className={styles.buyBtn}>
        <div>
          <Form form={form} initialValues={{ buyBoost: 1 }} onFinish={onFinish}>
            {energyChargePlan && (
              <Input
                type="number"
                name={'buyBoost'}
                min={1}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(
                      /^[a-zA-Z@~`!@#$%^&*()_=+-\\\\';:\"\\/?>.<,-]+$/i
                    ),
                    message: 'Please enter a number',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }

                      if (value > 1000) {
                        return Promise.reject(
                          new Error('should be less than 1000')
                        );
                      }
                      if (value < 0)
                        return Promise.reject(
                          new Error('should be atleast one')
                        );
                      return Promise.resolve();
                    },
                  }),
                ]}
              />
            )}
            <Button
              type="primary"
              htmlType="submit"
              disabled={!energyChargePlan}
            >
              <FormattedMessage id="energyChargePlan.toBuy" />
            </Button>
          </Form>
        </div>
      </Col>
      <div className={styles.question}>
        <div className={styles.questionTitle}>
          <FormattedMessage id="energyChargePlan.whatIsEnergy" />
        </div>
        <div className={styles.questionList}>
          <p>
            * <FormattedMessage id="energyChargePlan.questionListFist" />
          </p>
          <p>
            * <FormattedMessage id="energyChargePlan.questionListSecond" />
          </p>
        </div>
      </div>
    </div>
  );
};
