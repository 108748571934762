import { Modal } from 'antd';
import Cropper from 'react-cropper';
import { useRef, useState } from 'react';

import { Input } from '../Input/Input';
import { ImageBox } from '../ImageBox/ImageBox';
import { getCroppedImage } from './getCroppedImage';
import { ReactNode } from 'react-intl/node_modules/@types/react';

import styles from './index.module.scss';
import 'cropperjs/dist/cropper.css';

export interface ImageUploaderProps {
  name: string;
  label: ReactNode;
  upload: Function;
  imageUrl: string;
  size?: 'regular' | 'large';
  boxShape: 'round' | 'rect';
  required: boolean;
}

export function ImageUploader({
  name,
  label,
  upload,
  imageUrl,
  boxShape,
  size = 'regular',
  required,
}: ImageUploaderProps) {
  const [counter, setCounter] = useState(0);
  const [uploading, setUploading] = useState(false);
  const cropperRef = useRef<HTMLImageElement>(null);
  const [inputImg, setInputImg] = useState<string | null>(null);
  const heightWidth =
    boxShape === 'round'
      ? { width: 168, height: 168 }
      : size === 'large'
      ? { width: 730, height: 365 }
      : { width: 360, height: 180 };

  const [croppedAreaPixels, setCroppedAreaPixels] = useState({
    x: 0,
    y: 0,
    width: heightWidth.width,
    height: heightWidth.height,
  });

  const onChange = (e: any) => {
    setUploading(false);
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        setInputImg(reader?.result as string);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const onCrop = () => {
    const imageElement: any = cropperRef?.current;
    const cropper: any = imageElement?.cropper;
    setCroppedAreaPixels({
      x: cropper.getData().x,
      y: cropper.getData().y,
      width: cropper.getData().width,
      height: cropper.getData().height,
    });
  };
  const handleOk = async () => {
    setUploading(true);
    setInputImg(null);

    const croppedImage = await getCroppedImage(
      inputImg!,
      croppedAreaPixels,
      heightWidth
    );
    upload(croppedImage).then(() => setUploading(false));
  };

  const handleCancel = () => {
    setInputImg(null);
    setCounter(counter + 1);
  };

  return (
    <div className="imageUploaderContainer">
      <label htmlFor={boxShape + name}>
        <ImageBox
          size={size}
          label={label}
          type={boxShape}
          loading={uploading}
          imageUrl={imageUrl}
        />
        <Input
          label=""
          name={name}
          type="hidden"
          placeholder=""
          rules={[{ required: required }]}
        />
      </label>
      {!uploading && (
        <input
          type="file"
          accept="image/*"
          key={counter}
          onChange={onChange}
          id={boxShape + name}
          className={styles.hide}
        />
      )}
      {inputImg && (
        <Modal
          width={800}
          title="Basic Modal"
          onOk={handleOk}
          okText="Upload"
          closable={false}
          visible={!!inputImg}
          onCancel={handleCancel}
          bodyStyle={{ height: 500 }}
        >
          <div className={styles.cropContainer}>
            <Cropper
              className={boxShape === 'round' ? `${styles.roundCropper}` : ''}
              src={inputImg}
              style={{
                height: 500,
                width: '100%',
                backgroundColor: 'transparent',
              }}
              initialAspectRatio={2}
              guides={false}
              background={false}
              responsive={true}
              autoCropArea={1}
              crop={onCrop}
              ref={cropperRef}
            />
          </div>
        </Modal>
      )}
    </div>
  );
}
