import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { Row, Col, Card, Menu, Dropdown, Modal } from 'antd';

import { getUrl } from '../../utils';

import styles from './index.module.scss';

export interface ActivityReportBoxProps {
  activityReport: any;
  token: string | undefined;
  deleteAcitivityReport: (arg: number) => {};
}

export const ActivityReportBox: React.FC<ActivityReportBoxProps> = ({
  token,
  activityReport,
  deleteAcitivityReport,
}: ActivityReportBoxProps) => {
  const history = useHistory();
  const { id, title, reportDate, coverImage, description } = activityReport;

  const handleEditClick = (reportId: any) => {
    history.push(`/edit_activity_report/${reportId}`);
  };

  const config = {
    onOk: () => {
      deleteAcitivityReport(id);
    },
    title: 'Are you sure!',
    content: <div>Are you sure you want to delete?</div>,
  };

  const menu = (
    <Menu>
      <Menu.Item>
        <div
          onClick={() => {
            handleEditClick(id);
          }}
        >
          Edit
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => Modal.confirm(config)}>Delete</div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Col span={16} className={styles.activityReport}>
      <Card className={styles.cardStyle}>
        <Row gutter={[0, 32]} justify="space-between">
          <Col span="22" onClick={() => handleEditClick(id)}>
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>
              {moment(reportDate).format('YYYY/MM/DD hh:mm')}
            </div>
          </Col>

          <Col span="2">
            <div className={styles.moreIcon}>
              <Dropdown overlay={menu} placement="bottomLeft">
                <MoreOutlined />
              </Dropdown>
            </div>
          </Col>
          <Col span="24" onClick={() => handleEditClick(id)}>
            <img
              alt={title}
              width={'100%'}
              height={'auto'}
              src={getUrl(coverImage.id, token)}
            />
          </Col>
          <Col span="24" onClick={() => handleEditClick(id)}>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
