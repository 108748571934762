import { Auth } from 'aws-amplify';
import { notification } from 'antd';
import { useIntl } from 'react-intl';

import { getUsername } from '../../utils';

import { ForgotPasswordRequestOtpComponent } from '../../stories/ForgotPasswordRequestOtpComponent/ForgotPasswordRequestOtpComponent';

const ForgotPasswordRequestOtp = ({
  setPhoneNumber,
}: {
  setPhoneNumber: Function;
}) => {
  const intl = useIntl();

  const onFinish = ({ phoneNumber }: { phoneNumber: string }) => {
    const code = process.env.REACT_APP_PHONE_CODE;
    Auth.forgotPassword(getUsername(phoneNumber))
      .then((user) => {
        notification.success({
          message: `${intl.formatMessage({
            id: 'notification.successFullyOtp',
          })} ${code + phoneNumber}!!`,
          placement: 'topRight',
          duration: 5,
        });
        setPhoneNumber(code + phoneNumber);
      })
      .catch((err) => {
        notification.error({
          message: 'Error',
          description: intl.formatMessage({
            id: 'notification.noUserFound',
          }),
          placement: 'topRight',
        });
      });
  };

  return <ForgotPasswordRequestOtpComponent onFinish={onFinish} />;
};

export default ForgotPasswordRequestOtp;
