import { Row, Col, Form, Tag, Modal } from 'antd';

import { Input } from '../../../../../stories/Input/Input';
import { Button } from '../../../../../stories/Button/Button';

import styles from './index.module.scss';

interface MessageProps {
  selectedRows: any;
  isModalVisible: boolean;
  handleSendMessage: Function;
  handleCancle: Function;
  handleModal: Function;
}

const MessagePopup: React.FC<MessageProps> = ({
  selectedRows,
  isModalVisible,
  handleSendMessage,
  handleCancle,
  handleModal,
}) => {
  return (
    <Modal
      title="Basic Modal"
      visible={isModalVisible}
      onOk={() => handleSendMessage()}
      onCancel={() => handleCancle()}
      footer={[
        <Row gutter={12} justify="end">
          <Col span={6}>
            <Button
              type="primary"
              label="Cancel"
              onClick={() => handleModal()}
            />
          </Col>
          <Col span={6}>
            <Button type="primary" label="Send" onClick={() => handleModal()} />
          </Col>
        </Row>,
      ]}
    >
      <Row>
        <Col span={24}>
          <div className={styles.message}>
            <Form>
              <div className={styles.tags}>
                {selectedRows &&
                  selectedRows.length > 0 &&
                  selectedRows.map((d: any, i: number) => {
                    return (
                      <Tag key={i} closable className={styles.tagContent}>
                        <a href="https://github.com/ant-design/ant-design/issues/1862">
                          {d.username}
                        </a>
                      </Tag>
                    );
                  })}
              </div>
              <div className={styles.form}>
                <Input
                  type="text"
                  name="title"
                  placeholder="Title"
                  rules={[{ type: 'string' }]}
                />
              </div>
              <div className={styles.form}>
                <Input
                  type="text"
                  name="messageContent"
                  placeholder="Message Content"
                  rules={[{ type: 'string' }]}
                />
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default MessagePopup;
