import { Card } from 'antd';
import { ReactNode } from 'react-intl/node_modules/@types/react';

import styles from './index.module.scss';

export interface TabTitle {
  children: ReactNode;
  title: any;
}

export const AccountSettingTab = (props: TabTitle) => {
  return (
    <div className={styles.personalInfo}>
      <Card className={styles.card}>
        <div className={styles.heading}>{props.title}</div>
        <div className={styles.formSection}>{props.children}</div>
      </Card>
    </div>
  );
};
