import {
  ArrowLeftOutlined,
  EllipsisOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { Col, Divider, Popconfirm, Popover, Row, Space } from 'antd';

import { ThanksMessageResponse } from '../../services/apiService';

import styles from './index.module.scss';

interface thankMessageProps {
  index: number;
  onDeleteMessage: () => void;
  onBackArrowClick: () => void;
  setIndex: (index: number) => void;
  messages: ThanksMessageResponse[] | undefined;
}

export const ThanksMessageDetail = ({
  messages,
  onBackArrowClick,
  index,
  onDeleteMessage,
  setIndex,
}: thankMessageProps) => {
  const goToPrev = () => {
    if (index > 0) setIndex(index - 1);
  };
  const goToNext = () => {
    if (messages && index < messages?.length - 1) setIndex(index + 1);
  };

  const content = (
    <div className={styles.deleteContent}>
      <Popconfirm
        okText="Yes"
        cancelText="No"
        title="Are you sure you want to delete?"
        onConfirm={onDeleteMessage}
      >
        <p>Request for deletion</p>
      </Popconfirm>
      <p onClick={onDeleteMessage}>Forced deletion</p>
    </div>
  );

  return (
    <Row>
      <Col className={styles.message}>
        {messages && (
          <div className={styles.detailContent}>
            <div className={styles.head}>
              <ArrowLeftOutlined color={'#7d7d7d'} onClick={onBackArrowClick} />
              <div className={styles.headSecContent}>
                <p>
                  {moment(messages[index].createdAt).format('YYYY/MM/DD hh:mm')}
                </p>
                <Popover content={content} trigger="click">
                  <EllipsisOutlined />
                </Popover>
                <LeftOutlined onClick={goToPrev} />
                <RightOutlined onClick={goToNext} />
              </div>
            </div>
            <Divider className={styles.dividerContent} />
            <div className={styles.bodyFirst}>
              <b>Send settings</b>
              <br />
              <Space>
                {moment(messages[index].scheduledAt).format('YYYY/MM/DD hh:mm')}
                Scheduled to be sent
              </Space>
            </div>
            <Divider className={styles.dividerContent} />

            <div className={styles.bodySecond}>
              <p className={styles.titleContent}>Thank you for your support!</p>
              <p className={styles.descContent}>{messages[index].message}</p>
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};
