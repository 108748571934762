/* eslint-disable @typescript-eslint/no-unused-vars */
import Stories from 'react-insta-stories';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { Fragment, useEffect, useState } from 'react';
import { Col, Modal, Popconfirm, Popover, Row, Spin } from 'antd';

import { RootState } from '../../../../store';
import { Button } from '../../../../stories/Button/Button';
import { getAccessToken } from '../../../../getAccessToken';
import { UserPost } from '../../../../stories/UserPost/UserPost';
import { useGetPostsQuery } from '../../../../services/apiService';
import { UserStory } from '../../../../stories/UserStory/UserStory';
import { DonorFooter } from '../../../../stories/DonorFooter/DonorFooter';
import { AddStoryModal } from '../../../../stories/AddStoryModal/AddStoryModal';

import styles from './index.module.scss';

const SearchPages = () => {
  const [token, setToken] = useState<string | undefined>('');
  const [counter, setCounter] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const me = useSelector((state: RootState) => state.me);
  const history = useHistory();
  const [isAddStoryModal, setIsAddStoryModal] = useState(false);
  const [isVisibleStory, setIsVisibleStory] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const getUrl = (imgId: number | undefined) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };
  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);
  const [paginateInfo] = useState({
    page: 1,
    limit: 10,
    userId: me?.id,
  });
  const [stories, setStories] = useState<
    {
      url: string;
      duration: number;
      header: { heading: string; subheading: string; profileImage: string };
    }[]
  >([
    {
      url: 'https://images.pexels.com/photos/753626/pexels-photo-753626.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
      duration: 3000,
      header: {
        heading: me?.fullName,
        subheading: ' ',
        profileImage: getUrl(me?.profileImage?.id),
      },
    },
  ]);

  const { data: postData, isLoading } = useGetPostsQuery(paginateInfo);

  const onClose = () => {
    setIsVisibleStory(false);
    setIsDelete(false);
    setIsAction(false);
  };

  const onCancel = () => {
    setIsAddStoryModal(false);
  };

  const onPostSubmit = (imageUrl: string) => {
    if (imageUrl) {
      let arr = stories;
      arr.push({
        url: imageUrl,
        duration: 3000,
        header: {
          heading: me?.fullName,
          subheading: ' ',
          profileImage: getUrl(me?.profileImage?.id),
        },
      });
      setStories([...arr]);
      setIsAddStoryModal(false);
      setCounter(counter + 1);
    }
  };

  const onAddStory = () => {
    if (stories.length > 1) {
      setCounter(counter + 1);
      setIsAdd(true);
      setIsVisibleStory(true);
    } else {
      setIsAddStoryModal(true);
    }
  };

  const onDelete = () => {
    let arr = stories;
    arr.splice(currentIndex, 1);
    setIsDelete(false);
    setIsAction(false);
    if (arr.length === 0) {
      setIsVisibleStory(false);
    }
    setStories([...arr]);
  };

  const content = (
    <div className={styles.actionContent}>
      <Popconfirm
        okText="Yes"
        cancelText="No"
        title="Are you sure you want to delete?"
        onCancel={() => {
          setIsDelete(false);
          setIsAction(false);
        }}
        onConfirm={onDelete}
        visible={isDelete}
      >
        <p
          onClick={() => {
            setIsDelete(true);
          }}
        >
          Delete
        </p>
      </Popconfirm>
    </div>
  );
  const onEndStory = () => {
    setIsDelete(false);
    setIsVisibleStory(false);
    setIsAdd(false);
    setIsAction(false);
  };
  const onUserStoryClick = () => {
    if (stories?.length) {
      setCounter(counter + 1);
      setIsAdd(false);
      setIsVisibleStory(true);
    }
  };
  return (
    <Fragment>
      <Row justify="center" className={styles.userStoryContent}>
        <Col xxl={15} xl={20} lg={15} md={20} sm={23} xs={23}>
          <UserStory
            type={stories.length > 1 ? 'active' : 'user'}
            user={{
              id: Number(me.id),
              fullName: me.fullName,
              profileImage: { id: Number(me?.profileImage?.id) },
            }}
            onUserClick={onAddStory}
          />
          {stories?.length > 0 ? (
            <>
              <UserStory
                type="active"
                user={{
                  id: Number(me.id),
                  fullName: me.fullName,
                  profileImage: { id: Number(me?.profileImage?.id) },
                }}
                onUserClick={onUserStoryClick}
              />
              <UserStory
                user={{
                  id: Number(me.id),
                  fullName: me.fullName,
                  profileImage: { id: Number(me?.profileImage?.id) },
                }}
                onUserClick={onUserStoryClick}
              />
            </>
          ) : null}
        </Col>
      </Row>
      <Row justify="center">
        {isLoading ? (
          <div className={styles.blankContent}>
            <Spin size="large" />
          </div>
        ) : (
          postData?.data?.map((item, index) => (
            <Col xxl={15} xl={20} lg={15} md={20} sm={23} xs={23}>
              <UserPost
                item={item}
                key={index}
                user={{
                  id: Number(me.id),
                  fullName: me.fullName,
                  profileImage: { id: Number(me?.profileImage?.id) },
                }}
                onCardClick={() => {
                  history.push(`/posts/${item.id}`);
                }}
                onCommentClick={() => {
                  history.push(`/posts/${item.id}`, { focus: true });
                }}
              />
            </Col>
          ))
        )}
      </Row>
      <Modal
        centered
        footer={null}
        visible={isVisibleStory}
        onCancel={onClose}
        title={'User Story'}
        afterClose={onClose}
      >
        <div className={styles.modalContent}>
          {isAdd ? (
            <div className={styles.btnContent}>
              <Button
                type="primary"
                label="Add Story"
                onClick={() => {
                  setIsVisibleStory(false);
                  setIsAddStoryModal(true);
                }}
              />
            </div>
          ) : null}
          <Stories
            stories={stories}
            width={'100%'}
            height={768}
            onAllStoriesEnd={onEndStory}
            onStoryStart={(s: number) => {
              setCurrentIndex(s);
            }}
            key={counter}
            isPaused={isAction}
          />
          <Popover
            className={
              isAdd ? `${styles.moreContent1}` : `${styles.moreContent}`
            }
            placement={'bottomRight'}
            content={content}
            trigger="click"
            visible={isAction}
          >
            <MoreOutlined onClick={() => setIsAction(true)} />
          </Popover>
        </div>
      </Modal>
      <DonorFooter />
      <AddStoryModal
        open={isAddStoryModal}
        handleCancel={onCancel}
        handleSubmit={onPostSubmit}
      />
    </Fragment>
  );
};

export default SearchPages;
