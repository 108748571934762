import { Card, Table } from 'antd';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';

const Law = () => {
  const companyDetail = [
    {
      title: <FormattedMessage id="companyProfile.service" />,
      value: 'Astra',
    },
    {
      title: <FormattedMessage id="companyProfile.BusinessPerson" />,
      value: <FormattedMessage id="companyProfile.companyName" />,
    },
    {
      title: <FormattedMessage id="companyProfile.Representative" />,
      value: <FormattedMessage id="companyProfile.RepresentativeValue" />,
    },
    {
      title: <FormattedMessage id="companyProfile.location" />,
      value: <FormattedMessage id="companyProfile.locationDetail" />,
    },
    {
      title: <FormattedMessage id="companyProfile.telephoneNumber" />,
      value: '03-1234-5678',
    },
    {
      title: <FormattedMessage id="companyProfile.inquiry" />,
      value: '-',
    },
    {
      title: <FormattedMessage id="companyProfile.SpecialConditions" />,
      value: '-------------------',
    },
  ];

  const columns = [
    {
      title: '',
      dataIndex: 'title',
      key: 'title',
      render: (text: number, record: any, index: number) => (
        <div key={index}>
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'value',
      key: 'value',
      render: (text: string, record: any, index: number) => (
        <div className={styles.tableValue}>
          <p>{text}</p>
        </div>
      ),
    },
  ];

  return (
    <Card className={styles.cardContent}>
      <Table
        dataSource={companyDetail}
        columns={columns}
        pagination={false}
        className={styles.tableMainContent}
      />
    </Card>
  );
};

export default Law;
