/* eslint-disable react-hooks/exhaustive-deps */
import {
  Row,
  Col,
  Form,
  Spin,
  Card,
  Button,
  Typography,
  notification,
} from 'antd';
import { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  useCreateImageMutation,
  useUpdateImageMutation,
} from '../../../services/apiService';
import { getUrl } from '../../../utils';
import { Input } from '../../../stories/Input/Input';
import { FacilityType } from '../../../services/apiService';
import { ImageUploader } from '../../../stories/ImageUploader/ImageUploader';

const { Title } = Typography;

export interface FacilityFormProps {
  token: string | undefined;
  isLoading: boolean;
  facility?: FacilityType;
  setPhoneNumber?: Function;
  actionType: 'create' | 'update';
  onFinish: (facilityForm: FacilityType) => Promise<void>;
}

export const FacilityForm = ({
  token,
  onFinish,
  facility,
  isLoading,
  actionType,
  setPhoneNumber,
}: FacilityFormProps) => {
  const [form] = Form.useForm();
  const [imageIds, setImageIds] = useState<{ [key: string]: number | null }>(
    {}
  );

  const [postImage, { isSuccess: isPostSuccess }] = useCreateImageMutation();
  const [patchImage, { isSuccess: isPatchSuccess }] = useUpdateImageMutation();
  const intl = useIntl();
  useEffect(() => {
    if (isPatchSuccess || isPostSuccess)
      notification.success({
        message: intl.formatMessage({ id: 'notification.photoUploadSuccess' }),
        placement: 'topRight',
        duration: 5,
      });
  }, [isPatchSuccess, isPostSuccess]);

  useEffect(() => {
    if (facility) {
      setImageIds({
        coverImageId: facility.coverImageId,
        storyImageId: facility.storyImageId,
        missionImageId: facility.missionImageId,
        representativeImageId: facility.representativeImageId,
      });
    }
  }, [facility]);

  const uploadImage = async (
    imageUri: string,
    subType: string,
    imageId: number | null
  ) => {
    const data = new FormData();
    data.append('file', imageUri);

    if (actionType === 'create') {
      data.append('subType', subType);
      data.append('imageableType', 'Facility');

      await postImage(data).then((d: any) => {
        const responseImageId = d.data;
        const oldImageId = { ...imageIds };

        const key = `${subType}Id`;
        oldImageId[key] = responseImageId!;
        setImageIds(oldImageId);
        form.setFieldsValue({ [key]: responseImageId });
      });
    } else {
      if (imageId) await patchImage({ id: imageId, imageUri: data });
    }
  };

  if (!token) {
    return (
      <Row justify="center">
        <Spin />
      </Row>
    );
  }

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        requiredMark={false}
        initialValues={facility}
        wrapperCol={{ span: 24 }}
      >
        <Row>
          <Col span={24}>
            <Title level={4}>
              <FormattedMessage id="facilityForm.facilityInformation" />
            </Title>
            <Input
              name="name"
              label={<FormattedMessage id="facilityForm.name" />}
              type="string"
              placeholder=""
              rules={[{ required: true }]}
            />
            <Input
              type="number"
              placeholder=""
              name="children"
              label={<FormattedMessage id="facilityForm.children" />}
              rules={[{ required: true }]}
            />
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Input
                  name="area"
                  type="string"
                  label={<FormattedMessage id="facilityForm.area" />}
                  placeholder=""
                  rules={[{ required: true }]}
                />
              </Col>
              <Col className="gutter-row" span={18}>
                <Input
                  type="string"
                  placeholder=""
                  name="streetAddress"
                  label={<FormattedMessage id="facilityForm.address" />}
                  rules={[{ required: true }]}
                />
              </Col>
            </Row>
            <ImageUploader
              size="large"
              boxShape="rect"
              name="coverImageId"
              label={<FormattedMessage id="facilityForm.uploadCoverPhoto" />}
              imageUrl={getUrl(imageIds.coverImageId, token)}
              upload={(imgUri: string) =>
                uploadImage(imgUri, 'coverImage', imageIds.coverImageId)
              }
              required
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Title level={4}>
              <FormattedMessage id="facilityForm.representativeInformation" />
            </Title>
            <Input
              type="string"
              placeholder=""
              name="representativeName"
              label={<FormattedMessage id="facilityForm.representativeName" />}
              rules={[{ required: true }]}
            />
            <Input
              type="string"
              placeholder=""
              name="representativeProfile"
              label={
                <FormattedMessage id="facilityForm.representativeProfile" />
              }
              rules={[{ required: true }]}
            />
            <ImageUploader
              name="representativeImageId"
              imageUrl={getUrl(imageIds.representativeImageId, token)}
              boxShape="round"
              label={<FormattedMessage id="facilityForm.uploadAccountPhoto" />}
              upload={(imgUri: string) =>
                uploadImage(
                  imgUri,
                  'representativeImage',
                  imageIds.representativeImageId
                )
              }
              required={false}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col span={24}>
            <Title level={4}>
              <FormattedMessage id="facilityForm.facilityProfile" />
            </Title>
            <ImageUploader
              boxShape="rect"
              name="storyImageId"
              label={<FormattedMessage id="facilityForm.uploadCoverPhoto" />}
              imageUrl={getUrl(imageIds.storyImageId, token)}
              upload={(imgUri: string) =>
                uploadImage(imgUri, 'storyImage', imageIds.storyImageId)
              }
              required={false}
            />
            <Input
              placeholder=""
              name="mission"
              type="textarea"
              label={<FormattedMessage id="facilityForm.mission" />}
              rules={[{ required: true }]}
            />
            <ImageUploader
              boxShape="rect"
              name="missionImageId"
              label={<FormattedMessage id="facilityForm.uploadCoverPhoto" />}
              imageUrl={getUrl(imageIds!.missionImageId, token)}
              upload={(imgUri: string) =>
                uploadImage(imgUri, 'missionImage', imageIds.missionImageId)
              }
              required={false}
            />
            <Input
              name="story"
              label={<FormattedMessage id="facilityForm.story" />}
              type="textarea"
              placeholder=""
              rules={[{ required: true }]}
            />
          </Col>
        </Row>
        <Row align="middle" justify="end">
          <Col span="5">
            <Button
              block
              size="large"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              {actionType === 'create' ? (
                <FormattedMessage id="facilityForm.createFacility" />
              ) : (
                <FormattedMessage id="facilityForm.updateFacility" />
              )}
            </Button>
          </Col>
          {actionType === 'create' && (
            <>
              <Col span="1"></Col>
              <Col span="5">
                <Button
                  type="default"
                  size="large"
                  block
                  onClick={() => setPhoneNumber!('')}
                >
                  <FormattedMessage id="facilityForm.Cancel" />
                </Button>
              </Col>
            </>
          )}
        </Row>
      </Form>
    </Card>
  );
};
