/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col, Form, Modal, Button, notification } from 'antd';

import {
  useCreateImageMutation,
  useUpdateImageMutation,
  useUpdateProfileMutation,
} from '../../../services/apiService';
import { RootState } from '../../../store';
import { Input } from '../../../stories/Input/Input';
import { getAccessToken } from '../../../getAccessToken';
import { ImageUploader } from '../../../stories/ImageUploader/ImageUploader';

import styles from './index.module.scss';
export interface ProfileSettingProps {
  open: boolean;
  handleCancel: () => void;
}

export function ProfileSettingModal({
  open,
  handleCancel,
}: ProfileSettingProps) {
  const [form] = Form.useForm();
  const intl = useIntl();

  const me = useSelector((state: RootState) => state.me);
  const [profileImageId, setProfileImageId] = useState<number | undefined>(
    me?.profileImage?.id
  );
  const [token, setToken] = useState<string | undefined>('');
  const [counter, setCounter] = useState<number>(0);

  const [postImage] = useCreateImageMutation();
  const [patchImage] = useUpdateImageMutation();
  const [updateProfile, { data, isLoading, isSuccess, error }] =
    useUpdateProfileMutation();

  const onClose = () => {
    form.resetFields();
    handleCancel();
  };

  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  useEffect(() => {
    if (isSuccess && data) onClose();
  }, [data, isSuccess]);

  useEffect(() => {
    if (error) {
      const { message } = (error as any).data;
      const fields = Object.keys(message).map((fieldName) => {
        return { name: fieldName, errors: message[fieldName] };
      });
      form.setFields(fields);
    }
  }, [form, error]);
  const uploadImage = async (
    imageUri: string,
    subType: string,
    imageId: number | undefined
  ) => {
    const data = new FormData();
    data.append('file', imageUri);
    data.append('subType', subType);
    data.append('imageableType', 'User');
    data.append('imageableId', JSON.stringify(me?.id));

    if (imageId) await patchImage({ id: imageId!, imageUri: data });
    else
      postImage(data).then((d: any) => {
        const responseImageId = d.data;
        setProfileImageId(responseImageId);
        form.setFieldsValue({ profileImageId: responseImageId });
      });

    notification.success({
      message: intl.formatMessage({ id: 'notification.photoUploadSuccess' }),
      placement: 'topRight',
      duration: 5,
    });
  };

  const onFinish = async ({
    selfIntroduction,
    area,
    link,
    title,
  }: {
    dob: string;
    selfIntroduction: string;
    area: string;
    link: string;
    title: string;
  }) => {
    await updateProfile({
      id: me.id,
      intro: selfIntroduction,
      area: area,
      link: link,
      title: title,
      profileImage: { id: profileImageId },
    });
    notification.success({
      message: intl.formatMessage({ id: 'notification.profileUpdated' }),
      placement: 'topRight',
      duration: 5,
    });
  };

  return (
    <Modal
      centered
      footer={null}
      visible={open}
      onCancel={onClose}
      title={'Profile Update'}
    >
      <Row align="middle" justify="space-between">
        <ImageUploader
          key={counter}
          boxShape="round"
          name="profileImageId"
          label={<FormattedMessage id="facilityForm.uploadCoverPhoto" />}
          imageUrl={profileImageId ? getUrl(profileImageId) : ''}
          upload={(imgUri: string) =>
            uploadImage(imgUri, 'profileImage', profileImageId)
          }
          required
        />
      </Row>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          username: me?.fullName,
          area: me?.area,
          link: me?.link,
          title: me?.title,
          selfIntroduction: me?.intro,
        }}
        className={styles.form}
        wrapperCol={{ span: 24 }}
      >
        <Row>
          <Col span={24}>
            <Input
              type="text"
              name="username"
              label={'Username'}
              rules={[{ required: true }]}
            />
            <Input
              rows={5}
              label=""
              showCount
              name="selfIntroduction"
              type="textarea"
              placeholder="Self-introduction"
              maxLength={150}
              rules={[{ required: true }]}
            />
            <Input
              type="text"
              name="area"
              label={'Area'}
              rules={[{ required: true }]}
            />
            <Input
              type="text"
              name="link"
              label={'Link'}
              rules={[{ required: true }]}
            />
            <Input
              type="text"
              name="title"
              label={'Title'}
              rules={[{ required: true }]}
            />
          </Col>
        </Row>
        <Row justify="end" gutter={16}>
          <Col>
            <Button type="ghost" onClick={onClose}>
              <FormattedMessage id="supportCommentModel.cancel" />
            </Button>
          </Col>
          <Col>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              KEEP
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
