import { isDeviceMobile } from '../../utils';
import { PlainBox } from '../PlainBox/PlainBox';
import { CardBox } from '../../stories/CardBox/CardBox';
import { ForgotPasswordOtpVerifyForm } from './ForgotPasswordOtpVerifyForm';

export interface ForgotPasswordOtpVerifyComponentProps {
  loading: boolean;
  phoneNumber: string;
  resendCode: () => void;
  onFinish: (values: any) => void;
}

export const ForgotPasswordOtpVerifyComponent: React.FC<ForgotPasswordOtpVerifyComponentProps> =
  ({ loading, onFinish, resendCode, phoneNumber }) => {
    if (isDeviceMobile()) {
      return (
        <PlainBox title="New Password">
          <ForgotPasswordOtpVerifyForm
            loading={loading}
            onFinish={onFinish}
            resendCode={resendCode}
            phoneNumber={phoneNumber}
          />
        </PlainBox>
      );
    }

    return (
      <CardBox title="New Password">
        <ForgotPasswordOtpVerifyForm
          loading={loading}
          onFinish={onFinish}
          resendCode={resendCode}
          phoneNumber={phoneNumber}
        />
      </CardBox>
    );
  };
