export function Search() {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4972 7.91671C14.4972 11.5526 11.5498 14.5 7.9139 14.5C4.27802 14.5 1.33057 11.5526 1.33057 7.91671C1.33057 4.28083 4.27802 1.33337 7.9139 1.33337C11.5498 1.33337 14.4972 4.28083 14.4972 7.91671ZM13.2761 13.279C11.9038 14.6513 10.008 15.5 7.9139 15.5C3.72574 15.5 0.330566 12.1049 0.330566 7.91671C0.330566 3.72855 3.72574 0.333374 7.9139 0.333374C12.1021 0.333374 15.4972 3.72855 15.4972 7.91671C15.4972 9.62008 14.9356 11.1923 13.9875 12.4582L18.3755 16.8462L17.6094 17.6123L13.2761 13.279Z"
        fill="#312B2B"
      />
    </svg>
  );
}
