import { FormattedMessage } from 'react-intl';
import { CheckCircleOutlined } from '@ant-design/icons';

import { SowSeeds } from '../Icons/SowSeeds/SowSeeds';
import { SupportType } from '../../services/apiService';
import { GiveWater } from '../Icons/GiveWater/GiveWater';
import { PourLight } from '../Icons/PourLight/PourLight';

import styles from './index.module.scss';

export interface SupportAmountProps {
  energy: number;
  active: boolean;
  onClick: () => void;
  supportType: SupportType;
  supportTypeText: (supportType: SupportType) => JSX.Element;
}

export const SupportAmount = ({
  energy,
  active,
  onClick,
  supportType,
  supportTypeText,
}: SupportAmountProps) => {
  const PaymentImage = () => {
    switch (supportType) {
      case SupportType.sowSeeds:
        return <SowSeeds height={48} width={48} fill="#BDA35E" />;
      case SupportType.giveWater:
        return <GiveWater height={48} width={48} fill="#BDA35E" />;
      default:
        return <PourLight height={48} width={48} fill="#BDA35E" />;
    }
  };
  return (
    <div
      className={`${styles.supportAmount} ${
        active ? styles.supportAmountActive : ''
      }`}
      onClick={onClick}
    >
      <PaymentImage />
      <div className={styles.supportAmountEnergy}>
        {supportTypeText(supportType)}
      </div>
      <div className={styles.supportAmountEnergyText}>
        {energy} <FormattedMessage id="supportAmount.energy" />
      </div>
      <CheckCircleOutlined className={styles.checkCircle} />
    </div>
  );
};
