import { FormattedMessage } from 'react-intl';
import { Row, Col, Breadcrumb, Tabs } from 'antd';

import Post from './Post/Post';
import Profile from './Profile/Profile';
import UserInformation from './UserInformation/UserInformation';
import MemberDetailHeader from './Common/MemberDetailHeader/MemberDetailHeader';

import styles from './index.module.scss';

const { TabPane } = Tabs;

const MemberDetails = (props: any) => {
  const memberDetailMenu = [
    { id: 1, title: 'User Information' },
    { id: 2, title: 'Profile' },
    { id: 3, title: 'Post' },
    { id: 4, title: 'DM' },
  ];

  const { location } = props;

  return (
    <Row gutter={22} justify="center">
      <Col span={18}>
        <div>
          {location?.state?.params.map((data: any, index: number) => {
            return (
              <div key={index} className="container">
                <div className={styles.breadcrumb}>
                  <Row>
                    <Breadcrumb>
                      <Breadcrumb.Item>
                        <FormattedMessage id="memberDetail.member" />
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>{data.username}</Breadcrumb.Item>
                    </Breadcrumb>
                  </Row>
                </div>
                <MemberDetailHeader userDetail={data} />

                <Tabs defaultActiveKey={data.id}>
                  {memberDetailMenu.map((m, i) => {
                    return (
                      <TabPane tab={m.title} key={i}>
                        <Row justify="center" gutter={24}>
                          <Col className={styles.memberTab} span={16}>
                            {i === 0 && <UserInformation />}
                            {i === 1 && <Profile />}
                            {i === 2 && <Post />}
                            {i === 3 && <UserInformation />}
                          </Col>
                        </Row>
                      </TabPane>
                    );
                  })}
                </Tabs>
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default MemberDetails;
