export function TagIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.64866 8.88768L8.44866 13.6877C8.64066 13.8797 8.90733 13.9997 9.20065 13.9997C9.49398 13.9997 9.76333 13.8797 9.95533 13.6877L13.6887 9.95435C13.8807 9.75968 14.0007 9.49303 14.0007 9.19968C14.0007 8.90368 13.8807 8.637 13.686 8.44233L8.88866 3.64501C8.69398 3.45301 8.42731 3.33301 8.13398 3.33301H4.40066C3.81131 3.33301 3.33398 3.81033 3.33398 4.39968V8.133C3.33398 8.429 3.45398 8.69568 3.64866 8.88768ZM8.41925 4.11841C8.34193 4.04276 8.24116 3.99967 8.13398 3.99967H4.40066C4.1795 3.99967 4.00065 4.17852 4.00065 4.39968V8.133C4.00065 8.24465 4.04485 8.34207 4.11679 8.41302L4.12007 8.41626L4.12006 8.41627L8.92006 13.2163C8.9928 13.289 9.09213 13.333 9.20065 13.333C9.31095 13.333 9.41248 13.2877 9.48392 13.2163L13.2152 9.48496C13.2909 9.40763 13.334 9.30688 13.334 9.19968C13.334 9.0888 13.2902 8.98939 13.2146 8.91373L8.41925 4.11841ZM6.53399 7.99968C5.72313 7.99968 5.06733 7.34388 5.06733 6.53302C5.06733 5.72215 5.72313 5.06635 6.53399 5.06635C7.34486 5.06635 8.00066 5.72215 8.00066 6.53302C8.00066 7.34389 7.34482 7.99968 6.53399 7.99968ZM5.73399 6.53302C5.73399 6.97569 6.09132 7.33301 6.53399 7.33301C6.97664 7.33301 7.33399 6.97569 7.33399 6.53302C7.33399 6.09034 6.97667 5.73302 6.53399 5.73302C6.09132 5.73302 5.73399 6.09034 5.73399 6.53302Z"
        fill="#312B2B"
      />
    </svg>
  );
}
