import { useState } from 'react';

import ForgotPasswordOtpVerify from './ForgotPasswordOtpVerify';
import ForgotPasswordRequestOtp from './ForgotPasswordRequestOtp';

const ForgotPassword = () => {
  const [phoneNumber, setPhoneNumber] = useState<null | string>(null);

  if (phoneNumber) {
    return <ForgotPasswordOtpVerify phoneNumber={phoneNumber!} />;
  }

  return <ForgotPasswordRequestOtp setPhoneNumber={setPhoneNumber} />;
};

export default ForgotPassword;
