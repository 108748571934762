import Astra1 from './assets/images/astra1.png';

import Blog1 from './assets/images/report/1.png';
import Blog2 from './assets/images/report/2.png';
import Blog3 from './assets/images/report/3.png';
import Blog4 from './assets/images/report/4.png';
import Blog5 from './assets/images/report/5.png';

import Post1 from './assets/images/post/post1.png';
import Post2 from './assets/images/post/post2.png';
import Post3 from './assets/images/post/post3.png';
import Post4 from './assets/images/post/post4.png';

import { routes } from './constant';
import F1 from './assets/images/f1.png';
import { Mail } from './stories/Icons/Mail/Mail';
import Member from './stories/Icons/Member/Member';
import Facility1 from './assets/images/astra1.png';
import Inquiry from './stories/Icons/Inquiry/Inquiry';
import { Person } from './stories/Icons/Person/Person';
import MemberUserImg1 from './assets/images/usrImg.png';
import Facility from './stories/Icons/Facility/Facility';
import InquiryUserImg1 from './assets/images/profile.png';
import { MenuBook } from './stories/Icons/MenuBook/MenuBook';
import { MemberType } from './features/member/memberListSlice';
import { Equalizer } from './stories/Icons/Equalizer/Equalizer';
import { InquiryType } from './features/Inquiry/InquiryListSlice';

export const astraList = [
  {
    id: 1,
    image: Facility1,
    city: '品川景徳学園 ',
    children: 45,
    supporters: 100,
    tag: '東京都',
    tragetAmount: '1,000,000',
    reachedAmount: '500,000 ',
    capital: '東京都',
  },
  {
    id: 2,
    image: Astra1,
    city: '品川景徳学園 ',
    children: 45,
    supporters: 100,
    tag: '東京都',
    tragetAmount: '1,000,000',
    reachedAmount: '500,000 ',
    capital: '東京都',
  },
  {
    id: 3,
    image: Astra1,
    city: '品川景徳学園 ',
    children: 45,
    supporters: 100,
    tag: '東京都',
    tragetAmount: '1,000,000',
    reachedAmount: '500,000 ',
    capital: '東京都',
  },
];

export const facilityList = [
  {
    id: 1,
    image: Facility1,
    tragetAmount: '1,000,000',
    reachedAmount: '500,000 ',
    supporters: 100,
    city: '品川景徳学園',
    children: 45,
    capital: '東京都',
  },
  {
    id: 2,
    image: Facility1,
    tragetAmount: '1,000,000',
    reachedAmount: '500,000 ',
    supporters: 100,
    city: '品川景徳学園',
    children: 45,
    capital: '東京都',
  },
  {
    id: 3,
    image: Facility1,
    tragetAmount: '1,000,000',
    reachedAmount: '500,000 ',
    supporters: 100,
    city: '品川景徳学園',
    children: 45,
    capital: '東京都',
  },
  {
    id: 4,
    image: Facility1,
    tragetAmount: '1,000,000',
    reachedAmount: '500,000 ',
    supporters: 100,
    city: '品川景徳学園',
    children: 45,
    capital: '東京都',
  },
  {
    id: 5,
    image: Facility1,
    tragetAmount: '1,000,000',
    reachedAmount: '500,000 ',
    supporters: 100,
    city: '品川景徳学園',
    children: 45,
    capital: '東京都',
  },
];
export const adminPanel = [
  {
    id: 1,
    image: Facility,
    title: 'Facility',
    path: routes.FacilityListUrl,
  },
  {
    id: 2,
    image: Member,
    title: 'Members',
    path: routes.MembersListUrl,
  },
  {
    id: 3,
    image: Inquiry,
    title: 'Inquiry',
    path: routes.InquiryListUrl,
  },
];

export const facilityDetailMenu = [
  { id: 1, image: Equalizer, title: 'Support status' },
  { id: 2, image: Person, title: 'Profile' },
  { id: 3, image: MenuBook, title: 'Activity report' },
  {
    id: 4,
    image: Mail,
    title: 'Thanks message',
  },
];
export const memberDetailMenu = [
  { id: 1, title: 'User Information' },
  { id: 2, title: 'Profile' },
  { id: 3, title: 'Post' },
  { id: 4, title: 'DM' },
];

export const thanksMessageMenu = [
  { id: 1, title: 'Scheduled to be sent' },
  { id: 2, title: 'sent' },
];
export const facilityListData = [
  {
    key: 0,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 1,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 2,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 3,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 4,
    title: '〇〇〇〇学園',
    image: F1,
    childrenData: 45,
    supporters: 100,
    supportAmount: '1,525,200',
  },
  {
    key: 5,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 6,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 7,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 8,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 9,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 10,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 11,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 12,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 13,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 14,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 15,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 16,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 17,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 18,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 19,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
  {
    key: 20,
    children: 1,
    adminId: 1,
    area: '1',
    coverImage: {
      id: 1,
    },
    id: 1,
    mission: 'Mission',
    missionImage: {
      id: 4,
    },
    name: 'Facility Information 1',
    representativeImage: {
      id: 2,
    },
    representativeName: 'Representative Name',
    representativeProfile: 'Representative Profile',
    story: 'Story change yee',
    storyImage: { id: 3 },
    streetAddress: 'Street',
  },
];

export const userData = [
  {
    id: 1,
    image: Facility1,
    username: '松原 浩太郎',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 2,
    image: Facility1,
    username: '梅津 由宇',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 3,
    image: Facility1,
    username: '横井 広司',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 4,
    image: Facility1,
    username: '笠井 メイサ',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 5,
    image: Facility1,
    username: '北田 由宇',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 6,
    image: Facility1,
    username: '黒川 進',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 7,
    image: Facility1,
    username: '宇都宮 兼',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 8,
    image: Facility1,
    username: '根岸 直人',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 9,
    image: Facility1,
    username: '一木 光良',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 10,
    image: Facility1,
    username: '吉原 輝信',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 11,
    image: Facility1,
    username: '松原 浩太郎',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 12,
    image: Facility1,
    username: '梅津 由宇',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 13,
    image: Facility1,
    username: '横井 広司',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 14,
    image: Facility1,
    username: '笠井 メイサ',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 15,
    image: Facility1,
    username: '北田 由宇',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 16,
    image: Facility1,
    username: '黒川 進',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 17,
    image: Facility1,
    username: '宇都宮 兼',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 18,
    image: Facility1,
    username: '根岸 直人',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 19,
    image: Facility1,
    username: '一木 光良',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 20,
    image: Facility1,
    username: '吉原 輝信',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 21,
    image: Facility1,
    username: '松原 浩太郎',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 22,
    image: Facility1,
    username: '梅津 由宇',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 23,
    image: Facility1,
    username: '横井 広司',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 24,
    image: Facility1,
    username: '笠井 メイサ',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 25,
    image: Facility1,
    username: '北田 由宇',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 26,
    image: Facility1,
    username: '黒川 進',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 27,
    image: Facility1,
    username: '宇都宮 兼',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 28,
    image: Facility1,
    username: '根岸 直人',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 29,
    image: Facility1,
    username: '一木 光良',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
  {
    id: 30,
    image: Facility1,
    username: '吉原 輝信',
    userId: 'Tanaka_Taro',
    supportAmount: 100,
    supportDateTime: '2021-06-17T16:12:35.329Z',
  },
];

export const activityReport = [
  {
    id: 1,
    image: Blog1,
    title: 'I bought stationery ✏✏✏',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 2,
    image: Blog2,
    title: 'Yuuki-kun has entered the park 😄😄',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 3,
    image: Blog3,
    title: 'We all went on a picnic on Childrens Day 🍔🍙',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 4,
    image: Blog4,
    title: 'A new teacher has arrived 👩‍🏫',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 5,
    image: Blog5,
    title: 'I went to the aquarium 🐠',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 6,
    image: Blog1,
    title: 'I bought stationery ✏✏✏',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 7,
    image: Blog1,
    title: 'Yuuki-kun has entered the park 😄😄',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 8,
    image: Blog1,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 9,
    image: Blog1,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 10,
    image: Blog1,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 11,
    image: Blog2,
    title: 'I bought stationery ✏✏✏',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 12,
    image: Blog2,
    title: 'Yuuki-kun has entered the park 😄😄',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 13,
    image: Blog2,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 14,
    image: Blog2,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 15,
    image: Blog2,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 16,
    image: Blog3,
    title: 'I bought stationery ✏✏✏',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 17,
    image: Blog3,
    title: 'Yuuki-kun has entered the park 😄😄',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 18,
    image: Blog3,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 19,
    image: Blog3,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 20,
    image: Blog3,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 21,
    image: Blog4,
    title: 'I bought stationery ✏✏✏',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 22,
    image: Blog4,
    title: 'Yuuki-kun has entered the park 😄😄',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 23,
    image: Blog4,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 24,
    image: Blog4,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 25,
    image: Blog4,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 26,
    image: Blog5,
    title: 'I bought stationery ✏✏✏',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 27,
    image: Blog5,
    title: 'Yuuki-kun has entered the park 😄😄',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 28,
    image: Blog5,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 29,
    image: Blog5,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
  {
    id: 30,
    image: Blog5,
    title: 'I bought stationery',
    reportDate: '2021-06-17T16:12:35.329Z',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day. At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. It worked a lot of fun every day.',
  },
];

export const facilityThanksMessage = [
  {
    id: 1,
    title: 'Thank you for your support!',
    creationDate: '2021-08-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 2,
    title: 'Please cooperate for the future of children.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 3,
    title: 'Your support will change the future of children.',
    creationDate: '2021-10-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 4,
    title: 'For a gentle future.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 5,
    title: 'Thank you for your support!',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 6,
    title: 'Thank you for your support!',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 7,
    title: 'Please cooperate for the future of children.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 8,
    title: 'Your support will change the future of children.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 9,
    title: 'For a gentle future.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 10,
    title: 'Thank you for your support!',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 11,
    title: 'Thank you for your support!',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 12,
    title: 'Please cooperate for the future of children.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 13,
    title: 'Your support will change the future of children.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 14,
    title: 'For a gentle future.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 15,
    title: 'Thank you for your support!',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 1,
  },
  {
    id: 16,
    title: 'Thank you for your support!',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 17,
    title: 'Please cooperate for the future of children.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 1,
  },
  {
    id: 18,
    title: 'Thank you for your support!',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 19,
    title: 'For a gentle future.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 0,
  },
  {
    id: 20,
    title: 'Your support will change the future of children.',
    creationDate: '2021-06-17T16:12:35.329Z',
    scheduleDate: '2021-06-23T06:14:47.968Z',
    status: 1,
  },
];

export const levels = [1, 2, 3, 4, 5];
export const ranks = ['A', 'B', 'C', 'D', 'E'];
export const badges = ['Regular', 'Gold', 'Platinum', 'Black'];

export const membersListArr: MemberType[] = [
  {
    key: 0,
    image: MemberUserImg1,
    username: 'Hiroshi Yokoi',
    userId: '@Tanaka_Taro',
    level: 2,
    rank: 'A',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 1,
    image: MemberUserImg1,
    username: 'Hiroshi Yokoi',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'D',
    badge: 'Gold',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 2,
    image: MemberUserImg1,
    username: 'Hiroshi Yokoi',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'A',
    badge: 'Gold',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 3,
    image: MemberUserImg1,
    username: 'Masakazu Tobita',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'A',
    badge: 'Black',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 4,
    image: MemberUserImg1,
    username: 'Futian Shuli',
    userId: '@Tanaka_Taro',
    level: 4,
    rank: 'E',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 5,
    image: MemberUserImg1,
    username: 'Keita Hayakawa',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'B',
    badge: 'Platinum',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 6,
    image: MemberUserImg1,
    username: 'Utsunomiya',
    userId: '@Tanaka_Taro',
    level: 1,
    rank: 'A',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 7,
    image: MemberUserImg1,
    username: 'pacific saury Akashiya',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'D',
    badge: 'Gold',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 8,
    image: MemberUserImg1,
    username: 'Harumi Shida',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'A',
    badge: 'Platinum',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 9,
    image: MemberUserImg1,
    username: 'Masakazu Tobita',
    userId: '@Tanaka_Taro',
    level: 2,
    rank: 'D',
    badge: 'Black',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 10,
    image: MemberUserImg1,
    username: 'Kotaro Matsubara',
    userId: '@Tanaka_Taro',
    level: 1,
    rank: 'A',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 11,
    image: MemberUserImg1,
    username: 'Hiroshi Yokoi',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'A',
    badge: 'Platinum',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 12,
    image: MemberUserImg1,
    username: 'Hiroshi Yokoi',
    userId: '@Tanaka_Taro',
    level: 5,
    rank: 'C',
    badge: 'Black',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 13,
    image: MemberUserImg1,
    username: 'Masakazu Tobita',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'C',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 14,
    image: MemberUserImg1,
    username: 'Futian Shuli',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'B',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 15,
    image: MemberUserImg1,
    username: 'Keita Hayakawa',
    userId: '@Tanaka_Taro',
    level: 4,
    rank: 'A',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 16,
    image: MemberUserImg1,
    username: 'Utsunomiya',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'B',
    badge: 'Black',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 17,
    image: MemberUserImg1,
    username: 'Harumi Shida',
    userId: '@Tanaka_Taro',
    level: 1,
    rank: 'A',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 18,
    image: MemberUserImg1,
    username: 'pacific saury Akashiya',
    userId: '@Tanaka_Taro',
    level: 2,
    rank: 'A',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 19,
    image: MemberUserImg1,
    username: 'Kotaro Matsubara',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'A',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
  {
    key: 20,
    image: MemberUserImg1,
    username: 'Masakazu Tobita',
    userId: '@Tanaka_Taro',
    level: 3,
    rank: 'A',
    badge: 'Regular',
    TotalSupportAmount: '100,000,000',
  },
];

export const MemebrPosts = [
  {
    id: 1,
    userImg: MemberUserImg1,
    name: 'Hiroshi Yokoi',
    date: '2021-06-17T16:12:35.329Z',
    tags: ['Support', 'Help people'],
    postTitle: 'About recent facilities',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. I try to mutter half as if I were half-blown, but I always let go of the signal station on the other side. ..',
    like: 10,
    comment: 3,
    postImage: Post1,
  },
  {
    id: 2,
    userImg: MemberUserImg1,
    name: 'Hiroshi Yokoi',
    date: '2021-06-17T16:12:35.329Z',
    tags: ['Support', 'Help people'],
    postTitle: 'About recent facilities',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. I try to mutter half as if I were half-blown, but I always let go of the signal station on the other side. ..',
    like: 10,
    comment: 3,
    postImage: Post2,
  },
  {
    id: 3,
    userImg: MemberUserImg1,
    name: 'Hiroshi Yokoi',
    date: '2021-06-17T16:12:35.329Z',
    tags: ['Support', 'Help people'],
    postTitle: 'About recent facilities',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. I try to mutter half as if I were half-blown, but I always let go of the signal station on the other side. ..',
    like: 10,
    comment: 3,
    postImage: Post3,
  },
  {
    id: 4,
    userImg: MemberUserImg1,
    name: 'Hiroshi Yokoi',
    date: '2021-06-17T16:12:35.329Z',
    tags: ['Support', 'Help people'],
    postTitle: 'About recent facilities',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. I try to mutter half as if I were half-blown, but I always let go of the signal station on the other side. ..',
    like: 10,
    comment: 3,
    postImage: Post4,
  },
  {
    id: 5,
    userImg: MemberUserImg1,
    name: 'Hiroshi Yokoi',
    date: '2021-06-17T16:12:35.329Z',
    tags: ['Support', 'Help people'],
    postTitle: 'About recent facilities',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. I try to mutter half as if I were half-blown, but I always let go of the signal station on the other side. ..',
    like: 10,
    comment: 3,
    postImage: Post4,
  },
  {
    id: 6,
    userImg: MemberUserImg1,
    name: 'Hiroshi Yokoi',
    date: '2021-06-17T16:12:35.329Z',
    tags: ['Support', 'Help people'],
    postTitle: 'About recent facilities',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. I try to mutter half as if I were half-blown, but I always let go of the signal station on the other side. ..',
    like: 10,
    comment: 3,
    postImage: Post1,
  },
  {
    id: 7,
    userImg: MemberUserImg1,
    name: 'Hiroshi Yokoi',
    date: '2021-06-17T16:12:35.329Z',
    tags: ['Support', 'Help people'],
    postTitle: 'About recent facilities',
    description:
      'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. I try to mutter half as if I were half-blown, but I always let go of the signal station on the other side. ..',
    like: 10,
    comment: 3,
    postImage: Post2,
  },
];

export const cityList = [
  'Tokyo',
  'Kyoto',
  'Osaka',
  'Nagoya',
  'Kobe',
  'Hiroshima',
  'Yokohama',
];

export const InquiryListArray: InquiryType[] = [
  {
    key: 0,
    image: InquiryUserImg1,
    username: 'Hiroshi Yokoi',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 1,
    image: InquiryUserImg1,
    username: 'Kotaro Matsubara',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 2,
    image: InquiryUserImg1,
    username: 'Hiroshi Yokoi',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 3,
    image: InquiryUserImg1,
    username: 'Kotaro Matsubara',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 4,
    image: InquiryUserImg1,
    username: 'Hiroshi Yokoi',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 5,
    image: InquiryUserImg1,
    username: 'Kotaro Matsubara',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 6,
    image: InquiryUserImg1,
    username: 'Hiroshi Yokoi',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 7,
    image: InquiryUserImg1,
    username: 'Futian Shuli',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 8,
    image: InquiryUserImg1,
    username: 'Hiroshi Yokoi',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 9,
    image: InquiryUserImg1,
    username: 'Kotaro Matsubara',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 10,
    image: InquiryUserImg1,
    username: 'Futian Shuli',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 11,
    image: InquiryUserImg1,
    username: 'Hiroshi Yokoi',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 12,
    image: InquiryUserImg1,
    username: 'pacific saury Akashiya',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 13,
    image: InquiryUserImg1,
    username: 'Hiroshi Yokoi',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 14,
    image: InquiryUserImg1,
    username: 'pacific saury Akashiya',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 15,
    image: InquiryUserImg1,
    username: 'Futian Shuli',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 16,
    image: InquiryUserImg1,
    username: 'Hiroshi Yokoi',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 17,
    image: InquiryUserImg1,
    username: 'Futian Shuli',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 18,
    image: InquiryUserImg1,
    username: 'Hiroshi Yokoi',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
  {
    key: 19,
    image: InquiryUserImg1,
    username: 'Futian Shuli',
    title: 'Title Title Title',
    text: 'Text Text Text Text Text Text Text Text Text',
    date: '2021-06-17T16:12:35.329Z',
  },
];

export const InquiryMessage = [
  {
    id: 1,
    heading: 'Proposal of new support facility',
    discretion:
      "It was the last Sunday in May. I woke up with the sound of the bells of a lively city church. The day had already climbed so much that everyone around me was shining. Looking at the clock, it was just 6 o'clock. I immediately wore only a waistcoat and went to see the goat. Then, the straw was dented in the hut, and I couldn't see the white horns or the white skeleton.",
  },
  {
    id: 2,
    heading: 'It was the last Sunday in May',
    discretion:
      "It was the last Sunday in May. I woke up with the sound of the bells of a lively city church. The day had already climbed so much that everyone around me was shining. Looking at the clock, it was just 6 o'clock. I immediately wore only a waistcoat and went to see the goat. Then, the straw was dented in the hut, and I couldn't see the white horns or the white skeleton.",
  },
  {
    id: 3,
    heading: 'I woke up with the sound of the bells',
    discretion:
      "It was the last Sunday in May. I woke up with the sound of the bells of a lively city church. The day had already climbed so much that everyone around me was shining. Looking at the clock, it was just 6 o'clock. I immediately wore only a waistcoat and went to see the goat. Then, the straw was dented in the hut, and I couldn't see the white horns or the white skeleton.",
  },
  {
    id: 4,
    heading: 'The day had already climbed so much ',
    discretion:
      "It was the last Sunday in May. I woke up with the sound of the bells of a lively city church. The day had already climbed so much that everyone around me was shining. Looking at the clock, it was just 6 o'clock. I immediately wore only a waistcoat and went to see the goat. Then, the straw was dented in the hut, and I couldn't see the white horns or the white skeleton.",
  },
];

export const energyChargeList = [
  {
    id: 1,
    energy: 100,
    yan: 100,
  },
  {
    id: 2,
    energy: 1100,
    yan: 1000,
  },
  {
    id: 3,
    energy: 12000,
    yan: 10000,
  },
];

export const paymentMethods = [
  {
    id: 1,
    title: 'Pay by credit card',
  },
  {
    id: 2,
    title: 'Pay with debit card',
  },
  {
    id: 3,
    title: 'Pay by bank transfer',
  },
];

export const planChargeData = [
  {
    title: 'Monthly charge (tax included)',
    regular: '無料',
    gold: '660円',
    platinum: '6,600円',
    black: '66,000 yen',
  },
  {
    title: 'Amount of support (10 facilities per day)',
    regular: '100円',
    gold: '100円',
    platinum: '100円',
    black: '100 yen',
  },
  {
    title: 'Browse Gold TL',
    regular: '無料',
    gold: '無料',
    platinum: '無料',
    black: 'possible',
  },
  {
    title: 'Gold TL Comments',
    regular: '無料',
    gold: '無料',
    platinum: '無料',
    black: 'possible',
  },
  {
    title: 'Gold TL Post',
    regular: '-',
    gold: '無料',
    platinum: '-',
    black: '',
  },
  {
    title: 'Browse Platinum TL',
    regular: '-',
    gold: '無料',
    platinum: 'possible',
    black: '無料',
  },
  {
    title: 'Platinum TL Comments',
    regular: '-',
    gold: 'possible',
    platinum: 'possible',
    black: '無料',
  },
  {
    title: 'Platinum TL Post',
    regular: '-',
    gold: '-',
    platinum: '無料',
    black: '無料',
  },
  {
    title: '支援先に還元',
    regular: '-',
    gold: 'あり',
    platinum: 'あり',
    black: 'あり',
  },

  {
    title: 'オフラインイベント招待',
    regular: '-',
    gold: '-',
    platinum: '参加可能',
    black: '参加可能',
  },

  {
    title: 'その他',
    regular: '-',
    gold: '-',
    platinum: '収入証明書提出',
    black: '収入証明書提出',
  },
];
