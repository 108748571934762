import { FormattedMessage } from 'react-intl';
import { Row, Col, Button, Divider, Statistic } from 'antd';

import { EnergyChargePlanType } from './Payment';
import energy_symbol from '../../assets/images/energy_symbol.png';
import paymentModelImg from '../../assets/images/paymentModel.png';

import styles from './index.module.scss';

interface PaymentCompletedProps {
  buyBoost: number;
  closeModal: Function;
  selectedPlan: EnergyChargePlanType;
}

export const PaymentCompleted: React.FC<PaymentCompletedProps> = ({
  buyBoost,
  closeModal,
  selectedPlan,
}) => {
  return (
    <div className={styles.confirmModelMain}>
      <Row>
        <Col span={24}>
          <div className={styles.body}>
            <img src={paymentModelImg} alt="payment_modal_image" width={88} />
            <div className={styles.bodyYan}>
              <div className={styles.bodyYanIcon}>
                <img src={energy_symbol} alt="energy_symbol" width={15} />
              </div>
              <Statistic
                value={selectedPlan.energy * buyBoost}
                valueStyle={{
                  color: '#142d24',
                  fontSize: '1.7em',
                  fontFamily: 'Times New Roman',
                }}
              />
            </div>
            <div className={styles.label}>
              <FormattedMessage
                id="payment.successMessage"
                values={{ br: <br /> }}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Divider />
      <Row justify="center">
        <Col>
          <Button onClick={() => closeModal()}>Close</Button>
        </Col>
      </Row>
    </div>
  );
};
