import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ThanksMessageResponse } from '../../services/apiService';

export interface ThanksMessageData {
  thanksMessages: ThanksMessageResponse[];
}

const initialState: ThanksMessageData = {
  thanksMessages: [],
};

export const thanksMessageSlice = createSlice({
  name: 'thanks message',
  initialState,
  reducers: {
    setThanksMessageList: (
      state,
      action: PayloadAction<{ thanksMessages: ThanksMessageResponse[] }>
    ) => {
      state.thanksMessages = action.payload.thanksMessages;
    },
  },
});

export const { setThanksMessageList } = thanksMessageSlice.actions;

export default thanksMessageSlice.reducer;
