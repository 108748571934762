import { EnergyChargePlanType } from './../../stories/Payment/Payment';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { notification } from 'antd';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Status } from '../../stories/Payment/Payment';

const BASE_URL = process.env.REACT_APP_BASE_URL;
export const donation = createAsyncThunk(
  'energy/donation',
  (energyData: any) => {
    return axios
      .post(`${BASE_URL}/donations`, energyData)
      .then((resp: any) => {
        const intl = useIntl();
        notification.success({
          message: intl.formatMessage({ id: 'notification.Register' }),
          placement: 'topRight',
          duration: 5,
        });
        return resp.data;
      })
      .catch((error) => {
        return { errors: error.response.data.message };
      });
  }
);

export const energyChargeSlice = createSlice({
  name: 'energy',
  initialState: {
    isComplete: {},
    errors: {},
    loading: false,
    authLoading: true,
    saveLoading: false,
    authenticated: false,
    status: 'pending',
    isModal: false,
    selectedPlan: null,
  },
  reducers: {
    setStatus: (state, action: PayloadAction<{ status: Status }>) => {
      return { ...state, status: action.payload.status };
    },
    setModal: (state, action: PayloadAction<{ isModal: boolean }>) => {
      return { ...state, isModal: action.payload.isModal };
    },
    setPlan: (
      state,
      action: PayloadAction<{
        selectedPlan: EnergyChargePlanType | null | any;
      }>
    ) => {
      return { ...state, selectedPlan: action.payload.selectedPlan };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(donation.pending, (state, action) => {
        state.isComplete = {};
        state.errors = {};
        state.saveLoading = true;
      })
      .addCase(donation.fulfilled, (state, action: any) => {
        state.saveLoading = false;
        state.isComplete = action.payload;
      })
      .addCase(donation.rejected, (state, action: any) => {
        state.saveLoading = false;
        state.isComplete = { error: {} };
      });
  },
});

export const { setStatus, setModal, setPlan } = energyChargeSlice.actions;
export default energyChargeSlice.reducer;
