/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { SorterResult } from 'antd/lib/table/interface';
import { Form, Table, Popover, Space, Typography, Modal } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';

import {
  FacilityResponse,
  useGetFacilitiesQuery,
  useDeletePostMutation,
} from '../../../../services/apiService';
import { routes } from '../../../../constant';
import { Input } from '../../../../stories/Input/Input';
import { Button } from '../../../../stories/Button/Button';
import { getAccessToken } from '../../../../getAccessToken';

import styles from './index.module.scss';

const { Title } = Typography;

export interface Facility {
  adminID: number | null;
  area: string;
  children: number;
  coverImage: object;
  id: number;
  mission: string;
  name: string;
  representativeImage: object;
  representativeName: string;
  representativeProfile: string;
  story: string;
  storyImage: object;
  streetAddress: string;
}

export interface getFacilityData {
  page: number;
  limit: number;
  orderField: string;
  orderDirection: string;
}

const FacilityList = () => {
  const [paginateInfo, setPaginateInfo] = useState({
    page: 1,
    limit: 10,
    search: '',
    orderDirection: 'ASC',
    orderField: 'createdAt',
  });

  const history = useHistory();
  const [deletePost, { isSuccess }] = useDeletePostMutation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [token, setToken] = useState<string | undefined>('');
  const [deleteId, setDeleteId] = useState<number>(0);
  const { isLoading, data: facilities } = useGetFacilitiesQuery(paginateInfo);

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    return `${BASE_URL}/images/${imgId}?token=${token}`;
  };
  useEffect(() => {
    if (isSuccess) {
      setDeleteId(0);
    }
  }, [isSuccess]);

  const handleTableChange = (current: any, field: any, orderBy: any) => {
    setPaginateInfo({
      ...paginateInfo,
      page: current,
      orderField: field || 'createdAt',
      orderDirection: orderBy === 'ascend' ? 'ASC' : 'DESC',
    });
  };

  const handleSearch = (search: string) => {
    setPaginateInfo({
      ...paginateInfo,
      search,
    });
  };

  const addNewFacility = () => {
    history.push(routes.NewFacilityUrl);
  };

  const handleFacilityDetails = (id: number | undefined) => {
    history.push(`/facility/${id}/support-status`);
  };
  const handleChangeBasicInfo = (id: number | undefined) => {
    history.push(`/facility/${id}/basic-information`);
  };
  const columns = [
    {
      title: 'Name of facility',
      dataIndex: 'name',
      render: (text: string, record: any) => {
        return (
          <div
            className={styles.tableColumn}
            onClick={() => handleFacilityDetails(record?.id)}
          >
            <div className={styles.title}>
              <img src={getUrl(record?.coverImage?.id)} alt="" />
              <p>{text}</p>
            </div>
          </div>
        );
      },
      width: 610,
    },
    {
      title: 'Number of children',
      dataIndex: 'children',
      sorter: (a: any, b: any) => a.children - b.children,
      render: (text: string) => (
        <div className={styles.tableColumn}>
          <p>{text} 人</p>
        </div>
      ),
      width: 160,
    },
    {
      title: 'Number of supporters',
      dataIndex: 'children',
      render: (text: string) => (
        <div className={styles.tableColumn}>
          <p>100 人</p>
        </div>
      ),
      width: 160,
    },
    {
      title: 'Support amount',
      dataIndex: 'children',
      render: (text: string) => (
        <div className={styles.tableColumn}>
          <p>100 円</p>
        </div>
      ),
      width: 160,
    },
    {
      title: 'Action',
      key: 'action',
      render: (record: any) => (
        <Popover
          content={
            <div>
              <div
                className={styles.popoverButton}
                onClick={() => Modal.confirm(config)}
              >
                Delete
              </div>
              <div
                className={styles.popoverButton}
                key={record?.id}
                onClick={() => {
                  handleChangeBasicInfo(record?.id);
                }}
              >
                Change basic information
              </div>
            </div>
          }
          trigger="click"
        >
          <EllipsisOutlined
            onClick={() => {
              setDeleteId(parseInt(record?.id));
            }}
          />
        </Popover>
      ),
    },
  ];
  const config = {
    onOk: () => {
      if (deleteId !== 0) {
        deletePost(deleteId);
      }
    },
    title: 'Are you sure!',
    content: <div>Are you sure you want to delete?</div>,
  };

  return (
    <div>
      <div className={styles.tableHeadingMain}>
        <Title level={2}>
          <FormattedMessage id="facilityList.facility" />
        </Title>
        <div className={styles.tableNewButton}>
          <Button
            type="primary"
            onClick={addNewFacility}
            icon={<PlusOutlined />}
            label="New facility"
          />
        </div>
      </div>
      <div className={styles.facilityTable}>
        <Space style={{ marginLeft: 15, overflow: 'hidden' }} align={'end'}>
          <Form>
            <Input
              name="search"
              type="search"
              placeholder={'Search'}
              onSearch={handleSearch}
            />
          </Form>
        </Space>
        <Table
          columns={columns}
          loading={isLoading}
          dataSource={facilities?.data}
          rowKey={(record) => record?.id}
          childrenColumnName="antdChildren"
          onChange={(pagination, filters, sorter) => {
            const { field, order } = sorter as SorterResult<FacilityResponse>;
            handleTableChange(pagination.current, field, order);
          }}
          pagination={{
            total: facilities?.totalCount,
          }}
        />
      </div>
    </div>
  );
};

export default FacilityList;
