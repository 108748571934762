import moment from 'moment';
import Avatar from 'antd/lib/avatar/avatar';
import { FormattedMessage } from 'react-intl';
import { Card, Button, List } from 'antd';

import { isDeviceMobile } from '../../utils';
import { dummyUserProfile } from '../../constant';
import { Square } from '../../stories/Icons/Square/Square';
import { InvitationType } from '../../services/apiService';
import { CancelInvitationButton } from './CancelInvitationButton';

import styles from './index.module.scss';
interface InvitedUserListProps {
  sentInvitation?: InvitationType[] | undefined;
}

export const InvitedUserList = ({ sentInvitation }: InvitedUserListProps) => {
  return (
    <>
      <Card
        className={styles.card}
        title={<FormattedMessage id="inviteUser.invitationList" />}
      >
        <List
          locale={{ emptyText: 'No Invitations sent!' }}
          itemLayout="horizontal"
          dataSource={sentInvitation}
          className={styles.listMainContent}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={dummyUserProfile}
                    size={isDeviceMobile() ? 40 : 56}
                    shape={'circle'}
                  />
                }
                title={
                  <div className={styles.listTitle}>
                    <span>
                      {item.invitedToUser?.fullName ||
                        item.invitedToUser?.phoneNumber}
                    </span>
                    <Square />
                  </div>
                }
                description={
                  <>
                    <span className={styles.listDescription}>
                      {moment(item.createdAt).format('YYYY/MM/DD hh:mm')}
                    </span>
                  </>
                }
              />

              {item.status !== 'Pending' && (
                <div className={styles.invitationBtn}>
                  <Button
                    shape="round"
                    type="default"
                    // onClick={() => handleInviteModal()}
                  >
                    Nominated
                  </Button>
                </div>
              )}

              {item.status === 'Pending' && (
                <div className={styles.invitationBtn}>
                  <CancelInvitationButton invitation={item} />
                </div>
              )}
            </List.Item>
          )}
        />
      </Card>
    </>
  );
};
