import { Avatar, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { getUrl } from '../../utils';
import { SowSeeds } from '../Icons/SowSeeds/SowSeeds';
import { getAccessToken } from '../../getAccessToken';
import { SupportType } from '../../services/apiService';
import { GiveWater } from '../Icons/GiveWater/GiveWater';
import { PourLight } from '../Icons/PourLight/PourLight';

import styles from './index.module.scss';

const { Text } = Typography;
export interface SupporterCommentOption {
  energy: number;
  comment: string;
  supportUnit: {
    type: string;
  };
  user: {
    id: number;
    fullname: string;
    profileImage: { id: number };
  };
}

export const SupporterComments = ({
  comment,
  supportUnit,
  user,
  energy,
}: SupporterCommentOption) => {
  const [token, setToken] = useState<string | undefined>('');

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);
  const SupportTypeIcon = () => {
    switch (supportUnit.type) {
      case SupportType.sowSeeds:
        return <SowSeeds height={30} width={30} fill={'#000000'} />;
      case SupportType.giveWater:
        return <GiveWater height={30} width={30} fill={'#000000'} />;
      default:
        return <PourLight height={30} width={30} fill={'#ffffff'} />;
    }
  };

  return (
    <div className={styles.options}>
      <div
        className={
          supportUnit?.type === 'Sow Seed'
            ? styles.sowSeed
            : supportUnit?.type === 'Give Water'
            ? styles.giveWater
            : styles.pourLight
        }
      >
        <div className={styles.cardDesign}>
          <div className={styles.cardHeader}>
            <div className={styles.cardHeaderAvatar}>
              <Avatar
                size="large"
                src={
                  user.profileImage?.id
                    ? getUrl(user.profileImage.id, token)
                    : 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
                }
              />
              <div className={styles.supporterName}>
                <Text
                  className={
                    supportUnit.type === 'Pour Light'
                      ? styles.whiteText
                      : styles.blackText
                  }
                >
                  {user.fullname}
                </Text>
                <Text
                  className={
                    supportUnit.type === 'Pour Light'
                      ? styles.whiteText
                      : styles.blackText
                  }
                >
                  {energy + ' '}
                  <FormattedMessage id="energyChargePlan.energy" />
                </Text>
              </div>
            </div>
            <div className={styles.donateTypeIcon}>
              <SupportTypeIcon />
            </div>
          </div>

          <div className={styles.supporterComments}>
            <Text
              className={
                supportUnit.type === 'Pour Light'
                  ? styles.whiteText
                  : styles.blackText
              }
            >
              {comment}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};
