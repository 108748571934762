function Facility() {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9V3L9 0L6 3V5H0V19H18V9H12ZM4 17H2V15H4V17ZM4 13H2V11H4V13ZM4 9H2V7H4V9ZM10 17H8V15H10V17ZM10 13H8V11H10V13ZM10 9H8V7H10V9ZM10 5H8V3H10V5ZM16 17H14V15H16V17ZM16 13H14V11H16V13Z"
        fill="white"
      />
    </svg>
  );
}

export default Facility;
