import { Spin } from 'antd';
import styles from './index.module.scss';

export const Loader: React.FC = () => {
  return (
    <div className={styles.spinnerContent}>
      <Spin />
    </div>
  );
};
