import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MemberType {
  key: number;
  rank: string;
  badge: string;
  level: number;
  image: string;
  userId: string;
  username: string;
  TotalSupportAmount: string;
}

export interface MemberList {
  membersData: MemberType[];
}

const initialState: MemberList = {
  membersData: [],
};

export const memberListSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    setMemberList: (
      state,
      action: PayloadAction<{ membersData: MemberType[] }>
    ) => {
      state.membersData = action.payload.membersData;
    },
  },
});

export const { setMemberList } = memberListSlice.actions;

export default memberListSlice.reducer;
