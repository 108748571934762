/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './index.module.scss';
import { RootState } from '../../../../store';
import { getAccessToken } from '../../../../getAccessToken';
import { Square } from '../../../../stories/Icons/Square/Square';
import { FollowerCard } from '../../../../stories/FollowerCard/FollowerCard';

export interface FollowerProps {
  open: boolean;
  handleCancel: () => void;
}

export function FollowerModal({ open, handleCancel }: FollowerProps) {
  const dataSource = [...Array(10)].map((_, i) => {
    return {
      ranking: i + 1,
    };
  });
  const me = useSelector((state: RootState) => state.me);
  const [token, setToken] = useState<string | undefined>('');

  const onClose = () => {
    handleCancel();
  };

  const getUrl = (imgId: number) => {
    if (!imgId) {
      return '';
    }
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    return `${BASE_URL}/images/${imgId}?token=${token}&time=${new Date().getTime()}`;
  };

  useEffect(() => {
    getAccessToken().then((t) => setToken(t));
  }, []);

  return (
    <Modal
      centered
      footer={null}
      visible={open}
      onCancel={onClose}
      title={<FormattedMessage id="donorFacilityDetail.follower" />}
      className={styles.modalMainContent}
    >
      {dataSource?.map((item, index) => (
        <FollowerCard key={index} />
      ))}
    </Modal>
  );
}
