import { Card, Image, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { FormattedMessage } from 'react-intl';

import { getUrl } from '../../utils';
import { FacilityResponse } from '../../services/apiService';

import styles from './index.module.scss';

export interface DonorFacilityOtherInfoCardProps
  extends Partial<
    Omit<
      FacilityResponse,
      'id' | 'name' | 'area' | 'adminId' | 'children' | 'streetAddress'
    >
  > {
  token?: string;
}

export const DonorFacilityOtherInfoCard = ({
  token,
  story,
  mission,
  storyImage,
  missionImage,
  representativeName,
  representativeProfile,
  representativeImage,
}: DonorFacilityOtherInfoCardProps) => {
  return (
    <Card
      bodyStyle={{ padding: 24 }}
      className={styles.facilityOtherInformation}
    >
      <Space direction="vertical" size="large">
        <div>
          <p className={styles.description}>
            <FormattedMessage id="donorFacilityOtherInfoCard.mission" />
          </p>
          <Image
            placeholder
            width={400}
            height={200}
            src={getUrl(missionImage?.id, token)}
          />
          <p>{mission}</p>
        </div>
        <div>
          <p className={styles.description}>
            <FormattedMessage id="donorFacilityOtherInfoCard.story" />
          </p>
          <Image width={400} height={200} src={getUrl(storyImage?.id, token)} />
          <p>{story}</p>
        </div>
        <div>
          <p className={styles.description}>
            <FormattedMessage id="donorFacilityOtherInfoCard.representativeName" />
          </p>
          <div>
            <Space size="large" align="baseline">
              <Avatar
                size={64}
                src={<Image src={getUrl(representativeImage?.id, token)} />}
              />
              <p className={styles.representativeName}>{representativeName}</p>
            </Space>
          </div>
          <p>{representativeProfile}</p>
        </div>
      </Space>
    </Card>
  );
};
