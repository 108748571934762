import { Space, Statistic } from 'antd';

import { EnergySymbol } from '../Icons/EnergySymbol/EnergySymbol';

import styles from './index.module.scss';

export interface EnergyValueProps {
  energy: number;
}

export const EnergyValue: React.FC<EnergyValueProps> = ({ energy }) => {
  return (
    <Space direction="horizontal">
      <span className={styles.energySymbol}>
        <EnergySymbol />
      </span>
      <Statistic
        value={energy}
        valueStyle={{
          color: '#142d24',
          fontSize: '1.7em',
          fontFamily: 'Times New Roman',
        }}
      />
    </Space>
  );
};
