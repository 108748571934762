import moment from 'moment';
import { Divider, Image, Typography } from 'antd';

import { getUrl } from '../../utils';

import styles from './index.module.scss';

export interface MobileActivityReportTabProps {
  token?: string;
  coverImage: { id: number };
  createdAt: string;
  title: string;
  description: string;
}
const { Title } = Typography;
export function MobileActivityReportTab({
  token,
  coverImage,
  createdAt,
  title,
  description,
}: MobileActivityReportTabProps) {
  return (
    <div>
      <div className={styles.missionContent}>
        <p>{moment(createdAt).format('YYYY/MM/DD hh:mm')}</p>
        <Title level={3}>{title}</Title>
        <Image
          placeholder
          width="100%"
          src={
            coverImage.id
              ? getUrl(coverImage!.id, token)
              : 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
          }
        />
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </div>
      <Divider />
    </div>
  );
}
