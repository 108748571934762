import {
  Row,
  Col,
  Tag,
  Divider,
  Typography,
  Card,
  Popover,
  Pagination,
} from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EllipsisOutlined, HeartFilled, TagOutlined } from '@ant-design/icons';

import Post1 from '../../../../../assets/images/post/post1.png';
import MemberUserImg1 from '../../../../../assets/images/usrImg.png';
import CommentIcon from '../../../../../stories/Icons/CommentIcon/CommentIcon';

import styles from './index.module.scss';

const { Title } = Typography;

const content = (
  <div className="mr10">
    <p>
      <FormattedMessage id="post.request" />
    </p>
    <p>
      <FormattedMessage id="post.forced" />
    </p>
  </div>
);

const Post = () => {
  const MemberPosts = [...Array(10)].map((_, i) => {
    return {
      id: 1,
      userImg: MemberUserImg1,
      name: 'Hiroshi Yokoi',
      date: '2021-06-17T16:12:35.329Z',
      tags: ['Support', 'Help people'],
      postTitle: 'About recent facilities',
      description:
        'At that time, I was working at the Museum Bureau in the city of Molio. Since I was an 18th officer, I was a lot lower in the office, and the salary was very small, but I was born and liked to collect and organize specimens. I try to mutter half as if I were half-blown, but I always let go of the signal station on the other side. ..',
      like: 10,
      comment: 3,
      postImage: Post1,
    };
  });

  const [offset, setOffset] = useState(0);

  const numEachPage = 5;

  const handlePagination = (value: number) => {
    setOffset((value - 1) * numEachPage);
  };

  return (
    <Row>
      <Col className={styles.posts}>
        {MemberPosts.slice(offset, offset + numEachPage).map(
          (value: any, index: number) => {
            return (
              <div className={styles.view} key={index}>
                <Card key={index}>
                  <Row>
                    <Col span={22}>
                      <div className={styles.header}>
                        <img
                          className={styles.usrImg}
                          src={value.userImg}
                          alt={value.name}
                          width={40}
                          height={40}
                        />
                        <div className={styles.username}>
                          <div>{value.name}</div>
                          <p>{moment(value.date).format('YYYY/MM/DD hh:mm')}</p>
                        </div>
                      </div>
                    </Col>
                    <Col span={2} className={styles.menuIcon}>
                      <Popover content={content} trigger="click">
                        <EllipsisOutlined />
                      </Popover>
                    </Col>
                  </Row>
                  <Col span={24} className={styles.tag}>
                    {value.tags &&
                      value.tags.map((d: any, i: number) => {
                        return (
                          <Tag
                            className={styles.tagInner}
                            icon={<TagOutlined />}
                            key={i}
                          >
                            {d}
                          </Tag>
                        );
                      })}
                  </Col>
                  <Row>
                    <Col span={19}>
                      <Title level={3}>{value.postTitle}</Title>
                      <div className={styles.description}>
                        {value.description}
                      </div>
                    </Col>
                    <Col span={5}>
                      <img src={value.postImage} alt="content" width="100%" />
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <div className={styles.flexDiv}>
                      <div className={styles.mr3}>
                        <HeartFilled className={styles.redLike} />
                      </div>
                      <div className={styles.likeTitle}>{value.like}</div>
                    </div>
                    <div className={styles.flexDiv}>
                      <div className={styles.mr3}>
                        <CommentIcon />
                      </div>
                      <div className={styles.likeTitle}>{value.comment}</div>
                    </div>
                  </Row>
                </Card>
              </div>
            );
          }
        )}
        <div className={styles.pagination}>
          <Pagination
            defaultCurrent={1}
            defaultPageSize={numEachPage}
            onChange={handlePagination}
            total={MemberPosts.length}
          />
        </div>
      </Col>
    </Row>
  );
};

export default Post;
