function GreenUpArrow() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#4EB590" fillOpacity="0.08" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.29031 17.8062C7.90323 18.1936 7.90323 18.8218 8.29031 19.2093C8.6774 19.5968 9.30499 19.5968 9.69207 19.2093L16 12.8953L22.308 19.2094C22.6951 19.5969 23.3227 19.5969 23.7097 19.2094C24.0968 18.8219 24.0968 18.1937 23.7097 17.8063L16.7059 10.7956C16.7042 10.7939 16.7025 10.7922 16.7009 10.7906C16.3138 10.4031 15.6862 10.4031 15.2991 10.7906L8.29031 17.8062Z"
        fill="#4EB590"
      />
    </svg>
  );
}

export default GreenUpArrow;
