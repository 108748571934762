import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import { useGetEnergyTransactionsQuery } from '../../services/apiService';
import { SupportHistoryList } from '../../stories/SupportHistoryList/SupportHistoryList';

const SupportHistory = () => {
  const { energy } = useSelector((state: RootState) => state.me);

  const { data: energyTransaction } = useGetEnergyTransactionsQuery({
    page: 1,
    limit: 10,
    orderDirection: 'ASC',
    orderField: 'createdAt',
  });

  return <SupportHistoryList data={energyTransaction?.data} energy={energy} />;
};
export default SupportHistory;
