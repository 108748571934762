import {
  Row,
  Col,
  Avatar,
  Dropdown,
  Menu as AntdMenu,
  Layout as AntdLayout,
} from 'antd';
import { Link } from 'react-router-dom';

import { Button } from '../Button/Button';
import { UserRole } from '../../services/apiService';
import { AstraAdminHeader } from './AstraAdminHeader';
import { FacilityAdminHeader } from './FacilityAdminHeader';
import { AccountSetting } from '../Icons/AccountSetting/AccountSetting';

import styles from './index.module.scss';

const { Header } = AntdLayout;

export interface HeaderProps {
  name: string;
  email: string;
  logout: () => void;
  role: Omit<UserRole, 'Donor'>;
  facilityId: string | undefined;
}

export const AdminHeader: React.FC<HeaderProps> = ({
  role,
  name,
  email,
  logout,
  facilityId,
}) => {
  const menu = (
    <AntdMenu className={styles.settingDropdown}>
      <AntdMenu.Item key="0">
        <div className={styles.username}>{name}</div>
        <div className={styles.email}>{email}</div>
      </AntdMenu.Item>
      <AntdMenu.Divider />
      <AntdMenu.Item key="1">
        <div className={styles.accountSettings}>
          <AccountSetting />
          <span className={styles.title}>Account Settings</span>
        </div>
      </AntdMenu.Item>
      <AntdMenu.Item key="2">
        <div className={styles.accountSettings}>
          <AccountSetting />
          <span className={styles.title}>
            <Link to="/invite-user">Invite User</Link>
          </span>
        </div>
      </AntdMenu.Item>
      <AntdMenu.Item key="3">
        <Button label="Log out" type="ghost" onClick={logout} />
      </AntdMenu.Item>
    </AntdMenu>
  );

  return (
    <Header>
      <Row justify="center" className={styles.adminHeaderRow}>
        <Col span={2}>
          <div className={styles.adminLogo}>Astra</div>
        </Col>
        <Col span={12}>
          <AntdMenu
            multiple={true}
            theme="dark"
            mode="horizontal"
            defaultSelectedKeys={['1']}
          >
            {role === 'AstraAdmin' && <AstraAdminHeader />}
            {role === 'FacilityAdmin' && (
              <FacilityAdminHeader facilityId={facilityId!} />
            )}
          </AntdMenu>
        </Col>
        <Col span={3} className={styles.profileMain}>
          <Dropdown overlay={menu} trigger={['click']}>
            <Avatar className={styles.headerProfile} size={50}>
              A
            </Avatar>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};
