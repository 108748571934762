function RedDownArrow() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#F44336" fillOpacity="0.08" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7097 14.1938C24.0968 13.8064 24.0968 13.1782 23.7097 12.7907C23.3226 12.4032 22.695 12.4032 22.3079 12.7907L16 19.1047L9.69201 12.7906C9.30493 12.4031 8.67734 12.4031 8.29025 12.7906C7.90317 13.1781 7.90317 13.8063 8.29025 14.1937L15.2941 21.2044C15.2958 21.2061 15.2975 21.2078 15.2991 21.2094C15.6862 21.5969 16.3138 21.5969 16.7009 21.2094L23.7097 14.1938Z"
        fill="#F44336"
      />
    </svg>
  );
}

export default RedDownArrow;
