/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import { Breadcrumb, notification, Row, Spin } from 'antd';

import { ActivityReportForm } from '../ActivityReportForm/ActivityReportForm';
import {
  ActivityReportType,
  useEditActivityReportMutation,
  useGetActivityReportByIdQuery,
} from '../../../../../services/apiService';

const EditActivityReport = (props: any) => {
  const intl = useIntl();
  const history = useHistory();
  let { id } = useParams<{ id?: string }>();
  const { data: activityReport, isFetching } = useGetActivityReportByIdQuery(
    id!
  );
  const [updateActivityReport, { isSuccess, isError, isLoading }] =
    useEditActivityReportMutation();

  const onFinish = async (formData: ActivityReportType) => {
    await updateActivityReport({ id: id!, body: formData });
  };

  useEffect(() => {
    if (isSuccess) {
      history.goBack();
      notification.success({
        message: intl.formatMessage({
          id: 'notification.ActivityReportUpdate',
        }),
        placement: 'topRight',
        duration: 5,
      });
    }
    if (isError) {
      notification.success({
        message: intl.formatMessage({ id: 'notification.FailedToUpdate' }),
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [history, isError, isSuccess]);

  if (isFetching) {
    return (
      <Row justify="center">
        <div className="space-align-container">
          <Spin size="large" />
        </div>
      </Row>
    );
  }

  return (
    <>
      <>
        <Breadcrumb>
          <Breadcrumb.Item>Activity report</Breadcrumb.Item>
          <Breadcrumb.Item>Creating a new activity report</Breadcrumb.Item>
        </Breadcrumb>
      </>
      <Title level={3}>Edit Activity Report</Title>
      <ActivityReportForm
        onFinish={onFinish}
        actionType={'update'}
        isLoading={isLoading}
        activityReport={activityReport}
      />
    </>
  );
};

export default EditActivityReport;
